import dynamic from "next/dynamic";
import CmsForm from "./CmsForm";

const dynamicComponents = {
  // slideshow: dynamic(() => import("./CmsSlideshow")),
  text: dynamic(() => import("./CmsText")),
  image: dynamic(() => import("./CmsImage")),
  card: dynamic(() => import("./CmsCard")),
  cardGroup: dynamic(() => import("./CmsCardGroup")),
  swiper: dynamic(() => import("./CmsSwiper")),
  swiperIntro: dynamic(() => import("./CmsSwiperIntro")),
  introImage: dynamic(() => import("./CmsIntroImage")),
  video: dynamic(() => import("./CmsVideo")),
  attachment: dynamic(() => import("./CmsAttachment")),
  link: dynamic(() => import("./CmsButton")),
  map: dynamic(() => import("./CmsMap")),
  collection: dynamic(() => import("./CmsCollection")),
  item: dynamic(() => import("./CmsCollectionItem")),
};

function CmsRow({ row, page_id = "" }) {
  return (
    <section id={`${row.id ?? ""}`}>
      <div className={row.full ? "container-xxl mx-auto" : `container mx-auto`}>
        <div
          className={
            row.columns != "full" && row.columns > 1
              ? `griglia grid-cols-${row.columns} gap-8`
              : ""
          }
        >
          {row.components.map(function (component, i) {
            component.name = component.name == "card-group" ? "cardGroup" : component.name;
            component.name = component.id == "image-intro" ? "introImage" : component.name;
            component.name = component.id == "mappa" ? "map" : component.name;
            component.name = component.name == 'collection' && component.options.isDetail ? 'item' : component.name;

            const Component = dynamicComponents[component.name];

            component.options.columns =
              typeof component.options.columns != "undefined"
                ? component.options.columns
                : 1;

            if (typeof Component != "undefined") {

                return (
                <div
                    className={`col-span-${component.options.columns}`}
                    key={i}
                  >
                    <Component component={component} page_id={page_id} />
                  </div>
                )

            } else {
              
              if(component.id == "contact-form") {

                return (
                  <CmsForm component={component} page_id={page_id} key={i} />
                );

              } else {
                return (
                  <div key={i}></div>
                )
              }
            }
          })}
        </div>
      </div>
    </section>
  );
}

export default CmsRow;
