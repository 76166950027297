import Link from "next/link";
import React from "react";

function Cervello({ component, page_id = "" }) {
  return (
    <>
      <div className={`text`} data-aos="fade-right">
        <div dangerouslySetInnerHTML={{ __html: component.options.content }} />
      </div>
    </>
  );
}

export default Cervello;
