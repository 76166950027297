import React, { useEffect, useRef, useState } from "react";
import { NavLinkMobile } from "./NavLinkMobile";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import Link from "next/link";

function CmsMenuDropdownMobile({ link }) {
  let [isOpen, setIsOpen] = useState(false);

  let button = useRef(null);

  useEffect(() => {}, []);

  return (
    <div className="relative">
      <div className="flex items-start">
        <div className="dropdown-toggle" onClick={() => {
            setIsOpen(!isOpen)
        }}>
          {!isOpen ? (
            <ChevronDownIcon className="dropdown-icon-down" />
          ) : (
            <ChevronUpIcon className="dropdown-icon-up" />
          )}
        </div>
        <Link href={link.path != "" ? link.path : "#"}>
          <a
            ref={button}
            onClick={() => {
              document.body.classList.remove("mobileMenuOpened");
              document
                .querySelector("button.burger")
                .classList.remove("menu_open");
              document
                .querySelector("body, html")
                .classList.remove("no-scroll");
            }}
            className={`${isOpen ? "isOpen" : ""} ${
              link.active ? "active" : ""
            }`}
          >
            {link.name}
          </a>
        </Link>
      </div>

      <div className="dropdown-panel">
        <div
          className={`dropdown-panel-inner columns-${link.columns} ${
            isOpen ? "block" : "hidden"
          }`}
        >
          {link.navigation.map(function (link, i) {
            return <NavLinkMobile link={link} key={i} exact />;
          })}
        </div>
      </div>
    </div>
  );
}

export default CmsMenuDropdownMobile;
