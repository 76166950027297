import Link from "next/link";
import CmsMenuDropdownMobile from "./CmsMenuDropdownMobile";

export { NavLinkMobile };

function NavLinkMobile({ link, mobile = false }) {
  if (typeof link != "undefined") {
    if (typeof link.navigation == "undefined" || link.navigation.length == 0) {
      // console.log(link);
      return (
        <div className="flex items-start">
          <div className="dropdown-toggle"></div>
          <Link href={link.path != "" ? link.path : "#"}>
            <a
              className={`${link.active ? "active" : ""}`}
              onClick={() => {
                document.body.classList.remove("mobileMenuOpened");
                document
                  .querySelector("button.burger")
                  .classList.remove("menu_open");
                document
                  .querySelector("body, html")
                  .classList.remove("no-scroll");
              }}
            >
              {link.name}
            </a>
          </Link>
        </div>
      );
    } else {
      return <CmsMenuDropdownMobile link={link} mobile={mobile} />;
    }
  } else {
    return <></>;
  }
}
