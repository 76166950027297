import dynamic from "next/dynamic";

import CmsSocial from "./CmsSocial";

import CmsForm from "./CmsForm";
import Cervello from "./Cervello";

const dynamicComponents = {
  text: dynamic(() => import("./CmsText")),
  image: dynamic(() => import("./CmsImage")),
  card: dynamic(() => import("./CmsCard")),
  cardGroup: dynamic(() => import("./CmsCardGroup")),
  swiper: dynamic(() => import("./CmsSwiper")),
  swiperIntro: dynamic(() => import("./CmsSwiperIntro")),
  video: dynamic(() => import("./CmsVideo")),
  attachment: dynamic(() => import("./CmsAttachment")),
  link: dynamic(() => import("./CmsButton")),
};

function CmsRowCervello({ row, page_id = "" }) {
  return (
    <section id={`${row.id ?? ""}`}>
        <div className="cervello-components"> 
          {row.components.map(function (component, i) {
            
            const Component = dynamicComponents[component.name];

            component.options.columns =
              typeof component.options.columns != "undefined"
                ? component.options.columns
                : 1;

            if (typeof Component != "undefined") {
              
              if(component.id == "contact-form") {

                return (
                  <CmsForm component={component} page_id={page_id} key={i} />
                );

              } else if(component.id == "cervello") {

                return (
                  <Cervello component={component} page_id={page_id} key={i} />
                );

              } else {

                return (
                <div
                    className={`col-span-${component.options.columns}`}
                    key={i}
                    data-aos="fade-right"
                    data-aos-delay="300"
                  >
                    <Component component={component} page_id={page_id} />
                  </div>
                )

              }

            } else {
              return (
                <div key={i}>
                  {component.id == "footer-social" ? (
                    <>
                      <h5>Social</h5>
                      <CmsSocial />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              );
            }
          })}
        </div>
      <div id="cervello" data-aos="fade-left">
        <svg id="Livello_1" x="0px" y="0px" viewBox="0 0 1200 1037.1">
          <path
            className="st0"
            d="M814.2,893.9c-19.9-6-39.8-12.1-59.7-18.1c-0.8-0.3-1.7-0.5-2.5-0.8c-0.5,0-1.1,0-1.6-0.1 c-8.9-5.9-17.7-11.7-26.6-17.6c-3.5-3.6-7-7.2-10.5-10.9c11.2-0.7,22.8,0.1,33.4-2.9c3.1-0.9,3.3-12.3,4.8-18.9 c-2.1,0.2-4.5,1.3-6.2,0.6c-10.7-4.5-29.6,12.5-32.4-12.4c-0.3-3.1,0.5-7.4-1.2-9.2c-10.2-11.2-21.1-21.9-31.7-32.7 c-1.4-2.4-2.7-4.8-4.1-7.2c0.1-0.3-0.1-0.5-0.3-0.7c-0.2-0.4-0.3-0.9-0.5-1.3c-0.5-2-0.9-4-1.4-6c18.5-0.1,36.9-0.3,55.4-0.4 c0.1,0.2,0.2,0.5,0.3,0.7c9.6,9.3,19.2,18.6,28.9,27.8c-0.7,9.4-1.5,18.9-2.1,28.4c-0.7,11.3-5.8,27-0.4,33 c7.9,8.8,23.5,10.6,35.8,15.4l0,0c4.3,1.5,8.5,2.9,12.8,4.3c0.8,0.5,1.7,1.1,2.5,1.6c6,1.9,12,3.8,18,5.8c0.5,0.6,1.1,1.1,1.6,1.7 C822.4,880.6,818.3,887.2,814.2,893.9z"
          ></path>
          <path
            className="st1"
            d="M977,866.6c-0.2,0.5-0.4,1-0.6,1.5c-0.4-0.3-0.7-0.5-1.1-0.8c-10.6,6.8-21.3,13.6-31.9,20.4 c-14.5,2.4-29,4.8-43.5,7.3c-1.5-0.1-2.9-0.2-4.4-0.3c-0.6-1.1-1.1-2.3-1.7-3.4c1-1.9,2.1-3.7,2.9-5.6 c15.3-34.8,31.2-68.5,76.5-71.9c24.1-1.8,34.8-20.6,45.5-38.8c0.6-0.2,1.1-0.4,1.7-0.6c0.8-0.1,1.5-0.2,2.3-0.2 c0.2,0,0.4,0.1,0.5,0.1l0.3,0.1c0.5,1.4,1,2.7,1.6,4.1c0.3,0.7,0.6,1.4,1,2.1c-11.4,24-22.9,48.1-34.3,72.2 C986.8,857.3,981.9,861.9,977,866.6z"
          ></path>
          <path
            className="st2"
            d="M119.6,269.6c7.4-6.1,14.8-12.1,22.2-18.2c11.1-10.5,22.2-21,33.3-31.5c8.1-6.5,17.1-12.2,24.1-19.7 c4-4.2,5-11.3,7.3-17.1c13.3-8.8,26.6-17.5,39.9-26.3c18.9-2.7,37.7-5.3,56.6-8c-5.1,4.1-10.2,8.4-15.3,12.4 c-18.4,14.2-36.9,28.3-55.3,42.5c-1.7,2.7-3.3,5.4-5,8.2c-0.2,0.3-0.4,0.5-0.6,0.8c-7,13.5-14,27-21,40.5 c-29.2,6.9-58.3,13.8-87.5,20.6C118.7,272.3,119.1,271,119.6,269.6z"
          ></path>
          <path
            className="st3"
            d="M418.9,73.4c18.3-0.7,36.5-1.4,54.8-2.1c6.2-0.5,12.3-1,18.5-1.6c5.7-2.8,11.4-5.6,17.1-8.5 c3.1-0.2,6.2-0.3,9.4-0.5c3.2,0,6.4,0,9.6,0c1.6,0,3.2,0,4.9,0c0.9,0,1.8,0,2.7,0c5.3,0,10.7,0,16,0c9.4,0,18.8,0,28.2,0 c17.9,0.8,35.8,1.5,53.8,2.4c3.3,0.2,6.5,1.2,9.7,1.8c-3.1,1-6.1,2.3-9.2,3c-6.2,1.3-12.6,2.1-18.8,3.4c-1.3,0.3-2.3,1.7-3.4,2.6 c0.8,0.8,1.5,1.6,2.3,2.3c0.9,0.6,1.9,1.3,2.9,1.9c11.1,8.4,22.3,16.7,33.4,25.1c0.4,0.1,0.8,0.3,1.1,0.4c1.3,1.7,2.6,3.3,3.9,5 c-1.2,2.2-2.4,4.3-3.6,6.5c-17.8-9.3-35.5-18.6-53.3-27.9c-8.1-6.3-15.4-16.1-24.4-18.1c-16-3.6-33.1-2.5-49.8-3.2 c-1.6-0.1-3.2,0.6-4.8,1c0.7,1.5,1.2,3.1,2.1,4.4c6.6,9.9,13.3,19.7,20,29.6c-6.2-0.2-12.4-0.5-18.6-0.7c-0.2-0.2-0.5-0.4-0.7-0.7 c-18.2-5.3-36.3-10.6-54.5-15.9c-13.5,1-26.9,2.1-40.4,3.1c-0.9-0.1-1.8-0.3-2.7-0.4C423,82,420.9,77.7,418.9,73.4z"
          ></path>
          <path
            className="st4"
            d="M1054.8,320.5c8.6,4.7,6.5,30.8,26.3,13.3c6.2,8.6,13.8,16.4,18.3,25.8c11.4,24,20.8,48.9,32.3,72.9 c3.9,8.1,14.6,13.5,17.1,21.7c7.8,25.7,16.6,51.8,18.8,78.3c3,36.6,0.4,73.7,0,110.7c0,1.9-1.5,3.8-2.2,5.6 c-0.3-30.9-0.3-61.8-1.2-92.7c-0.6-20.3-2.4-40.5-3.7-60.7c-0.3-1.6-0.7-3.2-1-4.9c-0.4-2.2-0.9-4.3-1.3-6.5c-0.1-0.7-0.2-1.3-0.4-2 c-0.6-0.8-1.3-1.7-1.9-2.5c-1-1.5-2-3-3-4.5c-4.4-9.1-8.8-18.1-13.3-27.2c-0.1-0.3-0.1-0.6-0.2-0.9c-1.4-2.4-2.9-4.7-4.3-7.1 c-3.5-5.4-7-10.8-10.5-16.1c-0.8-2.1-1.4-4.3-2.4-6.2c-8.1-15.5-16.3-30.9-24.5-46.4c-0.5-0.6-1-1.1-1.4-1.7 c-0.4-0.4-0.7-0.8-1.1-1.2c-1.6-1.5-3.2-3-4.7-4.5c-0.4-0.5-0.8-1-1.2-1.5c-6.5-6.1-12.9-12.3-19.4-18.4c-2.5,8.1-4.9,16.2-7.4,24.3 c-0.8,1-1.7,2-2.5,3c-0.9-1.2-2-2.3-2.7-3.7c-8.6-16.8-17.1-33.6-25.6-50.5c-3.2-3.6-6.3-7.2-9.5-10.9c-2.2-10.2-4.5-20.4-6.7-30.7 c6.9,6,13.7,12.1,20.6,18.1C1042.3,302.6,1048.5,311.5,1054.8,320.5z"
          ></path>
          <path
            className="st5"
            d="M574.1,771c0.4,0.3,0.8,0.5,1.2,0.8c0.2-0.4,0.6-1.2,0.5-1.2c-0.5-0.4-1.2-0.8-1.8-0.8 c-39.6-0.4-79.2-0.8-118.8-1.1c-0.3-0.5-0.6-1-0.9-1.5c5.1-0.5,10.2-0.9,15.3-1.4c-5.5-0.4-11-0.7-16.5-1.2 c-0.8-0.1-1.6-0.7-2.4-1.1c0.5-0.8,0.9-1.8,1.6-2.2c17.1-8.8,34.3-17.5,51.4-26.4c5.5-2.9,10.9-6.1,16.3-9.2c-5.5,0-11,0.1-16.5,0.1 c-5.6-1.7-11.2-3.4-16.8-5.1c2.9-0.9,5.9-1.7,8.8-2.6c7.9,1.5,15.7,3.1,23.6,4.6c2,0.5,4,1,6,1.4c2.8,4.7,4.9,9.9,8.4,14 c9.5,11.1,19.6,21.8,29.4,32.6C566.7,770.8,570.4,770.9,574.1,771z"
          ></path>
          <path
            className="st0"
            d="M121.2,571.1c-17.5-17.2-35.8-33.6-52.1-51.8c-8.2-9.2-12.7-21.8-18.9-32.8c7-21.7,14-43.4,21-65 c0.9-1,1.7-1.9,2.6-2.9c-1.2,3.9-4.5,8.9-3.2,11.6c7.4,15.6,16.1,30.6,24,46c1.5,2.8,1.8,6.2,2.3,9.4c0.1,0.8-1.7,1.7-2.4,2.7 c-2.3,2.9-7,7.1-6.3,8.4c2,3.7,6.1,6.4,9.6,9.1c8.8,6.7,17.7,13.2,26.5,19.8c-10.7,1.5-21.5,2.9-32.2,4.4c-1.4,0.2-3.9,0.1-4.1,0.6 c-0.4,1.6-0.7,4.3,0.3,5.2c5.2,4.8,10.8,9.2,16.2,13.8c0.3,0.3,0.5,0.6,0.8,0.9c0.9,0.9,1.7,1.7,2.6,2.6c6.8,7,13.7,14.1,20.5,21.1 c-1.6-0.5-3.2-1-4.8-1.5c-0.5-0.4-1-0.7-1.4-1.1C121.8,571.4,121.5,571.2,121.2,571.1z M74.2,511.5c-2,0.2-4,0.5-6.1,0.7 c3.8,7.6,7.6,15.1,11.5,22.6c0.7,1.3,2.3,2,3.5,3c0.6-1.3,1.8-2.7,1.7-4c-2.2-22.7-4.5-45.4-6.9-68.1c-0.1-1-1.4-1.8-2.1-2.7 c-0.5,0.5-1.6,1.1-1.6,1.6C74.2,480.3,74.2,495.9,74.2,511.5z"
          ></path>
          <path
            className="st6"
            d="M756.2,966.5c0.6,3.8,1.1,7.5,1.7,11.3c-1.3,2.4-2.5,4.9-3.8,7.4c-5.1-5.6-10-11.4-15.3-16.8 c-14.4-14.8-29-29.4-43.5-44.1c-0.5-1.2-1.1-2.5-1.6-3.7c-0.3-0.6-0.6-1.2-0.9-1.8c-0.3-0.1-0.4-0.3-0.4-0.5 c9.6-14.9,19.2-29.7,28.9-44.6c0,0,0.2-0.2,0.2-0.2c6.1,15.6,12.1,31.2,18.2,46.8C745.2,935.6,750.7,951.1,756.2,966.5z"
          ></path>
          <path
            className="st3"
            d="M714.8,83.3c21.9-0.8,44.4-4.8,65.7-1.4c18.1,2.9,34.8,14.4,52.1,22.2c-3.4,0.6-6.9,1.3-10.3,1.9 c-1.4-0.1-2.8-0.1-4.2-0.2c-10.8-2-21.6-4-32.4-6c-19.3,1.5-38.7,3-58,4.5c-0.5,0-1.1,0-1.6,0c-18.7-3.4-37.3-6.7-56-10.1 c11.9-3,23.8-6.1,35.8-9.1C708.8,84.5,711.8,83.9,714.8,83.3z M726.9,96.8c1.4,1.9,2,2.7,2.6,3.5c3.7-4.7,7.4-9.4,11.2-14.1 c-2.4-1.2-4.8-2.4-7.2-3.7C731.3,87.3,729.1,92.2,726.9,96.8z"
          ></path>
          <path
            className="st7"
            d="M921,186.8c-0.5,0.3-0.9,0.6-1.4,0.9c-25.8-7.9-51.7-15.9-77.5-23.8c-5.6-1.4-11.2-2.8-16.9-4.2 c-12.4-3-24.8-5.9-37.2-8.8c-0.7-0.5-1.4-1.1-2.2-1.6c11.7-4,23.3-8.2,35.1-11.8c12.9-3.9,26-7.2,39-10.8c-1.8,0.7-3.5,1.6-5.3,2.2 c-2.9,0.9-5.8,1.6-8.7,2.3c2.7,1.5,5.2,3.4,8.1,4.6c6.4,2.7,13,5,19.4,7.4c-1.9,0.3-3.8,0.5-5.7,0.8c-6.1-1.8-12.2-4-18.4-5 c-1.5-0.2-3.6,3.2-5.3,5.1c-1.7,1.8-3.3,3.7-4.9,5.5c2.3,0.6,4.6,1.2,6.9,1.9c0.7,0.2,1.3,0.5,2,0.8c-1,0.7-1.9,1.4-3,2 c-2.3,1.1-4.7,2.1-7,3.1c2.9,1.2,6.1,3.9,8.7,3.4c11-2,21.9-5,32.9-7.6c1,0.2,2,0.5,3,0.7C895.3,164.8,908.2,175.8,921,186.8z"
          ></path>
          <path
            className="st3"
            d="M867.7,144c1.9-0.3,3.8-0.5,5.7-0.8c2.3,0.2,4.6,0.4,6.9,0.6c4.2,0.4,8.4,0.9,12.5,1.3c0.5,0.1,1.1,0.2,1.6,0.2 c0.6,0.2,1.2,0.4,1.8,0.6c1.1,0.3,2.3,0.7,3.4,1c0.2,0.2,0.5,0.4,0.7,0.6c5.9,1.4,11.8,2.8,17.7,4.2c-3.9-2.5-7.7-5-11.6-7.5 c-0.4-0.2-0.8-0.4-1.2-0.5c-10.4-5.5-20.8-10.9-31.1-16.4c-1.4-0.3-2.8-0.7-4.2-1c-0.5-0.4-1-0.9-1.6-1.3c-0.9-2.4-1.7-4.8-2.6-7.2 c23.7,11.2,48,21.1,70.7,34c12.3,7,21.9,18.7,32.4,28.7c2.7,2.6,4.3,6.4,6.4,9.7c-3.8-1.5-7.6-2.9-11.3-4.5 c-5.8-2.5-11.6-5.1-17.4-7.6c-1.7-0.8-3.3-1.7-5-2.5c-0.6-0.2-1.2-0.5-1.9-0.7c-3-0.6-6.1-0.7-8.8-1.8c-16.2-6.2-32.3-12.7-48.4-19 c-1-0.2-2-0.5-3-0.7C875.6,150.1,871.7,147.1,867.7,144z"
          ></path>
          <path
            className="st0"
            d="M503.5,725.8c5.5,0,11-0.1,16.5-0.1c-5.4,3.1-10.8,6.3-16.3,9.2c-17.1,8.9-34.3,17.6-51.4,26.4 c-0.7,0.4-1.1,1.5-1.6,2.2c0.8,0.4,1.6,1.1,2.4,1.1c5.5,0.5,11,0.8,16.5,1.2c-5.1,0.5-10.2,0.9-15.3,1.4c-11.4-1.5-22.8-3-34.2-4.5 c-6-3.4-12-6.7-18-10.1c0,0,0,0,0,0c-0.9-0.6-1.8-1.1-2.6-1.7c0,0,0.1,0,0.1,0c-0.7-0.5-1.3-1-2-1.4c-2.1-5.2-4.2-10.3-6.3-15.5 c10.8,5.2,22,9.7,32.4,15.6c24.9,13.9,25.7,13.6,25.9-14.6c0-1.4,0.7-2.7,1.1-4.1c9-0.9,18.1-1.8,27.1-2.8 C486.4,727.3,495,726.6,503.5,725.8z"
          ></path>
          <path
            className="st4"
            d="M987.4,203.8c1.6,1.2,3.6,2.1,4.8,3.7c10.5,13.7,20.9,27.4,31.1,41.3c16,21.7,31.8,43.6,47.8,65.3 c2.6,3.5,5.9,6.4,8.9,9.5c-2.9,1.1-6,3.5-8.6,3c-2-0.3-3-4.5-5-6.5c-6.3-6.5-12.9-12.8-19.4-19.1c-1.7-1.9-3.4-3.9-5.1-5.8 c-0.2-1.1,0.1-2.6-0.5-3.3c-21.3-27.4-42.8-54.8-64.2-82.1c4.7,2.8,9.4,5.6,14.1,8.3C990,213.3,988.7,208.5,987.4,203.8z"
          ></path>
          <path
            className="st8"
            d="M842.1,163.9c25.8,7.9,51.7,15.9,77.5,23.8c0.1,0.3,0.2,0.6,0.3,0.9c-4.8,6.4-9.5,12.8-14.3,19.2 c-7.6-3.6-15.3-7.1-22.9-10.7c-19.3-5.6-38.6-11.2-57.9-16.9C830.5,174.7,836.3,169.3,842.1,163.9z"
          ></path>
          <path
            className="st0"
            d="M645.7,848.3c-18.2-23.2-36.4-46.3-54.6-69.6c-2.2-2.8-3.8-5.9-5.7-8.9c0,0-0.1,0-0.1,0 c7.1-2.5,14.2-5,21.3-7.6c9.3-1.5,18.6-3.2,28-4.3c1.6-0.2,3.9,1.4,5.1,2.8c0.5,0.6-0.6,3.1-1.5,4.3c-8.9,11.1-18,22-27,33 c-0.6-0.3-1.2-0.5-1.9-0.8c-1.6-8.3-2.8-16.7-4.9-24.8c-0.7-2.8-3.4-7.3-5-7.3c-3.4,0.1-6.7,2.7-10.1,4.3 c6.6,9.3,13.3,18.6,19.9,27.8c1,1.1,2,2.2,3.1,3.3c0,0,0,0,0,0c0.8,1.4,1.6,2.8,2.4,4.2c0,0,0.6-0.2,0.9-0.4c-0.7-1.3-1.3-2.6-2-3.9 c4,3.5,8.1,6.8,11.9,10.5c2.2,2.1,3.8,4.7,5.7,7.1c-0.1-1.7-0.4-3.4-0.2-5.1c0.1-0.4,2.3-1.2,2.6-0.9c7.2,7.4,14.3,14.9,21.5,22.5 C652,839.3,648.9,843.8,645.7,848.3z"
          ></path>
          <path
            className="st9"
            d="M119.6,269.6c-0.5,1.4-0.9,2.8-1.4,4.1c-12.4,25.1-24.9,50.3-37.3,75.4C72.8,371,48.5,388.6,63,416.5 c-1.6,0.1-3.1,0.2-4.7,0.3c0.1-0.4,0.3-1,0.1-1.2c-25.4-38.9,9.7-62.7,23.9-90.9C92.3,305.1,107,287.9,119.6,269.6z"
          ></path>
          <path
            className="st10"
            d="M865.8,117.8c0.9,2.4,1.7,4.8,2.6,7.2c-2.3,0.6-4.7,1.1-7,1.7c-0.5,0-1,0-1.4,0c-13,3.6-26.1,6.8-39,10.8 c-11.8,3.6-23.4,7.9-35.1,11.8c-0.4-0.2-0.9-0.3-1.3-0.5c0.1-0.5,0.2-1,0.3-1.5c4.9-8.7,9.7-17.5,14.6-26.2c7.6-5,15.2-10,22.8-15 c3.4-0.6,6.9-1.3,10.3-1.9C843.7,108.7,854.7,113.3,865.8,117.8z M843.8,118.2c-1.4-3-2.3-5-3.2-7c-6.1,5.1-12.1,10.1-18.2,15.2 c5.4,0.3,11,1.5,16.1,0.4C840.8,126.4,842,121.4,843.8,118.2z"
          ></path>
          <path
            className="st11"
            d="M905.6,207.7c4.8-6.4,9.5-12.8,14.3-19.2c17.7,5.8,35.3,11.7,53,17.5c0.1,0.5,0.3,1,0.4,1.5 c0,0-0.1,0.1-0.1,0.1c-0.1,0.3-0.2,0.6-0.3,0.9c-4,5.8-7.9,11.7-11.9,17.5c-11.7-4.3-23.5-8.6-35.2-13c-2.4-0.6-4.7-1.3-7.1-1.9 C914.3,210,909.9,208.9,905.6,207.7z"
          ></path>
          <path
            className="st12"
            d="M1160.4,495.4c1.3,20.2,3.2,40.5,3.7,60.7c0.9,30.9,0.8,61.8,1.2,92.7c-0.5,3-0.9,6-1.4,9 c-2.9,6.9-5.9,13.9-8.8,20.8c-0.6,1.4-1.2,2.9-1.8,4.3c-0.7,1.4-1.3,2.8-2,4.3c-0.3,0.6-0.6,1.1-0.8,1.7c-0.2,0.5-0.4,1-0.6,1.5 c-0.6,1.5-1.2,3-1.8,4.4c-1.9,3.1-3.9,6.2-5.8,9.2c-18.7,20.3-34.9,44.4-57.1,59.7c-15.8,11-39.8,10.2-60.1,14.7 c-0.5-1.4-1.1-2.7-1.6-4.1c3-0.2,5.9-0.5,8.9-0.7c13.1,10.1,64.3-9.6,68.6-26.5c16.1-21.2,32.3-42.4,48.4-63.5 c4.5-19.5,9-39,13.5-58.5C1162.1,581.8,1161.2,538.6,1160.4,495.4z"
          ></path>
          <path
            className="st9"
            d="M645.7,848.3c3.2-4.5,6.4-9.1,9.5-13.6c0.6,1,1.2,2,1.8,3c5.1,12.5,10,25,15.4,37.4c7.7,17.6,15.7,35,23.6,52.4 c0.2,0.6,0.3,1.2,0.5,1.8c4.5,12.6,9,25.2,13.5,37.8c-19.4-35.9-38.9-71.9-58.3-107.8c-0.5-1.1-1.1-2.3-1.6-3.4 c-0.6-1-1.2-1.9-1.8-2.9c0-0.2-0.1-0.4-0.2-0.5c-0.5-0.7-1-1.4-1.5-2c0,0,0,0,0,0C646.3,849.8,646,849.1,645.7,848.3z"
          ></path>
          <path
            className="st2"
            d="M292.2,130.5c18.1-10.2,36.3-20.5,54.4-30.7c5.3,1.5,10.6,3,16,4.5c-2.8,1.6-5.8,2.8-8.3,4.8 c-15.5,12-31,24.2-46.4,36.4c2.2-3.7,4.4-7.5,6.6-11.2c-2.5,0.3-5.1,0.2-7.4,0.8c-10.1,2.8-20.2,5.7-30.2,8.6 C281.9,139.3,287.1,134.9,292.2,130.5z"
          ></path>
          <path
            className="st11"
            d="M739.6,920.2c-6.1-15.6-12.1-31.2-18.2-46.8c0.8-5.4,1.5-10.8,2.3-16.1c8.9,5.9,17.7,11.7,26.6,17.6 c-0.3,2.6-0.7,5.2-1,7.8c0,0-0.3,0.3-0.3,0.3l0.3,0.2c0.1,0.7,0.3,1.4,0.4,2c2,13.2,4,26.4,5.9,39.7c0.2,1.3-0.3,2.7-0.4,4.1 c-0.5-0.5-1.5-1.1-1.5-1.6C754.7,913.9,747.9,915.6,739.6,920.2z"
          ></path>
          <path
            className="st9"
            d="M71.1,421.4c-7,21.7-14,43.4-21,65c-2.3-4.8-5.5-9.4-6.8-14.4c-2.6-10.3-4.4-20.8-5.9-31.3 c-0.2-1.3,3.1-3.1,4.8-4.7c1.5,0,3.3,0.5,4.5-0.2C54.9,431.1,63,426.2,71.1,421.4z"
          ></path>
          <path
            className="st2"
            d="M71.1,421.4c-8.1,4.8-16.2,9.7-24.5,14.4c-1.2,0.7-3,0.1-4.5,0.2c5.4-6.4,10.8-12.7,16.2-19.1 c1.6-0.1,3.1-0.2,4.7-0.3c11.7-10.3,23.4-20.5,35.1-30.8c0.9,0.1,1.8,0.1,2.6,0.2c0.1,0.1,0.2,0.2,0.4,0.3 c6.8,4.1,13.5,8.3,20.3,12.4c-14.7,6.4-29.4,12.9-44.1,19.3c-1.2,0.2-2.3,0.4-3.5,0.6C72.9,419.5,72,420.4,71.1,421.4z"
          ></path>
          <path
            className="st13"
            d="M606.6,762.3c-7.1,2.5-14.2,5-21.3,7.6c-0.4,0.3-0.7,0.6-1,0.9c-3.3,0.1-6.7,0.2-10.1,0.2 c-3.7-0.1-7.4-0.2-11.1-0.3c-3.8-15.7,11-5.5,14.8-10.6c0.4-0.5,1.6-0.5,2.4-0.7c33.3-7.2,66.6-14.3,99.9-21.4 c2.1-0.4,4.5,0.5,6.7,0.8c-0.6,0.5-1.1,1.2-1.8,1.3c-5.2,1.3-10.5,2.2-15.5,3.7c-13.3,4-26.5,8.2-39.7,12.3 C622.1,758.2,614.3,760.3,606.6,762.3z"
          ></path>
          <path
            className="st10"
            d="M614.3,76.2c-0.8-0.8-1.5-1.6-2.3-2.3c1.1-0.9,2.1-2.3,3.4-2.6c6.2-1.3,12.6-2.1,18.8-3.4c3.1-0.6,6.1-2,9.2-3 c-3.2-0.6-6.5-1.7-9.7-1.8c-17.9-0.9-35.8-1.6-53.8-2.4c42.4-1.8,84.1,0.4,123.1,19.6c0.6,1,1.1,2,1.7,3.1c-1.7-0.5-3.4-1-5.2-1.4 c-0.7-0.2-1.4-0.4-2.1-0.6c-6.5-1.5-13.1-2.9-19.6-4.4c-0.8-0.2-1.6-0.5-2.4-0.7c-0.4-0.1-0.7-0.1-1.1-0.2c-0.9-0.1-1.8-0.2-2.8-0.3 c-0.5,0-1.1,0-1.6,0.1c-14.4-1-28.7-2.4-43.1-2.9C622.8,72.7,618.5,75,614.3,76.2z"
          ></path>
          <path
            className="st12"
            d="M739.6,920.2c8.2-4.6,15-6.3,14.1,7.2c0,0.5,1,1.1,1.5,1.6c0.2-1.4,0.6-2.8,0.4-4.1 c-1.9-13.2-3.9-26.4-5.9-39.7c2.6,14.7,5.3,29.3,7.8,44c1.5,9.2,3.3,18.3,3.8,27.6c0.2,3.1-3.3,6.5-5.2,9.7 C750.7,951.1,745.2,935.6,739.6,920.2z"
          ></path>
          <path
            className="st0"
            d="M710,967.1c-4.5-12.6-9-25.2-13.5-37.8c1.9,1.7,4.4,3.1,5.7,5.2c8.4,12.6,16.7,25.3,24.9,38 c2.6,4,4.9,8.3,7.4,12.4c1.8,2.4,3.7,4.8,5.5,7.2C730,983.8,720,975.5,710,967.1z"
          ></path>
          <path
            className="st14"
            d="M418.9,73.4c2,4.3,4.1,8.6,6.1,12.9c-0.8,0.3-1.6,0.6-2.4,0.8c-23.3,3.5-46.7,7-70,10.5 C374.7,89.5,396.8,81.5,418.9,73.4z"
          ></path>
          <path
            className="st15"
            d="M123.5,572.6c1.6,0.5,3.2,1,4.7,1.5c5.4,3.3,10.9,6.7,16.3,10c5.4,3.3,10.9,6.6,16.3,9.9 c1.3,0.8,2.6,1.6,3.8,2.4c0,0,0.1,0,0.1,0c9.1,3.7,18.2,7.5,27.3,11.2c9.5,1.6,19,3.3,28.5,4.9c0.3,0,0.6,0,0.9,0 c3.9,0.2,7.8,0.5,11.7,0.7c9.2,1.5,18.5,2.9,27.7,4.4c5.4,0.6,10.8,1.1,16.2,1.7c1.2,0.7,2.4,1.4,3.6,2c-0.3,0.4-0.6,0.8-0.9,1.2 c-56.3-2-110.1-12.2-155.8-48.4C123.6,574,123.7,573.2,123.5,572.6z"
          ></path>
          <path
            className="st16"
            d="M206.5,183.1c-2.3,5.8-3.3,12.9-7.3,17.1c-7.1,7.5-16,13.2-24.1,19.7c-0.7-4.5-1.4-9-2.1-13.5 C184.1,198.6,195.3,190.8,206.5,183.1z"
          ></path>
          <path
            className="st10"
            d="M882.5,153.9c16.1,6.4,32.3,12.8,48.4,19c2.8,1.1,5.9,1.2,8.8,1.8c-6.3,4-12.5,8-18.8,12.1 C908.2,175.8,895.3,164.8,882.5,153.9z"
          ></path>
          <path
            className="st3"
            d="M276.8,143.7c10.1-2.9,20.1-5.9,30.2-8.6c2.4-0.6,5-0.6,7.4-0.8c-2.2,3.7-4.4,7.5-6.6,11.2c0,0,0-0.1,0-0.1 c-0.4,0.5-0.9,0.9-1.3,1.4c-1.2,0.7-2.5,1.4-3.7,2c-18.9,2.7-37.7,5.3-56.6,8c8.8-3.7,17.6-7.4,26.3-11.1c1-0.4,1.9-0.9,2.9-1.3 c0.3-0.1,0.6-0.3,0.9-0.4C276.5,143.8,276.6,143.7,276.8,143.7z"
          ></path>
          <path
            className="st0"
            d="M172.9,206.4c0.7,4.5,1.4,9,2.1,13.5c-11.1,10.5-22.2,21-33.3,31.5C152.1,236.4,162.5,221.4,172.9,206.4z"
          ></path>
          <path
            className="st12"
            d="M893.8,891.2c0.6,1.1,1.1,2.3,1.7,3.4c-0.8,0.2-1.7,0.4-2.5,0.6c-5,0-10.1-0.1-15.1-0.1 c-21.2-0.4-42.5-0.8-63.7-1.2c4.1-6.7,8.2-13.3,12.3-20c0.5-0.5,1.1-1,1.6-1.4c0,0.2,0.1,0.5,0.1,0.7c-2.2,3.8-4.5,7.6-6.6,11.4 c-1,1.9-1.5,4-2.2,6.1c20.3,0.1,40.5,0.3,60.8,0.4C884.7,891.1,889.2,891.2,893.8,891.2z"
          ></path>
          <path
            className="st17"
            d="M352.6,97.6c23.3-3.5,46.7-7,70-10.5c-20,5.7-40,11.5-60,17.2c-5.3-1.5-10.6-3-16-4.5 C348.6,99.1,350.6,98.4,352.6,97.6z"
          ></path>
          <path
            className="st14"
            d="M391.3,733.9c2.1,5.2,4.2,10.3,6.3,15.5c-10.9-7.4-21.9-14.7-32.8-22.1c-0.5-0.5-1-0.9-1.5-1.4 c-0.8-0.3-1.6-0.7-2.4-1c-1.7-2.5-3.2-5.2-5.1-7.6c-1.6-2-3.6-3.7-5.5-5.6c0.5,0.2,1.1,0.4,1.6,0.5 C365.2,719.5,378.2,726.7,391.3,733.9z"
          ></path>
          <path
            className="st14"
            d="M279.7,622.6c0.3-0.4,0.6-0.8,0.9-1.2c0,0,0.3,0,0.3,0c0,0,0,0.1,0,0.1c4.4,13.8,6.1,29.6,14,41 c10.8,15.6,26.5,27.8,40.1,41.4c0.6,1,1.2,1.9,1.9,2.9c0,0.6-0.1,1.2-0.1,1.8c-29.4-14.9-45.3-39.7-52.8-70.9 C282.8,632.7,281.2,627.7,279.7,622.6z"
          ></path>
          <path
            className="st12"
            d="M991.7,852.7c11.4-24.1,22.9-48.1,34.3-72.2c-2.4,9.5-4.4,19-7.2,28.4c-1.7,5.7-4.5,11-6.9,16.5 c-0.3,0.6-0.5,1.3-0.8,1.9c-0.3,0.5-0.5,1.1-0.8,1.6c-0.3,0.5-0.5,1.1-0.8,1.6c-0.6,1.4-1.2,2.9-1.8,4.3 c-4.2,5.6-8.4,11.3-12.5,16.9c-0.6,0.4-1.2,0.9-1.7,1.3c-0.5-0.1-1.1-0.1-1.6-0.2L991.7,852.7z"
          ></path>
          <path
            className="st12"
            d="M973.3,207.6c-0.1-0.5-0.3-1-0.4-1.5c1.8-3.3,3.6-6.6,5.4-9.9c0.3-0.3,0.5-0.5,0.8-0.8c0.2,0,0.4,0,0.6,0.1 c0.9,1.1,1.8,2.1,2.7,3.2c1.7,1.7,3.3,3.4,5,5.1c1.3,4.8,2.6,9.5,4,14.3c-4.7-2.8-9.4-5.6-14.1-8.3c0,0,0,0,0,0 c-0.5-0.3-1-0.6-1.6-0.9c0,0-0.2-0.2-0.2-0.2C974.8,208.3,974.1,208,973.3,207.6z"
          ></path>
          <path
            className="st17"
            d="M740.1,992.2c-1.8-2.4-3.7-4.8-5.5-7.2c6.5,0.1,13.1,0.1,19.6,0.2c1.3-2.5,2.5-4.9,3.8-7.4 c1.4,4.4,2.9,8.9,4.3,13.3C754.8,991.4,747.4,991.8,740.1,992.2z"
          ></path>
          <path
            className="st3"
            d="M1042,295.2c1.7,1.9,3.4,3.9,5.1,5.8c2.6,6.5,5.1,13,7.7,19.5c-6.2-9-12.4-17.9-18.7-26.9 C1038,294.2,1040,294.7,1042,295.2z"
          ></path>
          <path
            className="st13"
            d="M275.5,144.4c-0.9,0.4-1.9,0.9-2.9,1.3c-0.9-0.5-1.8-0.9-2.7-1.4c3.1-3.9,5.6-8.7,9.6-11.3 c3.3-2.2,8.4-1.8,12.6-2.5c-5.1,4.4-10.3,8.8-15.4,13.2c-0.2,0-0.3,0-0.4,0.2C276.1,144.1,275.8,144.2,275.5,144.4z"
          ></path>
          <path
            className="st4"
            d="M420.2,762.6c11.4,1.5,22.8,3,34.2,4.5c0.3,0.5,0.6,1,0.9,1.5C443.6,766.6,431.9,764.6,420.2,762.6z"
          ></path>
          <path
            className="st3"
            d="M336.8,708.7c0-0.6,0.1-1.2,0.1-1.8c4.5,1.6,9,3.2,13.6,4.9c1.8,1.8,3.9,3.5,5.5,5.6c1.9,2.4,3.4,5,5.1,7.6 C352.9,719.5,344.8,714.1,336.8,708.7z"
          ></path>
          <path
            className="st13"
            d="M492.2,69.7c-6.2,0.5-12.3,1-18.5,1.6c-0.1-0.7-0.3-1.4-0.4-2.1c6.6-0.9,13.2-1.8,19.8-2.6 C492.8,67.6,492.5,68.6,492.2,69.7z"
          ></path>
          <path
            className="st4"
            d="M899.9,894.9c14.5-2.4,29-4.8,43.5-7.3c0.5,0.4,1,0.8,1.5,1.2C929.9,890.9,914.9,892.9,899.9,894.9z"
          ></path>
          <path
            className="st12"
            d="M944.9,888.8c-0.5-0.4-1-0.8-1.5-1.2c10.6-6.8,21.3-13.6,31.9-20.4c0.4,0.3,0.7,0.6,1.1,0.9 c-7,5.9-14.1,11.8-21.1,17.7c-0.9,0.3-1.8,0.5-2.7,0.8c-1.5,0.4-2.9,0.7-4.4,1.1c-0.5,0.2-1.1,0.4-1.6,0.6 C946.1,888.5,945.5,888.7,944.9,888.8z"
          ></path>
          <path
            className="st12"
            d="M977,866.6c4.9-4.6,9.8-9.3,14.7-13.9c0,0,0.1,0.2,0.1,0.2c-0.8,1.3-1.7,2.7-2.5,4 C985.2,860.1,981.1,863.3,977,866.6z"
          ></path>
          <path
            className="st4"
            d="M704.8,83.3c-0.6-1-1.1-2-1.7-3.1c3.9,1,7.8,2,11.7,3.1c-3,0.6-6,1.2-9,1.8C705.5,84.5,705.1,83.9,704.8,83.3z"
          ></path>
          <path
            className="st4"
            d="M1155.1,678.6c2.9-6.9,5.9-13.9,8.8-20.8C1161,664.7,1158,671.7,1155.1,678.6z"
          ></path>
          <path
            className="st4"
            d="M995.2,851.7c4.2-5.6,8.4-11.3,12.5-16.9C1003.6,840.5,999.4,846.1,995.2,851.7z"
          ></path>
          <path
            className="st4"
            d="M877.9,895.1c5,0,10.1,0.1,15.1,0.1C888,895.2,882.9,895.1,877.9,895.1z"
          ></path>
          <path
            className="st4"
            d="M1142.2,704c1.9-3.1,3.9-6.2,5.8-9.2C1146.1,697.8,1144.2,700.9,1142.2,704z"
          ></path>
          <path
            className="st10"
            d="M551.8,60.7c-5.3,0-10.7,0-16,0C541.1,60.7,546.5,60.7,551.8,60.7z"
          ></path>
          <path
            className="st4"
            d="M1151.3,687.2c0.7-1.4,1.3-2.8,2-4.3C1152.6,684.3,1152,685.8,1151.3,687.2z"
          ></path>
          <path
            className="st0"
            d="M650.1,855.9c0.5,1.1,1.1,2.3,1.6,3.4C651.2,858.2,650.6,857,650.1,855.9z"
          ></path>
          <path
            className="st4"
            d="M989.3,856.9c0.8-1.3,1.7-2.7,2.5-4c0.5,0.1,1.1,0.1,1.6,0.2C992.1,854.3,990.7,855.6,989.3,856.9z"
          ></path>
          <path
            className="st10"
            d="M518.6,60.7c-3.1,0.2-6.2,0.3-9.4,0.5C512.3,61.1,515.5,60.9,518.6,60.7z"
          ></path>
          <path
            className="st5"
            d="M584.2,770.8c0.3-0.3,0.7-0.6,1-0.9c0,0,0.1,0,0.1,0c-0.9-0.2-1.8-0.4-2.7-0.6c-0.1,0-0.2,0.8-0.3,1.3 C583,770.6,583.6,770.7,584.2,770.8z"
          ></path>
          <path
            className="st10"
            d="M941.6,175.5c1.7,0.8,3.3,1.7,5,2.5C944.9,177.2,943.3,176.3,941.6,175.5z"
          ></path>
          <path
            className="st4"
            d="M982.4,198.7c-0.9-1.1-1.8-2.1-2.7-3.2C980.6,196.5,981.5,197.6,982.4,198.7z"
          ></path>
          <path
            className="st10"
            d="M533,60.7c-1.6,0-3.2,0-4.9,0C529.8,60.7,531.4,60.7,533,60.7z"
          ></path>
          <path
            className="st4"
            d="M952.6,886.7c0.9-0.3,1.8-0.5,2.7-0.8C954.4,886.2,953.5,886.5,952.6,886.7z"
          ></path>
          <path
            className="st0"
            d="M648.1,852.5c0.2,0.1,0.2,0.3,0.2,0.5c-0.2,0-0.3,0-0.5-0.1C648,852.8,648,852.7,648.1,852.5z"
          ></path>
          <path
            className="st4"
            d="M1149.8,690.3c0.2-0.5,0.4-1,0.6-1.5C1150.3,689.4,1150.1,689.8,1149.8,690.3z"
          ></path>
          <path
            className="st15"
            d="M121.2,571.1c0.3,0.1,0.6,0.3,0.9,0.4C121.8,571.4,121.5,571.2,121.2,571.1z"
          ></path>
          <path
            className="st12"
            d="M752,874.9c0.8,0.3,1.7,0.5,2.5,0.8C753.7,875.5,752.8,875.2,752,874.9z"
          ></path>
          <path
            className="st3"
            d="M363.4,725.9c0.5,0.5,1,0.9,1.5,1.4C364.4,726.8,363.9,726.4,363.4,725.9z"
          ></path>
          <path
            className="st4"
            d="M946.6,888.4c0.5-0.2,1.1-0.4,1.6-0.6C947.7,888,947.2,888.2,946.6,888.4z"
          ></path>
          <path
            className="st4"
            d="M1011.1,827.3c0.3-0.6,0.5-1.3,0.8-1.9C1011.6,826,1011.4,826.6,1011.1,827.3z"
          ></path>
          <path
            className="st4"
            d="M1009.6,830.5c0.3-0.5,0.5-1.1,0.8-1.6C1010.1,829.4,1009.8,830,1009.6,830.5z"
          ></path>
          <path
            className="st4"
            d="M979.1,195.4c-0.3,0.3-0.5,0.5-0.8,0.8C978.5,195.9,978.8,195.7,979.1,195.4z"
          ></path>
          <path
            className="st14"
            d="M399.6,750.8C399.6,750.8,399.5,750.9,399.6,750.8C399.5,750.9,399.6,750.8,399.6,750.8z"
          ></path>
          <path
            className="st14"
            d="M402.2,752.5C402.2,752.5,402.2,752.5,402.2,752.5L402.2,752.5z"
          ></path>
          <path
            className="st0"
            d="M646.7,850.5C646.7,850.5,646.7,850.5,646.7,850.5C646.7,850.5,646.7,850.5,646.7,850.5z"
          ></path>
          <path
            className="st4"
            d="M976.4,868.2c-0.4-0.3-0.7-0.6-1.1-0.9c0.4,0.3,0.7,0.5,1.1,0.8C976.4,868.1,976.4,868.2,976.4,868.2z"
          ></path>
          <polygon
            className="st12"
            points="749.4,883.2 749,883 749.3,882.7 749.5,882.9 "
          ></polygon>
          <path
            className="st18"
            d="M629.9,756.2c13.2-4.1,26.4-8.3,39.7-12.3c5.1-1.5,10.4-2.4,15.5-3.7c0.7-0.2,1.2-0.9,1.8-1.3 c-2.3-0.3-4.6-1.2-6.7-0.8c-33.3,7-66.6,14.2-99.9,21.4c-0.8,0.2-2,0.1-2.4,0.7c-3.9,5.1-18.7-5.1-14.8,10.6 c-9.9-10.8-19.9-21.5-29.5-32.6c-3.5-4.1-5.7-9.3-8.4-14c0.8-0.3,1.6-0.7,2.4-1c1.2,0.3,2.4,0.9,3.7,0.9 c52.2,1.2,104.3,2.3,156.5,3.4c1.1,0,2.3,0,3.4,0c6.2,0.1,12.4,0.1,18.7,0.2c4.6,0.2,9.2,0.4,13.8,0.7c1.4,0.1,2.8,0.2,4.2,0.3 c0.7,0,1.3,0,2,0c6.9,2.5,13.8,5,20.7,7.5c21.4,6.2,42.9,12.4,64.3,18.6c0.5,0.3,1,0.6,1.5,0.9c-18.5-0.1-37.1-0.2-55.6-0.3 c-3.9,0-7.7,0-11.6,0c-3.9,0-7.9,0.1-11.8,0.1c-0.9,0-1.7,0-2.6,0l0.1,0c-1.3,0-2.6,0-3.9,0c-0.7-0.1-1.4-0.2-2.1-0.3 c-18.5,0.1-36.9,0.3-55.4,0.4c-10.5,0.7-21,1.5-31.4,2.2C638.1,757.3,634,756.7,629.9,756.2z"
          ></path>
          <path
            className="st5"
            d="M791.5,860.5c-12.3-4.8-27.9-6.6-35.8-15.4c-5.4-6-0.2-21.7,0.4-33c0.5-9.5,1.4-18.9,2.1-28.4 c0.6-0.1,1.1-0.2,1.7-0.3c0.2,0,0.4,0,0.6,0.1c14.9,0.1,29.8,0.1,44.7,0.2c0.4,0.3,0.7,0.6,1.1,0.8c2,5.1,4,10.2,6,15.3 c1.8,5.2,4,10.3,5.4,15.7c0.5,2.1-0.1,6.5-1.2,6.9c-19.3,6.6-38.8,12.7-58.3,19.1c-1,0.3-1.8,1.4-2.7,2.1c1,0.6,2.1,1.2,3.1,1.7 C769.6,850.4,780.5,855.5,791.5,860.5z"
          ></path>
          <path
            className="st6"
            d="M791.5,860.5c-11-5.1-22-10.1-32.9-15.2c-1.1-0.5-2.1-1.2-3.1-1.7c0.9-0.7,1.7-1.7,2.7-2.1 c19.4-6.3,38.9-12.4,58.3-19.1c1.1-0.4,1.8-4.7,1.2-6.9c-1.4-5.3-3.5-10.5-5.4-15.7c3,6.8,6.1,13.5,9.1,20.3 c0.2,1.3,0.4,2.5,0.5,3.8c0.1,0.4,0.1,0.9,0.2,1.3c0.2,1.8,0.4,3.7,0.7,5.5c0.1,1,0.2,1.9,0.4,2.9c1.6,12.9,3.3,25.8,4.9,38.8 c-0.5,0.5-1.1,1-1.6,1.4c-0.5-0.6-1.1-1.1-1.6-1.7c-6-1.9-12-3.8-18-5.8c-0.8-0.5-1.7-1.1-2.5-1.6 C800.1,863.4,795.8,861.9,791.5,860.5L791.5,860.5z"
          ></path>
          <path
            className="st9"
            d="M680,770.8c10.6,10.8,21.5,21.5,31.7,32.7c1.7,1.9,0.9,6.1,1.2,9.2c2.7,24.9,21.7,7.9,32.4,12.4 c1.7,0.7,4.1-0.3,6.2-0.6c-1.5,6.6-1.7,18-4.8,18.9c-10.6,3-22.2,2.2-33.4,2.9c-0.3-0.1-0.5-0.3-0.5-0.6c-0.6-1-1.2-2-1.9-3 c-3.3-4.7-6.7-9.4-10-14.1c-5.8-12.7-11.6-25.4-17.4-38.2l0,0c-0.8-1.5-1.6-3-2.3-4.6c-0.2-0.4-0.4-0.9-0.6-1.3 c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.8-0.3-1.6-0.4-2.4C680,777.7,680,774.2,680,770.8z"
          ></path>
          <path
            className="st13"
            d="M679.9,781.1c0.1,0.8,0.3,1.6,0.4,2.4c0.1,0.4,0.2,0.8,0.3,1.2c0.2,0.4,0.4,0.9,0.6,1.3c0.8,1.5,1.6,3,2.3,4.6 c0,0,0,0,0,0c0.6,4.9,1.1,9.8,1.7,14.8c-1.2-0.7-2.4-1.3-3.6-2c-3.9-4.8-7.9-9.7-11.8-14.5c-0.2-0.2-0.4-0.4-0.6-0.5 c-0.1-0.2-0.3-0.3-0.5-0.5c-0.5-0.7-1.1-1.5-1.6-2.2l0,0c-0.4-0.4-0.9-0.8-1.3-1.2l-0.1,0c-0.3-0.3-0.6-0.5-0.8-0.8l0,0 c-0.3-0.3-0.5-0.7-0.8-1c-1-1.2-2-2.4-3.1-3.6c-0.5-0.5-0.9-0.9-1.4-1.4l0,0.1c-0.3-0.4-0.6-0.8-0.9-1.2c-0.4-0.4-0.8-0.8-1.1-1.2 c-0.5-0.5-1-1-1.4-1.6c-0.5-0.6-1-1.2-1.5-1.9c-0.4-0.4-0.8-0.8-1.1-1.3c-1.6-1.8-3.3-3.6-4.9-5.5c-0.4-0.4-0.8-0.8-1.2-1.2 c-0.5-0.6-1-1.1-1.5-1.7c-0.2-0.3-0.5-0.5-0.7-0.8c-0.6-0.6-1.1-1.2-1.7-1.8c-0.2-0.2-0.4-0.4-0.5-0.6c-0.3-0.4-0.5-0.9-0.8-1.3 c10.5-0.7,21-1.5,31.4-2.2c0.5,2,0.9,4,1.4,6c0.2,0.4,0.3,0.9,0.5,1.3c0.3,0.1,0.4,0.4,0.3,0.7C677.3,769.5,678.6,775.3,679.9,781.1 z"
          ></path>
          <path
            className="st2"
            d="M759.9,783.5c-0.6,0.1-1.1,0.2-1.7,0.3c-9.6-9.3-19.2-18.6-28.9-27.8c0.6-0.2,1.2-0.3,1.7-0.5 c1.3,0,2.6,0,3.9,0c0,0-0.1,0-0.1,0c0.9,0,1.7,0,2.6,0c3.9,0,7.9-0.1,11.8-0.1c3.9,0,7.7,0,11.6,0 C760.6,764.7,760.3,774.1,759.9,783.5z"
          ></path>
          <path
            className="st19"
            d="M700.9,828.7c3.3,4.7,6.7,9.4,10,14.1c0.6,1,1.2,2,1.9,3c0,0.3,0.2,0.5,0.5,0.6c3.5,3.6,7,7.2,10.5,10.9 c-0.8,5.4-1.5,10.7-2.3,16.1c0,0-0.2,0.2-0.2,0.2c0-0.3-0.1-0.5-0.3-0.8c-9.6-14-19.2-28.1-28.8-42.1 C695.1,830,698,829.3,700.9,828.7z"
          ></path>
          <path
            className="st5"
            d="M806.9,866.5c6,1.9,12,3.8,18,5.8C818.9,870.3,812.9,868.4,806.9,866.5z"
          ></path>
          <path
            className="st12"
            d="M679.9,781.1c-1.3-5.8-2.7-11.6-4-17.4c1.4,2.4,2.7,4.8,4.1,7.2C680,774.2,680,777.7,679.9,781.1z"
          ></path>
          <path
            className="st5"
            d="M791.6,860.5c4.3,1.4,8.5,2.9,12.8,4.3C800.1,863.4,795.8,861.9,791.6,860.5z"
          ></path>
          <path
            className="st15"
            d="M731.1,755.5c-0.6,0.2-1.2,0.3-1.7,0.5c-0.1-0.2-0.2-0.5-0.3-0.7C729.8,755.3,730.5,755.4,731.1,755.5z"
          ></path>
          <path
            className="st12"
            d="M675.6,763c-0.2-0.4-0.3-0.9-0.5-1.3C675.3,762.1,675.4,762.6,675.6,763z"
          ></path>
          <path
            className="st11"
            d="M816.5,755.7c-0.5-0.3-1-0.6-1.5-0.9c8.9-2.5,18.6-3.6,26.4-8c10-5.6,34.8,5.6,26.9-22 c-0.9-3.2-1.1-6.6-1.6-9.9c0.5,0,1.1,0,1.6-0.1c1.1,0.5,2.1,1.5,3.2,1.4c37.9-2.4,75.8-4.9,113.6-7.5c1.5-0.1,2.9-1.1,4.3-1.7 c-0.9-0.7-1.8-1.8-2.9-2.1c-15.4-3.6-30.9-7.1-46.3-10.8c-3-0.7-5.8-2.2-8.7-3.4c2.3-4.7,3.3-11.8,7-13.8 c19.6-10.7,40-19.9,59.9-30.1c12-6.2,23.6-13.2,35.4-19.9c8.2-10.8,16.3-21.5,24.5-32.3c0.3,2.4,0.8,4.7,0.8,7.1 c0.3,29,0.4,58,0.7,87c3.4-0.6,7.3-0.4,10.3-1.8c8.2-4,16.1-8.8,24.1-13.2c-9.8,9.8-20.4,18.8-29.1,29.5 c-7.6,9.3-20.7,23.9-17.9,30.2c11.5,25.7,11.7,29.7-14.8,40.1c-3,0.2-5.9,0.5-8.9,0.7c0,0-0.3-0.1-0.3-0.1c-0.2-0.1-0.3-0.2-0.5-0.1 c-0.8,0.1-1.5,0.2-2.3,0.2c-11.5-3.6-22.9-7.3-34.4-10.9c-1.6-0.4-3.2-0.7-4.9-1.1l0-0.2l0,0.2c-0.7-0.1-1.5-0.1-2.2-0.2 c-3.2-0.4-6.4-0.8-9.6-1.2c-27-2.5-54-5-80.9-7.6c-0.7,0.1-1.5,0.2-2.2,0.3c-1.2,0.1-2.5,0.1-3.7,0.2c-5.7,0.4-11.4,0.7-17.1,1.1 c-10.7,0.6-21.5,1.2-32.2,1.9C827.5,756.5,822,756.1,816.5,755.7z M1044.5,722.9c-5.4-15.2-10-28.7-15-42.2 c-0.8-2.2-3.1-3.8-4.8-5.7c-7,12.9-18.6,25.4-19.8,38.8c-1,11.7,9.7,24.4,15.4,36.5c0.5,1,2,1.6,3.1,2.3 C1030.6,742.5,1037.8,732.4,1044.5,722.9z"
          ></path>
          <path
            className="st11"
            d="M893.8,891.2c-4.5,0-9-0.1-13.6-0.1c-16.9-5.9-33.8-11.8-50.7-17.7c13.5-16.5,27.1-33,40.6-49.4 c11.8-0.3,23.8,0,35.5-1.2c7.3-0.7,14.4-3.7,21.5-5.7c1.3-0.7,3.7-1.9,3.6-1.9c-8.7-18.4,12.4-18.4,17.7-28.1 c2.8-5,9.6-7.8,14.7-11.6c-4.4-1.2-8.8-2.4-13.2-3.6c5.1-0.9,10.1-1.7,15.2-2.6c1.2-0.2,2.5-0.4,3.7-0.5c3.6-0.4,7.3-0.8,10.9-1.2 c0.7-0.1,1.4-0.3,2.1-0.4c12.3,2.7,24.6,5.3,36.8,8c-10.7,18.2-21.4,37-45.5,38.8c-45.2,3.3-61.2,37.1-76.5,71.9 C895.8,887.5,894.7,889.3,893.8,891.2z"
          ></path>
          <path
            className="st20"
            d="M1018.6,775c-12.3-2.7-24.6-5.3-36.8-8c-0.6-0.1-1.3-0.2-1.9-0.3c-0.5,0-1.1-0.1-1.6-0.1c-3-1.8-6-3.7-9-5.5 c3.2,0.4,6.4,0.8,9.6,1.2c-0.8,0.2-1.6,0.4-2.4,0.6c3.1,0.2,6.3,0.4,9.4,0.6c11.5,3.6,22.9,7.3,34.4,10.9 C1019.7,774.6,1019.2,774.8,1018.6,775z"
          ></path>
          <path
            className="st12"
            d="M1022.6,774.1c0.2-0.1,0.4,0,0.5,0.1C1022.9,774.2,1022.8,774.2,1022.6,774.1z"
          ></path>
          <path
            className="st15"
            d="M362.6,104.3c20-5.7,40-11.5,60-17.2c0.8-0.3,1.6-0.6,2.4-0.8c0.9,0.1,1.8,0.3,2.7,0.4 c6.5,5.5,13,11,19.4,16.6c0.1,0.3,0.3,0.6,0.4,0.9c0.4,0.5,0.9,1.1,1.3,1.6c-2.7,0.5-5.4,1-8.1,1.5c-20.8,8.9-41.8,17.3-62.4,26.8 c-28.1,13-55.9,26.9-83.8,40.5c-19.6,9.6-39.1,19.4-58.6,29.1c-0.5,0.1-1.1,0.2-1.6,0.4c-0.7-0.1-1.4-0.3-2.2-0.4 c18.4-14.2,36.9-28.3,55.3-42.5c5.2-4,10.2-8.2,15.3-12.4c1.2-0.7,2.5-1.4,3.7-2c0.4-0.5,0.9-0.9,1.3-1.4c0,0,0,0.1,0,0.1 c15.5-12.1,30.9-24.3,46.4-36.4C356.8,107.1,359.8,105.9,362.6,104.3z"
          ></path>
          <path
            className="st16"
            d="M80.9,349.1c12.4-25.1,24.9-50.3,37.3-75.4c29.2-6.9,58.3-13.8,87.5-20.6c3.8,6.8,7.7,13.6,11.5,20.4 c2.6,4.1,5.2,8.2,7.8,12.2c-4,2.2-8,4.5-11.9,6.7c-24.8,7.4-49.5,14.8-74.3,22.1c0.1-0.3,0.2-0.5,0.3-0.8c1-0.8,1.9-1.5,2.9-2.3 c0,0,0.2-0.2,0.2-0.2c1.1-0.9,2.2-1.9,3.3-2.8c16.9-16.2,33.9-32.4,50.8-48.6c0,0,0.3-0.1,0.3-0.1s-0.2,0.1-0.2,0.1 c-5,3.4-10,6.9-15.1,10.3c-4.4,3.4-8.7,6.7-13.1,10.1c0,0,0,0,0,0c-1.1,0.9-2.2,1.8-3.4,2.7c-0.2,0.3-0.4,0.5-0.6,0.8 c-10,7.8-20,15.6-30,23.5c-0.5,0.2-0.9,0.4-1.4,0.6c-0.7,0.5-1.4,1.1-2,1.6c-0.2,0.3-0.3,0.5-0.5,0.8c-1.6,1.2-3.1,2.4-4.7,3.6 c-0.3,0.2-0.6,0.4-1,0.5c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.1-0.7,0.2c-9.9,7.8-19.8,15.5-29.6,23.3c-0.1,0.1-0.2,0.3-0.3,0.4 c-1.7,0.7-3.3,1.4-5,2.1c-0.5,1.1-1.1,2.1-1.6,3.2c-0.3,0.5-0.6,1.1-0.9,1.6c-1,1.8-2.1,3.6-3.1,5.4C82,350,81.4,349.6,80.9,349.1z"
          ></path>
          <path
            className="st10"
            d="M217.2,273.5c-3.8-6.8-7.7-13.6-11.5-20.4c7-13.5,14-27,21-40.5c0.2-0.3,0.4-0.5,0.6-0.8 c1.7-2.7,3.3-5.4,5-8.2c0.7,0.1,1.4,0.3,2.2,0.4c-1.2,2.8-2.3,5.5-3.5,8.3c-0.4,0.4-0.9,0.9-1.3,1.3c-0.1,0.4-0.2,0.8-0.3,1.3 c-1.2,5.6-2.3,11.2-3.5,16.8c0,1.2,0,2.4-0.1,3.6c-2.1,2.7-4.1,5.5-6.2,8.2c-1.5,0.7-3,1.4-4.6,2.1c-0.3-0.5-0.6-1.1-0.9-1.6 c-0.1,0.6-0.1,1.2-0.2,1.8c-1,4.5-3.3,9.1-2.7,13.4C211.9,264.1,215.1,268.7,217.2,273.5z M218.2,239.5c1.1-2.3,2-4,2.7-5.7 c0.1-0.2-0.8-0.8-1.2-1.3c-1.2,1.5-2.4,3-3.4,4.7C216.2,237.4,217.1,238.2,218.2,239.5z"
          ></path>
          <path
            className="st15"
            d="M227.3,211.8c-0.2,0.3-0.4,0.5-0.6,0.8C226.9,212.3,227.1,212.1,227.3,211.8z"
          ></path>
          <path
            className="st14"
            d="M541.8,100.9c-6.7-9.9-13.4-19.7-20-29.6c-0.9-1.3-1.4-2.9-2.1-4.4c1.6-0.3,3.2-1,4.8-1 c16.6,0.7,33.7-0.4,49.8,3.2c9,2,16.3,11.8,24.4,18.1c-0.2,0.5-0.3,1.1-0.5,1.6c-0.8,0.7-1.8,1.3-2.3,2.2 c-10.3,20.1-20.5,40.3-30.7,60.4c-0.3-0.2-0.6-0.3-0.9-0.5c-6.9-15.4-13.7-30.7-20.6-46.1c-0.4-1-0.7-1.9-1.1-2.9 C542.4,101.6,542.1,101.2,541.8,100.9z"
          ></path>
          <path
            className="st14"
            d="M670.1,94.2c18.7,3.4,37.3,6.7,56,10.1c-8.3,10.4-16.6,20.8-24.9,31.2c-16.3-6.5-32.6-13-48.9-19.5 c-0.1-0.3-0.2-0.6-0.3-1c1.2-2.2,2.4-4.3,3.6-6.5c0.5-0.5,1-1.1,1.5-1.6c0.2-0.3,0.5-0.6,0.7-0.9C661.9,102.1,666,98.1,670.1,94.2z"
          ></path>
          <path
            className="st19"
            d="M522.6,99.5c0.2,0.2,0.5,0.4,0.7,0.7c3,15.8,5.9,31.6,8.9,47.4c0.1,0.9,0.2,1.8,0.2,2.7 c-0.6-0.1-1.2-0.2-1.7-0.2c-14.4-8.2-28.7-16.4-43.1-24.6c1.2-1.8,2-4.3,3.6-5.5c9.5-6.8,19.3-13.4,29-20 C521,99.8,521.8,99.6,522.6,99.5z"
          ></path>
          <path
            className="st10"
            d="M652,115c0.1,0.3,0.2,0.6,0.3,1c-0.2,0.2-0.5,0.4-0.7,0.6c-6,8.4-12.1,16.8-18.1,25.2 c-5.3-7.6-10.7-15.1-15.8-22.8c-6.3-9.6-12.4-19.5-18.6-29.2c-0.3-0.3-0.6-0.7-0.9-1c0.2-0.5,0.3-1.1,0.5-1.6 C616.5,96.4,634.2,105.7,652,115z"
          ></path>
          <path
            className="st8"
            d="M657.8,106c-0.2,0.3-0.5,0.6-0.7,0.9c-1.8-1.1-3.6-2.2-5.4-3.4c-0.4-0.1-0.8-0.3-1.1-0.4 c-11.1-8.4-22.3-16.7-33.4-25.1c-1-0.6-1.9-1.3-2.9-1.9c4.2-1.2,8.5-3.5,12.6-3.3c14.4,0.5,28.8,1.9,43.1,2.9 C666,85.8,661.9,95.9,657.8,106z"
          ></path>
          <path
            className="st10"
            d="M522.6,99.5c-0.8,0.1-1.6,0.3-2.4,0.4c-22.6,1-45.1,2-67.7,3c5.2-6.5,10.4-12.9,15.6-19.4 C486.2,88.9,504.4,94.2,522.6,99.5z"
          ></path>
          <path
            className="st11"
            d="M532.2,147.5c-3-15.8-5.9-31.6-8.9-47.4c6.2,0.2,12.4,0.5,18.6,0.7c0.3,0.4,0.6,0.7,0.9,1 c-0.4,1.5-0.7,3.1-1.1,4.6c-0.2,1-0.3,2-0.5,3c-1.6,7.2-3.1,14.3-4.7,21.5c-0.1,1-0.2,2-0.3,3c-0.4,1.6-0.9,3.2-1.3,4.8 c-0.2,1-0.3,2-0.5,3C533.6,143.8,532.9,145.6,532.2,147.5z"
          ></path>
          <path
            className="st14"
            d="M468.1,83.6c-5.2,6.5-10.4,12.9-15.6,19.4c-0.5,0-1,0.1-1.5,0.1c-1.3,0.1-2.6,0.1-3.9,0.2 c-6.5-5.5-13-11-19.4-16.6C441.2,85.6,454.6,84.6,468.1,83.6z"
          ></path>
          <path
            className="st20"
            d="M617.2,78.1c11.1,8.4,22.3,16.7,33.4,25.1C639.4,94.8,628.3,86.4,617.2,78.1z"
          ></path>
          <path
            className="st20"
            d="M651.7,103.6c1.8,1.1,3.6,2.2,5.4,3.4c-0.5,0.5-1,1.1-1.5,1.6C654.3,106.9,653,105.2,651.7,103.6z"
          ></path>
          <path
            className="st11"
            d="M1160.4,495.4c0.8,43.2,1.7,86.5,2.5,129.7c-2.3,0.3-4.7,0.5-7,0.8c0.3-2.9,1.8-6.4,0.9-8.7 c-5.3-12.7-11.3-25.2-17.1-37.8c-0.6-1.3-2-2.1-3.1-3.2c-0.3,1.5-0.7,2.9-0.8,4.4c-3.4,32.3,5.9,67.4-21.4,94.2 c-1.5,1.5-3,3.1-4.5,4.6c-0.1-2.1-0.5-4.1-0.4-6.2c0.7-15.1,1.6-30.3,2.4-45.4c0.1-1.1-0.4-2.3-0.7-3.5c-0.4,1-0.9,2-1.3,3 c-5.1,14.4-10.1,28.8-15.2,43.3c-0.6-1.2-1.4-2.4-1.6-3.6c-2.4-13.3-4.7-26.6-7.1-40c1.2-1.4,2.2-3.6,3.7-4.2 c7.3-2.5,14.8-4.6,22.2-6.8c0-0.5,0-1,0-1.5c0-0.5,0-1,0-1.5c-1.4-4-2.3-11.1-4.4-11.5c-12.6-2.3-25.5-3-38.3-4.2 c-0.5-0.2-1.1-0.4-1.6-0.5c0.4-1.7,0.9-3.3,1.3-5c0,0,0.2-0.2,0.2-0.2c1-1.1,2.1-2.2,3.1-3.2c0.3-0.3,0.7-0.7,1-1 c0.3-0.3,0.6-0.5,0.9-0.8c0.6-0.7,1.2-1.4,1.8-2.1c0.1,0.1,0.2,0.2,0.4,0.3l0,0.1c2.8,1.2,5.5,2.4,8.3,3.6c-1.7-2.8-3.4-5.6-5-8.4 c0-0.2,0.1-0.5,0.2-0.7c5.3-5,11.2-9.6,15.9-15.1c7.8-9.4,15.2-15.6,25.6-3.5c0.5,0.6,1.9,0.4,2.9,0.6c0.1-1.2,0.6-2.4,0.4-3.5 c-2.5-11.9-5.1-23.7-7.6-35.6c4.4-17.4,8.8-34.7,13.2-52.1c2.8,2.3,5.7,4.6,8.5,6.9c2.2,1.7,4.4,3.4,6.5,5.1 c-0.8-2.5-1.5-5.1-2.4-7.6c-6-16.9-12.1-33.9-18.1-50.8c3.5,5.4,7,10.8,10.5,16.1c1.4,2.4,2.9,4.7,4.3,7.1c0.1,0.3,0.1,0.6,0.2,0.9 c4.4,9.1,8.8,18.1,13.3,27.2c1,1.5,2,3,3,4.5c0.6,0.8,1.3,1.7,1.9,2.5c0.1,0.7,0.2,1.3,0.4,2c0.4,2.2,0.9,4.3,1.3,6.5 C1159.7,492.1,1160,493.8,1160.4,495.4z M1146.2,516.2c1-0.4,2.1-0.8,3.1-1.1c0-6.7,0-13.3,0-20c0-0.7-0.5-1.4-0.8-2.2 c-0.3,0.6-0.7,1.1-0.8,1.7c-3.7,23.2-7.4,46.5-10.8,69.7c-0.2,1.5,1.6,3.3,2.5,4.9c5.5-10.1,13.2-19.7,15.5-30.5 C1156.3,532.3,1149.4,523.8,1146.2,516.2z"
          ></path>
          <path
            className="st12"
            d="M1031.6,317c8.5,16.8,17,33.7,25.6,50.5c0.7,1.3,1.8,2.4,2.7,3.7c0.8-1,1.7-2,2.5-3 c10.5,13.4,21,26.7,31.5,40.1c-1.9,5.9-3.7,11.7-5.6,17.6c-5.5,10.9-10.9,21.8-16.4,32.7c-3.2-24.1-6.3-48.2-9.5-72.2 c-0.2-1.3-0.7-2.5-1.1-3.7c-0.2,1.2-0.6,2.4-0.5,3.6c0.6,22.1,1.2,44.1,1.8,66.2c-1.2-1.4-2.7-2.7-3.7-4.3 c-19-29.7-37.9-59.5-56.8-89.3c4.9-13.9,9.9-27.9,14.8-41.8C1021.7,317,1026.7,317,1031.6,317z M1054,381.3c1.2-0.1,2.5-0.2,3.7-0.3 c-0.6-1.5-1.1-3-1.8-4.5c-0.1-0.1-1.1,0-1.5,0.3c-1.2,1.1-2.2,2.4-3.3,3.6c-0.7-2.8-1.5-5.6-2-8.5c-0.7-4.6,0.4-10.2-1.9-13.7 c-8-12.5-17-24.3-25.7-36.4c-0.8-1.1-1.6-2-2.5-3c0,1.3-0.4,2.7,0,3.8c10.1,30.8,20.1,61.6,30.4,92.3c1.5,4.5,4.8,8.4,7.3,12.6 c-1-4.5-2.6-9-2.8-13.6C1053.8,403,1054,392.2,1054,381.3z M1061.4,434c-1-1.1-2-2.6-3.3-3.5c-0.3-0.2-2.6,1.3-2.6,1.6 c1,3,2.2,5.9,3.4,8.9C1059.6,439,1060.3,437,1061.4,434z"
          ></path>
          <path
            className="st11"
            d="M1093.9,408.3c-10.5-13.4-21-26.7-31.5-40.1c2.5-8.1,4.9-16.2,7.4-24.3c6.5,6.1,12.9,12.3,19.4,18.4 c0.4,0.5,0.8,1,1.2,1.5c1.6,1.5,3.2,3,4.7,4.5c0.4,0.4,0.7,0.8,1.1,1.2c0.5,0.6,1,1.1,1.4,1.7c7.5,16.3,15.2,32.5,22.4,48.9 c1.9,4.4,2.2,9.4,3.3,14.2c-3.3-2.7-6.7-5.3-9.8-8.2c-5.4-4.9-10.7-10-16-15C1096.3,410.1,1095.1,409.2,1093.9,408.3z"
          ></path>
          <path
            className="st12"
            d="M1097.5,411c5.3,5,10.6,10.1,16,15c3.1,2.9,6.5,5.5,9.8,8.2c-1-4.7-1.4-9.8-3.3-14.2 c-7.2-16.4-14.9-32.6-22.4-48.9c8.2,15.5,16.4,30.9,24.5,46.4c1,2,1.6,4.1,2.4,6.2c6,16.9,12.1,33.8,18.1,50.8 c0.9,2.5,1.6,5.1,2.4,7.6c-2.2-1.7-4.4-3.4-6.5-5.1c-2.9-2.3-5.7-4.6-8.5-6.9c-2.9-4-5.9-7.9-8.8-11.9c-7,10-14,20-21,29.9 c-3.8-20.7-7.6-41.5-11.4-62.2c8.6,7.8,17.3,15.6,26,23.4c0.9,0.8,2.3,1.1,3.5,1.6c-0.3-1.2-0.3-2.6-0.9-3.7 C1110.8,435.2,1104.1,423.1,1097.5,411z"
          ></path>
          <path
            className="st21"
            d="M1042,295.2c-2-0.5-3.9-1-5.9-1.5c-6.9-6-13.7-12.1-20.6-18.1c-1.3-2-2.7-4.1-4-6.1c-0.7-0.7-1.4-1.5-2.1-2.2 c-1.3-1.7-2.3-3.8-4-5.2c-14.7-12.1-29.5-24-44.3-36c4-5.8,7.9-11.7,11.9-17.5c0.3,0,0.6-0.1,1-0.1c0.6,0.1,1.1,0.2,1.7,0.2 c0,0,0.2,0.2,0.2,0.2c0.5,0.3,1,0.6,1.6,0.9c0,0,0,0,0,0c21.4,27.4,42.8,54.7,64.2,82.1C1042.1,292.6,1041.8,294.1,1042,295.2z"
          ></path>
          <path
            className="st7"
            d="M1011.4,269.4c1.3,2,2.7,4.1,4,6.1c2.2,10.2,4.5,20.5,6.7,30.7c-6.5-5-13-10-19.4-15.1 c-1.3-1.3-2.5-2.5-3.8-3.8C1003.1,281.4,1007.3,275.4,1011.4,269.4z"
          ></path>
          <path
            className="st20"
            d="M1002.7,291.1c6.5,5,13,10,19.4,15.1c3.2,3.6,6.3,7.2,9.5,10.9c-5,0-9.9-0.1-14.9-0.1 c-0.2-0.4-0.3-0.8-0.5-1.2C1011.8,307.5,1007.3,299.3,1002.7,291.1z"
          ></path>
          <path
            className="st12"
            d="M1152.8,475.1c-4.4-9.1-8.8-18.1-13.3-27.2C1143.9,457,1148.4,466.1,1152.8,475.1z"
          ></path>
          <path
            className="st12"
            d="M1139.4,447c-1.4-2.4-2.9-4.7-4.3-7.1C1136.5,442.3,1137.9,444.7,1139.4,447z"
          ></path>
          <path
            className="st12"
            d="M1159.4,490.5c-0.4-2.2-0.9-4.3-1.3-6.5C1158.5,486.2,1158.9,488.3,1159.4,490.5z"
          ></path>
          <path
            className="st12"
            d="M1157.7,482.1c-0.6-0.8-1.3-1.7-1.9-2.5C1156.4,480.4,1157,481.2,1157.7,482.1z"
          ></path>
          <path
            className="st12"
            d="M1096.2,369.5c-0.4-0.4-0.7-0.8-1.1-1.2C1095.5,368.6,1095.8,369.1,1096.2,369.5z"
          ></path>
          <path
            className="st12"
            d="M1090.4,363.7c-0.4-0.5-0.8-1-1.2-1.5C1089.6,362.8,1090,363.2,1090.4,363.7z"
          ></path>
          <path
            className="st2"
            d="M495.5,718.1c-2.9,0.9-5.9,1.7-8.8,2.6c-6.6,1.7-13.2,3.4-19.8,5.1c-8.4,1.4-16.9,2.9-25.3,4.3 c-19.5-3.4-39-6.8-58.4-10.2c37.4-0.7,74.9-1.4,112.3-2.1L495.5,718.1z"
          ></path>
          <path
            className="st10"
            d="M519.1,722.7c-7.9-1.5-15.7-3.1-23.6-4.6c0,0-0.1-0.3-0.1-0.3c-3.9-3.6-7.8-7.2-11.7-10.8 c-0.7-1.6-1.5-3.2-2.2-4.9c1.5,0.6,2.9,1.2,4.4,1.8c2,2.2,4.1,4.5,6.1,6.7c14.7,1,29.5,2.1,44.2,3.1c-1,1.1-1.8,2.7-3,3.2 C528.6,719.1,523.8,720.9,519.1,722.7z"
          ></path>
          <path
            className="st17"
            d="M466.9,725.8c6.6-1.7,13.2-3.4,19.8-5.1c5.6,1.7,11.2,3.4,16.8,5.1c-8.6,0.8-17.2,1.5-25.7,2.3 C474.2,727.3,470.6,726.6,466.9,725.8z"
          ></path>
          <path
            className="st22"
            d="M519.1,722.7c4.7-1.9,9.5-3.6,14.1-5.7c1.2-0.6,2-2.1,3-3.2c3.9-0.2,7.8-0.4,11.7-0.6 c-6.8,3.4-13.6,6.7-20.4,10.1c-0.8,0.3-1.6,0.7-2.4,1C523.2,723.7,521.1,723.2,519.1,722.7z"
          ></path>
          <path
            className="st17"
            d="M73.7,418.5c1.2-0.2,2.3-0.4,3.5-0.6c19.9,6.7,39.8,13.5,59.7,20.2c2-1.4,3.9-2.8,5.9-4.1 c13.6-10.8,27.2-21.7,40.9-32.5c0,0-0.1-0.1-0.1-0.1c0.8-0.5,1.7-1,2.5-1.5c0.6-2.7,1.3-5.5,1.9-8.2c-2.9,1.4-5.8,2.8-8.7,4.2 c-9.3-2.1-18.9-3.4-27.8-6.7c-8-3-23.9,2.3-19.7-16.6c0.6-2.8-5-6.9-7.8-10.5c0-0.6-0.1-1.1-0.1-1.7c8.2-8.2,16.1-16.8,24.8-24.4 c2.9-2.5,8-2.4,12.1-3.4c-1.5,2.8-2.4,6.2-4.5,8.4c-10.9,11.3-11.8,13.6-7.6,22.5c0,0.1-1.2,0.8-1.9,1.2c-1.6,0.9-4.8,1.8-4.7,2.6 c0.3,5,0.7,14.2,2.2,14.4c14.9,2.1,30,2.7,45,3.7c3.2,2.7,9.6,6.6,9.1,8c-6.1,17.1-13.2,33.8-20.1,50.6c-4.5,6.3-9,12.5-13.5,18.8 c-1.6-0.6-3.1-1.2-4.7-1.7c-4.2-4.3-8.4-8.7-12.8-12.9c-1.8-1.7-4.2-2.6-6.3-3.9c-0.2,1.6-0.3,3.1-0.5,4.7 c1.9,10.8,3.8,21.6,5.8,32.4c0.1,0.6,0.3,1.2,0.4,1.9c0.1,0.6,0.1,1.1,0.2,1.7c0.2,0.9,0.4,1.7,0.5,2.6c0.1,0.6,0.1,1.2,0.2,1.8 c0,1.4,0,2.8,0,4.2c-0.5-0.5-1-1.1-1.5-1.6c-2.6-15.5-5.2-30.9-7.8-46.4c-5.9,0.4-12.4-0.8-17.4,1.4c-8.4,3.6-20.7,7.9-22.6,14.5 c-2.3,7.9,1.8,21.5,8,27.2c12.3,11.2,28,18.5,42.3,27.5c-8.1,3.2-16.3,6.3-24.4,9.5c-8.9-6.6-17.8-13.1-26.5-19.8 c-3.5-2.7-7.6-5.4-9.6-9.1c-0.7-1.3,4-5.6,6.3-8.4c0.8-1,2.5-1.9,2.4-2.7c-0.4-3.2-0.8-6.6-2.3-9.4c-8-15.4-16.6-30.4-24-46 C69.3,427.4,72.6,422.5,73.7,418.5z"
          ></path>
          <path
            className="st5"
            d="M160.1,461c1.6,0.6,3.1,1.2,4.7,1.7c8.8,1.8,17.7,3.5,26.5,5.4c1.3,0.3,3.4,1.1,3.4,1.8c0,1.4-0.7,3.1-1.7,4.1 c-8,7.5-16.1,14.8-24.2,22.3c-0.6,0.5-0.5,1.8-0.7,2.7c17.1,3.6,34.3,7.2,51.4,10.8c1.5,0.1,3,0.1,4.5,0.2c4.6,10,9.2,20,13.9,30 c-1.1,0.1-2.2,0.1-3.3,0.2c-2.9,0-5.7,0.1-8.6,0.1c-5,0-10,0.1-15,0.1c-12.8,0-26.1-2.4-38.2,0.4c-23.1,5.4-45.4,16.6-68.2-0.8 c-3.9-3-9.2-4-13.9-5.9c4.1,4.3,8.2,8.5,12.2,12.9c0.7,0.7,1.1,1.6,1.6,2.5c-5.4-4.5-11-9-16.2-13.8c-1-0.9-0.7-3.5-0.3-5.2 c0.1-0.5,2.6-0.4,4.1-0.6c10.7-1.5,21.5-2.9,32.2-4.4c8.1-3.2,16.3-6.3,24.4-9.5c0.9-0.2,1.7-0.4,2.6-0.6 c3.4-5.1,6.8-10.2,10.3-15.3c0-1.5,0.1-3,0.1-4.5C161.1,484.2,160.6,472.6,160.1,461z"
          ></path>
          <path
            className="st6"
            d="M104.4,549.5c-0.5-0.8-0.9-1.7-1.6-2.5c-4-4.3-8.1-8.6-12.2-12.9c4.7,1.9,10,2.9,13.9,5.9 c22.8,17.4,45.1,6.1,68.2,0.8c12.1-2.8,25.5-0.4,38.2-0.4c-1.5,0.9-2.8,2.3-4.4,2.5c-22,3.2-44,6.2-66,9.3c-1.3,0.2-2.5,0.9-3.7,1.4 c0.8,0.6,1.6,1.3,2.5,1.9c15.1,10.4,29.2,22.7,45.5,30.5c18.3,8.8,38.2,15.4,58.1,19.5c16.2,3.3,33.5,1.5,50.3,1.9 c-10.8,3.5-21.6,6.9-32.4,10.4c-9.2-1.5-18.5-2.9-27.7-4.4c-3.9-0.2-7.8-0.5-11.7-0.7c-0.3,0-0.6,0-0.9,0c-3.3-2.2-6.5-4.4-9.8-6.6 c-22.9-15.2-45.8-30.5-68.8-45.6c-1.2-0.8-2.8-1.1-4.2-1.7c0.2,1.6,0.1,3.3,0.5,4.8c2,6.9,4.1,13.7,6.2,20.6 c-5.4-3.4-10.9-6.7-16.3-10c-6.8-7-13.7-14.1-20.5-21.1c-0.9-0.9-1.7-1.7-2.6-2.6C104.9,550.1,104.7,549.8,104.4,549.5z"
          ></path>
          <path
            className="st9"
            d="M74.2,511.5c0-15.6,0-31.2,0.1-46.9c0-0.5,1-1.1,1.6-1.6c0.7,0.9,2,1.7,2.1,2.7c2.4,22.7,4.7,45.4,6.9,68.1 c0.1,1.3-1.1,2.7-1.7,4c-1.2-1-2.8-1.7-3.5-3c-4-7.5-7.7-15.1-11.5-22.6C70.1,512,72.1,511.8,74.2,511.5z"
          ></path>
          <path
            className="st5"
            d="M105.2,550.4c0.9,0.9,1.7,1.7,2.6,2.6C106.9,552.1,106,551.2,105.2,550.4z"
          ></path>
          <path
            className="st18"
            d="M721.2,873.6c-9.6,14.9-19.2,29.7-28.9,44.6c-6.7-16.7-13.3-33.3-20-50c16.2,1.5,32.4,3.1,48.6,4.6 C721.1,873,721.2,873.3,721.2,873.6z"
          ></path>
          <path
            className="st5"
            d="M754.1,985.1c-6.5-0.1-13.1-0.1-19.6-0.2c-2.4-4.2-4.7-8.4-7.4-12.4c-8.2-12.7-16.5-25.4-24.9-38 c-1.4-2.1-3.8-3.5-5.7-5.2c-0.2-0.6-0.3-1.2-0.5-1.8c-0.2-1.1-0.5-2.2-0.7-3.4c14.5,14.7,29.1,29.3,43.5,44.1 C744.1,973.7,749,979.5,754.1,985.1z"
          ></path>
          <path
            className="st0"
            d="M695.3,924.2c0.2,1.1,0.5,2.2,0.7,3.4c-7.9-17.5-15.9-34.9-23.6-52.4c-5.4-12.3-10.3-24.9-15.4-37.4 c0.5,0,0.9-0.1,1.4-0.1c0.9,1.7,1.9,3.4,2.8,5.1c0.1,0.2,0.2,0.4,0.5,0.5c0.3,0.9,0.6,1.7,0.9,2.6c0,0.3,0.1,0.5,0.4,0.7 c0.3,1,0.5,1.9,0.8,2.9c1.1,2.3,2.1,4.5,3.2,6.8c0.5,0.8,0.9,1.7,1.4,2.5c0.1,0.3,0.2,0.5,0.5,0.7c1,2.1,2,4.3,3,6.4 c0.2,0.8,0.4,1.6,0.6,2.4c6.7,16.7,13.3,33.3,20,50c0,0.3,0.2,0.5,0.4,0.5c0.3,0.6,0.6,1.2,0.9,1.8 C694.2,921.7,694.8,923,695.3,924.2z"
          ></path>
          <path
            className="st18"
            d="M693.7,920.5c-0.3-0.6-0.6-1.2-0.9-1.8C693.1,919.3,693.4,919.9,693.7,920.5z"
          ></path>
          <path
            className="st10"
            d="M727.7,104.3c19.3-1.5,38.7-3,58-4.5c-0.6,1-1.2,2.1-1.8,3.1c-0.3,0.2-0.6,0.5-0.9,0.7c0,0-0.3,0-0.3,0 s0,0.3,0,0.3c-0.2,0.3-0.4,0.5-0.7,0.8c0,0-0.2,0-0.2,0l0,0.2c-5.8,7.7-11.7,15.4-17.5,23.2c-0.3,0.4-0.6,0.8-0.9,1.2 c0,0-0.1,0.2-0.1,0.2c-0.4,0.5-0.8,1-1.2,1.6c0,0,0.1-0.2,0.1-0.2c-0.4,0.6-0.9,1.2-1.3,1.9c0,0.2-0.1,0.4-0.3,0.5 c-4.5-3-9.1-5.7-13.4-9c-6.7-5.2-13-10.8-19.5-16.2C727.8,106.7,727.7,105.5,727.7,104.3z"
          ></path>
          <path
            className="st15"
            d="M727.7,104.3c0,1.2,0.1,2.4,0.1,3.6c-0.1,2.1-0.2,4.2-0.3,6.4c-0.9,16.3-1.8,32.5-2.7,48.8 c-7.4-8.6-14.8-17.1-22.2-25.7c-0.4,0-0.8,0.1-1.2,0.1c0-0.6-0.1-1.3-0.1-1.9c8.3-10.4,16.6-20.8,24.9-31.2 C726.6,104.3,727.2,104.3,727.7,104.3z"
          ></path>
          <path
            className="st4"
            d="M783.9,102.9c0.6-1,1.2-2.1,1.8-3.1c10.8,2,21.6,4,32.4,6c-4.4,3.5-8.5,7.3-13.1,10.5c-4.4,3-9.2,5.2-13.9,7.8 c0,0,0-0.2,0-0.2c-0.5,0.4-1.1,0.9-1.6,1.3c-2.7,1.8-5.4,3.7-8.1,5.5c-0.3-0.1-0.6-0.2-0.9-0.4C781.6,121.2,782.7,112,783.9,102.9z"
          ></path>
          <path
            className="st13"
            d="M657.8,106c4.1-10.1,8.2-20.2,12.3-30.3c0.5,0,1.1,0,1.6-0.1c0.9,0.1,1.8,0.2,2.8,0.3c0.4,0.1,0.7,0.1,1.1,0.2 c0.8,0.2,1.6,0.5,2.4,0.7c6.5,1.5,13.1,2.9,19.6,4.4c0.7,0.2,1.4,0.4,2.1,0.6c1.7,0.5,3.4,1,5.2,1.4c0.4,0.6,0.7,1.2,1.1,1.8 c-11.9,3-23.8,6-35.7,9.1C666,98.1,661.9,102.1,657.8,106z"
          ></path>
          <path
            className="st20"
            d="M791.1,124.1c4.7-2.6,9.5-4.8,13.9-7.8c4.6-3.1,8.8-6.9,13.1-10.5c1.4,0.1,2.8,0.1,4.2,0.2 c-7.6,5-15.2,10-22.8,15C796.6,122,793.9,123.1,791.1,124.1z"
          ></path>
          <path
            className="st14"
            d="M726.9,96.8c2.1-4.6,4.3-9.4,6.5-14.3c2.4,1.2,4.8,2.4,7.2,3.7c-3.7,4.7-7.4,9.4-11.2,14.1 C728.9,99.4,728.3,98.6,726.9,96.8z"
          ></path>
          <path
            className="st23"
            d="M784.6,148.8c0.4,0.2,0.9,0.3,1.3,0.5c0.7,0.5,1.4,1.1,2.2,1.6c11.5,9.6,23.1,19.2,34.6,28.9 c-15.7,1-31.3,2.3-47,3c-5.6,0.3-11.2-0.7-16.8-1.2C767.4,170.7,776,159.7,784.6,148.8z"
          ></path>
          <path
            className="st24"
            d="M822.7,179.8c-11.5-9.6-23.1-19.2-34.6-28.9c12.4,2.9,24.8,5.8,37.2,8.8c5.6,1.3,11.3,2.8,16.9,4.2 c-5.8,5.4-11.6,10.9-17.4,16.3C824,180,823.3,179.9,822.7,179.8z"
          ></path>
          <path
            className="st24"
            d="M859.9,126.7c0.5,0,1,0,1.4,0c2.9-0.1,5.7-0.2,8.6-0.3c1.4,0.3,2.8,0.7,4.2,1c10.4,5.5,20.8,10.9,31.1,16.4 c0.4,0.2,0.8,0.4,1.2,0.5c0.8,1.1,1.6,2.2,2.4,3.2c-2.8-0.2-5.6-0.5-8.4-0.7c-0.3,0.1-0.6,0.2-0.8,0.3c-1.1-0.3-2.3-0.7-3.4-1 c-0.6-0.2-1.2-0.4-1.8-0.6c-0.5-0.1-1.1-0.2-1.6-0.2c-4.2-0.4-8.4-0.9-12.5-1.3c-2.3-0.2-4.6-0.4-6.9-0.6c-6.5-2.5-13-4.8-19.4-7.4 c-2.8-1.2-5.4-3-8.1-4.6c2.9-0.8,5.8-1.4,8.7-2.3C856.4,128.4,858.2,127.5,859.9,126.7z"
          ></path>
          <path
            className="st10"
            d="M867.7,144c3.9,3,7.9,6.1,11.8,9.1c-10.9,2.6-21.8,5.6-32.9,7.6c-2.6,0.5-5.8-2.2-8.7-3.4c2.3-1,4.7-2,7-3.1 c1.1-0.5,2-1.3,3-2c-0.7-0.3-1.3-0.6-2-0.8c-2.3-0.6-4.6-1.2-6.9-1.9c1.6-1.9,3.2-3.7,4.9-5.5c1.7-1.8,3.9-5.3,5.3-5.1 C855.5,140.1,861.6,142.2,867.7,144z"
          ></path>
          <path
            className="st7"
            d="M905.3,143.8c-10.4-5.5-20.8-10.9-31.1-16.4C884.5,132.9,894.9,138.3,905.3,143.8z"
          ></path>
          <path
            className="st7"
            d="M900.6,146.8c2.8,0.2,5.6,0.5,8.4,0.7c-0.8-1.1-1.6-2.2-2.4-3.2c3.9,2.5,7.7,5,11.6,7.5 c-5.9-1.4-11.8-2.8-17.7-4.2C900.5,147.4,900.5,147.1,900.6,146.8z"
          ></path>
          <path
            className="st10"
            d="M880.4,143.9c4.2,0.4,8.4,0.9,12.5,1.3C888.8,144.8,884.6,144.3,880.4,143.9z"
          ></path>
          <path
            className="st7"
            d="M870,126.4c-2.9,0.1-5.7,0.2-8.6,0.3c2.4-0.6,4.7-1.1,7-1.7C868.9,125.5,869.4,125.9,870,126.4z"
          ></path>
          <path
            className="st10"
            d="M894.5,145.4c0.6,0.2,1.2,0.4,1.8,0.6C895.7,145.8,895.1,145.6,894.5,145.4z"
          ></path>
          <path
            className="st10"
            d="M900.6,146.8c-0.1,0.3-0.1,0.6-0.2,0.9c-0.2-0.2-0.5-0.4-0.7-0.6C900,147,900.3,146.9,900.6,146.8z"
          ></path>
          <path
            className="st5"
            d="M383.2,719.9c19.5,3.4,39,6.8,58.4,10.2c3,0.2,6.1,0.5,9.1,0.7c-0.4,1.4-1.1,2.7-1.1,4.1 c-0.2,28.2-1,28.5-25.9,14.6c-10.5-5.8-21.6-10.4-32.4-15.6c-13.1-7.2-26.2-14.4-39.2-21.7c5.5,1.3,10.9,3.1,16.4,3.9 c2.9,0.4,6-0.6,9.1-1c-2.6-10-5-20.1-7.9-30c-7.7-25.7-15.6-51.3-23.4-77c0.8-0.2,1.7-0.5,2.5-0.7c3.8,3,7.2,6.8,11.4,8.7 c19.1,8.4,40.6,13.8,33.5,43.5c-1.7,7.3,4,16.3,6.2,24.6c-3.5,5-6.9,10.1-10.4,15.1c-1.2,2.5-2.5,5.1-3.7,7.6 C385,711.2,384.1,715.6,383.2,719.9z"
          ></path>
          <path
            className="st16"
            d="M450.7,730.8c-3-0.2-6.1-0.5-9.1-0.7c8.4-1.4,16.9-2.9,25.3-4.3c3.6,0.8,7.2,1.5,10.9,2.3 C468.8,729,459.7,729.9,450.7,730.8z"
          ></path>
          <path
            className="st10"
            d="M905.6,207.7c4.4,1.1,8.8,2.3,13.2,3.4c-0.1,0.2-0.2,0.3-0.3,0.5c-2.3,0-4.6-0.1-6.9-0.1c1.6,1.7,3,3.5,4.7,5 c9,7.2,18.1,14.4,27.2,21.5c-9.7-2.9-19.3-5.8-29-8.7c-0.4,0-0.9-0.1-1.3-0.1c-0.8,0-1.5,0-2.3,0.1c-3.6-1.3-7.4-2.1-10.6-4 c-14.2-8.7-28.1-17.7-42.1-26.5l-0.1-0.1c-0.6-0.5-1.2-1-1.7-1.6c-0.8-0.1-1.6-0.2-2.4-0.2c-0.1-0.4-0.2-0.8-0.3-1.1 c9.7,0.5,19.4,0.9,29.1,1.4C890.3,200.6,897.9,204.2,905.6,207.7z"
          ></path>
          <path
            className="st3"
            d="M882.6,197c-9.7-0.5-19.4-0.9-29.1-1.4c-5.2-0.9-10.4-1.8-15.7-2.7c-0.7-0.3-1.4-0.6-2.1-1 c-10.1-1.7-20.2-3.5-30.3-5.2c-11.6-0.5-23.3-1.3-34.9-1.5c-6.9-0.1-16.8-2.6-11.7,10.7c-1-0.4-1.9-0.7-2.9-1.1 c-0.4-0.1-0.8-0.3-1.3-0.4c-5.8-6-11.7-12.1-17.5-18.1c0-0.7,0-1.4,0-2.1c2.3,0.7,4.5,1.4,6.8,2c3.7,2,7.4,3.9,11.1,5.9 c0.6,0.4,1.3,0.8,1.9,1.2l0,0.2l0.1,0c0.6-0.7,1.2-1.3,1.7-2l-0.1,0.1c5.6,0.4,11.2,1.4,16.8,1.2c15.7-0.7,31.3-2,47-3 c0.7,0.1,1.4,0.3,2,0.4C844,185.8,863.3,191.4,882.6,197z"
          ></path>
          <path
            className="st5"
            d="M658.4,837.7c-0.5,0-0.9,0.1-1.4,0.1c-0.6-1-1.2-2-1.8-3c-7.1-7.5-14.2-15-21.5-22.5c-0.3-0.3-2.5,0.5-2.6,0.9 c-0.2,1.7,0.1,3.4,0.2,5.1c-1.9-2.4-3.6-5-5.7-7.1c-3.8-3.7-7.9-7-11.9-10.5c-0.4,0-0.9,0.1-1.3,0.1c0,0,0,0,0,0 c-0.4-0.9-0.8-1.8-1.1-2.6c9-11,18-22,27-33c0.9-1.2,2-3.7,1.5-4.3c-1.2-1.4-3.5-3-5.1-2.8c-9.4,1.2-18.7,2.8-28,4.3 c7.8-2,15.5-4.1,23.3-6.1c4.1,0.6,8.2,1.1,12.4,1.7c0.3,0.4,0.5,0.9,0.8,1.3c0.2,0.2,0.4,0.4,0.5,0.6c0.6,0.6,1.1,1.2,1.7,1.8 c0.2,0.3,0.5,0.5,0.7,0.8c0.5,0.6,1,1.1,1.5,1.7c0.4,0.4,0.8,0.8,1.2,1.2c1.6,1.8,3.3,3.6,4.9,5.5c0.4,0.4,0.8,0.8,1.1,1.3 c0.5,0.6,1,1.2,1.5,1.9c0.5,0.5,1,1,1.4,1.6c0.4,0.4,0.8,0.8,1.1,1.2c0.3,0.4,0.6,0.8,0.9,1.2c0,0,0-0.1,0-0.1 c0.5,0.5,0.9,0.9,1.4,1.4c1,1.2,2,2.4,3.1,3.6c0.3,0.3,0.5,0.7,0.8,1c0,0,0,0,0,0c0.3,0.3,0.6,0.5,0.8,0.8c0,0,0.1,0,0.1,0 c0.4,0.4,0.9,0.8,1.3,1.2c0,0,0,0,0,0c0.5,0.7,1.1,1.5,1.6,2.2c0.2,0.1,0.3,0.3,0.5,0.5c0.2,0.2,0.4,0.4,0.6,0.5 c3.9,4.8,7.9,9.7,11.8,14.5c-0.4,1.9-0.2,4.4-1.3,5.6c-7.4,8-15,15.7-22.6,23.4C658,834.1,658.2,835.9,658.4,837.7z"
          ></path>
          <path
            className="st9"
            d="M609.3,797.4c-6.6-9.3-13.3-18.6-19.9-27.9c3.4-1.6,6.7-4.3,10.1-4.3c1.7,0,4.3,4.5,5,7.3 C606.6,780.7,607.8,789.1,609.3,797.4C609.4,797.4,609.3,797.4,609.3,797.4z"
          ></path>
          <path
            className="st9"
            d="M612.4,800.7c0.4,0,0.9-0.1,1.3-0.1c0.7,1.3,1.3,2.6,2,3.9c-0.3,0.1-0.9,0.4-0.9,0.4 C614,803.5,613.2,802.1,612.4,800.7z"
          ></path>
          <path
            className="st9"
            d="M611.3,798.1c0.4,0.9,0.8,1.8,1.1,2.6c-1-1.1-2-2.2-3.1-3.3c0,0,0.1-0.1,0.1-0.1 C610,797.6,610.6,797.9,611.3,798.1z"
          ></path>
          <path
            className="st5"
            d="M80.9,349.1c0.5,0.4,1,0.9,1.6,1.3c5,9.9,10.1,19.9,15.1,29.8c0.2,0.6,0.4,1.2,0.7,1.9 c-0.1,1.2-0.1,2.4-0.2,3.7C86.4,396,74.7,406.3,63,416.5C48.5,388.6,72.8,371,80.9,349.1z"
          ></path>
          <path
            className="st0"
            d="M758.8,181.6L758.8,181.6c7-17,14-33.9,21-50.8c0.3-0.1,0.5-0.2,0.6-0.5c0.3,0.1,0.6,0.2,0.9,0.4 c1.2,5.5,2.3,11.1,3.5,16.6c-0.1,0.5-0.2,1-0.3,1.5C776,159.7,767.4,170.7,758.8,181.6z"
          ></path>
          <path
            className="st2"
            d="M784.8,147.3c-1.2-5.5-2.3-11.1-3.5-16.6c2.7-1.8,5.4-3.7,8.1-5.5c0.5-0.4,1.1-0.9,1.6-1.3c0,0,0,0.2,0,0.2 c2.8-1,5.6-2,8.4-3C794.6,129.8,789.7,138.5,784.8,147.3z"
          ></path>
          <path
            className="st3"
            d="M843.8,118.2c-1.8,3.1-3.1,8.2-5.3,8.7c-5.1,1-10.7-0.1-16.1-0.4c6.1-5.1,12.1-10.1,18.2-15.2 C841.5,113.2,842.5,115.2,843.8,118.2z"
          ></path>
          <path
            className="st20"
            d="M961.1,226c14.8,12,29.6,23.9,44.3,36c1.6,1.3,2.7,3.4,4,5.2c-19.7-8.2-39.5-16.5-59.2-24.7 c-0.6-0.3-1.3-0.6-1.9-0.8c-1.3-0.9-2.7-1.9-4-2.8c2.3-4.9,5.1-9.7,6.4-14.9c0.3-1.2-4.2-4.4-7-5.4c-6.2-2.2-12.7-3.7-19.1-5.4 c0.5,0,0.9-0.1,1.4-0.1C937.6,217.4,949.3,221.7,961.1,226z"
          ></path>
          <path
            className="st10"
            d="M925.8,213.1c-0.5,0-0.9,0.1-1.4,0.1c-2-0.5-4-1-6.1-1.5c0.1-0.2,0.2-0.3,0.3-0.5 C921.1,211.8,923.5,212.4,925.8,213.1z"
          ></path>
          <path
            className="st4"
            d="M975.6,208.7c-0.6-0.1-1.1-0.2-1.7-0.2c-0.2-0.3-0.4-0.5-0.7-0.8c0,0,0.1-0.1,0.1-0.1 C974.1,208,974.8,208.3,975.6,208.7z"
          ></path>
          <path
            className="st12"
            d="M973.2,207.7c0.2,0.3,0.4,0.5,0.7,0.8c-0.3,0-0.6,0.1-1,0.1C973,208.2,973.1,207.9,973.2,207.7z"
          ></path>
          <path
            className="st1"
            d="M1094.7,670.6c5-14.4,10.1-28.8,15.2-43.3c0.4-1,0.9-2,1.3-3c0.2,1.2,0.7,2.3,0.7,3.5 c-0.8,15.1-1.6,30.3-2.4,45.4c-0.1,2.1,0.3,4.1,0.4,6.2c1.5-1.5,2.9-3.1,4.5-4.6c27.3-26.8,18-62,21.4-94.2c0.2-1.5,0.5-2.9,0.8-4.4 c1,1.1,2.5,1.9,3.1,3.2c5.9,12.5,11.8,25,17.1,37.8c1,2.4-0.5,5.8-0.9,8.7c2.3-0.3,4.7-0.5,7-0.8c-4.5,19.5-9,39-13.5,58.5 c0.3-13.1,0.5-26.2,0.7-39.2c0-1.1-0.3-2.2-0.5-3.3c-0.6,0.2-1.5,0.2-1.7,0.6c-21.1,32.7-61.7,56.8-46.9,105.5 c-4.3,16.9-55.5,36.6-68.6,26.5c26.5-10.5,26.3-14.4,14.8-40.1c-2.8-6.2,10.3-20.9,17.9-30.2c8.7-10.7,19.3-19.7,29.1-29.5 C1094.3,672.8,1094.5,671.7,1094.7,670.6z"
          ></path>
          <path
            className="st11"
            d="M1101,747.1c-14.8-48.7,25.9-72.7,46.9-105.5c0.3-0.4,1.1-0.4,1.7-0.6c0.2,1.1,0.5,2.2,0.5,3.3 c-0.2,13.1-0.5,26.2-0.7,39.2C1133.3,704.8,1117.1,725.9,1101,747.1z"
          ></path>
          <path
            className="st16"
            d="M136.9,438.2c-19.9-6.7-39.8-13.5-59.7-20.2c14.7-6.4,29.4-12.9,44.1-19.3c0,0,0,0.1,0,0.1 C126.5,411.9,131.7,425,136.9,438.2z"
          ></path>
          <path
            className="st0"
            d="M121.3,398.8C121.3,398.8,121.3,398.7,121.3,398.8c-6.7-4.2-13.5-8.4-20.2-12.5c3,1,5.9,2.1,8.9,3.1 c3.4,1.1,6.9,2.2,10.3,3.3c0.7-3.2,2.8-7.2,1.7-9.5c-5.1-10.1-11.1-19.7-17-29.5c-0.4-0.6-1.7-0.6-2.6-0.8c-0.4,1.3-1,2.6-1.1,4 c-0.3,9.7-0.5,19.5-0.7,29.2c-0.9-0.1-1.8-0.1-2.6-0.2c0.1-1.2,0.1-2.4,0.2-3.7c0.1-1.6,0.2-3.1,0.3-4.7c0-0.5,0-0.9,0-1.4 c-2.1-22.5,2.7-33.7,25.4-15.5c0,0.6,0.1,1.1,0.1,1.7c-4.9,21.9,6.8,32.1,25.9,37.7c4.3,1.3,7.9,4.9,11.8,7.5 c-4.5-0.9-9.1-2-13.6-2.8c-2.9-0.5-5.8-0.8-8.7-1.1C133.4,401.9,127.4,400.3,121.3,398.8z"
          ></path>
          <path
            className="st8"
            d="M674.4,76c-0.9-0.1-1.8-0.2-2.8-0.3C672.6,75.8,673.5,75.9,674.4,76z"
          ></path>
          <path
            className="st20"
            d="M699.6,81.9c-0.7-0.2-1.4-0.4-2.1-0.6C698.2,81.5,698.9,81.7,699.6,81.9z"
          ></path>
          <path
            className="st8"
            d="M677.9,76.9c-0.8-0.2-1.6-0.5-2.4-0.7C676.3,76.4,677.1,76.6,677.9,76.9z"
          ></path>
          <path
            className="st17"
            d="M260.9,617.8c10.8-3.5,21.6-6.9,32.4-10.4c2.6-0.9,5.3-1.7,7.7-3c0.3-0.2,0.1-3-0.7-3.7 c-5-4.5-10.3-8.6-15.3-13c-8.6-7.6-17.1-15.3-25.6-22.9c-7.6-6.3-15.3-12.6-22.9-18.9c-3.5-1.9-7-3.8-10.5-5.7 c2.9,0,5.7-0.1,8.6-0.1c25,14.7,50,29.3,74.7,44.4c2.2,1.3,3.8,6.1,3.2,8.8c-1.7,7.8-4.6,15.4-7,23c0,0.4,0,0.7,0,1.1 c-0.4,0.1-0.7,0.1-1.1,0.2c-9.1,0.6-18.2,1.2-27.3,1.8C271.6,618.9,266.2,618.3,260.9,617.8z"
          ></path>
          <path
            className="st17"
            d="M144.5,584.1c-2.1-6.9-4.2-13.7-6.2-20.6c-0.4-1.5-0.4-3.2-0.5-4.8c1.4,0.6,2.9,0.9,4.2,1.7 c23,15.2,45.9,30.4,68.8,45.6c3.3,2.2,6.5,4.4,9.8,6.6c-9.5-1.6-19-3.3-28.5-4.9c-9.1-3.7-18.2-7.5-27.3-11.2c0,0-0.1,0-0.1,0 c-1.3-0.8-2.6-1.6-3.8-2.4C155.4,590.7,150,587.4,144.5,584.1z"
          ></path>
          <path
            className="st6"
            d="M277,619.5c9.1-0.6,18.2-1.2,27.3-1.8c0,1.1,0,2.2,0,3.3l0,0c-0.6,0-1.1,0.1-1.7,0.1c-1.1-0.2-2.3-0.4-3.4-0.5 c-6.1,0.3-12.2,0.6-18.4,0.9c0,0-0.3,0-0.3,0C279.4,620.8,278.2,620.1,277,619.5z"
          ></path>
          <path
            className="st16"
            d="M164.8,596.5c9.1,3.7,18.2,7.5,27.3,11.2C182.9,604,173.9,600.2,164.8,596.5z"
          ></path>
          <path
            className="st17"
            d="M221.4,612.6c3.9,0.2,7.8,0.5,11.7,0.7C229.2,613.1,225.3,612.9,221.4,612.6z"
          ></path>
          <path
            className="st16"
            d="M160.9,594.1c1.3,0.8,2.6,1.6,3.8,2.4C163.4,595.7,162.1,594.9,160.9,594.1z"
          ></path>
          <path
            className="st20"
            d="M275.5,144.4c0.3-0.1,0.6-0.3,0.9-0.4C276.1,144.1,275.8,144.2,275.5,144.4z"
          ></path>
          <path
            className="st2"
            d="M307.9,145.4c-0.4,0.5-0.9,0.9-1.3,1.4C307.1,146.3,307.5,145.8,307.9,145.4z"
          ></path>
          <path
            className="st25"
            d="M870.1,823.9c-13.5,16.5-27.1,33-40.6,49.4c-0.4-0.1-0.8-0.1-1.2-0.1c0-0.2-0.1-0.5-0.1-0.7 c-1.6-12.9-3.3-25.8-4.9-38.8c-0.1-1-0.2-1.9-0.4-2.9c-0.2-1.8-0.4-3.7-0.7-5.5c-0.1-0.4-0.1-0.9-0.2-1.3c-0.2-1.3-0.4-2.5-0.5-3.8 c0.4-0.3,0.8-0.7,1.2-1c15.5,1.2,30.9,2.4,46.4,3.6c0.3,0.1,0.5,0.1,0.8,0.2C869.9,823.3,870,823.6,870.1,823.9z"
          ></path>
          <path
            className="st1"
            d="M828.2,873.2c0.4,0,0.8,0.1,1.2,0.1c16.9,5.9,33.8,11.8,50.7,17.7c-20.3-0.1-40.5-0.3-60.8-0.4 c0.7-2,1.2-4.2,2.2-6.1C823.7,880.7,826,877,828.2,873.2z"
          ></path>
          <path
            className="st2"
            d="M377.6,715.2c-3,0.4-6.2,1.4-9.1,1c-5.5-0.9-11-2.6-16.4-3.9c-0.5-0.2-1.1-0.4-1.6-0.5c-4.5-1.6-9-3.2-13.6-4.9 c-0.6-1-1.2-1.9-1.9-2.9c-0.7-2.5-1.4-5.1-2.1-7.6c2.4,1.2,4.7,2.7,7.2,3.5c3.8,1.2,7.7,1.8,11.5,2.7c-0.8-1.5-1.7-2.9-2.5-4.4 c-0.9-1.5-1.7-3.1-2.5-4.7c2.1-0.1,5.1-1.2,6.3-0.1C361.4,700.4,369.4,707.8,377.6,715.2z"
          ></path>
          <path
            className="st0"
            d="M377.6,715.2c-8.2-7.3-16.2-14.8-24.6-21.9c-1.2-1-4.2,0-6.3,0.1c0.8,1.6,1.6,3.1,2.5,4.7 c0.8,1.5,1.7,2.9,2.5,4.4c-3.8-0.9-7.8-1.5-11.5-2.7c-2.5-0.8-4.8-2.3-7.2-3.5c0.7,2.5,1.4,5.1,2.1,7.6 c-13.6-13.6-29.2-25.9-40.1-41.4c-7.9-11.4-9.6-27.2-14-41c7.8-0.2,15.6-0.4,23.5-0.5c0,0,0,0,0,0c0.5,0,1.1,0.1,1.6,0.1 c-0.2,1.3-0.6,2.6-0.4,3.8c2.7,14,2.1,30.2,9.4,41.2c6.1,9.2,21.4,12.4,32.8,18c1.1,0.6,2.8-0.1,4.2-0.1c-0.6-1.3-1.2-2.6-1.9-3.9 c-13.9-22.8-38.9-40.6-32.2-72.6c0.6-3-0.6-6.5-0.9-9.7c-2.4,1.7-4.9,3.5-7.3,5.2c2.5-6.6,5-13.1,7.5-19.7c2.2-2.8,4.5-5.6,6.7-8.4 c0.3-0.5,0.6-1.1,0.9-1.6c5.2-4.3,10.4-8.5,15.5-12.8c1.5-0.5,3-1.1,4.6-1.6c1.3,0.6,2.6,1.2,3.9,1.7c-0.9,15.9-1.7,31.7-2.6,47.6 c7.8,25.7,15.7,51.3,23.4,77C372.6,695.1,375,705.2,377.6,715.2z M357.5,689.5c-0.3-0.1-0.7-0.3-1-0.4c-0.1,0.2-0.3,0.6-0.2,0.6 c0.2,0.2,0.6,0.4,0.9,0.5C357.2,690,357.3,689.8,357.5,689.5z M342.9,692c-0.1-0.2-0.1-0.6-0.3-0.6c-0.2-0.1-0.5,0.1-0.7,0.2 c0.1,0.2,0.1,0.6,0.2,0.6C342.3,692.3,342.6,692.1,342.9,692z"
          ></path>
          <path
            className="st16"
            d="M304.4,621c-7.8,0.2-15.6,0.4-23.5,0.5c0,0,0-0.1,0-0.1c6.1-0.3,12.2-0.6,18.4-0.9c1.1,0.2,2.3,0.4,3.4,0.5 C303.3,621.1,303.9,621,304.4,621z"
          ></path>
          <path
            className="st4"
            d="M977.3,209.7c-0.5-0.3-1-0.6-1.6-0.9C976.3,209.2,976.8,209.4,977.3,209.7z"
          ></path>
          <path
            className="st19"
            d="M729.9,728.6c-0.7,0-1.3,0-2,0c2.7-1.4,5.2-3.2,8-4.2c36.8-13.3,73.5-26.6,110.3-39.9c0.8-0.3,1.4-0.7,2.1-1.1 c-0.7-0.1-1.5-0.4-2.2-0.3c-41.7,7.1-83.3,14.2-124.9,21.4c-2.1-8.1-4.1-16.1-6.2-24.2c46.3,0.4,92.5,0.8,138.8,1.1 c3.4,11.3,6.7,22.6,10.1,33.9c-0.2,0.1-0.5,0.2-0.7,0.3c-2.6,0.3-5.2,0.5-7.8,0.8c-2.5,0.2-5,0.3-7.4,0.5c-4.1,0.5-8.1,0.9-12.2,1.4 c-0.8,0-1.5,0.1-2.3,0.1c-1.8,0.2-3.5,0.4-5.3,0.7c-0.8,0-1.6,0-2.4,0.1C793.9,722.4,761.9,725.5,729.9,728.6z"
          ></path>
          <path
            className="st13"
            d="M825.8,719.2c0.8,0,1.6,0,2.4-0.1c1.8-0.2,3.5-0.4,5.3-0.7c0.8,0,1.5-0.1,2.3-0.1c4.1-0.5,8.1-0.9,12.2-1.4 c2.5-0.2,5-0.3,7.4-0.5c2.6-0.3,5.2-0.5,7.8-0.8c0.2-0.1,0.5-0.2,0.7-0.3c0.9-0.2,1.8-0.4,2.7-0.6c0.5,3.3,0.7,6.7,1.6,9.9 c8,27.6-16.9,16.4-26.9,22c-7.8,4.4-17.6,5.4-26.4,8c-21.4-6.2-42.9-12.4-64.3-18.6c12.6-0.7,25.2-0.9,37.6-2.4 c5.8-0.7,11.3-4.2,16.8-6.5c1.1-0.5,1.6-2.2,2.7-2.6C813.8,722.7,819.8,721,825.8,719.2z"
          ></path>
          <path
            className="st26"
            d="M723.7,728.3c-4.6-0.2-9.2-0.4-13.8-0.7c-1.4-0.6-2.7-1.6-4.1-1.7c-32.8-2.7-65.7-5.1-98.5-7.8 c-16.6-1.4-33.2-3.3-49.8-5c33.5-1.7,66.9-3.7,100.4-5.2c21-0.9,42-1.2,63-1.7C721.8,713.6,722.7,721,723.7,728.3z"
          ></path>
          <path
            className="st21"
            d="M723.7,728.3c-0.9-7.4-1.9-14.7-2.8-22.1c0.1-0.6,0.3-1.1,0.4-1.7c41.6-7.1,83.3-14.3,124.9-21.4 c0.7-0.1,1.5,0.2,2.2,0.3c-0.7,0.4-1.4,0.8-2.1,1.1c-36.8,13.3-73.6,26.5-110.3,39.9c-2.8,1-5.4,2.8-8,4.2 C726.5,728.6,725.1,728.4,723.7,728.3z"
          ></path>
          <path
            className="st15"
            d="M816.5,755.7c5.5,0.4,11,0.8,16.5,1.3c-0.1,0.2-0.3,0.4-0.6,0.5c-1.9,0.3-3.7,0.6-5.6,0.8 c1.5,1.2,3,2.5,4.6,3.5c9.6,5.7,19.3,11.2,29,16.8c-1.5,0.5-3,1.4-4.5,1.5c-16.6,1.6-33.1,3-49.7,4.5c-0.4-0.3-0.7-0.6-1.1-0.8 c0.8-1.8,1.8-3.5,2.4-5.4c2.5-7.1,4.9-14.3,7.3-21.5c-6.4,2.2-13.2,3.6-19.1,6.7c-12,6.2-23.5,13.3-35.3,20 c-0.2-0.1-0.4-0.1-0.6-0.1c0.3-9.4,0.7-18.8,1-28.2C779.5,755.4,798,755.5,816.5,755.7z"
          ></path>
          <path
            className="st27"
            d="M557.5,713.1c16.6,1.7,33.1,3.6,49.8,5c32.8,2.7,65.7,5.2,98.5,7.8c1.4,0.1,2.7,1.1,4.1,1.7 c-6.2-0.1-12.4-0.1-18.7-0.2c-1.1,0-2.3,0-3.4,0c-52.2-1.1-104.3-2.3-156.5-3.4c-1.2,0-2.4-0.6-3.7-0.9c6.8-3.4,13.6-6.7,20.4-10.1 c0.8-0.4,1.6-0.7,2.4-1.1c0.3,0,0.6,0.1,1,0.1c1.9,0.1,3.7,0.3,5.6,0.4C557.1,712.7,557.3,712.9,557.5,713.1z"
          ></path>
          <path
            className="st21"
            d="M825.8,719.2c-6,1.8-12.1,3.5-18,5.4c-1.1,0.3-1.6,2.1-2.7,2.6c-5.6,2.4-11,5.8-16.8,6.5 c-12.4,1.6-25.1,1.7-37.6,2.4c-6.9-2.5-13.8-5-20.7-7.5C761.9,725.5,793.9,722.4,825.8,719.2z"
          ></path>
          <path
            className="st15"
            d="M749.4,755.4c-3.9,0-7.9,0.1-11.8,0.1C741.5,755.4,745.4,755.4,749.4,755.4z"
          ></path>
          <path
            className="st26"
            d="M687.7,727.5c1.1,0,2.3,0,3.4,0C690,727.5,688.9,727.5,687.7,727.5z"
          ></path>
          <path
            className="st15"
            d="M735,755.5C735,755.5,735,755.5,735,755.5C735,755.5,735,755.5,735,755.5z"
          ></path>
          <path
            className="st27"
            d="M806.3,784.6c16.6-1.5,33.1-3,49.7-4.5c1.5-0.1,3-1,4.5-1.5c0.6-0.2,1.1-0.3,1.7-0.5c0.2,0.3,0.4,0.6,0.6,0.9 c-13.4,13.4-26.8,26.8-40.2,40.2c-0.4,0.3-0.8,0.7-1.2,1c-3-6.8-6.1-13.5-9.1-20.3C810.3,794.8,808.3,789.7,806.3,784.6z"
          ></path>
          <path
            className="st2"
            d="M760.5,783.6c11.7-6.7,23.3-13.8,35.3-20c6-3.1,12.7-4.5,19.1-6.7c-2.4,7.2-4.8,14.3-7.3,21.5 c-0.6,1.9-1.6,3.6-2.4,5.4C790.3,783.8,775.4,783.7,760.5,783.6z"
          ></path>
          <path
            className="st27"
            d="M822.8,830.8c0.1,1,0.2,1.9,0.4,2.9C823.1,832.7,822.9,831.8,822.8,830.8z"
          ></path>
          <path
            className="st27"
            d="M821.9,824c0.1,0.4,0.1,0.9,0.2,1.3C822.1,824.9,822,824.4,821.9,824z"
          ></path>
          <path
            className="st12"
            d="M700.9,828.7c-2.9,0.7-5.9,1.3-8.8,2c-0.4-0.1-0.8-0.2-1.2-0.2c-1.9-8.4-3.8-16.7-5.7-25.1 c-0.6-4.9-1.1-9.8-1.7-14.8C689.3,803.3,695.1,816,700.9,828.7z"
          ></path>
          <path
            className="st12"
            d="M683.5,790.5c-0.8-1.5-1.6-3-2.3-4.6C682,787.5,682.7,789,683.5,790.5z"
          ></path>
          <path
            className="st0"
            d="M712.8,845.8c-0.6-1-1.2-2-1.9-3C711.5,843.8,712.2,844.8,712.8,845.8z"
          ></path>
          <path
            className="st12"
            d="M680.6,784.7c-0.1-0.4-0.2-0.8-0.3-1.2C680.4,783.9,680.5,784.3,680.6,784.7z"
          ></path>
          <path
            className="st6"
            d="M685.2,805.3c1.9,8.4,3.8,16.7,5.7,25.1c-0.4,0.2-0.7,0.4-1.1,0.5c-10.7,0.5-21.3,0.9-32,1.4 c7.6-7.8,15.2-15.5,22.6-23.4c1.1-1.2,0.9-3.7,1.3-5.6C682.8,804,684,804.6,685.2,805.3z"
          ></path>
          <path
            className="st18"
            d="M668.8,787.8c-0.5-0.7-1.1-1.5-1.6-2.2C667.7,786.3,668.2,787.1,668.8,787.8z"
          ></path>
          <path
            className="st18"
            d="M657.7,775.4c-0.5-0.5-1-1-1.4-1.6C656.7,774.3,657.2,774.8,657.7,775.4z"
          ></path>
          <path
            className="st18"
            d="M654.8,771.9c-0.4-0.4-0.8-0.8-1.1-1.3C654,771.1,654.4,771.5,654.8,771.9z"
          ></path>
          <path
            className="st18"
            d="M661.1,779c-0.5-0.5-0.9-0.9-1.4-1.4C660.2,778.1,660.7,778.5,661.1,779z"
          ></path>
          <path
            className="st18"
            d="M667.2,785.6c-0.4-0.4-0.9-0.8-1.3-1.2C666.3,784.8,666.7,785.2,667.2,785.6z"
          ></path>
          <path
            className="st18"
            d="M669.8,788.8c-0.2-0.2-0.4-0.4-0.6-0.5C669.4,788.5,669.6,788.6,669.8,788.8z"
          ></path>
          <path
            className="st18"
            d="M659.7,777.7c-0.3-0.4-0.6-0.8-0.9-1.2C659.1,776.9,659.4,777.3,659.7,777.7z"
          ></path>
          <path
            className="st18"
            d="M664.9,783.6c-0.3-0.3-0.5-0.7-0.8-1C664.4,782.9,664.7,783.2,664.9,783.6z"
          ></path>
          <path
            className="st18"
            d="M665.8,784.3c-0.3-0.3-0.6-0.5-0.8-0.8C665.2,783.8,665.5,784.1,665.8,784.3z"
          ></path>
          <path
            className="st18"
            d="M646,762.4c-0.2-0.3-0.5-0.5-0.7-0.8C645.5,761.8,645.8,762.1,646,762.4z"
          ></path>
          <path
            className="st18"
            d="M648.7,765.2c-0.4-0.4-0.8-0.8-1.2-1.2C647.9,764.4,648.3,764.8,648.7,765.2z"
          ></path>
          <path
            className="st18"
            d="M643.6,759.7c-0.2-0.2-0.4-0.4-0.5-0.6C643.2,759.3,643.4,759.5,643.6,759.7z"
          ></path>
          <path
            className="st27"
            d="M689.8,831c0.4-0.2,0.7-0.4,1.1-0.5c0.4,0.1,0.8,0.2,1.2,0.2c9.6,14,19.2,28.1,28.8,42.1 c-16.2-1.5-32.4-3.1-48.6-4.6c-0.2-0.8-0.4-1.6-0.6-2.4C677.8,854.2,683.8,842.6,689.8,831z"
          ></path>
          <path
            className="st13"
            d="M853.8,681.5c-46.3-0.4-92.5-0.8-138.8-1.1c-6,0.9-11.9,1.7-17.9,2.6c2.2-1.1,4.4-2.5,6.7-3.3 c1.6-0.6,3.4-0.6,5.1-0.8c-1.4-0.6-2.9-1.8-4.2-1.6c-13.5,2.1-26.9,4.4-40.4,6.6c11.5-4.2,22.9-8.4,34.4-12.7 c51.5-3.2,103-6.3,154.5-9.7c17.3-1.1,34.5-3,51.7-4.5c3.4-0.9,6.7-1.8,10.1-2.7c0.6,0.6,1.2,1.2,1.8,1.8l0.1,0.1 c7.8,6,15.7,11.9,23.5,17.9c25.3-24.7,50.7-49.4,76-74.2c2.9-16.2,5.7-32.5,8.6-48.7c5.7-4.2,11.5-8.4,17.2-12.5 c0.6,1.1,1.3,2.2,1.9,3.2c5.8,16.8,11.6,33.6,17.4,50.4c-1,0.1-2.1,0.1-3.1,0.2c-4.4,0.5-8.8,1.1-13.2,1.5c-25,2.5-27,3.8-21.7,15 c3,6.2,6.7,12.1,10.2,18.2c-11.8,6.7-23.3,13.7-35.4,19.9c-19.9,10.2-40.3,19.4-59.9,30.1c-3.7,2-4.8,9.1-7,13.8 c2.9,1.1,5.7,2.6,8.7,3.4c15.4,3.7,30.9,7.2,46.3,10.8c1.1,0.2,1.9,1.4,2.9,2.1c-1.4,0.6-2.8,1.6-4.3,1.7 c-37.9,2.6-75.7,5.1-113.6,7.5c-1,0.1-2.1-0.9-3.2-1.4c18.8-6.6,37.6-13.1,56.4-19.8c1.4-0.5,2.5-1.5,3.8-2.3 c-0.7-0.3-1.5-0.7-2.2-0.8C902.1,688.4,878,685,853.8,681.5z"
          ></path>
          <path
            className="st12"
            d="M1044.5,722.9c-6.7,9.4-13.9,19.6-21.1,29.8c-1-0.8-2.6-1.3-3.1-2.3c-5.7-12.2-16.4-24.9-15.4-36.5 c1.2-13.4,12.7-25.9,19.8-38.8c1.6,1.9,4,3.5,4.8,5.7C1034.4,694.2,1039.1,707.8,1044.5,722.9z"
          ></path>
          <path
            className="st10"
            d="M969.3,761c3,1.8,6,3.7,9,5.5c-3.2,0.7-6.3,1.4-9.5,2c-1.2,0.2-2.5,0.4-3.7,0.5c-21.5,1.6-43.1,3.2-64.6,4.9 c-12.7,1-25.4,2.3-38.1,3.4c0-1.9,0-3.8-0.1-5.7c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.4-0.3,0.4-0.5 c4.2-0.3,9.1,0.7,12.3-1.2c6.2-3.8,11.3-9.3,16.9-14.1c-1.4-0.4-2.9-0.8-4.3-1.2C915.3,756,942.3,758.5,969.3,761z"
          ></path>
          <path
            className="st8"
            d="M853.8,681.5c24.1,3.4,48.3,6.9,72.4,10.4c0.8,0.1,1.5,0.5,2.2,0.8c-1.3,0.8-2.4,1.9-3.8,2.3 c-18.8,6.6-37.6,13.2-56.4,19.8c-0.5,0-1.1,0-1.6,0.1c-0.9,0.2-1.8,0.4-2.7,0.6C860.6,704.1,857.2,692.8,853.8,681.5z"
          ></path>
          <path
            className="st12"
            d="M1094.7,670.6c-0.2,1.1-0.4,2.2-0.7,3.2c-8,4.4-15.9,9.2-24.1,13.2c-3,1.5-6.8,1.3-10.3,1.9 c0,0,0.3,0.2,0.3,0.2c8.3-20.6,16.5-41.3,24.8-61.9c0.4-0.1,0.8-0.1,1.2-0.2c2.3,13.3,4.7,26.7,7.1,40 C1093.3,668.2,1094.1,669.4,1094.7,670.6z"
          ></path>
          <path
            className="st12"
            d="M1033.7,627c-3.4-6-7.2-11.9-10.2-18.2c-5.4-11.2-3.3-12.6,21.7-15c4.4-0.4,8.8-1,13.2-1.5 c-0.1,0.8-0.1,1.6-0.2,2.4C1050.1,605.5,1041.9,616.3,1033.7,627z"
          ></path>
          <path
            className="st3"
            d="M888.3,753.5c1.4,0.4,2.9,0.8,4.3,1.2c-5.6,4.8-10.7,10.3-16.9,14.1c-3.1,1.9-8.1,0.9-12.3,1.2 c-0.1-0.3-0.2-0.5-0.3-0.8c0-1.1,0-2.2-0.1-3.2c0.7-3.6,1.5-7.3,2.2-10.9c5.7-0.4,11.4-0.7,17.1-1.1c1.2-0.1,2.5-0.1,3.7-0.2 C886.8,753.6,887.6,753.6,888.3,753.5z"
          ></path>
          <path
            className="st1"
            d="M1058.2,594.7c0.1-0.8,0.1-1.6,0.2-2.4c1-0.1,2.1-0.1,3.1-0.2c0.2,0.1,0.3,0.2,0.3,0.4c0.1,0.3,0.3,0.6,0.4,0.9 c2.2-0.5,4.4-1,6.7-1.5c-0.4,1.7-0.9,3.3-1.3,5c-1.1,0.4-2.2,0.7-3.3,1.1c-1.4,30.4-2.8,60.7-4.2,91.1c0,0-0.3-0.2-0.3-0.2 c-0.2-29-0.4-58-0.7-87C1059,599.5,1058.5,597.1,1058.2,594.7z"
          ></path>
          <path
            className="st14"
            d="M865.3,755c-0.7,3.6-1.5,7.3-2.2,10.9c-8.9-2.6-17.9-5.1-26.8-7.7c-1.2-0.3-2.5-0.6-3.7-0.9l0,0 c0.2-0.1,0.4-0.3,0.6-0.5C843.8,756.3,854.5,755.7,865.3,755z"
          ></path>
          <path
            className="st10"
            d="M985.9,763.5c-3.1-0.2-6.3-0.4-9.4-0.6c0.8-0.2,1.6-0.4,2.4-0.6c0.7,0.1,1.5,0.1,2.2,0.2c0,0,0,0,0,0 C982.7,762.8,984.3,763.1,985.9,763.5z"
          ></path>
          <path
            className="st10"
            d="M886.1,753.7c-1.2,0.1-2.5,0.1-3.7,0.2C883.6,753.9,884.8,753.8,886.1,753.7z"
          ></path>
          <polygon
            className="st20"
            points="981.1,762.4 981.1,762.4 981.1,762.2 "
          ></polygon>
          <path
            className="st13"
            d="M870.1,823.9c-0.1-0.3-0.2-0.6-0.3-0.9c13.9-15.3,27.8-30.7,41.7-46c1.4-0.2,2.7-0.4,4.1-0.6 c0.8,2.8,1.6,5.6,2.4,8.4c3,10.8,6.1,21.5,9.1,32.3c-7.2,2-14.2,4.9-21.5,5.7C893.8,823.8,881.9,823.6,870.1,823.9z"
          ></path>
          <path
            className="st12"
            d="M927.1,817c-3-10.8-6.1-21.5-9.1-32.3c-0.8-2.8-1.6-5.6-2.4-8.4c11.5-1.5,22.9-3.1,34.4-4.6 c4.4,1.2,8.8,2.4,13.2,3.6c-5,3.8-11.9,6.6-14.7,11.6c-5.3,9.8-26.5,9.8-17.7,28.1C930.8,815.1,928.4,816.3,927.1,817z"
          ></path>
          <path
            className="st7"
            d="M949.9,771.7c-11.5,1.5-22.9,3.1-34.4,4.6c-1.4,0.2-2.7,0.4-4.1,0.6c-16,0.8-32.1,1.6-48.1,2.5 c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.3-0.4-0.6-0.6-0.9c0-0.2,0.1-0.4,0.2-0.6c12.7-1.1,25.4-2.4,38.1-3.4c21.5-1.7,43.1-3.3,64.6-4.9 C960,770,955,770.9,949.9,771.7z"
          ></path>
          <path
            className="st7"
            d="M968.8,768.6c3.2-0.7,6.3-1.4,9.5-2c0.5,0,1.1,0.1,1.6,0.1c0,0.2-0.1,0.5-0.1,0.7 C976.1,767.8,972.4,768.2,968.8,768.6z"
          ></path>
          <path
            className="st10"
            d="M979.7,767.4c0.1-0.2,0.1-0.5,0.1-0.7c0.6,0.1,1.3,0.2,1.9,0.3C981.1,767.1,980.4,767.2,979.7,767.4z"
          ></path>
          <path
            className="st27"
            d="M236.1,203.7c19.5-9.7,39-19.6,58.6-29.1c27.9-13.6,55.6-27.5,83.8-40.5c20.5-9.5,41.6-17.9,62.4-26.8 c-3.4,4.1-6.8,8.2-10.2,12.3c-1.9-1-4.4-3.3-5.6-2.8c-17.1,8-34.3,15.8-50.7,25c-4.2,2.3-5.7,9.3-8.5,14.2c-2.7,1.6-5.4,3.2-8,4.8 c-0.6,0.4-1.1,0.8-1.7,1.2c-4.6,3.1-9.2,6.2-13.8,9.3c-0.5,0.4-0.9,0.7-1.4,1.1c-3.9,2.4-7.8,4.9-11.7,7.3c-2.4,0.9-4.7,1.7-7.1,2.6 c-14.9,6.1-29.7,12.2-44.6,18.3c-1.3,1.6-2.6,3.1-3.8,4.7c-0.9,1.3-1.8,2.6-2.7,3.9l-0.2,0.3c-0.4-0.2-0.8-0.4-1.2-0.5 C258.4,207.1,247.2,205.4,236.1,203.7z"
          ></path>
          <path
            className="st26"
            d="M430.6,119.5c3.4-4.1,6.8-8.2,10.2-12.3c2.7-0.5,5.4-1,8.1-1.5c11.2,6,22.4,12,33.6,18 c-13.4,4.3-26.8,8.4-40.1,12.9c-6,2-11.8,4.7-17.6,7.1c1.5,0.8,3,1.7,4.5,2.5c-10.1,1.6-20.2,3.1-30.2,4.7c-0.5-0.5-1.1-1.1-1.6-1.6 c1.4-0.9,2.8-1.8,4.1-2.6C411.2,137.6,420.9,128.6,430.6,119.5L430.6,119.5z"
          ></path>
          <path
            className="st22"
            d="M482.5,123.7c-11.2-6-22.4-12-33.6-18c-0.4-0.5-0.9-1.1-1.3-1.6c1.2-0.3,2.3-0.7,3.5-1c0.5,0,1-0.1,1.5-0.1 c22.6-1,45.1-2,67.7-3c-9.7,6.6-19.4,13.2-29,20c-1.7,1.2-2.5,3.6-3.6,5.5C485.9,124.8,484.2,124.3,482.5,123.7z"
          ></path>
          <path
            className="st22"
            d="M236.1,203.7c11.2,1.7,22.3,3.4,33.5,5.2c-3.4,4.7-6.7,9.5-10.1,14.2c-1,0.7-2,1.3-3,2 c-9.1,4.6-18.2,9.2-27.3,13.7c-1.1-1.1-2.3-2.3-3.4-3.4c0-1.2,0-2.4,0.1-3.6c4.1-2,8.2-3.9,12.3-5.9c-2.9-3.7-5.9-7.3-8.8-11 c0.1-0.4,0.2-0.8,0.3-1.3c0.4-0.4,0.9-0.9,1.3-1.3c1.2-2.8,2.3-5.5,3.5-8.3C235,203.9,235.5,203.8,236.1,203.7z"
          ></path>
          <path
            className="st10"
            d="M451,103.1c-1.2,0.3-2.3,0.7-3.5,1c-0.1-0.3-0.3-0.6-0.4-0.9C448.4,103.2,449.7,103.1,451,103.1z"
          ></path>
          <path
            className="st3"
            d="M213.1,292.5c4-2.2,8-4.5,11.9-6.7c0.4-2.6,0.8-5.2,1.2-7.9c7.3-13.2,14.6-26.3,21.9-39.5 c2.9-3.6,5.8-7.3,8.6-10.9c1.7,1.2,3.3,2.6,5.1,3.7c0.5,0.3,1.5-0.2,2,0.1c0.3,0.2,0.5,1.3,0.3,1.7c-5.9,9.9-13.8,19.2-17.3,29.9 c-7.6,23-12.9,46.8-19.1,70.2c0.5,1.8,0.9,3.6,1.4,5.4c0.6,1.8,1.1,3.6,1.7,5.4c3.3,9.3,6.6,18.7,10,28c0,1.8,0.1,3.7,0.1,5.5 c2.4,10,2.3,21.8,7.8,29.7c12.8,18.7,26.1,36.3,18.9,60.9c-11.2,0.3-22.3,0.6-33.5,0.9c0.8-20.8,1.6-41.6,2.4-62.4 c0.2-6.3,0.5-12.6,0.7-18.9c-2.9,2.6-5.9,5.1-8.8,7.7c-2.9-2.3-7.4-4-8.4-6.9c-1.2-3.3,0.8-7.7,1.4-11.7 c-10.1-1.2-20.3-2.4-30.4-3.6c0-1.5,0-3,0-4.5c0-0.4,0-0.9,0-1.3c-0.1-0.6-0.1-1.3-0.2-1.9c-1.4-7.6-2.8-15.1-4.1-22.7 c-0.1-0.6-0.1-1.2-0.2-1.7c4.3-7.1,8.5-14.1,12.8-21.2c3.7,3.9,7.4,7.8,11,11.7c0.3,0.4,0.4,0.9,0.7,1.4c0.5-0.5,1.1-0.8,1.4-1.4 c3.9-7.2,8.9-14.1,11.1-21.8c1.5-5.4-0.9-11.9-1.6-17.8c-12.3,6.3-24.5,12.6-36.8,18.8c-0.9,0.1-1.9,0.1-2.8,0.2 c0,0-0.1-0.1-0.1-0.1C192.3,304.7,202.7,298.6,213.1,292.5z"
          ></path>
          <path
            className="st24"
            d="M226.2,277.9c-0.4,2.6-0.8,5.2-1.2,7.9c-2.6-4.1-5.2-8.2-7.8-12.2c-2.1-4.8-5.3-9.4-6.1-14.4 c-0.6-4.2,1.7-8.9,2.7-13.4c0.4-0.1,0.7-0.1,1.1-0.2c1.5-0.7,3-1.4,4.6-2.1c3.2-1.6,6.4-3.2,9.6-4.8c9.1-4.6,18.2-9.2,27.3-13.7 c0.1,0.9,0.2,1.7,0.3,2.6l0-0.1c-2.9,3.6-5.8,7.3-8.6,10.9C240.8,251.6,233.5,264.8,226.2,277.9z"
          ></path>
          <path
            className="st28"
            d="M98.5,376c0,0.5,0,0.9,0,1.4c-0.3,1-0.6,1.9-0.9,2.9c-5-9.9-10.1-19.9-15.1-29.8c1.1-1.8,2.1-3.6,3.1-5.4 c0.3-0.5,0.6-1.1,0.9-1.6c0.5-1.1,1.1-2.1,1.6-3.2c1.1,0.8,2.1,1.6,3.2,2.4c4-1.8,7.9-3.7,11.9-5.5c0.2,1.9,0.8,3.8,0.5,5.6 C102.1,353.8,100.3,364.9,98.5,376z"
          ></path>
          <path
            className="st14"
            d="M213.1,292.5c-10.4,6.1-20.7,12.2-31.1,18.4c-1.3,0.1-2.7,0.2-4,0.3c-13.2,1.3-26.4,2.5-39.6,3.8 c0.1-0.1,0.2-0.2,0.4-0.3C163.5,307.2,188.3,299.9,213.1,292.5z"
          ></path>
          <path
            className="st26"
            d="M130.3,310.3c0.2-0.3,0.3-0.5,0.5-0.8c0.7-0.5,1.4-1.1,2-1.6c0.5-0.2,0.9-0.4,1.4-0.6 c8.1-6.1,16.2-12.2,24.3-18.2c-0.3,1.9,0,4.3-1,5.6c-3.8,4.8-8,9.3-12.1,13.9c-1.1,0.9-2.2,1.9-3.3,2.8c-0.1,0-0.3,0-0.4-0.1 c0.1,0.1,0.1,0.2,0.2,0.2c-1,0.8-1.9,1.5-2.9,2.3c-1.1,0.5-2.2,1.1-3.2,1.6c-1.3,0.9-2.6,1.7-3.9,2.6c-0.4,0.3-0.8,0.5-1.2,0.8 c-0.7,0.7-1.4,1.5-2.1,2.2c-0.2,0-0.4,0-0.5,0.2c-0.7,0.1-1.4,0.2-2.1,0.3c-0.7,0.5-1.3,1.1-2,1.6l0,0c-4,2.3-7.9,4.6-11.9,6.9 l0.1,0.1c-2.8,0.7-5.5,1.3-8.3,2c0.7-0.7,1.4-1.5,2.1-2.2c0,0,0.2,0,0.2,0l0-0.2c0.1-0.2,0.2-0.4,0.5-0.6c0.2-0.3,0.5-0.6,0.7-0.9 c5.7-4.6,11.5-9.3,17.2-13.9c0.3-0.2,0.6-0.4,1-0.5C127.2,312.7,128.8,311.5,130.3,310.3z"
          ></path>
          <path
            className="st15"
            d="M145.5,308.6c4.1-4.6,8.3-9,12.1-13.9c1-1.3,0.7-3.7,1-5.6c1.9-1.7,3.8-3.5,5.7-5.2c0.2-0.3,0.4-0.5,0.6-0.8 c1.1-0.9,2.2-1.8,3.4-2.7c0,0,0,0,0,0c4.4-3.4,8.7-6.7,13.1-10.1c5-3.4,10-6.9,15.1-10.3c0,0,0,0,0,0 C179.4,276.2,162.4,292.4,145.5,308.6z"
          ></path>
          <path
            className="st17"
            d="M164.2,283.9c-1.9,1.7-3.8,3.5-5.7,5.2c-8.1,6.1-16.2,12.2-24.3,18.2C144.2,299.5,154.2,291.7,164.2,283.9z"
          ></path>
          <path
            className="st6"
            d="M106,330c-0.7,0.7-1.4,1.5-2.1,2.2c-3.5,1.8-7,3.7-10.5,5.5c9.9-7.8,19.8-15.5,29.6-23.3c0.2,0,0.5-0.1,0.7-0.2 c0.3,0.1,0.6,0.1,0.9,0.2c-5.7,4.6-11.5,9.3-17.2,13.9c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.1-0.4,0.3-0.5,0.6 C106.3,329.8,106,330,106,330z"
          ></path>
          <path
            className="st29"
            d="M93.5,337.7c3.5-1.8,7-3.7,10.5-5.5c2.8-0.7,5.5-1.3,8.3-2c-1.5,1.5-3,3.1-4.5,4.6c-1.2,0.7-2.4,1.4-3.6,2.2 c-0.3,0-0.6,0.1-0.9,0.1c-4,1.8-7.9,3.7-11.9,5.5c0.6-1.5,1.2-3,1.8-4.5C93.3,338,93.4,337.9,93.5,337.7z"
          ></path>
          <path
            className="st17"
            d="M181.3,270.3c-4.4,3.4-8.7,6.7-13.1,10.1C172.5,277,176.9,273.7,181.3,270.3z"
          ></path>
          <path
            className="st6"
            d="M93.2,338.2c-0.6,1.5-1.2,3-1.8,4.5c-1.1-0.8-2.1-1.6-3.2-2.4C89.9,339.5,91.5,338.9,93.2,338.2z"
          ></path>
          <path
            className="st17"
            d="M130.3,310.3c-1.6,1.2-3.1,2.4-4.7,3.6C127.2,312.7,128.8,311.5,130.3,310.3z"
          ></path>
          <path
            className="st17"
            d="M168.2,280.4c-1.1,0.9-2.2,1.8-3.4,2.7C165.9,282.2,167.1,281.3,168.2,280.4z"
          ></path>
          <path
            className="st15"
            d="M135.9,315.5c1.1-0.5,2.2-1.1,3.2-1.6c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.1-0.3,0.2-0.4,0.3 C137.5,315.1,136.7,315.3,135.9,315.5z"
          ></path>
          <path
            className="st6"
            d="M86.6,343.4c-0.3,0.5-0.6,1.1-0.9,1.6C86,344.5,86.3,344,86.6,343.4z"
          ></path>
          <path
            className="st15"
            d="M142,311.6c-0.1-0.1-0.1-0.2-0.2-0.2c0.1,0,0.3,0,0.4,0.1C142.2,311.4,142,311.6,142,311.6z"
          ></path>
          <path
            className="st17"
            d="M132.9,307.9c-0.7,0.5-1.4,1.1-2,1.6C131.5,309,132.2,308.4,132.9,307.9z"
          ></path>
          <polygon
            className="st15"
            points="196.3,260 196.6,259.8 196.3,260 "
          ></polygon>
          <path
            className="st17"
            d="M123.8,314.2c-0.2,0.1-0.5,0.1-0.7,0.2C123.3,314.2,123.5,314.1,123.8,314.2z"
          ></path>
          <path
            className="st7"
            d="M229.3,214.9c2.9,3.7,5.9,7.3,8.8,11c-4.1,2-8.2,3.9-12.3,5.9C226.9,226.1,228.1,220.5,229.3,214.9z"
          ></path>
          <path
            className="st7"
            d="M229.1,238.8c-3.2,1.6-6.4,3.2-9.6,4.8c2.1-2.7,4.1-5.5,6.2-8.2C226.8,236.5,227.9,237.6,229.1,238.8z"
          ></path>
          <path
            className="st7"
            d="M218.2,239.5c-1.1-1.3-2-2.1-1.9-2.3c1-1.6,2.2-3.1,3.4-4.7c0.4,0.4,1.3,1,1.2,1.3 C220.2,235.5,219.3,237.2,218.2,239.5z"
          ></path>
          <path
            className="st7"
            d="M214.9,245.6c-0.4,0.1-0.7,0.1-1.1,0.2c0.1-0.6,0.2-1.2,0.2-1.8C214.3,244.5,214.6,245.1,214.9,245.6z"
          ></path>
          <path
            className="st7"
            d="M230.9,212.3c-0.4,0.4-0.9,0.9-1.3,1.3C230,213.2,230.5,212.7,230.9,212.3z"
          ></path>
          <path
            className="st15"
            d="M598.2,88.7c0.3,0.3,0.6,0.7,0.9,1c0.2,6.8,0.3,13.7,0.5,20.5c0,1.9,0,3.7,0.1,5.6c0.1,4.5,0.2,9,0.3,13.5 c-0.1,0.1-0.3,0.2-0.3,0.3c0.1,0.2,0.2,0.4,0.4,0.7c0.1,4.6,0.2,9.2,0.3,13.8c0.1,3,0.1,6,0.2,9c-0.3,2.4-0.6,4.8-0.9,7.2 c-5.4,4.3-10.8,8.6-16.2,13c-16.4,13.8-32.8,27.6-49.2,41.4c0.5-1.6,1-3.2,1.5-4.8c0.2-0.3,0.3-0.7,0.5-1 c5.3-10.1,10.5-20.3,15.8-30.4c0.3-0.7,0.6-1.4,1-2c2.5-4.8,5.1-9.6,7.6-14.4c1.2-1.5,2.3-3.1,3.5-4.6c0.3-0.9,0.6-1.7,0.9-2.6 c0-1.1,0.1-2.3,0.1-3.4c10.2-20.1,20.5-40.3,30.7-60.4C596.4,90.1,597.4,89.5,598.2,88.7z"
          ></path>
          <path
            className="st16"
            d="M541.1,109.6c0.2-1,0.3-2,0.5-3c0.7-0.6,1.4-1.1,2.1-1.7c6.9,15.4,13.7,30.7,20.6,46.1c-9,0-18.1,0.4-27.1-0.3 c-1.2-0.1-1.9-5.7-2.9-8.7c0.2-1,0.3-2,0.5-3c0.4-1.6,0.9-3.2,1.3-4.8c0.1-1,0.2-2,0.3-3C538,123.9,539.6,116.7,541.1,109.6z"
          ></path>
          <path
            className="st17"
            d="M534.3,141.9c1,3,1.7,8.6,2.9,8.7c9,0.7,18,0.3,27.1,0.3c0.3,0.2,0.6,0.3,0.9,0.5c0,1.1-0.1,2.3-0.1,3.4 c-0.3,0.9-0.6,1.7-0.9,2.6c-1.2,1.5-2.3,3.1-3.5,4.6c-4.8,5.3-9.6,10.7-14.4,16c-0.1,0.9-0.2,1.7-0.4,2.6c-0.2,0.3-0.4,0.5-0.6,0.8 c-0.2,0-0.5,0-0.7,0.1c-4.1-10.4-8.1-20.8-12.2-31.2c-0.1-0.9-0.2-1.8-0.2-2.7C532.9,145.6,533.6,143.8,534.3,141.9z"
          ></path>
          <path
            className="st17"
            d="M543.8,104.8c-0.7,0.6-1.4,1.1-2.1,1.7c0.4-1.5,0.7-3.1,1.1-4.6C543,102.9,543.4,103.8,543.8,104.8z"
          ></path>
          <path
            className="st16"
            d="M701.2,135.5c0,0.6,0.1,1.3,0.1,1.9c-0.5,0.6-1,1.2-1.5,1.9c-1.2-0.8-2.9-2.5-3.4-2.2 c-11.3,7.5-22.5,15.2-33.8,22.9c-0.3-0.7-0.6-1.5-0.9-2.2c0-0.7-0.1-1.3-0.1-2c-3.3-13.1-6.7-26.1-10-39.2c0.2-0.2,0.4-0.4,0.7-0.6 C668.6,122.5,684.9,129,701.2,135.5z"
          ></path>
          <path
            className="st27"
            d="M482.5,123.7c1.7,0.6,3.4,1.1,5.1,1.7c14.4,8.2,28.7,16.4,43.1,24.6c-0.8,0.1-1.6,0.2-2.4,0.4 c-20.3-2-40.6-4.1-60.9-6.1c-5.5-0.2-11-0.4-16.5-0.6l0-0.2c-0.2,0-0.5,0-0.7,0c-0.8,0.1-1.6,0.1-2.4,0.2c-1.1,0.2-2.3,0.4-3.4,0.6 c-1.5,0.2-3,0.4-4.5,0.7c-0.2-0.2-0.5-0.2-0.7,0.1c-3.3,0.4-6.6,0.8-9.8,1.2c-1.5-0.8-3-1.7-4.5-2.5c5.9-2.4,11.6-5.1,17.6-7.1 C455.7,132.1,469.1,128,482.5,123.7z"
          ></path>
          <path
            className="st20"
            d="M528.3,150.4c0.8-0.1,1.6-0.2,2.4-0.4c0.6,0.1,1.2,0.2,1.7,0.2c4.1,10.4,8.1,20.8,12.2,31.2 c-0.4,0.9-0.8,1.8-1.2,2.7c-0.2-0.4-0.4-0.8-0.6-1.2c-9.3-9-18.6-18-27.9-27c-0.4-0.4-0.7-0.9-1.1-1.3 C518.6,153.2,523.4,151.8,528.3,150.4z"
          ></path>
          <path
            className="st7"
            d="M600,129.3c-0.1-4.5-0.2-9-0.3-13.4c0-1.9,0-3.7-0.1-5.6c-0.2-6.8-0.3-13.7-0.5-20.5 c6.2,9.7,12.2,19.6,18.6,29.2c5.1,7.7,10.5,15.2,15.8,22.8c-0.1,0.8-0.1,1.7-0.2,2.5c-0.1,0.3-0.2,0.7-0.3,1c-1.8,0.6-3.9,0.9-5.4,2 c-9.9,7.5-19.6,15.3-29.4,23c0.3-0.9,0.6-1.8,0.9-2.7c0.3-1.1,0.6-2.2,0.9-3.3c-0.1-1.3-0.2-2.6-0.3-3.9c0.3-2.4,0.6-4.8,0.9-7.2 c-0.1-3-0.1-6-0.2-9c-0.1-4.6-0.2-9.2-0.3-13.8c0.1-0.1,0.2-0.2,0.1-0.3C600.2,129.7,600.1,129.5,600,129.3z"
          ></path>
          <path
            className="st4"
            d="M633.3,144.3c0.1-0.8,0.1-1.7,0.2-2.5c6-8.4,12.1-16.8,18.1-25.2c3.3,13.1,6.7,26.1,10,39.2c-0.5,0-0.9,0-1.4,0 C651.3,151.9,642.3,148.1,633.3,144.3z"
          ></path>
          <path
            className="st17"
            d="M541.1,109.6c-1.6,7.2-3.1,14.4-4.7,21.5C538,123.9,539.6,116.7,541.1,109.6z"
          ></path>
          <path
            className="st17"
            d="M536.1,134.1c-0.4,1.6-0.9,3.2-1.3,4.8C535.3,137.3,535.7,135.7,536.1,134.1z"
          ></path>
          <path
            className="st12"
            d="M1116.9,522.3c2.6,11.9,5.2,23.7,7.6,35.6c0.2,1.1-0.2,2.4-0.4,3.5c-1-0.2-2.4,0-2.9-0.6 c-10.4-12.2-17.9-5.9-25.6,3.5c-4.6,5.6-10.5,10.1-15.9,15.1c-0.1-17.1-0.3-34.3-0.4-51.4c0,0,0.2-0.3,0.2-0.3 c6.5-12,13-24.1,19.6-36.1c2.6,13.5,5.2,27,7.8,40.4c0.2,1.2,0.6,2.3,0.9,3.4c0.3-1.1,0.6-2.3,0.9-3.4c1.9-6.9,3.9-13.8,5.8-20.7 c-0.7,6.5-1.5,13-2.2,19.4C1113.7,528,1115.3,525.1,1116.9,522.3z"
          ></path>
          <path
            className="st25"
            d="M1079.2,528c0.1,17.1,0.3,34.3,0.4,51.4c-0.1,0.2-0.2,0.4-0.2,0.7c-0.4,0-0.7,0.1-1.1,0.1 c-11.4-12.8-22.8-25.7-34.3-38.5c-0.6-1.1-1.3-2.2-1.9-3.3c0.5-0.9,0.9-1.7,1.4-2.6C1055.4,533.3,1067.3,530.7,1079.2,528z"
          ></path>
          <path
            className="st17"
            d="M1044.1,541.8c11.4,12.8,22.8,25.7,34.3,38.5c-0.8,1.4-1.7,2.9-2.5,4.3c-0.6,0.7-1.2,1.4-1.8,2.1 c-0.3,0.3-0.6,0.5-0.9,0.8c-0.3,0.3-0.7,0.7-1,1c-1,1.1-2.1,2.2-3.1,3.2c0,0-0.2,0.2-0.2,0.2c-2.2,0.5-4.4,1-6.7,1.5 c-0.1-0.3-0.3-0.6-0.4-0.9c0-0.2,0-0.4-0.3-0.4C1055.7,575.3,1049.9,558.5,1044.1,541.8z"
          ></path>
          <path
            className="st1"
            d="M1146.2,516.2c3.2,7.6,10.1,16,8.7,22.6c-2.3,10.8-10.1,20.4-15.5,30.5c-0.9-1.7-2.7-3.4-2.5-4.9 c3.4-23.3,7.1-46.5,10.8-69.7c0.1-0.6,0.5-1.2,0.8-1.7c0.3,0.7,0.8,1.4,0.8,2.2c0.1,6.7,0,13.3,0,20 C1148.3,515.5,1147.2,515.9,1146.2,516.2z"
          ></path>
          <path
            className="st8"
            d="M1064.2,598c1.1-0.4,2.2-0.7,3.3-1.1c0.5,0.2,1.1,0.4,1.6,0.5c14.3,6.2,28.5,12.4,42.8,18.6 c-7.4,2.2-14.9,4.3-22.2,6.8c-1.5,0.5-2.5,2.7-3.7,4.2c-0.4,0.1-0.8,0.1-1.2,0.2C1077.9,617.5,1071.1,607.7,1064.2,598z"
          ></path>
          <path
            className="st1"
            d="M1116.9,522.3c-1.6,2.8-3.1,5.7-4.7,8.5c0.7-6.5,1.5-13,2.2-19.4c2.3-17.7,4.5-35.4,6.8-53 c2.9,4,5.9,7.9,8.8,11.9C1125.6,487.5,1121.2,504.9,1116.9,522.3z"
          ></path>
          <path
            className="st10"
            d="M1112,616c-14.3-6.2-28.5-12.4-42.8-18.6c12.8,1.2,25.7,2,38.3,4.2c2.1,0.4,2.9,7.5,4.4,11.5c0,0.5,0,1,0,1.5 C1111.9,615.1,1111.9,615.5,1112,616z"
          ></path>
          <path
            className="st12"
            d="M1075.8,584.6c0.8-1.4,1.7-2.9,2.5-4.3c0.4,0,0.7-0.1,1.1-0.1c1.7,2.8,3.4,5.6,5,8.4c-2.8-1.2-5.5-2.4-8.3-3.6 c0,0,0-0.1,0-0.1C1076,584.8,1075.9,584.7,1075.8,584.6z"
          ></path>
          <path
            className="st1"
            d="M1069.1,591.7c1-1.1,2.1-2.2,3.1-3.2C1071.1,589.6,1070.1,590.6,1069.1,591.7z"
          ></path>
          <path
            className="st1"
            d="M1073.1,587.5c0.3-0.3,0.6-0.5,0.9-0.8C1073.7,586.9,1073.4,587.2,1073.1,587.5z"
          ></path>
          <path
            className="st8"
            d="M1111.9,614.6c0-0.5,0-1,0-1.5C1111.9,613.6,1111.9,614.1,1111.9,614.6z"
          ></path>
          <path
            className="st1"
            d="M1076.2,584.9C1076.2,584.9,1076.2,584.9,1076.2,584.9C1076.2,584.9,1076.2,584.9,1076.2,584.9z"
          ></path>
          <path
            className="st13"
            d="M1002,358.8c18.9,29.8,37.9,59.6,56.8,89.3c1,1.6,2.5,2.9,3.7,4.3c-0.9,3.5-1.3,7.2-2.7,10.5 c-10.3,24.4-20.8,48.8-31.2,73.1c-1,1.7-2,3.4-3,5c-2.8,1.1-5.9,1.9-8.5,3.5c-21.5,12.7-42.9,25.6-64.3,38.4 c13.1-24.1,26.2-48.1,39.2-72.2c0-0.4-0.1-0.8-0.1-1.2c0,0,0.1,0.2,0.1,0.2c0.9-0.6,1.7-1.1,2.6-1.7c0.5-0.6,1.1-1.2,1.6-1.8 c11.8-13.6,23.5-27.1,35.3-40.7c-0.8-1.1-1.7-2.1-2.5-3.2c-0.2-1.3-0.4-2.5-0.6-3.8c-4.1-7.5-8.2-15-12.3-22.6c-0.2-1-0.4-2-0.6-2.9 c-0.7-0.9-1.5-1.8-2.2-2.7l0,0c-1.4-2.9-2.9-5.7-4.3-8.6c-0.3-0.5-0.7-1-1-1.6c-1.7-3.7-3.4-7.4-5.1-11.2c-0.1-0.5-0.1-1.1-0.2-1.6 C1002.4,391.2,1002.2,375,1002,358.8z"
          ></path>
          <path
            className="st8"
            d="M1002,358.8c0.2,16.2,0.4,32.5,0.6,48.7c-1.2-0.6-2.4-1.2-3.6-1.8c-0.7-0.6-1.4-1.3-2.2-1.9 c-18.8-1.1-37.5-2.3-56.3-3.4c-0.1-0.2-0.1-0.5,0-0.7c10.2-7,19.9-15.2,30.8-20.9c23.1-12.2,35.9-26,14.3-50.8 c-3.5-4-3.8-10.9-5.6-16.4c12.1,1.4,24.2,2.7,36.2,4.1c0.2,0.4,0.3,0.8,0.5,1.2C1011.9,330.9,1006.9,344.8,1002,358.8z"
          ></path>
          <path
            className="st21"
            d="M1099,491.6c-6.5,12-13,24.1-19.6,36.1c-0.1-2.1-0.2-4.1-0.3-6.2c-1.3-7.4-2.6-14.9-3.9-22.3 c0-1.1-0.1-2.2-0.1-3.3c-1.2-10.1-2.4-20.2-3.6-30.3c0.1-2.4,0.3-4.8,0.4-7.2c5.5-10.9,10.9-21.8,16.4-32.7c0.1,0.1,0.3,0.1,0.4,0.2 c3.8,20.7,7.6,41.5,11.4,62.2C1099.8,489.4,1099.4,490.5,1099,491.6z"
          ></path>
          <path
            className="st11"
            d="M1071.9,458.5c-0.1,2.4-0.3,4.8-0.4,7.2c-0.5,1.7-1,3.5-1.5,5.2c-2.1,8.9-4.1,17.8-6.2,26.8 c-11.8,12.8-23.5,25.5-35.3,38.3c10.4-24.4,20.9-48.7,31.2-73.1c1.4-3.3,1.8-7,2.7-10.5c-0.6-22.1-1.2-44.1-1.8-66.2 c0-1.2,0.3-2.4,0.5-3.6c0.4,1.2,1,2.5,1.1,3.7C1065.6,410.3,1068.8,434.4,1071.9,458.5z"
          ></path>
          <path
            className="st11"
            d="M1054,381.3c0,10.8-0.3,21.7,0.1,32.5c0.2,4.6,1.8,9.1,2.8,13.6c-2.5-4.2-5.7-8.1-7.3-12.6 c-10.4-30.7-20.4-61.5-30.4-92.3c-0.4-1.1,0-2.5,0-3.8c0.8,1,1.7,2,2.5,3c8.6,12.1,17.7,23.9,25.7,36.4c2.3,3.5,1.2,9.1,1.9,13.7 c0.4,2.9,1.3,5.7,2,8.5c1.1-1.2,2.1-2.4,3.3-3.6c0.3-0.3,1.4-0.4,1.5-0.3c0.7,1.5,1.2,3,1.8,4.5 C1056.5,381.2,1055.3,381.3,1054,381.3z"
          ></path>
          <path
            className="st4"
            d="M1088.8,426c-0.1-0.1-0.3-0.2-0.4-0.2c1.9-5.9,3.7-11.7,5.6-17.6c1.2,0.9,2.4,1.8,3.6,2.8 c6.6,12.1,13.3,24.2,19.8,36.3c0.6,1.1,0.6,2.5,0.9,3.7c-1.2-0.5-2.6-0.8-3.5-1.6C1106,441.7,1097.4,433.8,1088.8,426z"
          ></path>
          <path
            className="st11"
            d="M1061.4,434c-1,3-1.7,5-2.5,7c-1.2-2.9-2.5-5.9-3.4-8.9c-0.1-0.3,2.2-1.8,2.6-1.6 C1059.4,431.5,1060.4,432.9,1061.4,434z"
          ></path>
          <path
            className="st11"
            d="M1099,491.6c0.4-1.1,0.8-2.2,1.2-3.3c7-10,14-20,21-29.9c-2.3,17.7-4.5,35.4-6.8,53 c-1.9,6.9-3.9,13.8-5.8,20.7c-0.3,1.1-0.6,2.3-0.9,3.4c-0.3-1.1-0.6-2.3-0.9-3.4C1104.2,518.6,1101.6,505.1,1099,491.6z"
          ></path>
          <path
            className="st12"
            d="M950.1,242.5c19.7,8.2,39.5,16.5,59.2,24.7c0.7,0.7,1.4,1.5,2.1,2.2c-4.1,6-8.3,12-12.4,17.9 c-3,0.5-6,1-8.9,1.4c-3.9-5.1-7.4-10.5-11.7-15.3C969.1,263,959.6,252.8,950.1,242.5z"
          ></path>
          <path
            className="st10"
            d="M990,288.8c3-0.5,6-1,8.9-1.4c1.3,1.3,2.5,2.5,3.8,3.8c4.5,8.2,9,16.4,13.6,24.6c-12.1-1.4-24.2-2.7-36.2-4.1 c-1.5-0.4-3-0.7-4.5-1.1c-8.5-6.1-16.9-12.1-25.4-18.2c0.1-0.4,0.3-0.8,0.4-1.1c2.4-0.1,4.8-0.2,7.1-0.3c0.3,0,0.6,0,0.9,0 C969.1,290.2,979.6,289.5,990,288.8z"
          ></path>
          <path
            className="st14"
            d="M383.2,719.9c0.9-4.3,1.8-8.7,2.7-13c1.7,1.4,3.4,4,5.2,4c16,0.3,32,0.8,47.9,0.1c15-0.6,29.9-2.6,44.9-4 c3.9,3.6,7.8,7.2,11.7,10.8C458,718.5,420.6,719.2,383.2,719.9z"
          ></path>
          <path
            className="st25"
            d="M486,704c-1.5-0.6-2.9-1.2-4.4-1.8c-3.1-2.4-6.2-4.7-9.3-7.1c-1.5-2.3-2.9-4.6-4.4-7 c11.1-7.6,22.2-15.2,33.4-22.8c0.6-0.5,1.2-0.9,1.9-1.4c0,0,0.1-0.1,0.1-0.1c11.4-11.4,21-6.1,30.1,3.3l0.2,0.1c1.3,1,2.7,2.1,4,3.1 c2.1,1.8,4.1,3.6,6.2,5.3c1.1,0.8,2.2,1.6,3.3,2.4c0.3,0,0.7,0,1,0l0,0c1.1,1.2,2.3,2.4,3.4,3.5c4.2,3.7,8.4,7.4,12.5,11.1 c0.4,0.2,0.7,0.5,1.1,0.7c0,0,0,0.1,0,0.1c0.7,0.8,1.4,1.6,2.1,2.4c-4,3.9-8,7.8-12,11.7C532.1,706.5,509,705.2,486,704z"
          ></path>
          <path
            className="st2"
            d="M472.3,695.1c3.1,2.4,6.2,4.7,9.3,7.1c0.7,1.6,1.5,3.2,2.2,4.9c-14.9,1.4-29.9,3.3-44.9,4 c-15.9,0.7-31.9,0.2-47.9-0.1c-1.7,0-3.4-2.6-5.2-4c1.2-2.5,2.5-5.1,3.7-7.6C417.1,697.9,444.7,696.5,472.3,695.1z"
          ></path>
          <path
            className="st7"
            d="M550.4,712c-0.8,0.4-1.6,0.7-2.4,1.1c-3.9,0.2-7.8,0.4-11.7,0.6c-14.7-1-29.4-2.1-44.2-3.1 c7-1.4,14.1-4.3,21-3.9C525.6,707.5,538,710.1,550.4,712z"
          ></path>
          <path
            className="st22"
            d="M550.4,712c-12.4-1.9-24.8-4.5-37.3-5.2c-6.9-0.4-14,2.5-21,3.9c-2-2.2-4.1-4.5-6.1-6.7 c23,1.2,46.1,2.5,69.1,3.7c-1.2,1.5-2.5,2.9-3.7,4.4C551,712.1,550.7,712,550.4,712z"
          ></path>
          <path
            className="st16"
            d="M103.3,337.2c0.3,0,0.6-0.1,0.9-0.1c1.2-0.7,2.4-1.4,3.6-2.2c1.5-1.5,3-3.1,4.5-4.6c0,0-0.1-0.1-0.1-0.1 c4-2.3,7.9-4.6,11.9-6.9c0,0,0,0,0,0c0.7-0.5,1.3-1.1,2-1.6c0.7-0.1,1.4-0.2,2.1-0.3c-6.2,6-12.4,12-18.5,18c-0.8,0.8-1.4,2-2.1,2.9 c1.1,0.1,2.5,0.6,3.4,0.1c22.4-10.4,44.7-20.8,67-31.3c1.3-0.1,2.7-0.2,4-0.3c0,0,0.1,0.1,0.1,0.1c0.5,5.8,1,11.5,1.4,17.3 c-2.6,0.1-6.4-0.8-7.5,0.5c-7.2,8.3-13.9,17-20.5,25.7c-1.6,2.2-2.5,4.9-3.7,7.4c3.2,0.4,6.5,0.8,9.7,1.2c1.1,0.2,2.3,0.4,3.4,0.6 c0.6,3.4,0.6,9.6,1.8,9.8c7.5,1.3,15.2,1.3,22.8,1.8c0.1,3.2,0.2,6.5,0.3,9.7c-0.1,0.1-0.3,0.3-0.4,0.4c-15-1-30.1-1.6-45-3.7 c-1.4-0.2-1.8-9.3-2.2-14.4c0-0.7,3.1-1.7,4.7-2.6c0.7-0.4,1.9-1.1,1.9-1.2c-4.1-8.9-3.2-11.2,7.6-22.5c2.1-2.2,3-5.5,4.5-8.4 c-4.1,1.1-9.3,0.9-12.1,3.4c-8.7,7.6-16.6,16.2-24.8,24.4c-22.7-18.2-27.5-7-25.4,15.5c1.8-11.1,3.6-22.1,5.2-33.2 C104,341,103.5,339,103.3,337.2z"
          ></path>
          <path
            className="st6"
            d="M219.6,509.7c-17.1-3.6-34.3-7.2-51.4-10.8c0.2-0.9,0.2-2.1,0.7-2.7c8-7.5,16.2-14.8,24.2-22.3 c1-1,1.8-2.7,1.7-4.1c0-0.6-2.2-1.5-3.4-1.8c-8.8-1.9-17.7-3.6-26.5-5.4c4.5-6.3,9-12.5,13.5-18.8c1.5-0.5,2.9-1,4.4-1.5 c8.5,0.5,17,1.1,25.5,1.6c-2.7,8.3-5.5,16.6-8.2,24.8C206.5,482.5,213.1,496.1,219.6,509.7z"
          ></path>
          <path
            className="st28"
            d="M138.4,445.4c2.6,15.5,5.2,30.9,7.8,46.4c0.5,1,1.1,2.1,1.6,3.1c0.3,0,0.7,0.1,1,0.1c-0.1,0.3-0.3,0.7-0.4,1 c-0.1,1.9-0.2,3.8-0.3,5.8c0.2,1.2,0.4,2.5,0.7,3.7c0.5,0.9,0.9,1.8,1.4,2.8c0.2,0.7,0.5,1.3,0.7,2c-0.2,0.4-0.3,0.8-0.5,1.1 c-1.1,0.3-2.5,1.1-3.2,0.7c-8.2-5.2-16.5-10.3-24.4-15.9c-6.4-4.5-12.4-9.4-18.7-14.1C115.5,469.8,126.9,457.6,138.4,445.4z"
          ></path>
          <path
            className="st6"
            d="M138.4,445.4c-11.5,12.2-22.9,24.4-34.4,36.5c6.2,4.7,12.3,9.7,18.7,14.1c8,5.5,16.2,10.7,24.4,15.9 c0.7,0.4,2.1-0.4,3.2-0.7c0.3,0,0.5,0,0.8,0c0.1,1.4,0.1,2.8,0.2,4.2c-0.9,0.2-1.7,0.4-2.6,0.6c-14.3-8.9-30-16.3-42.3-27.5 c-6.3-5.7-10.3-19.3-8-27.2c1.9-6.6,14.2-10.9,22.6-14.5C126,444.7,132.5,445.8,138.4,445.4z"
          ></path>
          <path
            className="st5"
            d="M139.5,403.5c2.9,0.4,5.8,0.6,8.7,1.1c4.6,0.8,9.1,1.9,13.6,2.8c-3.9-2.6-7.5-6.3-11.8-7.5 c-19.2-5.6-30.8-15.8-25.9-37.7c2.7,3.5,8.4,7.7,7.8,10.5c-4.3,18.9,11.7,13.6,19.7,16.6c8.9,3.3,18.5,4.5,27.8,6.7 c-4.7,4.4-9.4,8.8-14,13.1c-1.6,0.5-3.2,0.9-4.7,1.4C153.5,408.1,146.5,405.8,139.5,403.5z"
          ></path>
          <path
            className="st15"
            d="M139.5,403.5c7,2.3,14,4.6,21,6.9c-5.9,7.9-11.8,15.7-17.7,23.6c-2,1.4-3.9,2.8-5.9,4.1 c-5.2-13.1-10.4-26.3-15.6-39.4C127.4,400.3,133.4,401.9,139.5,403.5z"
          ></path>
          <path
            className="st9"
            d="M148.4,496c0.1-0.3,0.3-0.7,0.4-1c-0.4-1.9-0.8-3.9-1.1-5.8c-0.1-0.6-0.1-1.2-0.2-1.8c-0.2-0.9-0.4-1.7-0.5-2.6 c-0.1-0.6-0.1-1.1-0.2-1.7c-0.1-0.6-0.3-1.2-0.4-1.9c-1.9-10.8-3.8-21.6-5.7-32.4c2.9,3.6,7,6.8,8.6,10.9 c4.7,11.8,8.4,23.9,12.5,35.9c0,1.5-0.1,3-0.1,4.5c-4-0.1-7.9-0.1-11.9-0.2c-0.2,0-0.4,0.1-0.5,0.2 C148.9,498.9,148.6,497.4,148.4,496z"
          ></path>
          <path
            className="st0"
            d="M161.7,495.8c-4.1-12-7.8-24.2-12.5-35.9c-1.6-4.1-5.7-7.3-8.6-10.9c0.2-1.6,0.3-3.1,0.5-4.7 c2.1,1.3,4.5,2.2,6.3,3.9c4.4,4.1,8.5,8.5,12.8,12.9C160.6,472.6,161.1,484.2,161.7,495.8z"
          ></path>
          <path
            className="st16"
            d="M182.7,442.4c-1.5,0.5-2.9,1-4.4,1.5c6.9-16.8,14-33.5,20.1-50.6c0.5-1.4-5.9-5.3-9.1-8 c0.1-0.1,0.3-0.3,0.4-0.4c8.8,0.6,17.3,0.4,10.1,13.9c-5.5,10.3-8.5,22-12.5,33C185.8,435.4,184.2,438.9,182.7,442.4z"
          ></path>
          <path
            className="st2"
            d="M142.8,434c5.9-7.9,11.8-15.7,17.7-23.6c1.6-0.5,3.2-0.9,4.7-1.4c6.1-2.5,12.3-5,18.4-7.5 C170,412.3,156.4,423.2,142.8,434z"
          ></path>
          <path
            className="st0"
            d="M183.7,401.5c-6.1,2.5-12.3,5-18.4,7.5c4.7-4.4,9.4-8.8,14-13.1c2.9-1.4,5.8-2.8,8.7-4.2 c-0.6,2.7-1.3,5.5-1.9,8.2C185.2,400.4,184.4,400.9,183.7,401.5L183.7,401.5z"
          ></path>
          <path
            className="st0"
            d="M147.6,489.2c0.4,1.9,0.8,3.9,1.1,5.8c-0.3,0-0.7-0.1-1-0.1c0-0.5-0.1-1-0.1-1.5 C147.7,492,147.6,490.6,147.6,489.2z"
          ></path>
          <path
            className="st6"
            d="M147.7,493.4c0,0.5,0.1,1,0.1,1.5c-0.5-1-1.1-2.1-1.6-3.1C146.7,492.3,147.2,492.9,147.7,493.4z"
          ></path>
          <path
            className="st0"
            d="M146.9,484.8c0.2,0.9,0.4,1.7,0.5,2.6C147.3,486.5,147.1,485.7,146.9,484.8z"
          ></path>
          <path
            className="st2"
            d="M183.6,401.4c0.8-0.5,1.7-1,2.5-1.5C185.2,400.4,184.4,400.9,183.6,401.4z"
          ></path>
          <path
            className="st0"
            d="M146.3,481.3c0.1,0.6,0.3,1.2,0.4,1.9C146.6,482.5,146.4,481.9,146.3,481.3z"
          ></path>
          <path
            className="st28"
            d="M259.4,564.8c8.5,7.6,17,15.3,25.6,22.9c5,4.4,10.3,8.5,15.3,13c0.8,0.7,1,3.5,0.7,3.7c-2.5,1.2-5.1,2-7.7,3 c-16.8-0.4-34.1,1.4-50.3-1.9c-19.9-4.1-39.8-10.7-58.1-19.5c-16.3-7.8-30.4-20.2-45.5-30.5c-0.9-0.6-1.7-1.3-2.5-1.9 c1.2-0.5,2.4-1.2,3.7-1.4c22-3.1,44-6.1,66-9.3c1.5-0.2,2.9-1.6,4.4-2.5c5,0,10-0.1,15-0.1c3.5,1.9,7,3.8,10.5,5.7 C244.1,552.2,251.7,558.5,259.4,564.8z"
          ></path>
          <path
            className="st0"
            d="M237.9,539.9c-4.6-10-9.2-20-13.9-30c0.1-0.5,0.1-1,0.2-1.5c0.6-2.1,1.2-4.3,1.8-6.4c-0.1-0.7-0.2-1.5-0.4-2.2 c0.1-0.4,0.1-0.8,0.2-1.2c0.1-0.8,0.2-1.6,0.2-2.5c0.8-2.3,1.6-4.6,2.3-6.9c0.2-0.7,0.3-1.4,0.5-2.1l0.4,0l0.4-0.2 c11,3.4,22,6.9,33.1,10.3c3.8,17.4,7.6,34.8,11.3,52.1c-2.8,1.1-6.2,3.7-8.2,2.9C256.3,548.7,247.2,544.1,237.9,539.9z"
          ></path>
          <path
            className="st16"
            d="M237.9,539.9c9.3,4.2,18.4,8.8,28,12.3c2.1,0.8,5.5-1.9,8.2-2.9c14.4,11.3,28.8,22.7,43.2,34 c-2.5,6.6-5,13.1-7.5,19.7c-0.2,0.6-0.4,1.3-0.6,1.9c-0.6,2.2-1.3,4.4-1.9,6.6c-0.1,1.5-0.2,3-0.3,4.5c-0.5,0.1-1,0.2-1.5,0.3 c2.4-7.7,5.4-15.2,7-23c0.6-2.7-1.1-7.5-3.2-8.8c-24.7-15.1-49.8-29.7-74.7-44.4C235.7,540,236.8,540,237.9,539.9z"
          ></path>
          <path
            className="st13"
            d="M224.2,508.4c-0.1,0.5-0.1,1-0.2,1.5c-1.5-0.1-3-0.1-4.5-0.2c-6.5-13.6-13.1-27.2-19.6-40.8 c3.2-1.5,6.4-3.1,9.6-4.6c0.8,0.6,2.2,1,2.4,1.8c3.9,13.6,7.7,27.2,11.5,40.8C223.7,507.4,223.9,507.9,224.2,508.4z"
          ></path>
          <path
            className="st0"
            d="M149.6,500.1c4,0,7.9,0.1,11.9,0.2c-3.4,5.1-6.8,10.2-10.3,15.3c-0.1-1.4-0.1-2.8-0.2-4.2 c-0.1-0.4-0.2-0.8-0.3-1.1c-0.2-0.7-0.5-1.3-0.7-2c0-0.4,0.1-0.8,0.1-1.2C150,504.7,149.8,502.4,149.6,500.1z"
          ></path>
          <path
            className="st28"
            d="M689.8,831c-6,11.6-12,23.2-18,34.8c-1-2.1-2-4.3-3-6.4c-0.2-0.2-0.3-0.4-0.5-0.7c-0.5-0.8-0.9-1.7-1.4-2.5 c-1-2.3-2.1-4.5-3.2-6.8c-0.3-1-0.5-1.9-0.8-2.9c0-0.3-0.2-0.5-0.4-0.7c-0.3-0.9-0.6-1.7-0.9-2.6c0-0.3-0.2-0.5-0.5-0.5 c-0.9-1.7-1.9-3.4-2.8-5.1c-0.2-1.8-0.5-3.5-0.7-5.3C668.4,831.9,679.1,831.4,689.8,831z"
          ></path>
          <path
            className="st5"
            d="M663.8,849.4c1.1,2.3,2.1,4.5,3.2,6.8C665.9,854,664.8,851.7,663.8,849.4z"
          ></path>
          <path
            className="st5"
            d="M661.3,842.8c0.3,0.1,0.4,0.3,0.5,0.5C661.5,843.2,661.4,843,661.3,842.8z"
          ></path>
          <path
            className="st5"
            d="M662.6,845.9c0.2,0.2,0.3,0.4,0.4,0.7C662.7,846.4,662.6,846.2,662.6,845.9z"
          ></path>
          <path
            className="st5"
            d="M668.3,858.7c0.2,0.2,0.3,0.4,0.5,0.7C668.5,859.3,668.3,859,668.3,858.7z"
          ></path>
          <path
            className="st7"
            d="M724.7,163c0.9-16.3,1.8-32.5,2.7-48.8c0.1-2.1,0.2-4.2,0.3-6.4c6.5,5.4,12.8,11,19.5,16.2 c4.2,3.3,8.9,6,13.4,9c-0.4,0.6-0.9,1.2-1.3,1.8c-14.4,1.1-29.9,24.8-23.1,35.5C732.4,168,728.5,165.5,724.7,163z"
          ></path>
          <path
            className="st12"
            d="M759.3,135c0.4-0.6,0.9-1.2,1.3-1.8c0.2-0.1,0.3-0.2,0.3-0.5c0.4-0.6,0.9-1.2,1.3-1.9c0,0-0.1,0.2-0.1,0.2 c0.4-0.5,0.8-1,1.2-1.6c0,0,0.1-0.2,0.1-0.2c0.3-0.4,0.6-0.8,0.9-1.2c5.8-7.7,11.7-15.4,17.5-23.2c0,0,0.2,0,0.2,0s0-0.2,0-0.2 c0.2-0.3,0.4-0.5,0.7-0.8c0,0,0.2,0,0.2,0s0-0.3,0-0.3c0.3-0.2,0.6-0.5,0.9-0.7c-1.1,9.2-2.3,18.3-3.5,27.5 c-0.1,0.3-0.3,0.5-0.6,0.5c-6.8,1.6-13.5,3.2-20.3,4.9C759.5,135.5,759.4,135.2,759.3,135z"
          ></path>
          <path
            className="st7"
            d="M762.3,130.8c-0.4,0.6-0.9,1.2-1.3,1.9C761.4,132,761.8,131.4,762.3,130.8z"
          ></path>
          <path
            className="st7"
            d="M763.4,129.4c-0.4,0.5-0.8,1-1.2,1.6C762.6,130.4,763,129.9,763.4,129.4z"
          ></path>
          <polygon
            className="st4"
            points="783,103.6 783,103.8 782.8,103.9 782.8,103.6 "
          ></polygon>
          <path
            className="st7"
            d="M764.4,128c-0.3,0.4-0.6,0.8-0.9,1.2C763.8,128.8,764.1,128.4,764.4,128z"
          ></path>
          <polygon
            className="st4"
            points="782.1,104.7 782.1,104.9 781.9,104.9 781.9,104.7 "
          ></polygon>
          <path
            className="st14"
            d="M699.9,139.3c0.5-0.6,1-1.2,1.5-1.9c0.4,0,0.8-0.1,1.2-0.1c3.2,17,6.4,33.9,9.6,50.9c-0.8,1.5-1.5,3-2.3,4.4 c-17.2,8.1-38.1,12.7-50.4,25.6c-8.8,9.2-6.2,29.4-8.7,44.7c-0.7,1.3-1.3,2.6-2,3.9c0.2-14.5,0.5-29,0.7-43.5 c1.3-2.5,2.5-5.1,3.9-7.5C668.8,190.3,684.3,164.8,699.9,139.3z"
          ></path>
          <path
            className="st10"
            d="M712.1,188.3c-3.2-17-6.4-33.9-9.6-50.9c7.4,8.6,14.8,17.1,22.2,25.7c3.8,2.5,7.6,4.9,11.5,7.4 c0,0,0.2,0.2,0.2,0.2c0.3,1.2,0.6,2.5,0.8,3.7c0,0.7,0,1.4,0,2.1c-17.9-14.3-18,4.7-24,12.8C712.8,188.9,712.5,188.6,712.1,188.3z"
          ></path>
          <path
            className="st20"
            d="M791,123.9c-0.5,0.4-1.1,0.9-1.6,1.3C790,124.8,790.5,124.3,791,123.9z"
          ></path>
          <path
            className="st17"
            d="M346.3,608.2c0.9-15.9,1.7-31.7,2.5-47.6c23.5-7.1,47.1-14.2,70.6-21.3c-0.9,1.7-1.8,3.5-2.6,5.2 c-0.2,0.5-0.5,1-0.7,1.5c-1.2,2.1-2.3,4.1-3.5,6.2c-2.6,0.9-6,1.1-7.7,2.8c-13.8,14.4-27.3,29.2-40.9,43.8c-1.1,0-2.2,0-3.2,0.1 c-4,2.8-8,5.7-12,8.5l0,0C347.9,607.7,347.1,607.9,346.3,608.2z"
          ></path>
          <path
            className="st6"
            d="M360.8,598.9c1.1,0,2.2,0,3.2-0.1c6.6-2.3,13.3-4.3,19.8-6.9c3.9-1.6,8.5-3.2,10.7-6.3c6-8.2,10.9-17.3,16.2-26 c0.1,0.1,0.2,0.2,0.3,0.3c-4,9-8.1,18-12.1,27c-1.2,22.6-2.7,45.3-3.4,67.9c-0.3,8.7,1.4,17.5,2.2,26.3c6.6-4.9,13.2-9.8,19.8-14.6 c0.1,2,1,5.1,0.1,5.8c-5.7,4.3-11.8,8-17.8,11.9l0.1,0c-2.3-8.2-8-17.3-6.2-24.6c7.1-29.6-14.4-35-33.5-43.5 c-4.3-1.9-7.6-5.7-11.4-8.7c0,0,0,0,0,0C352.8,604.6,356.8,601.8,360.8,598.9z"
          ></path>
          <path
            className="st16"
            d="M472.3,695.1c-27.6,1.4-55.1,2.8-82.7,4.2c3.5-5,6.9-10.1,10.4-15.1c0,0-0.1,0-0.1,0 c21.9,1.4,43.7,2.8,65.6,4.1c0.8-0.1,1.6-0.1,2.4-0.2C469.3,690.4,470.8,692.8,472.3,695.1z"
          ></path>
          <path
            className="st24"
            d="M853.8,196.8c0.8,0.1,1.6,0.2,2.4,0.2c0.6,0.5,1.2,1,1.7,1.6c0,0,0.1,0.1,0.1,0.1 c11.6,14.8,21.2,31.8,35.2,43.7c13.1,11,30.8,16.5,46.5,24.4l0,0c0.2-0.1,0.5-0.2,0.7-0.3c1,1.3,1.9,2.5,2.9,3.8 c-1.6,9-3.3,18-4.9,26.9c-22.8,2.2-45.7,4.3-68.5,6.5c-1.1-0.4-2.3-0.7-3.4-1.1c-13.6-19.9-27.1-39.7-40.7-59.6 c-9.2,10.7-23.4,20.1-26.4,32.4c-5,20-2.8,41.9-3.5,63c0,1.1,2.2,2.2,3.3,3.3c0.1,0.8,0.1,1.5,0.2,2.3c-1.2,0.4-2.4,0.8-3.7,1.2 c-7.1,0.1-14.2,0.1-21.2,0.2c-0.2-0.1-0.5-0.2-0.7-0.3c7.3-22.8,14.5-45.5,21.8-68.3c0.3-1.1,0.7-2.1,1-3.2 c-0.5-1.4-0.9-2.8-1.4-4.3c-7-12.5-13.9-24.9-20.9-37.4c0.2-1.5,0.3-2.9,0.5-4.4l0-0.1C801.2,217.3,827.5,207,853.8,196.8z"
          ></path>
          <path
            className="st7"
            d="M939.8,266.7c-15.7-7.9-33.4-13.4-46.5-24.4c-14-11.8-23.7-28.9-35.2-43.7c14,8.9,28,17.9,42.1,26.5 c3.2,1.9,7,2.7,10.6,4C920.5,241.8,930.2,254.2,939.8,266.7z"
          ></path>
          <path
            className="st7"
            d="M853.8,196.8c-26.3,10.2-52.7,20.4-79,30.7c10.2-13.6,20.4-27.1,30.6-40.7c10.1,1.7,20.2,3.5,30.3,5.2 c0.7,0.3,1.4,0.6,2.1,1c5.2,0.9,10.4,1.8,15.7,2.7C853.6,196.1,853.7,196.4,853.8,196.8z"
          ></path>
          <path
            className="st19"
            d="M939.8,266.7c-9.7-12.5-19.3-25-29-37.5c0.8,0,1.5,0,2.3-0.1c0.4,0,0.9,0.1,1.3,0.1c9.7,2.9,19.3,5.8,29,8.7 c0.2,0.3,0.5,0.5,0.7,0.8c1.3,0.9,2.7,1.9,4,2.8c0.6,15.7,1.2,31.3,1.8,47c-2.2-6.1-4.3-12.2-6.5-18.3c-1-1.3-1.9-2.5-2.9-3.8 C940.3,266.5,940.1,266.6,939.8,266.7L939.8,266.7z"
          ></path>
          <path
            className="st3"
            d="M944.2,238.8c-0.2-0.3-0.5-0.5-0.7-0.8c-9.1-7.2-18.2-14.3-27.2-21.5c-1.8-1.4-3.2-3.3-4.7-5 c2.3,0,4.6,0.1,6.9,0.1c2,0.5,4,1,6.1,1.5c6.4,1.8,12.9,3.2,19.1,5.4c2.8,1,7.3,4.2,7,5.4C949.3,229.1,946.5,233.9,944.2,238.8z"
          ></path>
          <path
            className="st7"
            d="M858,198.6c-0.6-0.5-1.2-1-1.7-1.6C856.9,197.6,857.4,198.1,858,198.6z"
          ></path>
          <path
            className="st7"
            d="M914.5,229.3c-0.4,0-0.9-0.1-1.3-0.1C913.6,229.2,914,229.3,914.5,229.3z"
          ></path>
          <path
            className="st24"
            d="M754.7,194.6c0.4,0.1,0.8,0.3,1.3,0.4c0.5,1.4,1,2.8,1.5,4.2c-9,18.5-17.9,37.1-26.9,55.6 c-0.2,0.4-0.6,0.7-0.6,1.1c-0.4,28.1-0.8,56.3-1.1,84.4c-0.1,0.8-0.2,1.6-0.4,2.4c-10.7-4.1-21.4-8.2-32.2-12.3 c-0.1-1.3-0.2-2.6-0.3-3.8c1.3-10.5,2.6-21,3.8-31.6c3.7-32.7,7.3-65.3,10.9-98c0.8-2.2,1.6-4.4,2.4-6.6 C727,191.8,740.8,193.2,754.7,194.6z"
          ></path>
          <path
            className="st14"
            d="M805.5,186.8c-10.2,13.6-20.4,27.1-30.6,40.7c0,0,0,0.1,0,0.1c-0.7,1.1-1.4,2.2-2.1,3.3 c-0.2-0.3-0.4-0.6-0.6-0.9c-4.4-10.8-8.9-21.5-13.3-32.3c0-0.5,0-1,0-1.6c-5-13.3,4.8-10.8,11.7-10.7 C782.2,185.5,793.8,186.3,805.5,186.8z"
          ></path>
          <path
            className="st24"
            d="M759.3,135c0.1,0.3,0.1,0.5,0.2,0.7c0,0-0.2,0.2-0.2,0.2c-0.3,0.7-0.6,1.5-0.9,2.2c0,2-0.1,4-0.1,6 c-0.1,0.9-0.1,1.7-0.2,2.6c-0.2,5.1-0.4,10.2-0.7,15.3c0,0.3,0,0.6,0,0.8c-0.8,6.5-1.6,12.9-2.4,19.4c-3.7-2-7.4-3.9-11.1-5.9 c-2.5-1.9-5.1-3.8-7.6-5.7c0,0-0.2-0.2-0.2-0.2C729.4,159.8,744.9,136.1,759.3,135z"
          ></path>
          <path
            className="st7"
            d="M754.7,194.6c-13.9-1.4-27.7-2.8-41.6-4.2c0-0.4,0-0.8,0.1-1.2c6-8.1,6.1-27.1,24-12.8 C743,182.5,748.9,188.5,754.7,194.6z"
          ></path>
          <path
            className="st11"
            d="M758.4,144.2c0-2,0.1-4,0.1-6c0.3-0.7,0.6-1.5,0.9-2.2c0,0,0.2-0.2,0.2-0.2c6.8-1.6,13.5-3.2,20.3-4.9 c-7,16.9-14,33.8-21,50.7c-0.6,0.7-1.2,1.3-1.7,2c0,0-0.2-0.1-0.2-0.1c-0.6-0.4-1.3-0.8-1.9-1.2c0.8-6.5,1.6-12.9,2.4-19.4 c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2-0.1-0.4-0.2-0.6c0.2-5.1,0.4-10.2,0.7-15.3C758.2,145.9,758.3,145,758.4,144.2z"
          ></path>
          <path
            className="st7"
            d="M736.4,170.7c2.5,1.9,5.1,3.8,7.6,5.7c-2.3-0.7-4.5-1.4-6.8-2C736.9,173.1,736.7,171.9,736.4,170.7z"
          ></path>
          <path
            className="st7"
            d="M758.8,196.1c0,0.5,0,1,0,1.6c-0.4,0.5-0.9,1-1.3,1.6c-0.5-1.4-1-2.8-1.5-4.2 C756.9,195.4,757.9,195.7,758.8,196.1z"
          ></path>
          <path
            className="st10"
            d="M837.9,193c-0.7-0.3-1.4-0.6-2.1-1C836.5,192.3,837.2,192.7,837.9,193z"
          ></path>
          <polygon
            className="st0"
            points="757,183.5 757.1,183.6 757,183.6 "
          ></polygon>
          <path
            className="st16"
            d="M97.6,380.2c0.3-1,0.6-1.9,0.9-2.9c-0.1,1.6-0.2,3.1-0.3,4.7C98,381.4,97.8,380.8,97.6,380.2z"
          ></path>
          <path
            className="st11"
            d="M949.9,288.6c-0.6-15.7-1.2-31.3-1.8-47c0.6,0.3,1.3,0.5,1.9,0.8c9.4,10.3,19,20.5,28.3,31 c4.2,4.8,7.8,10.2,11.7,15.3c-10.5,0.7-20.9,1.4-31.4,2.1c-0.3,0-0.6,0-1,0c-2.4,0.1-4.8,0.2-7.1,0.3 C950.4,290.4,950.2,289.5,949.9,288.6z"
          ></path>
          <path
            className="st9"
            d="M100.7,385.9c0.2-9.7,0.4-19.5,0.7-29.2c0-1.3,0.7-2.6,1.1-4c0.9,0.3,2.3,0.3,2.6,0.8 c5.8,9.7,11.9,19.4,17,29.5c1.2,2.3-1,6.3-1.7,9.5c-3.4-1.1-6.9-2.2-10.3-3.3c-3-1-6-2-8.9-3.1C100.9,386.1,100.8,386.1,100.7,385.9 z"
          ></path>
          <path
            className="st6"
            d="M259.4,564.8c-7.6-6.3-15.3-12.6-22.9-18.9C244.1,552.2,251.7,558.5,259.4,564.8z"
          ></path>
          <path
            className="st0"
            d="M305.5,616.4c0.5-0.1,1-0.2,1.5-0.3c-0.2,1.1-0.4,2.2-0.6,3.3c-0.3-0.6-0.6-1.2-0.9-1.8 C305.5,617.1,305.5,616.8,305.5,616.4z"
          ></path>
          <path
            className="st16"
            d="M305.5,617.5c0.3,0.6,0.6,1.2,0.9,1.8c-0.1,0.6-0.2,1.2-0.3,1.8c-0.5,0-1.1-0.1-1.6-0.1c0-1.1,0-2.2,0-3.3 C304.7,617.6,305.1,617.6,305.5,617.5z"
          ></path>
          <path
            className="st17"
            d="M302.8,621.1c-1.1-0.2-2.3-0.4-3.4-0.5C300.5,620.7,301.6,620.9,302.8,621.1z"
          ></path>
          <path
            className="st8"
            d="M863.3,779.4c16-0.8,32.1-1.6,48.1-2.5c-13.9,15.3-27.8,30.7-41.7,46c-0.3-0.1-0.6-0.1-0.8-0.2 C867.1,808.4,865.2,793.9,863.3,779.4z"
          ></path>
          <path
            className="st19"
            d="M863.3,779.4c1.9,14.5,3.7,28.9,5.6,43.4c-15.5-1.2-30.9-2.4-46.4-3.6c13.4-13.4,26.8-26.8,40.2-40.2 C863,779.1,863.2,779.2,863.3,779.4z"
          ></path>
          <path
            className="st6"
            d="M344.9,558.9c-1.5,0.5-3,1.1-4.6,1.6c-1.5,0.1-3,0.1-4.6,0.2c-18.6-3.8-37.3-7.5-55.9-11.3 c-3.1-11.1-6.2-22.2-9.3-33.3c0.5,0.2,1,0.3,1.5,0.5c1,0.4,1.9,0.7,2.9,1.1c0.4,0.1,0.8,0.3,1.2,0.4c1.9,0.7,3.9,1.4,5.8,2.1 c0.6,0.2,1.2,0.3,1.9,0.5c0.7,0.2,1.3,0.4,2,0.6c0.7,0.3,1.4,0.6,2.1,0.9l0.3,0.2l0.3-0.1c1,0.2,1.9,0.4,2.9,0.6l-0.1,0 c11.3,3.9,22.5,7.8,33.8,11.7c8.3,6.7,16.5,13.4,24.8,20.2c0.1,0.3,0.2,0.6,0.3,0.9C348.4,556.8,346.7,557.8,344.9,558.9z"
          ></path>
          <path
            className="st16"
            d="M344.9,558.9c1.7-1,3.5-2.1,5.2-3.1c1.2,0.2,2.9,1,3.7,0.5c18.9-12.3,37.7-24.7,56.4-37.2 c14-9.3,27.8-18.8,41.7-28.3c0,0,0.4,0.1,0.4,0.1c-9.7,15.3-19.5,30.5-29.2,45.8c-1.2,0.9-2.5,1.7-3.7,2.6 c-23.5,7.1-47.1,14.2-70.6,21.3C347.5,560,346.2,559.4,344.9,558.9z"
          ></path>
          <path
            className="st9"
            d="M306,621.1c0.1-0.6,0.2-1.2,0.3-1.8c0.2-1.1,0.4-2.2,0.6-3.3c0.1-1.5,0.2-3,0.3-4.5c0.6-2.2,1.3-4.4,2-6.6 c0.2-0.6,0.4-1.3,0.6-1.9c2.4-1.7,4.9-3.5,7.3-5.2c0.3,3.2,1.5,6.7,0.9,9.7c-6.6,32,18.3,49.8,32.2,72.6c0.7,1.2,1.3,2.6,1.9,3.9 c-1.4,0.1-3.1,0.7-4.2,0.1c-11.3-5.7-26.7-8.8-32.8-18c-7.3-11-6.7-27.2-9.4-41.2C305.4,623.7,305.9,622.4,306,621.1z"
          ></path>
          <path
            className="st17"
            d="M270.5,516.1c3.1,11.1,6.2,22.2,9.3,33.3c14.7,8.5,29.4,17,44.1,25.5c-2.2,2.8-4.5,5.6-6.7,8.4 c-14.4-11.3-28.8-22.7-43.2-34c-3.8-17.4-7.6-34.8-11.3-52.1c1.7-1.4,3.4-2.8,5.1-4.2c0.4,2.3,0.8,4.6,1.2,6.9 c0.1,1.6,0.2,3.2,0.3,4.8c0,1.1,0.1,2.2,0.1,3.3C269.8,510.7,270.2,513.4,270.5,516.1z"
          ></path>
          <path
            className="st5"
            d="M323.9,574.9c-14.7-8.5-29.4-17-44.1-25.5c18.6,3.8,37.3,7.5,55.9,11.3c-3.7,4.2-7.3,8.4-11,12.6 C324.5,573.8,324.2,574.4,323.9,574.9z"
          ></path>
          <path
            className="st17"
            d="M324.8,573.3c3.7-4.2,7.3-8.4,11-12.6c1.5-0.1,3-0.1,4.6-0.2C335.2,564.7,330,569,324.8,573.3z"
          ></path>
          <path
            className="st9"
            d="M357.5,689.5c-0.1,0.3-0.2,0.5-0.3,0.8c-0.3-0.2-0.6-0.3-0.9-0.5c-0.1-0.1,0.1-0.4,0.2-0.6 C356.8,689.2,357.1,689.3,357.5,689.5z"
          ></path>
          <path
            className="st2"
            d="M342.9,692c-0.3,0.1-0.5,0.2-0.7,0.2c-0.1-0.1-0.2-0.4-0.2-0.6c0.2-0.1,0.5-0.2,0.7-0.2 C342.8,691.4,342.8,691.8,342.9,692z"
          ></path>
          <path
            className="st27"
            d="M697.2,683c6-0.9,11.9-1.7,17.9-2.6c2.1,8.1,4.1,16.1,6.2,24.2c-0.1,0.6-0.3,1.1-0.4,1.7 c-21,0.5-42,0.8-63,1.7c-33.5,1.5-66.9,3.4-100.4,5.2c-0.2-0.2-0.4-0.4-0.5-0.6c12-4.9,24-9.9,36-14.8c4.8,0,9.7,0.6,14.4-0.1 c14.3-2.2,28.5-4.9,42.8-7.3c1.6-0.4,3.2-0.7,4.9-1.1c6.6-1.2,13.2-2.4,19.8-3.5c1.2-0.1,2.3-0.1,3.5-0.2c3.1-0.5,6.2-1,9.3-1.6 c0.6-0.1,1.2-0.1,1.7-0.2c1.7-0.2,3.4-0.5,5.1-0.7C695.4,683,696.3,683,697.2,683z"
          ></path>
          <path
            className="st21"
            d="M848,717c-4.1,0.5-8.1,0.9-12.2,1.4C839.9,717.9,844,717.5,848,717z"
          ></path>
          <path
            className="st21"
            d="M863.3,715.7c-2.6,0.3-5.2,0.5-7.8,0.8C858.1,716.3,860.7,716,863.3,715.7z"
          ></path>
          <path
            className="st21"
            d="M833.5,718.5c-1.8,0.2-3.5,0.4-5.3,0.7C830,719,831.8,718.7,833.5,718.5z"
          ></path>
          <path
            className="st16"
            d="M862.3,777.4c-0.1,0.2-0.2,0.4-0.2,0.6c-0.6,0.2-1.1,0.3-1.7,0.5c-9.7-5.6-19.3-11.2-29-16.8 c-1.7-1-3.1-2.3-4.6-3.5c1.9-0.3,3.7-0.6,5.6-0.8c0,0,0,0,0,0c1.2,0.3,2.5,0.6,3.7,0.9c8.9,2.6,17.9,5.1,26.8,7.7 c0,1.1,0,2.2,0.1,3.2c0,0.5-0.1,0.9-0.1,1.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.3-0.2,0.5-0.3,0.8 C862.3,773.6,862.3,775.5,862.3,777.4z"
          ></path>
          <path
            className="st13"
            d="M593,697.7c-12,4.9-24,9.9-36,14.8c-1.9-0.1-3.7-0.3-5.6-0.4c1.2-1.5,2.5-2.9,3.7-4.4c4-3.9,8-7.8,12-11.7 c9,0.2,18,0.4,27,0.6C593.7,697,593.3,697.4,593,697.7z"
          ></path>
          <path
            className="st21"
            d="M905,656.9c-17.2,1.5-34.5,3.4-51.7,4.5c-51.5,3.4-103,6.5-154.5,9.7c0.5-0.8,0.8-1.9,1.5-2.5 c19.4-14.8,38.8-29.4,58.2-44.1c0.5-0.3,1.1-0.5,1.6-0.8c2.3-0.1,4.7-0.1,7-0.2c28,6.8,56,13.7,83.9,20.5 C868.9,648.4,886.9,652.6,905,656.9z"
          ></path>
          <path
            className="st25"
            d="M758.4,624.5c-19.4,14.7-38.8,29.4-58.2,44.1c-0.7,0.5-1,1.6-1.5,2.5c-11.5,4.2-22.9,8.4-34.4,12.7 c-1.3,0.3-2.6,0.7-4,1c-2.3,1.1-4.6,2.3-6.9,3.4c-11.8-4.1-23.6-8.1-35.4-12.2c-1.2-0.7-2.3-1.3-3.5-2c-0.9-0.2-1.8-0.5-2.7-0.7 c-0.6-3.6-3-9.4-1.6-10.6c6.8-5.5,14.6-9.7,22-14.4c0.9-0.4,1.7-0.7,2.6-1.1c3.1-2,6.2-3.9,9.3-5.9 C682.3,635.8,720.3,630.2,758.4,624.5z"
          ></path>
          <path
            className="st12"
            d="M952.7,582.8c21.4-12.8,42.8-25.7,64.3-38.4c2.6-1.5,5.7-2.3,8.5-3.5c-35.5,36.7-70.9,73.5-106.4,110.2 c-0.6,0.6-0.9,1.3-1.4,2c0.8-0.3,1.8-0.4,2.5-0.9c15-9.3,30-18.7,45-28c-8.3,16.5-16.6,33-25,49.5c-7.8-6-15.7-11.9-23.5-17.9 c0.2-0.1,0.3-0.2,0.5-0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.6-0.6-1.2-1.2-1.8-1.8c0.6-1.7,1.1-3.3,1.7-5c1.2-2.3,2.3-4.5,3.5-6.8 c0.3-0.6,0.6-1.1,0.9-1.7c0.3-0.6,0.5-1.1,0.8-1.7c1.1-2,2.3-4,3.4-6c8.3-15.8,16.6-31.7,24.9-47.5 C951,584.6,951.9,583.7,952.7,582.8z"
          ></path>
          <path
            className="st8"
            d="M921.1,640.7c-0.3,0.6-0.6,1.1-0.9,1.7c-1.2,2.3-2.3,4.5-3.5,6.8c-0.6,1.7-1.1,3.3-1.7,5 c-3.4,0.9-6.7,1.8-10.1,2.7c-18-4.3-36-8.5-54-12.9c-28-6.8-56-13.7-83.9-20.5c28.6-3.7,57.3-7.4,85.9-11 c11.3,5.8,22.6,11.7,34,17.3c2.4,1.2,5,2.3,7.6,2.4c7.5,0.2,14.9-0.3,22.4-0.2c2.8,0,5.6,0.7,8.4,1.1c-1.1,2-2.3,4-3.4,6 C921.7,639.6,921.4,640.1,921.1,640.7z M908.3,642.1c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.2-0.1,0.3-0.1 C908.5,642.4,908.4,642.3,908.3,642.1z"
          ></path>
          <path
            className="st1"
            d="M965.3,624.4c-15,9.3-30,18.7-45,28c-0.7,0.5-1.7,0.6-2.5,0.9c0.4-0.7,0.8-1.4,1.4-2 c35.5-36.8,70.9-73.5,106.4-110.2c1-1.7,2-3.4,3-5c11.8-12.8,23.5-25.5,35.3-38.3c-6.8,12.8-13.5,25.5-20.3,38.3 c-0.5,0.9-0.9,1.7-1.4,2.6c-5.7,4.2-11.5,8.4-17.2,12.5c-18.7,23-37.4,46.1-56.2,69.1C967.6,621.6,966.5,623,965.3,624.4z"
          ></path>
          <path
            className="st19"
            d="M968.8,620.2c18.7-23,37.4-46.1,56.2-69.1c-2.9,16.2-5.7,32.5-8.6,48.7c-1.7,0.7-3.4,1.3-5,2 C997.2,607.9,983,614,968.8,620.2z"
          ></path>
          <path
            className="st8"
            d="M968.8,620.2c14.2-6.2,28.4-12.3,42.6-18.5c1.7-0.7,3.4-1.3,5-2c-25.3,24.7-50.7,49.4-76,74.2 c8.3-16.5,16.6-33,25-49.5C966.5,623,967.6,621.6,968.8,620.2z"
          ></path>
          <path
            className="st12"
            d="M660.4,684.8c1.3-0.3,2.7-0.7,4-1c13.4-2.2,26.9-4.5,40.4-6.6c1.3-0.2,2.8,1.1,4.2,1.6 c-1.7,0.3-3.5,0.2-5.1,0.8c-2.3,0.9-4.5,2.2-6.7,3.3c-0.9,0-1.8,0-2.7,0c-1.7,0.2-3.4,0.5-5.1,0.7c-0.6,0.1-1.2,0.1-1.7,0.2 c-3.1,0.5-6.2,1-9.3,1.6c-1.2,0.1-2.3,0.1-3.5,0.2C670,685.4,665.2,685.1,660.4,684.8z"
          ></path>
          <path
            className="st14"
            d="M863,770.6c0-0.5,0.1-0.9,0.1-1.4c0.1,0.3,0.2,0.5,0.3,0.8C863.4,770.3,863.3,770.5,863,770.6z"
          ></path>
          <path
            className="st14"
            d="M862.3,771.7c0.1-0.3,0.2-0.5,0.3-0.8C862.5,771.2,862.4,771.4,862.3,771.7z"
          ></path>
          <path
            className="st19"
            d="M1064.2,598c6.8,9.7,13.7,19.5,20.5,29.2c-8.3,20.6-16.5,41.3-24.8,61.9C1061.4,658.7,1062.8,628.4,1064.2,598 z"
          ></path>
          <path
            className="st13"
            d="M1061.8,592.6c0.1,0.3,0.3,0.6,0.4,0.9C1062,593.1,1061.9,592.8,1061.8,592.6z"
          ></path>
          <path
            className="st15"
            d="M836.2,758.2c-1.2-0.3-2.5-0.6-3.7-0.9C833.8,757.7,835,758,836.2,758.2z"
          ></path>
          <path
            className="st14"
            d="M227.7,333.2c6.2-23.4,11.6-47.2,19.1-70.2c3.5-10.7,11.4-20,17.3-29.9c0.2-0.4,0-1.5-0.3-1.7 c-0.5-0.3-1.5,0.2-2-0.1c-1.8-1.1-3.4-2.4-5.1-3.7c0,0,0,0.1,0,0.1c0.9-1.5,1.8-3,2.7-4.5c3.4-4.7,6.7-9.5,10.1-14.2 c0.4,0.2,0.8,0.4,1.2,0.5c3.7,1,7.4,1.9,11.1,2.9c-8.8,15.5-17.5,31.1-26.5,46.5c-5.5,9.4-13,18-16.5,28.1 C233.7,301.8,231.3,317.7,227.7,333.2z"
          ></path>
          <path
            className="st23"
            d="M401.6,146.7c-1.4,0.9-2.8,1.8-4.1,2.6c-9.4,2.1-18.7,4.1-28.1,6.2c-1.2,0.3-2.5,0.6-3.7,0.8 c0-0.2,0.1-0.3,0.1-0.5c2.8-4.8,4.3-11.8,8.5-14.2c16.4-9.2,33.6-17,50.7-25c1.2-0.5,3.7,1.8,5.6,2.8c0,0,0,0,0,0 C420.9,128.6,411.2,137.6,401.6,146.7z"
          ></path>
          <path
            className="st3"
            d="M322,182.2c2.4-0.9,4.7-1.7,7.1-2.6c1.3,0.5,2.5,1,3.8,1.5c5.3,4.7,10.5,9.4,15.8,14.2c0.8,0.7,1.7,1.5,2.5,2.2 c0.3,0.3,0.6,0.5,0.9,0.8c1.1,1.1,2.2,2.1,3.2,3.2c0.5,0.4,1.1,0.7,1.6,1.1c0.1,0.3,0.2,0.6,0.4,0.9c0,0.3-0.1,0.5-0.4,0.7 c-20.6-0.4-41.2-0.9-61.7-1.3C304.2,195.9,313.1,189.1,322,182.2z"
          ></path>
          <path
            className="st2"
            d="M357,202.5c-0.5-0.4-1.1-0.7-1.6-1.1c-1.1-1.1-2.2-2.1-3.2-3.2c-0.3-0.3-0.6-0.5-0.9-0.8 c-0.8-0.7-1.7-1.5-2.5-2.2c-5.3-4.7-10.5-9.4-15.8-14.2c2.7-2.9,5.3-5.9,8-8.8c0.5-0.4,0.9-0.7,1.4-1.1c4.6-3.1,9.2-6.2,13.8-9.3 c0.6-0.4,1.1-0.8,1.7-1.2c1.6,2.7,3.2,5.5,4.9,8.2c-0.1,0.6-0.1,1.2-0.2,1.8c-0.1,2.6-0.2,5.1-0.3,7.7c0,0.4,0,0.8,0,1.2 C360.4,187.2,358.7,194.9,357,202.5z"
          ></path>
          <path
            className="st14"
            d="M322,182.2c-8.9,6.9-17.9,13.7-26.8,20.6c-0.4,0.1-0.9,0.3-1.3,0.4c-0.5-0.2-0.9-0.4-1.4-0.6 c-5-0.7-10.1-1.4-15.1-2.1C292.3,194.4,307.2,188.3,322,182.2z"
          ></path>
          <path
            className="st2"
            d="M277.4,200.5c5,0.7,10.1,1.4,15.1,2.1c-3.6,3.2-7.2,6.4-10.8,9.7c-3.7-1-7.4-1.9-11.1-2.9c0,0,0.2-0.3,0.2-0.3 c0.9-1.3,1.8-2.6,2.7-3.9C274.9,203.6,276.2,202.1,277.4,200.5z"
          ></path>
          <path
            className="st14"
            d="M362.6,168.9c-1.6-2.7-3.2-5.5-4.9-8.2c2.7-1.6,5.4-3.2,8-4.8c-0.1,0.2-0.1,0.3-0.1,0.5 C364.7,160.6,363.6,164.7,362.6,168.9z"
          ></path>
          <path
            className="st14"
            d="M340.8,172.3c-2.7,2.9-5.3,5.9-8,8.8c-1.3-0.5-2.5-1-3.8-1.5C333,177.2,336.9,174.7,340.8,172.3z"
          ></path>
          <path
            className="st14"
            d="M356.1,161.9c-4.6,3.1-9.2,6.2-13.8,9.3C346.9,168.1,351.5,165,356.1,161.9z"
          ></path>
          <path
            className="st14"
            d="M273.6,205.2c-0.9,1.3-1.8,2.6-2.7,3.9C271.8,207.8,272.7,206.5,273.6,205.2z"
          ></path>
          <path
            className="st15"
            d="M362.6,168.9c1-4.2,2-8.3,3-12.5c1.2-0.3,2.5-0.6,3.7-0.8c9.9-1.5,19.8-3.1,29.7-4.6 c10.1-1.6,20.2-3.1,30.2-4.7c3.3-0.4,6.6-0.8,9.8-1.2c0,0,0.4,0,0.4,0l0.3-0.1c1.5-0.2,3-0.4,4.5-0.7c1.1-0.2,2.3-0.4,3.5-0.6 c0.8-0.1,1.6-0.1,2.4-0.2c0,0,0.4,0,0.4,0l0.3-0.1c0,0,0,0.2,0,0.2c-0.6,1.5-0.7,3.6-1.8,4.3c-12.5,8.2-25.1,16.4-37.9,24.2 c-16.4,9.9-33,19.4-49.5,29.1c0.2-7.2,0.3-14.5,0.5-21.7c0-0.4,0-0.8,0-1.2c0.1-2.6,0.2-5.1,0.3-7.7 C362.5,170.1,362.6,169.5,362.6,168.9z"
          ></path>
          <path
            className="st27"
            d="M401.6,146.7c9.7-9.1,19.3-18.1,29-27.2C420.9,128.6,411.2,137.6,401.6,146.7z"
          ></path>
          <path
            className="st27"
            d="M399,150.9c-9.9,1.5-19.8,3.1-29.7,4.6c9.4-2.1,18.7-4.1,28.1-6.2C398,149.9,398.5,150.4,399,150.9z"
          ></path>
          <path
            className="st3"
            d="M259.4,223.1c-0.9,1.5-1.8,3-2.7,4.5c-0.1-0.9-0.2-1.7-0.3-2.6C257.4,224.4,258.4,223.7,259.4,223.1z"
          ></path>
          <path
            className="st10"
            d="M267.4,468.1c7.2-24.6-6.1-42.2-18.9-60.9c-5.4-8-5.4-19.7-7.8-29.7c1.1,2.6,1.8,5.4,3.3,7.7 c8.9,14.3,18,28.4,27.1,42.6c0.2,0.2,0.4,0.4,0.5,0.7c-0.1,0.5-0.2,1.1-0.2,1.6c-0.3,0.4-0.6,0.8-0.9,1.2c0.5,0.2,1,0.4,1.5,0.6 c2.6,2.8,4.8,6.7,8,8c7.3,3,15.1,4.7,22.7,6.8c4.8,1.3,9.7,2.4,14.6,3.6c0.3-2.8,0.6-5.6,0.9-8.4c8.9,3.2,18,6,26.6,9.8 c5,2.2,9.9,5.5,13.7,9.3c1.9,2,1.5,6.3,2.1,9.6c0.1,0.5,0,1.3,0.3,1.4c11.5,6.8,23,13.6,34.6,20.3c2.9,3.5,5.8,6.9,8.6,10.4 c0.7,1,1.3,2.1,2,3.1c-2.4-1.2-4.7-2.5-7.1-3.7c-5.1-2.4-10.8-4.1-15.2-7.4c-13.1-9.7-25.8-20.1-38.7-30.2c-1.4-1.1-3.3-1.4-5-1.9 c-0.2-0.1-0.9,1.5-1.3,2.3c-2.1,5.2-3.7,14.5-6.4,14.8c-10,1.1-20.3-0.8-30.6-1.3c-11.3-0.6-21.5-18.1-33.8-2.3 C267.8,473.6,267.6,470.9,267.4,468.1z"
          ></path>
          <path
            className="st28"
            d="M230.7,343.9c-0.6-1.8-1.1-3.6-1.7-5.4c1.1-2.1,2.5-4.1,3.1-6.3c3.9-12.7,7.6-25.5,11.4-38.3 c9,8.7,19,16.7,26.5,26.6c3,3.9,0.4,12,0.4,18.2c-1.6,1.6-3.3,3.2-4.9,4.7c-0.3,0.3-0.6,0.6-0.9,1 C253.3,344.3,242,344.1,230.7,343.9z"
          ></path>
          <path
            className="st14"
            d="M187.3,431.9c4.1-11.1,7.1-22.7,12.5-33c7.2-13.5-1.3-13.3-10.1-13.9c-0.1-3.2-0.2-6.5-0.3-9.7 c0.4-0.6,0.9-1.3,1.3-1.9c10.1,1.2,20.3,2.4,30.4,3.6c-0.6,3.9-2.5,8.3-1.4,11.7c1,2.9,5.5,4.6,8.4,6.9c-3.4,9.3-6.8,18.7-10.2,28 C207.8,426.3,197.6,429.1,187.3,431.9z"
          ></path>
          <path
            className="st15"
            d="M230.7,343.9c11.3,0.2,22.6,0.4,33.9,0.6c-4.2,5-9.8,9.5-12.2,15.3c-2,4.8-2,12.1,0.3,16.7 c3.5,7.1,9.8,12.8,14.9,19c2.7,7.7,5.4,15.4,8.1,23.1c0.1,1.5,0.3,3,0.4,4.5c-1.4,1.8-2.9,3.6-4.3,5.3c-0.2-0.2-0.4-0.4-0.5-0.7 c-10.1-18.6-20.3-37.3-30.4-55.9C237.3,362.6,234,353.3,230.7,343.9z"
          ></path>
          <path
            className="st10"
            d="M218,423.5c3.4-9.3,6.8-18.7,10.2-28c2.9-2.6,5.9-5.1,8.8-7.7c-0.2,6.3-0.5,12.6-0.7,18.9 c-0.8,20.8-1.6,41.6-2.4,62.4c-1.4,5.9-2.8,11.9-4.3,17.8l-0.4,0.2c0,0-0.4,0-0.4,0c-0.2-0.5-0.4-1-0.5-1.5 c-0.2-0.8-0.4-1.6-0.5-2.4c0.6-1.5,1.2-2.9,1.8-4.4c0.7-6,2.8-12.3,1.6-17.9c-1.8-9.3-5.8-18.2-8.7-27.3c-0.9-3-1.1-6.3-1.7-9.4 c-0.8,0.5-1.6,0.9-2.3,1.4C218.4,424.9,218.2,424.2,218,423.5z"
          ></path>
          <path
            className="st14"
            d="M229.7,486.9c1.4-5.9,2.8-11.9,4.3-17.8c11.2-0.3,22.3-0.6,33.5-0.9c0.2,2.8,0.4,5.5,0.5,8.3 c0,3.8-0.1,7.6-0.1,11.3c0.1,1,0.3,2,0.4,3.1c0.3,0.2,0.4,0.4,0.2,0.7c-0.2,0.5-0.4,0.9-0.7,1.4c0,0,0,0.1,0,0.1 c-1.7,1.4-3.4,2.8-5.1,4.2C251.7,493.8,240.7,490.3,229.7,486.9z"
          ></path>
          <path
            className="st10"
            d="M184.9,310.7c12.3-6.3,24.5-12.6,36.8-18.8c0.7,6,3.1,12.5,1.6,17.8c-2.2,7.7-7.2,14.6-11.1,21.8 c-0.3,0.6-0.9,0.9-1.4,1.4c-0.2-0.5-0.3-1-0.7-1.4c-3.7-3.9-7.4-7.8-11.1-11.7C194.3,316.8,189.6,313.8,184.9,310.7z"
          ></path>
          <path
            className="st16"
            d="M243.5,294c-3.8,12.8-7.5,25.5-11.4,38.3c-0.7,2.2-2.1,4.2-3.1,6.3c-0.5-1.8-0.9-3.6-1.4-5.4 c3.6-15.5,6-31.4,11.2-46.3c3.5-10.1,11-18.7,16.5-28.1c8.9-15.4,17.7-31,26.5-46.5c3.6-3.2,7.2-6.4,10.8-9.7 c0.5,0.2,0.9,0.4,1.4,0.6c-0.3,0.5-0.7,1-1,1.6c-1.1,1.6-2.2,3.3-3.2,4.9c-0.9,2-1.8,3.9-2.7,5.9c-10.7,18.3-21.5,36.5-32.2,54.8 c-1.3,2.2-2.6,4.5-3.9,6.7c-0.2,0.3-0.5,0.5-0.7,0.8c0.2-0.3,0.5-0.5,0.7-0.8c0.4-0.2,0.8-0.4,1.3-0.6c-0.9,1.2-1.7,2.5-2.6,3.7 c-0.6,1.2-1.2,2.5-1.7,3.7c-1.2,1.7-2.5,3.5-3.7,5.2c-0.4-0.2-0.7-0.4-1.1-0.6c0.2,0.6,0.4,1.2,0.5,1.8 C243.5,291.4,243.5,292.7,243.5,294z"
          ></path>
          <path
            className="st2"
            d="M161.5,363.1c-3.2-0.4-6.5-0.8-9.7-1.2c1.2-2.5,2.1-5.2,3.7-7.4c6.6-8.7,13.3-17.5,20.5-25.7 c1.2-1.3,5-0.4,7.5-0.5c0.9,4.3,1.8,8.5,2.8,12.8c0.1,0.6,0.1,1.2,0.2,1.7C178.2,349.6,169.8,356.3,161.5,363.1z"
          ></path>
          <path
            className="st14"
            d="M161.5,363.1c8.4-6.8,16.7-13.5,25.1-20.3c1.4,7.6,2.8,15.1,4.1,22.7c-8.6-0.6-17.2-1.2-25.8-1.9 C163.7,363.4,162.6,363.3,161.5,363.1z"
          ></path>
          <path
            className="st14"
            d="M186.3,341.1c-0.9-4.3-1.8-8.5-2.8-12.8c-0.5-5.8-1-11.5-1.4-17.3c0.9-0.1,1.9-0.1,2.8-0.2 c4.7,3,9.4,6.1,14.2,9.1C194.8,326.9,190.6,334,186.3,341.1z"
          ></path>
          <path
            className="st20"
            d="M240.7,371.9c10.1,18.6,20.3,37.3,30.4,55.9c-9-14.2-18.1-28.4-27.1-42.6c-1.5-2.3-2.2-5.2-3.3-7.7 C240.7,375.6,240.7,373.8,240.7,371.9z"
          ></path>
          <path
            className="st2"
            d="M164.8,363.6c8.6,0.6,17.2,1.2,25.8,1.9c0.1,0.6,0.1,1.3,0.2,1.9c0,0.4,0,0.9,0,1.3c0,1.5,0,3,0,4.5 c-0.4,0.6-0.9,1.3-1.3,1.9c-7.6-0.4-15.4-0.5-22.8-1.8C165.5,373.2,165.4,367.1,164.8,363.6z"
          ></path>
          <path
            className="st10"
            d="M226.2,277.9c7.3-13.2,14.6-26.3,21.9-39.5C240.8,251.6,233.5,264.8,226.2,277.9z"
          ></path>
          <path
            className="st14"
            d="M190.8,368.7c0-0.4,0-0.9,0-1.3C190.8,367.9,190.8,368.3,190.8,368.7z"
          ></path>
          <path
            className="st2"
            d="M178,311.1c-22.3,10.5-44.6,20.9-67,31.3c-0.9,0.4-2.3-0.1-3.4-0.1c0.7-1,1.3-2.1,2.1-2.9 c6.1-6,12.4-12,18.5-18c0.1-0.2,0.3-0.2,0.5-0.2c1-0.7,2-1.3,3-2c0.1-0.4,0.2-0.7,0.3-1.1c1.3-0.9,2.6-1.7,3.9-2.6 c0.8-0.2,1.7-0.4,2.5-0.5C151.6,313.6,164.8,312.4,178,311.1z"
          ></path>
          <path
            className="st29"
            d="M124.1,323.2c-4,2.3-7.9,4.6-11.9,6.9C116.1,327.8,120.1,325.5,124.1,323.2z"
          ></path>
          <path
            className="st16"
            d="M131.6,319.1c-1,0.7-2,1.3-3,2c0.7-0.7,1.4-1.5,2.1-2.2C131.1,318.9,131.3,319,131.6,319.1z"
          ></path>
          <path
            className="st29"
            d="M126.1,321.6c-0.7,0.5-1.3,1.1-2,1.6C124.8,322.7,125.4,322.2,126.1,321.6z"
          ></path>
          <path
            className="st29"
            d="M106.7,329.2c0.2-0.3,0.5-0.6,0.7-0.9C107.2,328.6,107,328.9,106.7,329.2z"
          ></path>
          <path
            className="st29"
            d="M106,330c0,0,0.3-0.2,0.2-0.2c0,0,0,0.2,0,0.2L106,330z"
          ></path>
          <path
            className="st15"
            d="M131.6,319.1c-0.3-0.1-0.6-0.2-0.9-0.3c0.4-0.3,0.8-0.5,1.2-0.8C131.9,318.4,131.8,318.8,131.6,319.1z"
          ></path>
          <path
            className="st28"
            d="M107.7,334.9c-1.2,0.7-2.4,1.4-3.6,2.2C105.3,336.3,106.5,335.6,107.7,334.9z"
          ></path>
          <path
            className="st22"
            d="M599.1,167.5c-0.3,0.9-0.6,1.8-0.9,2.7c-6.9,31-13.9,62-20.8,93c-0.5-0.2-1-0.5-1.6-0.7 c-14.1-14.5-28.1-29-42.2-43.5c-0.3-0.3-0.7-0.6-1-0.8c0.6-1.2,1.1-2.4,1.7-3.6c16.4-13.8,32.8-27.6,49.2-41.4 c5.3-4.4,10.8-8.7,16.2-13c0.1,1.3,0.2,2.6,0.3,3.9C599.7,165.3,599.4,166.4,599.1,167.5z"
          ></path>
          <path
            className="st0"
            d="M534.4,214.7c-0.6,1.2-1.1,2.4-1.7,3.6c-2.8,20.6-5.6,41.3-8.5,61.9c-2.1,14.5-4.6,29-6.8,43.6 c-3.7-10.4-7.4-20.7-11.1-31.1c0.9-3.2,1.9-6.4,2.8-9.6c4.9-13.5,9.7-26.9,14.6-40.4c4-11,8.2-21.9,12.3-32.9 C535.4,211.5,534.9,213.1,534.4,214.7z"
          ></path>
          <path
            className="st9"
            d="M535.9,209.9c-4.1,11-8.3,21.9-12.3,32.9c-4.9,13.4-9.8,26.9-14.6,40.4c0,0-0.1-0.1-0.1-0.1 c4.1-19.5,8.3-38.9,12.4-58.4c0.2-0.6,0.4-1.2,0.6-1.8c7.2-12.9,14.3-25.7,21.5-38.6c0.4-0.9,0.8-1.8,1.2-2.7c0.2,0,0.5-0.1,0.7-0.1 c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.9,0.2-1.7,0.4-2.6c2.3-0.5,4.6-1.1,6.9-1.6c-0.3,0.7-0.6,1.4-1,2c-5.3,10.1-10.5,20.3-15.8,30.4 C536.2,209.2,536.1,209.5,535.9,209.9z"
          ></path>
          <path
            className="st0"
            d="M553.2,176.4c-2.3,0.5-4.6,1.1-6.9,1.6c4.8-5.3,9.6-10.7,14.4-16C558.2,166.8,555.7,171.6,553.2,176.4z"
          ></path>
          <path
            className="st0"
            d="M536.4,208.8c5.3-10.1,10.5-20.3,15.8-30.4C546.9,188.6,541.6,198.7,536.4,208.8z"
          ></path>
          <path
            className="st22"
            d="M600.4,144.1c0.1,3,0.1,6,0.2,9C600.6,150.1,600.5,147.1,600.4,144.1z"
          ></path>
          <path
            className="st22"
            d="M599.7,110.3c0,1.9,0,3.7,0.1,5.6C599.7,114,599.7,112.2,599.7,110.3z"
          ></path>
          <path
            className="st22"
            d="M600,129.3c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.2-0.3-0.4-0.4-0.7 C599.8,129.5,599.9,129.4,600,129.3z"
          ></path>
          <path
            className="st14"
            d="M564.2,157.3c0.3-0.9,0.6-1.7,0.9-2.6C564.8,155.6,564.5,156.5,564.2,157.3z"
          ></path>
          <path
            className="st0"
            d="M545.9,180.6c-0.2,0.3-0.4,0.5-0.6,0.8C545.5,181.1,545.7,180.9,545.9,180.6z"
          ></path>
          <path
            className="st2"
            d="M699.9,139.3c-15.5,25.5-31.1,51-46.5,76.5c-1.5,2.4-2.6,5-3.9,7.5c-10.1,10.6-20.2,21.1-30.3,31.7 c8.2-14.1,16.3-28.3,24.5-42.4c0.7-1.1,1.3-2.1,2-3.2l0.1-0.2c0.4-0.7,0.9-1.4,1.3-2.1c0,0,0.1-0.2,0.1-0.2c0.8-1.3,1.6-2.6,2.4-3.8 c0,0,0.1,0,0.1,0c4.1-15.1,8.1-30.2,12.2-45.2c0.3,0.7,0.6,1.5,0.9,2.2c11.2-7.7,22.4-15.4,33.8-22.9 C696.9,136.8,698.7,138.5,699.9,139.3z"
          ></path>
          <path
            className="st12"
            d="M661.8,157.8c-4.1,15.1-8.1,30.2-12.2,45.2c-4.2-3.6-8.5-7.3-12.7-10.9c7.8-12.1,15.6-24.2,23.4-36.3 c0.5,0,0.9,0,1.4,0C661.7,156.4,661.7,157.1,661.8,157.8z"
          ></path>
          <path
            className="st22"
            d="M361.7,201.2c16.5-9.7,33.1-19.2,49.5-29.1c12.8-7.8,25.4-16,37.9-24.2c1.1-0.7,1.2-2.8,1.8-4.3 c5.5,0.2,11,0.4,16.5,0.6c15.5,3.5,30.9,6.9,46.4,10.4c0.4,0.4,0.7,0.9,1.1,1.3c-1.7,10.1-3.4,20.3-5.1,30.4 c-3.8-0.6-7.5-1.3-11.3-1.8c-26.7-3.9-53.4-7.7-80.1-11.5c-0.7,0-1.5,0.1-2.2,0.1c0,0.5,0,1,0,1.5c0,0,0.1,0.1,0.1,0.1 c0.6,3,1.2,6.1,1.8,9.1c-2.1-3-5.8-8.9-6.1-8.7c-17.2,9.8-34.1,20.1-51.1,30.3c-1.5,0.5-3,1-4.5,1.5c0.2-1,0.5-1.9,0.7-2.9 c0.2-0.2,0.4-0.4,0.4-0.7C358.8,202.7,360.3,202,361.7,201.2z"
          ></path>
          <path
            className="st3"
            d="M513.8,154.7c-15.5-3.5-30.9-6.9-46.4-10.4c20.3,2,40.6,4.1,60.9,6.1C523.4,151.8,518.6,153.2,513.8,154.7z"
          ></path>
          <path
            className="st26"
            d="M447.7,143.7c-1.2,0.2-2.3,0.4-3.5,0.6C445.4,144.1,446.5,143.9,447.7,143.7z"
          ></path>
          <path
            className="st26"
            d="M450.8,143.5l-0.3,0.1c0,0-0.4,0-0.4,0C450.4,143.5,450.6,143.5,450.8,143.5z"
          ></path>
          <path
            className="st26"
            d="M439.8,145l-0.3,0.1c0,0-0.4,0-0.4,0C439.3,144.8,439.5,144.8,439.8,145z"
          ></path>
          <path
            className="st13"
            d="M543.4,184.2c-7.2,12.9-14.3,25.7-21.5,38.6c-0.3,0.1-0.6,0-0.8-0.1c-3.6-11.9-7.2-23.8-10.8-35.7 c10.8-1.3,21.7-2.7,32.5-4C543,183.4,543.2,183.8,543.4,184.2z"
          ></path>
          <path
            className="st8"
            d="M542.8,183c-10.8,1.3-21.7,2.7-32.5,4c-0.2-0.2-0.4-0.4-0.5-0.6c1.7-10.1,3.4-20.3,5.1-30.4 C524.2,165,533.5,174,542.8,183z"
          ></path>
          <path
            className="st24"
            d="M577.4,263.3c6.9-31,13.9-62,20.8-93c9.8-7.7,19.5-15.4,29.4-23c1.5-1.1,3.6-1.3,5.4-2 c1,15.6,2.1,31.1,3.1,46.7c-0.8,1.3-1.6,2.7-2.4,4c-18.1,21.1-36.1,42.2-54.2,63.4c-0.4,0.5-0.1,1.5-0.2,2.3 c1.2-0.1,2.5,0.1,3.6-0.3c9.2-2.7,18.4-5.5,27.6-8.2c4.1,20.4,8.2,40.8,12.2,61.2c0,0,0.1,0.2,0.1,0.2c0.3,1.5,0.5,2.9,0.8,4.4 c1.8,10.9,3.7,21.8,5.5,32.7c-1.2-1.2-2.8-2.2-3.6-3.6c-16-27.1-31.9-54.2-47.9-81.3c0,0,0,0,0,0 C577.7,265.7,577.6,264.5,577.4,263.3z"
          ></path>
          <path
            className="st13"
            d="M636.2,192c-1-15.6-2.1-31.1-3.1-46.7c0.1-0.3,0.2-0.7,0.3-1c9,3.8,18,7.6,27,11.5 c-7.8,12.1-15.6,24.2-23.4,36.3C636.7,192.1,636.4,192.1,636.2,192z"
          ></path>
          <path
            className="st24"
            d="M599.1,167.5c0.3-1.1,0.6-2.2,0.9-3.3C599.7,165.3,599.4,166.4,599.1,167.5z"
          ></path>
          <path
            className="st27"
            d="M1043.6,535.9c6.8-12.8,13.5-25.5,20.3-38.3c2.1-8.9,4.1-17.8,6.2-26.7c1.7,8.4,3.4,16.7,5.1,25.1 c0,1.1,0.1,2.2,0.1,3.3c1.3,7.4,2.6,14.9,3.9,22.3c0.1,2,0.2,4.1,0.3,6.2c0,0-0.2,0.3-0.2,0.3 C1067.3,530.7,1055.4,533.3,1043.6,535.9z"
          ></path>
          <path
            className="st19"
            d="M852.9,612.5c-28.6,3.7-57.3,7.4-85.9,11c-2.3,0.1-4.7,0.1-7,0.2c-4.2-13-8.3-25.9-12.5-38.9 c15.1-0.9,30.2-1.7,45.3-2.9c19.5-1.5,39-3.5,58.5-5.2c0.6-0.3,1.2-0.5,1.8-0.8c2.4-1.3,4.9-2.5,7.3-3.8c0.8-0.2,1.7-0.5,2.5-0.7 c2.6-1.3,5.3-2.7,7.9-4c0.2-0.2,0.4-0.3,0.7-0.3c6.8-3.4,13.6-6.8,20.5-10.2c11.1-5.8,22.2-11.6,33.3-17.4 c20.9-25.9,41.8-51.8,62.6-77.7c0.2-0.2,0.4-0.5,0.7-0.7c0.3-0.6,0.6-1.3,1-1.9l0,0.3c0.5-0.6,1.1-1.2,1.6-1.9 c0.4-0.1,0.8-0.2,1.2-0.3c0.8,2.1,2.4,4.1,2.3,6.1c-0.7,15.4-1.7,30.8-2.6,46.2c0,0-0.1-0.2-0.1-0.2c0,0.4,0.1,0.8,0.1,1.2 c-13.1,24.1-26.2,48.1-39.2,72.2c-0.8,0.9-1.7,1.8-2.5,2.7C917.8,594.5,885.3,603.5,852.9,612.5z"
          ></path>
          <path
            className="st24"
            d="M991.9,509.7c0.9-15.4,1.9-30.8,2.6-46.2c0.1-2-1.5-4.1-2.3-6.1c1-1.6,2-3.2,3-4.8c0.3-0.2,0.4-0.4,0.3-0.7 c0.2-0.1,0.4-0.2,0.6-0.2c10.8,4,21.5,8,32.3,12.1c1,0.6,2,1.2,3.1,1.8c-11.8,13.6-23.5,27.1-35.3,40.7c-0.5,0.6-1.1,1.2-1.6,1.8 C993.6,508.6,992.7,509.1,991.9,509.7z"
          ></path>
          <path
            className="st19"
            d="M1028.3,463.6c-10.8-4-21.5-8-32.3-12c0-2.5,0-5.1,0-7.6c0.1-1.2,0.2-2.4,0.3-3.6c0.2-3.3,0.4-6.7,0.6-10 c0.1-0.6,0.1-1.3,0.2-1.9c0.2-4.8,0.5-9.5,0.7-14.3c0-0.9,0.1-1.8,0.1-2.8c0.2-0.8,0.4-1.6,0.6-2.4c0.1-0.1,0.2-0.2,0.2-0.2 c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.5-0.2-1-0.2-1.5c0.2-0.3,0.4-0.6,0.7-0.9c1.2,0.6,2.4,1.2,3.6,1.8c0.1,0.5,0.1,1.1,0.2,1.6 c1.7,3.7,3.4,7.4,5.1,11.2c0.3,0.5,0.7,1,1,1.6c1.4,2.9,2.9,5.7,4.3,8.6c0,0,0,0,0,0c0.7,0.9,1.5,1.8,2.2,2.7c0.2,1,0.4,2,0.6,2.9 c4.1,7.5,8.2,15,12.3,22.6c0.2,1.3,0.4,2.5,0.6,3.8C1028.6,462.7,1028.5,463.2,1028.3,463.6z"
          ></path>
          <path
            className="st8"
            d="M1028.2,458.5c-4.1-7.5-8.2-15-12.3-22.6C1020,443.5,1024.1,451,1028.2,458.5z"
          ></path>
          <path
            className="st8"
            d="M1013.1,430.3c-1.4-2.9-2.9-5.7-4.3-8.6C1010.2,424.6,1011.7,427.4,1013.1,430.3z"
          ></path>
          <path
            className="st8"
            d="M1007.8,420.2c-1.7-3.7-3.4-7.4-5.1-11.2C1004.4,412.7,1006.1,416.5,1007.8,420.2z"
          ></path>
          <path
            className="st8"
            d="M1028.3,463.6c0.2-0.5,0.3-0.9,0.5-1.4c0.8,1.1,1.7,2.1,2.5,3.2C1030.3,464.9,1029.3,464.2,1028.3,463.6z"
          ></path>
          <path
            className="st8"
            d="M1015.3,433c-0.7-0.9-1.5-1.8-2.2-2.7C1013.8,431.2,1014.6,432.1,1015.3,433z"
          ></path>
          <path
            className="st19"
            d="M994.5,508c0.5-0.6,1.1-1.2,1.6-1.8C995.5,506.8,995,507.4,994.5,508z"
          ></path>
          <path
            className="st24"
            d="M991.9,510.7c0-0.4-0.1-0.8-0.1-1.2C991.9,509.9,991.9,510.3,991.9,510.7z"
          ></path>
          <path
            className="st19"
            d="M975.6,310.6c1.5,0.4,3,0.7,4.5,1.1c1.8,5.5,2.1,12.4,5.6,16.4c21.5,24.7,8.8,38.6-14.3,50.8 c-10.9,5.8-20.6,13.9-30.8,20.9c-0.1-0.2-0.1-0.4-0.1-0.5c-0.4-0.7-0.9-1.4-1.3-2.1c-0.4-2-0.8-4-1.3-5.9c-0.1-0.5-0.2-1.1-0.3-1.6 c0.1-0.3,0-0.6-0.2-0.8c-0.6-2.6-1.2-5.2-1.8-7.8c-0.1-0.6-0.2-1.1-0.3-1.7c-3.1-15-6.3-29.9-9.4-44.9c0.1-1.6,0.3-3.3,0.4-4.9 c0.5-0.2,0.9-0.3,1.4-0.5C943.7,322.8,959.6,316.7,975.6,310.6z"
          ></path>
          <path
            className="st13"
            d="M937.8,391.1c0.4,2,0.8,4,1.3,5.9c0.4,0.7,0.9,1.4,1.3,2.1c0,0.2,0,0.4,0.1,0.5c0,0.2,0,0.5,0,0.7 c-14.2,12.2-28.4,24.5-42.6,36.7c-0.9,0.1-1.8,0.1-2.7,0.2c0,0,0.1-0.1,0.1-0.1c0-0.4,0-0.8,0-1.2c0-1.3,0-2.6,0-3.9 c1-1.7,2.5-3.4,3-5.3c6.9-23.8,13.6-47.7,20.4-71.6c2.5,5.1,4.8,10.4,7.5,15.4C930,377.6,934,384.3,937.8,391.1z"
          ></path>
          <path
            className="st24"
            d="M898,437.1c14.2-12.2,28.4-24.5,42.6-36.7c18.8,1.1,37.5,2.3,56.3,3.4c-0.6,0.6-1.2,1.2-1.8,1.8 c-5.4,1.5-10.9,2.7-16.2,4.4C951.8,419,924.9,428.1,898,437.1z"
          ></path>
          <path
            className="st13"
            d="M995,405.5c0.6-0.6,1.2-1.2,1.8-1.8c0.7,0.6,1.4,1.3,2.2,1.9c-0.2,0.3-0.4,0.6-0.7,0.9 c-1.3,0.5-2.6,1.1-3.8,1.6C994.7,407.3,994.9,406.4,995,405.5z"
          ></path>
          <path
            className="st25"
            d="M1075.2,496c-1.7-8.4-3.4-16.7-5.1-25.1c0.5-1.7,1-3.5,1.5-5.2C1072.7,475.8,1073.9,485.9,1075.2,496z"
          ></path>
          <path
            className="st25"
            d="M1079.2,521.5c-1.3-7.4-2.6-14.9-3.9-22.3C1076.5,506.7,1077.9,514.1,1079.2,521.5z"
          ></path>
          <path
            className="st15"
            d="M975.6,310.6c-16,6.1-31.9,12.2-47.9,18.3c7.5-12.2,15-24.4,22.5-36.5C958.6,298.4,967.1,304.5,975.6,310.6z"
          ></path>
          <path
            className="st1"
            d="M950.2,292.4c-7.5,12.2-15,24.4-22.5,36.5c-0.5,0.2-0.9,0.3-1.4,0.5c4.1-10.7,8.1-21.4,12.2-32.1 c1.6-9,3.3-18,4.9-26.9c2.2,6.1,4.3,12.2,6.5,18.3c0.2,0.9,0.4,1.7,0.7,2.6C950.5,291.6,950.3,292,950.2,292.4z"
          ></path>
          <path
            className="st1"
            d="M957.7,290.9c0.3,0,0.6,0,1,0C958.4,290.9,958.1,290.9,957.7,290.9z"
          ></path>
          <path
            className="st6"
            d="M467.9,688.1c-0.8,0.1-1.6,0.1-2.4,0.2c0.5-17.6,1-35.1,1.4-52.7c0-2-0.5-3.9-0.8-5.9c-2.6,1.1-5.5,1.7-7.7,3.3 c-7.2,5.2-14,10.7-21,16.1c8-7.3,16-14.7,24-22c0.6-0.6,1.2-1.2,1.8-1.8c0.7,0.2,1.4,0.5,2.1,0.7c0.5,0.3,1,0.6,1.5,0.9 c0.7,0.1,1.3,0.1,2,0.2c1.4,1.1,2.7,2.5,4.2,3.3c14,7.5,28,14.9,42.1,22.4c-3.9,3.7-7.9,7.3-11.8,11c0,0-0.1,0.1-0.1,0.1 c-0.6,0.5-1.2,0.9-1.9,1.4C490.1,672.9,479,680.5,467.9,688.1z M476.2,678.8c-2-0.7-2.9-1.4-3.8-1.3c-0.7,0-1.4,0.9-2.1,1.3 c0.9,0.5,1.7,1.4,2.6,1.5C473.6,680.5,474.4,679.7,476.2,678.8z M477.7,677.1c1-0.9,1.6-1.4,2.2-1.9c-0.3-0.3-0.8-0.9-1-0.8 c-0.6,0.3-1.1,0.7-1.5,1.2C477.2,675.7,477.5,676.3,477.7,677.1z"
          ></path>
          <path
            className="st18"
            d="M611.9,673.4c0.9,0.2,1.8,0.5,2.7,0.7c1.2,0.7,2.3,1.3,3.5,2c11.8,4.1,23.6,8.1,35.4,12.2c0.5,0.3,1,0.7,1.5,1 c-1.6,0.4-3.2,0.7-4.9,1.1c-18.7,2.1-37.4,4.2-56.1,6.3c-9-0.2-18-0.4-27-0.6c-0.7-0.8-1.4-1.6-2.1-2.4c7-4.2,13.9-8.5,20.9-12.7 c4.1-4.3,8.3-8.5,12.4-12.8c0.6-0.1,1.2-0.1,1.7-0.2C604,669.7,607.9,671.6,611.9,673.4z"
          ></path>
          <path
            className="st17"
            d="M585.9,680.9c-7,4.2-13.9,8.5-20.9,12.7c0,0,0-0.1,0-0.1c-0.4-0.2-0.7-0.5-1.1-0.7c-4.2-3.7-8.4-7.4-12.5-11.1 c-1.1-1.2-2.3-2.4-3.4-3.5c0,0,0,0,0,0c-0.5-0.6-1-1.2-1.5-1.8c-0.2-0.2-0.3-0.3-0.4-0.5c-0.2-1.7-0.6-4.9-0.6-4.9 c10.6-1.5,21.2-3.3,31.9-4C588.2,666.2,591.2,671.3,585.9,680.9z"
          ></path>
          <path
            className="st6"
            d="M585.9,680.9c5.3-9.6,2.4-14.7-8.4-14c-10.7,0.7-21.3,2.5-31.9,4c0,0,0.4,3.2,0.6,4.9c-0.8,0-1.6,0-2.3-0.1 c-2.1-1.8-4.1-3.6-6.2-5.3c-1.3-1-2.7-2.1-4-3.1c0,0-0.2-0.1-0.2-0.1c-4-4.5-7.9-8.9-11.9-13.4c1.4-0.4,2.7-0.7,4.1-1.1 c1,0.6,2,1.2,2.9,1.8c0.3,0.2,0.6,0.4,0.8,0.6c23,4.3,46,8.7,68.9,13C594.1,672.4,590,676.6,585.9,680.9z"
          ></path>
          <path
            className="st18"
            d="M521.4,653.8c4,4.5,7.9,8.9,11.9,13.4c-9.1-9.4-18.7-14.7-30.1-3.3c3.9-3.6,7.9-7.3,11.8-11 C517.2,653.2,519.3,653.5,521.4,653.8z"
          ></path>
          <path
            className="st18"
            d="M533.5,667.3c1.3,1,2.7,2.1,4,3.1C536.2,669.4,534.9,668.3,533.5,667.3z"
          ></path>
          <path
            className="st6"
            d="M548,678.2c1.1,1.2,2.3,2.4,3.4,3.5C550.3,680.5,549.1,679.3,548,678.2z"
          ></path>
          <path
            className="st18"
            d="M543.7,675.8c0.8,0,1.6,0,2.3,0.1c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.6,0.4,1.2,0.5,1.8 C545.9,677.4,544.8,676.6,543.7,675.8z"
          ></path>
          <path
            className="st18"
            d="M501.2,665.3c0.6-0.5,1.2-0.9,1.9-1.4C502.5,664.4,501.9,664.8,501.2,665.3z"
          ></path>
          <path
            className="st6"
            d="M547,678.2c-0.2-0.6-0.4-1.2-0.5-1.8c0.5,0.6,1,1.2,1.5,1.8C547.7,678.2,547.4,678.2,547,678.2z"
          ></path>
          <path
            className="st18"
            d="M563.9,692.8c0.4,0.2,0.7,0.5,1.1,0.7C564.6,693.2,564.3,693,563.9,692.8z"
          ></path>
          <path
            className="st15"
            d="M218,423.5c0.2,0.7,0.4,1.4,0.6,2c-1.1,3.3-2.2,6.6-3.3,9.8c-1.5,2.8-2.9,5.6-4.4,8.5 c-0.9,0.1-1.8,0.2-2.8,0.2c-8.5-0.5-17-1.1-25.5-1.6c1.5-3.5,3.1-7.1,4.6-10.6C197.6,429.1,207.8,426.3,218,423.5z"
          ></path>
          <path
            className="st26"
            d="M208.2,444.1c0.9-0.1,1.8-0.2,2.8-0.2c1,5.8,1.9,11.6,2.9,17.4c0,0.2-0.1,0.4,0,0.6c-1.4,0.9-2.8,1.7-4.2,2.6 c-3.2,1.5-6.4,3.1-9.6,4.6C202.7,460.6,205.4,452.4,208.2,444.1z"
          ></path>
          <path
            className="st17"
            d="M149.6,500.1c0.2,2.3,0.4,4.6,0.6,7c-0.5-0.5-1-1-1.5-1.6c-0.2-1.2-0.4-2.5-0.7-3.7c0.4-0.5,0.7-1,1.1-1.5 C149.2,500.2,149.4,500.1,149.6,500.1z"
          ></path>
          <path
            className="st6"
            d="M149.1,500.3c-0.4,0.5-0.7,1-1.1,1.5c0.1-1.9,0.2-3.8,0.3-5.8C148.6,497.4,148.9,498.9,149.1,500.3z"
          ></path>
          <path
            className="st6"
            d="M148.7,505.5c0.5,0.5,1,1,1.5,1.6c0,0.4-0.1,0.8-0.1,1.2C149.6,507.3,149.1,506.4,148.7,505.5z"
          ></path>
          <path
            className="st17"
            d="M150.8,510.2c0.1,0.4,0.2,0.8,0.3,1.1c-0.3,0-0.5,0-0.8,0C150.4,510.9,150.6,510.6,150.8,510.2z"
          ></path>
          <path
            className="st21"
            d="M209.6,464.3c1.4-0.9,2.8-1.7,4.2-2.6c4.7,7.1,9.4,14.2,14,21.3c0.2,0.8,0.4,1.6,0.5,2.4 c-0.2,0.9-0.5,1.9-0.7,2.8c-0.5,2.6-1.1,5.2-1.6,7.8c-0.1,0.8-0.2,1.6-0.2,2.5c-0.1,0.4-0.1,0.8-0.2,1.2c0.1,0.7,0.2,1.5,0.4,2.2 c-0.9,1.6-1.7,3.2-2.6,4.9c-3.8-13.6-7.6-27.2-11.5-40.8C211.8,465.4,210.4,464.9,209.6,464.3z"
          ></path>
          <path
            className="st8"
            d="M226.1,496.1c0.5-2.6,1.1-5.2,1.6-7.8c0.3,0.3,0.5,0.6,0.8,0.8C227.7,491.5,226.9,493.8,226.1,496.1z"
          ></path>
          <path
            className="st5"
            d="M223.5,506.9c0.9-1.6,1.7-3.2,2.6-4.9c-0.6,2.1-1.2,4.3-1.8,6.4C223.9,507.9,223.7,507.4,223.5,506.9z"
          ></path>
          <path
            className="st7"
            d="M228.5,489.2c-0.3-0.3-0.5-0.6-0.8-0.8c0.2-0.9,0.5-1.9,0.7-2.8c0.2,0.5,0.4,1,0.5,1.5 C228.8,487.7,228.6,488.5,228.5,489.2z"
          ></path>
          <path
            className="st8"
            d="M225.7,499.8c0.1-0.4,0.1-0.8,0.2-1.2C225.8,499,225.7,499.4,225.7,499.8z"
          ></path>
          <path
            className="st0"
            d="M309.2,604.9c-0.7,2.2-1.3,4.4-2,6.6C307.9,609.3,308.5,607.1,309.2,604.9z"
          ></path>
          <path
            className="st15"
            d="M650.7,262.9c2.5-15.3-0.2-35.5,8.7-44.7c12.3-12.8,33.2-17.4,50.4-25.6C690.1,216.1,670.4,239.5,650.7,262.9z "
          ></path>
          <path
            className="st16"
            d="M619.1,254.9c10.1-10.5,20.2-21.1,30.3-31.7c-0.2,14.5-0.5,29-0.7,43.5c-0.1,0.2-0.2,0.2-0.4,0.2 c-10.4-3.3-20.8-6.5-31.2-9.8C617.7,256.5,618.4,255.7,619.1,254.9z"
          ></path>
          <path
            className="st22"
            d="M648.3,267c0.2,0,0.3,0,0.4-0.2c0.7-1.3,1.3-2.6,2-3.9c19.7-23.4,39.4-46.8,59.1-70.2c0.8-1.5,1.5-3,2.3-4.4 c0.4,0.3,0.7,0.6,1.1,0.9c0,0.4,0,0.8-0.1,1.2c-0.8,2.2-1.6,4.4-2.4,6.6c-20.2,24.7-40.3,49.4-60.5,74.1c-0.6-0.1-1.2-0.1-1.8-0.2 C648.4,269.7,648.3,268.3,648.3,267z"
          ></path>
          <path
            className="st5"
            d="M410.8,559.6c-5.3,8.7-10.2,17.7-16.2,26c-2.3,3.1-6.9,4.8-10.7,6.3c-6.5,2.6-13.2,4.7-19.8,6.9 c13.6-14.7,27.1-29.4,40.9-43.8c1.7-1.8,5.1-1.9,7.7-2.8C412,554.7,411.4,557.1,410.8,559.6z"
          ></path>
          <path
            className="st6"
            d="M410.8,559.6c0.6-2.5,1.2-4.9,1.9-7.4c1.1-2.1,2.3-4.1,3.5-6.2c0.2-0.5,0.5-1,0.7-1.5c0.9-1.7,1.8-3.5,2.6-5.2 c1.2-0.9,2.5-1.7,3.7-2.6c0.3,0.3,0.5,0.7,0.8,1c-4.3,7.4-8.5,14.8-12.8,22.2C411,559.8,410.9,559.7,410.8,559.6z"
          ></path>
          <path
            className="st5"
            d="M360.8,598.9c-4,2.8-8,5.7-12,8.5C352.8,604.6,356.8,601.8,360.8,598.9z"
          ></path>
          <path
            className="st5"
            d="M416.8,544.5c-0.2,0.5-0.5,1-0.7,1.5C416.3,545.5,416.5,545,416.8,544.5z"
          ></path>
          <path
            className="st29"
            d="M411.1,559.9c4.3-7.4,8.5-14.8,12.8-22.2c1.2-0.4,2.5-0.8,3.7-1.2c2.7-0.3,5.4-0.6,8.1-0.9 c0.4,0,0.9,0,1.3,0.1c36.5-1.8,73.1-3.5,109.6-5.4c12.5-0.7,24.9-2,37.4-3c1.1-0.1,2.1-0.2,3.2-0.3c0.8,1.6,1.7,3.2,2.5,4.8 c0.3,0.5,0.7,1.1,1,1.6c7.9,9.1,15.8,18.2,23.7,27.3c0.3,0.3,0.6,0.6,0.9,0.9c0.9,1.7,1.7,3.4,2.6,5c-2,1.7-3.9,4.3-6.2,4.9 c-17.2,4.5-34.6,8.5-51.9,12.7c-1.7,0.3-3.4,0.6-5.1,0.8c-0.8,0-1.5,0.1-2.3,0.1c-0.3-0.5-0.7-1.1-1-1.6c2.7-1.7,5.5-3.3,8.2-5 c-2.1-0.6-4.4-1.9-6.4-1.7c-25.5,2.6-50.9,6.7-76.4,7.9c-21.6,1.1-43.3-1-64.9-1.7c-2-0.1-4.1,0.2-6.1,0.4c0.2,1.3-0.2,3.3,0.6,3.8 c5.3,4,10.9,7.7,16.4,11.6c-7.9-4-15.9-8-23.8-12C403,577.9,407.1,568.9,411.1,559.9z"
          ></path>
          <path
            className="st18"
            d="M399,586.9c7.9,4,15.9,8,23.8,12c0.5,0.4,1.1,0.7,1.6,1.1c0.8,0.9,1.5,2,2.5,2.7c11.4,8.1,22.9,16.2,34.4,24.3 c-8,7.3-16,14.7-24,22l0,0.1c-6.4,5.6-12.8,11.3-19.2,16.9c-0.2,0.1-0.3,0.2-0.5,0.4c-6.6,4.9-13.2,9.8-19.8,14.6 c-0.8-8.8-2.4-17.5-2.2-26.3C396.3,632.2,397.8,609.6,399,586.9z"
          ></path>
          <path
            className="st5"
            d="M437.3,649.1c7-5.4,13.9-10.9,21-16.1c2.2-1.6,5.1-2.2,7.7-3.3c0.3,2,0.9,3.9,0.8,5.9 c-0.4,17.6-0.9,35.1-1.4,52.7c-21.9-1.4-43.7-2.8-65.6-4.1c6-3.9,12.1-7.6,17.8-11.9c0.9-0.7,0-3.8-0.1-5.8c0.1-0.2,0.3-0.3,0.5-0.4 C424.5,660.4,430.9,654.8,437.3,649.1L437.3,649.1z"
          ></path>
          <path
            className="st7"
            d="M799.6,344c-0.1-0.8-0.1-1.5-0.2-2.3c10.3-15,20.7-30.1,31-45.1c12.1,2,24.2,4.1,36.2,6.1 c1.1,0.4,2.3,0.7,3.4,1.1c0.3,0.3,0.5,0.5,0.8,0.8c6,10.4,12.1,20.7,18.1,31.1C859.1,338.4,829.3,341.2,799.6,344z"
          ></path>
          <path
            className="st19"
            d="M799.6,344c29.8-2.8,59.5-5.6,89.3-8.4c0,0,0,0.1,0,0.1c0,0.3,0,0.6,0.1,0.9c-0.4,0.1-0.9,0.1-1.3,0.2 c-0.8,0.5-1.5,1.1-2.3,1.6c-1.1,0.2-2.3,0.2-3.3,0.6c-34.8,16.9-69.5,33.8-104.3,50.7c-1.7,0.8-3.7,1.2-5.6,1.7 c0.2-1.6,0.4-3.1,0.6-4.7c1.5-2.8,2.9-5.5,4.4-8.3c0.5-0.7,1-1.3,1.4-2c5.8-10.4,11.5-20.9,17.3-31.3 C797.1,344.8,798.4,344.4,799.6,344z"
          ></path>
          <path
            className="st8"
            d="M830.3,296.6c-10.3,15-20.7,30.1-31,45.1c-1.2-1.1-3.3-2.2-3.3-3.3c0.6-21.1-1.5-42.9,3.5-63 c3.1-12.2,17.2-21.7,26.4-32.4C827.4,260.9,828.9,278.7,830.3,296.6z"
          ></path>
          <path
            className="st19"
            d="M795.7,276.8c-7.3,22.8-14.5,45.5-21.8,68.3c-0.2,0-0.4,0-0.7,0c-0.1-0.3-0.3-0.6-0.4-0.9 c-0.2-0.6-0.4-1.3-0.6-1.9c-6.1-15.2-12.1-30.4-18.2-45.6c4-14.8,8-29.6,12-44.5l0,0c1.7-2.2,3.3-4.4,5-6.6c0.8,2,2.6,4.4,2.1,6 c-2.2,7-5.4,13.7-7.7,20.7c-0.6,1.9,0.8,4.5,1.4,6.8c0.5-0.4,1.3-0.7,1.4-1.1c0.8-3.2,1.4-6.4,2-9.6c0.8,3.7,0.6,8.1,2.6,10.9 c1.4,2,5.7,2,8.8,2.7c1.2,0.3,3.3-0.1,3.6,0.4c1.3,3.1,2.2,6.3,3.2,9.5c0.3-4.1,0.1-8.3,1.2-12.2 C790.1,278.4,793.6,277.8,795.7,276.8z"
          ></path>
          <path
            className="st21"
            d="M870.8,304.5c-0.3-0.3-0.5-0.5-0.8-0.8c22.8-2.2,45.7-4.3,68.5-6.5c-4.1,10.7-8.1,21.4-12.2,32.1 c-0.1,1.6-0.3,3.3-0.4,4.9c-0.5-0.3-0.9-0.6-1.4-1c-0.8-0.9-1.5-1.7-2.3-2.6C905.1,322,887.9,313.3,870.8,304.5z"
          ></path>
          <path
            className="st23"
            d="M830.3,296.6c-1.5-17.8-2.9-35.7-4.4-53.5c13.6,19.9,27.1,39.7,40.7,59.6C854.5,300.6,842.4,298.6,830.3,296.6 z"
          ></path>
          <path
            className="st22"
            d="M795.7,276.8c-2.1,1-5.6,1.6-6,3c-1.1,3.9-0.9,8.1-1.2,12.2c-1-3.2-1.9-6.5-3.2-9.5c-0.2-0.5-2.4-0.1-3.6-0.4 c-3-0.8-7.4-0.7-8.8-2.7c-2-2.8-1.8-7.2-2.6-10.9c-0.7,3.2-1.3,6.4-2,9.6c-0.1,0.5-0.9,0.8-1.4,1.1c-0.5-2.3-2-4.9-1.4-6.8 c2.2-7,5.4-13.7,7.7-20.7c0.5-1.6-1.3-4-2.1-6c-1.7,2.2-3.3,4.4-5,6.6c2-7.5,4-14.9,6-22.4c0.2,0.3,0.4,0.6,0.6,0.9 c0.5,0.4,1.1,0.7,1.6,1.1c7,12.5,13.9,24.9,20.9,37.4c0.5,1.4,0.9,2.8,1.4,4.3C796.4,274.7,796.1,275.8,795.7,276.8z"
          ></path>
          <path
            className="st21"
            d="M795.9,345.2c-5.8,10.4-11.5,20.9-17.3,31.3c-0.5,0.7-1,1.3-1.4,2c-1.5,2.8-2.9,5.5-4.4,8.3 c-0.6-3-1.3-6-1.9-9.1c0-0.2,0.1-0.4,0.2-0.6c0-0.6,0-1.1,0-1.7c0-0.9,0-1.9-0.1-2.8c0-1.3,0.1-2.5,0.1-3.8c0-0.5,0-0.9,0-1.4 c0.5-2.5,1.1-5,1.6-7.4c-0.2-3.9-0.4-7.7-0.6-11.6c0.3-0.5,0.5-1,0.8-1.5c0.6-0.5,1.1-1,1.7-1.5 C781.8,345.3,788.8,345.3,795.9,345.2z"
          ></path>
          <path
            className="st19"
            d="M796.7,273.6c-0.5-1.4-0.9-2.8-1.4-4.3C795.8,270.8,796.2,272.2,796.7,273.6z"
          ></path>
          <path
            className="st7"
            d="M774.4,231.9c-0.5-0.4-1.1-0.7-1.6-1.1c0.7-1.1,1.4-2.2,2.1-3.3C774.7,229,774.6,230.5,774.4,231.9z"
          ></path>
          <path
            className="st8"
            d="M774.7,345.4c-0.6,0.5-1.1,1-1.7,1.5c0.1-0.6,0.2-1.2,0.3-1.8c0.2,0,0.4,0,0.7,0 C774.2,345.2,774.4,345.3,774.7,345.4z"
          ></path>
          <path
            className="st7"
            d="M939.8,266.8c0.2-0.1,0.5-0.2,0.7-0.3C940.3,266.6,940.1,266.7,939.8,266.8z"
          ></path>
          <path
            className="st23"
            d="M577.8,266.8c15.9,27.1,31.8,54.2,47.9,81.3c0.8,1.4,2.4,2.4,3.6,3.6c0,0,0.1-0.3,0.1-0.3s0.3,0,0.3,0 c12.9-3.8,26.1-6.8,38.7-11.5c9-3.4,17.1-9,25.6-13.7c0.7,0.1,1.3,0.2,2,0.3c0.1,1.3,0.2,2.6,0.3,3.8c0,0.7-0.1,1.3-0.1,2 c-2.8,18.2-5.6,36.4-8.5,54.6c-25.3,14.3-50.7,28.5-75.9,42.9c-15.6,8.9-31.2,17.9-46.7,26.9c-2.6,1.1-5.3,2.2-7.9,3.3 c-0.4-0.2-0.8-0.4-1.2-0.6c0.1-0.6,0.3-1.3,0.4-1.9c0.1-2.3,0.1-4.5,0.2-6.8c0.2-1.4,0.3-2.7,0.5-4.1c0.1-0.6,0.2-1.3,0.3-1.9 c0.3-3.7,0.6-7.4,0.8-11.1c0-0.6,0.1-1.1,0.1-1.7c0.1-3.1,0.2-6.1,0.3-9.2c0.3-1,0.6-2,0.9-2.9c0.1-1.1,0.2-2.2,0.2-3.3 c0.1-1.1,0.2-2.2,0.3-3.3c0.5-4.8,1-9.7,1.4-14.5c0.1-0.8,0.2-1.7,0.4-2.5c0-1.1,0-2.2,0.1-3.4c-0.8-19-4.2-38.4-1.6-56.9 C563.6,312.6,571.8,289.9,577.8,266.8z"
          ></path>
          <path
            className="st19"
            d="M696,326.6c-0.7-0.1-1.3-0.2-2-0.3c-14.6-18.4-29.2-36.7-43.8-55.1c20.2-24.7,40.3-49.4,60.5-74.1 c-3.6,32.7-7.3,65.3-10.9,98C698.6,305.5,697.3,316.1,696,326.6z"
          ></path>
          <path
            className="st16"
            d="M772.1,229.9c-2,7.5-4,14.9-6,22.4c0,0,0,0,0,0c-4.3,12.5-8.6,24.9-13,37.4c-1.4,0.1-3.7,0.7-4,0.2 c-6.3-11.6-12.4-23.3-18.5-35c9-18.5,17.9-37.1,26.9-55.6c0.4-0.5,0.9-1,1.3-1.6C763.2,208.4,767.7,219.2,772.1,229.9z"
          ></path>
          <path
            className="st26"
            d="M773.3,345.1c-0.1,0.6-0.2,1.2-0.3,1.8c-0.3,0.5-0.5,1-0.8,1.5c-8.6,9.1-17.2,18.2-25.7,27.3 c6.5-7.7,13.1-15.3,19.4-23.1c1.4-1.7,2-4,3-6c-2.2-0.7-4.4-1.8-6.6-2c-7.2-0.5-14.5-1.8-21.5-0.6c-3.9,0.7-9.8,5.3-10.1,8.5 c-1.2,12.1-0.4,24.4-0.3,36.7c-0.2,0-0.5,0.1-0.7,0.1c-0.2-14.3-0.5-28.6-0.7-42.8c-0.2-1.3-0.4-2.5-0.5-3.8 c0.1-0.8,0.2-1.6,0.4-2.4c1.3-1.2,3-2.2,3.8-3.6c6.8-13.3,13.4-26.7,20-40c0.5,0.1,1,0.1,1.4,0.2c6.1,15.2,12.1,30.4,18.2,45.6 c0.2,0.6,0.4,1.3,0.6,1.9C773,344.6,773.1,344.9,773.3,345.1z"
          ></path>
          <path
            className="st17"
            d="M752.7,296.7c-6.7,13.3-13.3,26.7-20,40c-0.8,1.5-2.5,2.4-3.8,3.6c0.4-28.1,0.7-56.3,1.1-84.4 c0-0.4,0.4-0.7,0.6-1.1c6.1,11.7,12.2,23.4,18.5,35c0.3,0.5,2.6-0.1,4-0.2C753,292,752.8,294.3,752.7,296.7z"
          ></path>
          <path
            className="st8"
            d="M728.5,342.7c0.2,1.3,0.4,2.5,0.5,3.8c-0.3,12.2-0.6,24.3-1,36.5c0,1-0.8,2-1.2,3c-0.6-0.8-1.3-1.6-1.8-2.5 c-9.6-17-19.2-34-28.8-51c0-0.7,0.1-1.3,0.1-2C707,334.5,717.7,338.6,728.5,342.7z"
          ></path>
          <path
            className="st12"
            d="M758.4,144.2c-0.1,0.9-0.1,1.7-0.2,2.6C758.2,145.9,758.3,145,758.4,144.2z"
          ></path>
          <path
            className="st12"
            d="M759.4,135.9c-0.3,0.7-0.6,1.5-0.9,2.2C758.8,137.4,759.1,136.7,759.4,135.9z"
          ></path>
          <path
            className="st12"
            d="M757.5,162c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1-0.1,0.2-0.2,0.3C757.5,162.6,757.5,162.3,757.5,162z"
          ></path>
          <path
            className="st23"
            d="M270.5,516.1c-0.4-2.7-0.7-5.4-1.1-8.1c1.4-2.4,3.6-7.2,4.1-7c17.2,6.2,34.2,12.9,51.3,19.6 c0.2,4.4,0.3,8.8,0.5,13.2c-0.1,0.3-0.2,0.6-0.3,1c-11.3-3.9-22.5-7.8-33.8-11.7c0,0,0.1,0,0.1,0c-1-0.2-1.9-0.4-2.9-0.6 c0,0-0.2-0.3-0.2-0.3l-0.4,0.1c-0.7-0.3-1.4-0.6-2.1-0.9c-0.7-0.2-1.3-0.4-2-0.6c-0.6-0.2-1.2-0.3-1.9-0.5c-1.9-0.7-3.9-1.4-5.8-2.1 c-0.4-0.1-0.8-0.3-1.2-0.4c-1-0.4-1.9-0.7-2.9-1.1C271.5,516.5,271,516.3,270.5,516.1z"
          ></path>
          <path
            className="st13"
            d="M325,534.7c0.1-0.3,0.2-0.6,0.3-1c5.2-2.2,10.4-4.3,15.7-6.5c7,6.7,13.9,13.5,20.9,20.2c-4,2.5-8,4.9-12,7.4 C341.6,548.2,333.3,541.5,325,534.7z"
          ></path>
          <path
            className="st2"
            d="M349.8,554.9c4-2.5,8-4.9,12-7.4c1.1-0.7,2.2-1.4,3.4-2.1c5.7-4.6,11.4-9.3,17-13.9c0.8-0.5,1.6-1,2.3-1.5l0,0 c9-6.3,18-12.7,27-19c7.5-4.7,15-9.4,22.6-14.1c0.9-0.3,1.8-0.5,2.7-0.8l0,0.1c4.2-2.9,8.4-5.8,12.6-8.7c2.3-1.1,4.6-2.2,7-3.3 c-1.5,2.2-3,4.4-4.5,6.6c-13.9,9.4-27.7,19-41.7,28.3c-18.7,12.5-37.6,24.9-56.4,37.2c-0.8,0.5-2.4-0.3-3.7-0.5 C350,555.5,349.9,555.2,349.8,554.9z"
          ></path>
          <path
            className="st17"
            d="M276.1,518.2c1.9,0.7,3.9,1.4,5.8,2.1C280,519.6,278,518.9,276.1,518.2z"
          ></path>
          <path
            className="st17"
            d="M272,516.6c1,0.4,1.9,0.7,2.9,1.1C273.9,517.4,273,517,272,516.6z"
          ></path>
          <path
            className="st17"
            d="M287.9,522.2l0.4-0.1c0,0,0.2,0.3,0.2,0.3c0,0-0.3,0.1-0.3,0.1L287.9,522.2z"
          ></path>
          <path
            className="st17"
            d="M283.8,520.7c0.7,0.2,1.3,0.4,2,0.6C285.1,521.2,284.4,520.9,283.8,520.7z"
          ></path>
          <path
            className="st17"
            d="M291.3,523C291.3,523,291.3,523,291.3,523C291.3,523,291.3,523,291.3,523z"
          ></path>
          <path
            className="st15"
            d="M437,535.7c-0.4,0-0.9,0-1.3-0.1c-2.7,0.3-5.4,0.6-8.1,0.9c-1.2,0.4-2.5,0.8-3.7,1.2c-0.3-0.3-0.5-0.7-0.8-1 c9.7-15.3,19.5-30.5,29.2-45.8c27.8-3.8,55.6-7.6,83.4-11.4c-0.5,0.8-0.9,1.6-1.4,2.4c-3.6,0.7-7.2,1.3-10.8,2c3,1.2,6,2.5,9,3.7 c-2.9,7.6-5.7,15.1-8.6,22.7c-21,5.6-42,10.9-62.9,16.8C452.8,529.4,445,532.8,437,535.7z"
          ></path>
          <path
            className="st3"
            d="M535.7,479.5c-27.8,3.8-55.6,7.6-83.4,11.4c0,0-0.4-0.1-0.4-0.1c1.5-2.2,3-4.4,4.5-6.6c3.1-1.5,6.3-3,9.4-4.6 c0.4,0,0.8-0.1,1.2-0.1c28.4,0.6,56.8,1.2,85.2,1.8c0.3,0,0.6,0.1,0.9,0.1C547.2,480.8,541.5,480.2,535.7,479.5z"
          ></path>
          <path
            className="st7"
            d="M267.8,493c0,0,0-0.1,0-0.1c0.2-0.5,0.4-0.9,0.7-1.4c0.1-0.3,0.1-0.5-0.2-0.7c-0.1-1-0.3-2-0.4-3.1 c0-3.8,0.1-7.6,0.1-11.3c12.4-15.9,22.5,1.7,33.8,2.3c10.2,0.5,20.6,2.5,30.6,1.3c2.7-0.3,4.3-9.6,6.4-14.8c0.3-0.8,1-2.4,1.3-2.3 c1.7,0.4,3.7,0.8,5,1.9c12.9,10,25.6,20.4,38.7,30.2c4.5,3.3,10.1,5,15.2,7.4c-6,1.4-12.3,2.1-17.9,4.3c-4.3,1.7-8,5.2-11.9,7.9 c-6.1,2.1-12.2,4.1-18.3,6.2c-5.8-4.1-11.5-8.3-17.5-12.2c-1.3-0.8-3.3-0.5-4.9-0.7c-0.2-1-0.1-2.8-0.5-2.9 c-15.5-4.5-30.9-9.4-46.7-12.6c-3.3-0.7-7.8,4.9-11.7,7.6c0,0-0.4,0.1-0.4,0.1C268.6,497.6,268.2,495.3,267.8,493z"
          ></path>
          <path
            className="st22"
            d="M269.4,499.8c3.9-2.7,8.5-8.3,11.7-7.6c15.8,3.2,31.2,8.1,46.7,12.6c0.4,0.1,0.4,1.9,0.5,2.9 c-1.2,4.3-2.4,8.5-3.5,12.8c-17.1-6.6-34.1-13.3-51.3-19.6c-0.5-0.2-2.7,4.6-4.1,7c0-1.1-0.1-2.2-0.1-3.3 C269.4,503.1,269.4,501.4,269.4,499.8z"
          ></path>
          <path
            className="st23"
            d="M269.4,499.8c0,1.6-0.1,3.3-0.1,4.9c-0.1-1.6-0.2-3.2-0.3-4.8C269,499.9,269.4,499.8,269.4,499.8z"
          ></path>
          <path
            className="st23"
            d="M594.1,696.6c18.7-2.1,37.4-4.2,56.1-6.3c-14.3,2.5-28.5,5.2-42.8,7.3c-4.7,0.7-9.6,0.1-14.4,0.1 C593.3,697.4,593.7,697,594.1,696.6z"
          ></path>
          <path
            className="st13"
            d="M655,689.2c-0.5-0.3-1-0.7-1.5-1c2.3-1.1,4.6-2.3,6.9-3.4c4.8,0.3,9.6,0.6,14.5,0.9 C668.2,686.9,661.6,688,655,689.2z"
          ></path>
          <path
            className="st13"
            d="M678.3,685.5c3.1-0.5,6.2-1,9.3-1.6C684.5,684.4,681.4,685,678.3,685.5z"
          ></path>
          <path
            className="st13"
            d="M689.4,683.7c1.7-0.2,3.4-0.5,5.1-0.7C692.8,683.3,691.1,683.5,689.4,683.7z"
          ></path>
          <path
            className="st23"
            d="M747.4,584.8c4.2,13,8.3,25.9,12.5,38.9c-0.5,0.3-1.1,0.5-1.6,0.8c-38,5.6-76.1,11.3-114.1,16.9 c-9.3-5.9-18.7-11.9-28-17.8c-2.4-1.7-4.8-3.5-7.1-5.2c-0.6-0.2-1.1-0.5-1.7-0.7c-0.5-0.1-1-0.3-1.5-0.4c-1.4-1-2.9-2-4.3-3 c-0.2,0-0.5,0-0.6-0.2c-3.2-4.8-6.4-9.5-9.6-14.3c10.1-0.4,20.3-0.7,30.4-1.1c1.4-0.2,2.8-0.4,4.3-0.6c1.9-0.5,3.7-0.9,5.6-1.4 c17-5,34-9.9,51-14.9c21.4,0.9,42.7,1.8,64.1,2.7C747,584.6,747.2,584.7,747.4,584.8z"
          ></path>
          <path
            className="st29"
            d="M609.1,618.5c2.4,1.7,4.8,3.5,7.1,5.2c1.4,1.6,3.3,2.9,4,4.7c3,7.9,24.8,1.2,14.7,19c-0.9,0.4-1.7,0.7-2.6,1.1 c-10.8,6.5-21.6,13-32.4,19.5c-0.6,0.1-1.2,0.1-1.7,0.2c-23-4.3-46-8.7-68.9-13c-0.3-0.2-0.6-0.4-0.8-0.6c-1-0.6-2-1.2-2.9-1.8 c20.9-6.9,42-13.3,62.7-20.9c7.3-2.7,13.5-8.2,20.2-12.5C608.6,619,608.9,618.7,609.1,618.5z"
          ></path>
          <path
            className="st26"
            d="M600,667.9c10.8-6.5,21.6-13,32.4-19.5c-7.4,4.7-15.2,8.9-22,14.4c-1.4,1.1,0.9,6.9,1.6,10.6 C607.9,671.6,604,669.7,600,667.9z"
          ></path>
          <path
            className="st26"
            d="M634.9,647.3c10-17.8-11.8-11.1-14.7-19c-0.7-1.8-2.6-3.1-4-4.7c9.3,5.9,18.7,11.9,28,17.8 C641.1,643.4,638,645.4,634.9,647.3z"
          ></path>
          <path
            className="st26"
            d="M618.1,676.1c-1.2-0.7-2.3-1.4-3.5-2C615.8,674.7,616.9,675.4,618.1,676.1z"
          ></path>
          <path
            className="st21"
            d="M852.9,612.5c32.4-9,64.9-18,97.3-27c-8.3,15.8-16.6,31.7-24.9,47.5c-2.8-0.4-5.6-1-8.4-1.1 c-7.5,0-14.9,0.4-22.4,0.2c-2.6-0.1-5.2-1.2-7.6-2.4C875.5,624.2,864.2,618.3,852.9,612.5z"
          ></path>
          <path
            className="st21"
            d="M916.7,649.2c1.2-2.3,2.3-4.5,3.5-6.8C919.1,644.6,917.9,646.9,916.7,649.2z"
          ></path>
          <path
            className="st21"
            d="M921.1,640.7c0.3-0.6,0.5-1.1,0.8-1.7C921.7,639.6,921.4,640.1,921.1,640.7z"
          ></path>
          <path
            className="st13"
            d="M916.8,656c0.2-0.1,0.4-0.2,0.6-0.3C917.2,655.8,917,655.9,916.8,656C916.9,656,916.8,656,916.8,656z"
          ></path>
          <path
            className="st21"
            d="M908.4,642.2c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.3,0.1C908.2,642.4,908.3,642.3,908.4,642.2z"
          ></path>
          <path
            className="st15"
            d="M357,204.1c-0.2,1-0.5,1.9-0.7,2.9c-3.6,1.8-7.2,3.6-10.9,5.5c-17.7,15.4-47,18.2-51.5,48.1 c-1.2,7.9-3.8,15.7-5.8,23.5c-1.1-5.7-2.8-11.4-3.1-17.2c-0.5-9.1-0.3-18.3,0.1-27.4c0.3-8,1.2-16,1.9-24c0.9-2,1.8-3.9,2.7-5.9 c1.8,0.7,4.4,2.6,5.2,2c1.8-1.2,2.6-3.8,3.9-5.8c-2-0.4-3.9-0.7-5.9-1.1c0.3-0.5,0.7-1,1-1.6c0.4-0.1,0.9-0.3,1.3-0.4 C315.8,203.2,336.4,203.7,357,204.1z"
          ></path>
          <path
            className="st14"
            d="M361.7,201.2c-1.5,0.7-2.9,1.4-4.4,2.1c-0.1-0.3-0.2-0.6-0.4-0.9c1.7-7.6,3.5-15.3,5.2-23 C362,186.8,361.9,194,361.7,201.2z"
          ></path>
          <path
            className="st14"
            d="M352.1,198.3c1.1,1.1,2.2,2.1,3.2,3.2C354.3,200.4,353.2,199.3,352.1,198.3z"
          ></path>
          <path
            className="st14"
            d="M348.6,195.3c0.8,0.7,1.7,1.5,2.5,2.2C350.3,196.8,349.5,196,348.6,195.3z"
          ></path>
          <path
            className="st14"
            d="M362.5,170.7c-0.1,2.6-0.2,5.1-0.3,7.7C362.3,175.8,362.4,173.2,362.5,170.7z"
          ></path>
          <path
            className="st15"
            d="M395.4,492.5c-11.5-6.8-23.1-13.5-34.6-20.3c-0.3-0.2-0.2-0.9-0.3-1.4c-0.6-3.3-0.1-7.6-2.1-9.6 c-3.8-3.9-8.7-7.1-13.7-9.3c-8.7-3.8-17.7-6.6-26.6-9.8c-4.6-3.8-9.1-7.6-13.7-11.4c-0.2-0.6-0.5-1.2-0.7-1.8c0.3-0.1,0.7-0.2,1-0.3 c17,4,34.1,8,51.1,12c0.3,0,0.7,0,1,0c2.5,1.3,5,2.7,7.5,4c0.7,0.1,1.4,0.1,2.2,0.2c0.9,0.6,1.8,1.1,2.8,1.7c6.7,2.3,13.4,4.7,20,7 c-1.5,0.3-3.1,0.6-4.5,1.1c-0.5,0.2-1.1,1.6-1,1.7c9.6,8.6,19.2,17.2,28.9,25.7c-1.1,0-2.3,0.1-3.4,0.1c3.1,2.5,6.2,5,9.3,7.6 c0.2,0.4,0.4,0.8,0.6,1.1c-1.7,0.7-3.3,2-5,2.1C407.9,492.9,401.7,492.6,395.4,492.5z"
          ></path>
          <path
            className="st7"
            d="M304.4,430.6c4.6,3.8,9.1,7.6,13.7,11.4c-0.3,2.8-0.6,5.6-0.9,8.4c-4.9-1.2-9.8-2.3-14.6-3.6 c-7.6-2.1-15.4-3.9-22.7-6.8c-3.2-1.3-5.4-5.3-8-8c-0.2-0.6-0.4-1.2-0.5-1.8c0.1-0.5,0.2-1.1,0.2-1.6c1.4-1.8,2.9-3.5,4.3-5.3 c0.3,0.1,0.6,0.1,0.8,0.2c2.9,1.2,5.6,2.8,8.6,3.5C291.7,428.3,298.1,429.4,304.4,430.6z"
          ></path>
          <path
            className="st4"
            d="M369.1,514.5c3.9-2.7,7.6-6.3,11.9-7.9c5.7-2.2,11.9-2.9,17.9-4.3c2.4,1.2,4.7,2.5,7.1,3.7 c1.8,1.7,3.7,3.3,5.5,5c-9,6.3-18,12.7-27,19c3.6-3.5,7.3-6.9,10.9-10.4c1.7-1.2,3.5-2.4,5.2-3.6c-0.3-0.4-0.8-1.1-1-1 c-2.6,0.9-5.2,1.9-7.8,2.9c-0.2,0-0.4,0.1-0.6,0.2c-6.3-0.2-12.6-0.4-18.9-0.6c-0.5-0.1-1-0.1-1.5-0.2 C370.3,516.3,369.7,515.4,369.1,514.5z"
          ></path>
          <path
            className="st18"
            d="M411.6,511c-1.8-1.7-3.6-3.3-5.5-5c-0.7-1-1.3-2.1-2-3.1c6.5-1.8,13-3.7,19.5-5.5c-1.5-2.2-2.9-4.4-4.4-6.6 c-0.2-0.4-0.4-0.8-0.6-1.1c-2-2.6-3.9-5.1-5.9-7.7c-9.6-8.6-19.3-17.1-28.9-25.7c-0.1-0.1,0.5-1.5,1-1.7c1.4-0.5,3-0.8,4.5-1.1 c1,0,1.9,0,2.9,0c0.5,0,0.9,0.1,1.4,0.1c0.2,0.1,0.5,0.2,0.7,0.2c11.4,14.3,22.7,28.6,34.1,42.9c1.9,0.2,3.8,0.3,5.7,0.5 C426.6,501.6,419.1,506.3,411.6,511z"
          ></path>
          <path
            className="st6"
            d="M419.1,490.8c1.5,2.2,2.9,4.4,4.4,6.6c-6.5,1.8-13,3.7-19.5,5.5c-2.9-3.5-5.8-6.9-8.6-10.4 c6.2,0.2,12.5,0.5,18.7,0.4C415.8,492.8,417.5,491.5,419.1,490.8z"
          ></path>
          <path
            className="st20"
            d="M271.4,430.1c0.2,0.6,0.4,1.2,0.5,1.8c-0.5-0.2-1-0.4-1.5-0.6C270.8,430.9,271.1,430.5,271.4,430.1z"
          ></path>
          <path
            className="st26"
            d="M286.9,215.5c-0.6,8-1.5,16-1.9,24c-0.3,9.1-0.6,18.3-0.1,27.4c0.3,5.8,2,11.4,3.1,17.2 c-2.7,14.7-5.4,29.4-8.1,44.1c-0.1,1.4-0.3,2.8-0.4,4.3c0,0.8,0,1.7,0,2.5c0,4,0.1,8,0.1,12c0.1,19,0.1,38,0.2,57.1 c0.1,1,0.3,1.9,0.4,2.9c-4.2-3.7-8.5-7.5-12.7-11.2c-5.1-6.3-11.3-12-14.9-19c-2.3-4.6-2.3-11.9-0.3-16.7c2.4-5.8,8-10.3,12.2-15.3 c0.3-0.3,0.6-0.6,0.9-1c1.6-1.6,3.3-3.2,4.9-4.7c0-6.2,2.6-14.3-0.4-18.2c-7.5-9.9-17.5-17.9-26.5-26.6c0-1.3,0-2.6,0-3.8 c0.2-0.4,0.4-0.8,0.6-1.1c1.2-1.7,2.5-3.5,3.7-5.2c0.3-0.1,0.6-0.2,0.9-0.3l0,0c0.3-1.1,0.6-2.3,0.8-3.4c0.9-1.2,1.7-2.5,2.6-3.7 c0.9-2,1.8-4,2.6-6C265.4,252,276.2,233.8,286.9,215.5z"
          ></path>
          <path
            className="st15"
            d="M270.4,338.8c-1.6,1.6-3.3,3.2-4.9,4.7C267.1,342,268.8,340.4,270.4,338.8z"
          ></path>
          <path
            className="st23"
            d="M276.8,423.3c-0.3-0.1-0.6-0.1-0.8-0.2c-0.1-1.5-0.3-3-0.4-4.6c1.8-3.3,3.5-6.5,5.3-9.7c1,0.7,2,1.4,3,2 c0.6,0.5,1.1,1.1,1.7,1.6c0.6,0,1.3,0,1.9-0.1l-0.1,0c0.1,0.8,0.1,1.6,0.2,2.4c0.6,0.5,1.2,1,1.8,1.5c0.7,0.4,1.5,0.9,2.2,1.3 c1.2,1.1,2.4,2.1,3.5,3.2l0,0c0.9,0.8,1.7,1.6,2.6,2.4c0,0,0.1,0.1,0.1,0.1c0.2,0.6,0.3,1.1,0.5,1.7 C291.2,424.5,284,423.9,276.8,423.3z"
          ></path>
          <path
            className="st6"
            d="M280.9,408.9c-1.8,3.2-3.5,6.5-5.3,9.7c-2.7-7.7-5.4-15.4-8.1-23.1c4.2,3.8,8.5,7.5,12.7,11.2c0,0,0.1,0,0.1,0 c0.2,0.3,0.4,0.7,0.6,1c-0.1,0.2-0.1,0.4,0,0.6C280.8,408.5,280.7,408.7,280.9,408.9z"
          ></path>
          <path
            className="st7"
            d="M215.3,435.4c1.1-3.3,2.2-6.6,3.3-9.8c0.8-0.5,1.6-0.9,2.3-1.4c0.5,3.1,0.7,6.4,1.7,9.4 c2.9,9.1,6.9,18,8.7,27.3c1.1,5.6-1,11.9-1.6,17.9c-3.8-6.5-7.5-13-11.3-19.5C217.3,451.2,216.3,443.3,215.3,435.4z"
          ></path>
          <path
            className="st8"
            d="M218.3,459.2c3.8,6.5,7.5,13,11.3,19.5c-0.6,1.5-1.2,2.9-1.8,4.4c-4.7-7.1-9.4-14.2-14-21.3c0-0.2,0-0.4,0-0.6 C215.3,460.5,216.8,459.8,218.3,459.2z"
          ></path>
          <path
            className="st17"
            d="M267.9,487.7c0.1,1,0.3,2,0.4,3.1C268.1,489.8,268,488.8,267.9,487.7z"
          ></path>
          <path
            className="st17"
            d="M268.5,491.5c-0.2,0.5-0.4,0.9-0.7,1.4C268.1,492.4,268.3,491.9,268.5,491.5z"
          ></path>
          <path
            className="st26"
            d="M292.9,204.7c2,0.4,3.9,0.7,5.9,1.1c-1.2,2-2.1,4.5-3.9,5.8c-0.9,0.6-3.4-1.2-5.2-2 C290.7,208,291.8,206.3,292.9,204.7z"
          ></path>
          <path
            className="st28"
            d="M254.7,270.3c-0.9,2-1.7,4-2.6,6c-0.4,0.2-0.8,0.4-1.3,0.6l0,0C252.1,274.8,253.4,272.5,254.7,270.3z"
          ></path>
          <path
            className="st28"
            d="M249.5,280.1c-0.3,1.1-0.6,2.3-0.8,3.4c0,0,0,0,0,0c-0.3,0.1-0.6,0.2-0.9,0.3 C248.3,282.5,248.9,281.3,249.5,280.1z"
          ></path>
          <path
            className="st28"
            d="M244,289c-0.2,0.4-0.4,0.8-0.6,1.1c-0.2-0.6-0.4-1.2-0.5-1.8C243.3,288.6,243.7,288.8,244,289z"
          ></path>
          <path
            className="st28"
            d="M250.8,277c-0.2,0.3-0.5,0.5-0.7,0.8C250.3,277.5,250.6,277.3,250.8,277C250.8,277,250.8,277,250.8,277z"
          ></path>
          <path
            className="st17"
            d="M517.3,323.7c2.3-14.5,4.7-29,6.8-43.6c3-20.6,5.7-41.3,8.5-61.9c0.3,0.3,0.7,0.6,1,0.8 c3.9,21.8,7.8,43.7,11.6,65.5c0.6,2.9,1.2,5.7,1.8,8.6c0.3,1.4,0.6,2.9,0.9,4.3c0,0.1-0.2,0.2-0.1,0.2c0,0.2,0.1,0.3,0.2,0.5 c0,0.7,0.1,1.5,0.1,2.2c2.8,13.6,5.6,27.2,8.4,40.8c0,0.6,0.1,1.1,0.1,1.7c-0.6,5.9-0.1,12.2-2.1,17.6c-3.1,8.5-8.1,16.2-11.7,24.4 c-3.5,8-6.3,16.4-9.5,24.6c-4.5-24.2-9.1-48.4-13.6-72.5c-0.2-0.9-0.4-1.8-0.6-2.7c-0.5-2.7-1-5.5-1.4-8.2c-0.2-0.4-0.3-0.7-0.5-1.1 C517.3,324.6,517.3,324.2,517.3,323.7z"
          ></path>
          <path
            className="st26"
            d="M556.7,343c0-0.6-0.1-1.1-0.1-1.7c-2.8-13.6-5.6-27.2-8.4-40.8c0-0.7-0.1-1.5-0.1-2.2c0.1-0.3,0-0.5-0.1-0.7 c-0.3-1.4-0.6-2.9-0.9-4.3c-0.6-2.9-1.2-5.7-1.8-8.6c-3.9-21.8-7.8-43.7-11.6-65.5c14.1,14.5,28.1,29,42.2,43.5 c0.6,1.4,1.3,2.8,1.9,4.2c0,0,0,0,0,0c-6.1,23-14.2,45.7-17.5,69.1c-2.6,18.5,0.8,37.9,1.6,56.9c-0.3-0.1-0.6-0.2-0.8-0.3 C559.6,376.1,558.2,359.6,556.7,343z"
          ></path>
          <path
            className="st23"
            d="M577.8,266.8c-0.6-1.4-1.3-2.8-1.9-4.2c0.5,0.2,1,0.5,1.6,0.7C577.6,264.5,577.7,265.7,577.8,266.8z"
          ></path>
          <path
            className="st20"
            d="M517.3,323.7c0,0.4,0,0.9,0,1.3c-0.4,0.1-0.8,0.3-1.2,0.4c-9.7-11.2-19.5-22.3-29.2-33.5 c3.5,0.3,7.1,0.6,10.6,0.8c2.4,0.2,4.7,0.4,7.1,0.6c0.3-0.1,0.6-0.2,0.9-0.3c0.2-0.2,0.4-0.4,0.7-0.5 C509.9,303,513.6,313.4,517.3,323.7z"
          ></path>
          <path
            className="st9"
            d="M509,283.1c-0.9,3.2-1.9,6.4-2.8,9.6c-0.3,0.1-0.5,0.3-0.7,0.5C506.7,289.8,507.8,286.4,509,283.1L509,283.1z"
          ></path>
          <path
            className="st7"
            d="M521.1,222.7c0.3,0.1,0.5,0.1,0.8,0.1c-0.2,0.6-0.4,1.2-0.6,1.8c-11,2.5-22,4.9-33,7.4 c-0.9-0.1-1.7-0.1-2.6-0.2c-1.7-0.5-3.4-0.9-5.1-1.4c-0.8-1.7-1.1-4-2.4-5c-7.2-5.4-15.2-9.9-21.9-16c-10.1-9.2-19.3-19.4-29.2-28.9 c-1-1-4,0.1-6.1,0.3c-1.7-2-3.3-4.1-5-6.1c0,0-0.1-0.1-0.1-0.1c0-0.5,0-1,0-1.5c0.7,0,1.5-0.1,2.2-0.1c0,0,0.2,0.2,0.2,0.2 c0.4,0.2,0.7,0.4,1.1,0.7C453.4,190.2,487.3,206.5,521.1,222.7z"
          ></path>
          <path
            className="st13"
            d="M488.4,232.1c11-2.5,22-4.9,33-7.4c-4.1,19.5-8.3,38.9-12.4,58.4c-1.1,3.4-2.3,6.8-3.4,10.2 c-0.3,0.1-0.6,0.2-0.9,0.3C499.2,273,493.8,252.5,488.4,232.1z"
          ></path>
          <path
            className="st11"
            d="M619.1,254.9c-0.7,0.8-1.4,1.5-2,2.3c-0.7,2.2-2.2,4.6-1.9,6.7c2.4,16.9,5.1,33.7,7.8,50.5 c-4.1-20.4-8.2-40.8-12.2-61.2c7.7-19.1,15.4-38.1,23.1-57.2c0.8-1.3,1.6-2.7,2.4-4c0.2,0.1,0.5,0.1,0.7,0.1 c4.2,3.6,8.5,7.3,12.7,10.9c0,0-0.1,0-0.1,0c-0.8,1.3-1.6,2.6-2.4,3.8c0,0-0.1,0.2-0.1,0.2c-0.4,0.7-0.9,1.4-1.3,2.1 c0,0-0.1,0.2-0.1,0.2c-0.7,1.1-1.3,2.1-2,3.2C635.4,226.7,627.2,240.8,619.1,254.9z"
          ></path>
          <path
            className="st12"
            d="M647.1,206.8c0.8-1.3,1.6-2.6,2.4-3.8C648.7,204.3,647.9,205.6,647.1,206.8z"
          ></path>
          <path
            className="st12"
            d="M643.6,212.5c0.7-1.1,1.3-2.1,2-3.2C644.9,210.4,644.2,211.4,643.6,212.5z"
          ></path>
          <path
            className="st12"
            d="M645.7,209.1c0.4-0.7,0.9-1.4,1.3-2.1C646.5,207.7,646.1,208.4,645.7,209.1z"
          ></path>
          <path
            className="st23"
            d="M345.4,212.5c3.6-1.8,7.2-3.6,10.9-5.5c1.5-0.5,3-1,4.5-1.5c1,1.5,2.4,2.9,3.1,4.5c8,17.9,40.6,23.8,25.8,52.9 c-7.7,15.1-14.9,30.5-22.3,45.8c-22.1-20.8-44.2-41.6-66.3-62.4c3.6-2.9,7.2-5.8,10.9-8.6C323.1,229.3,334.2,220.9,345.4,212.5z"
          ></path>
          <path
            className="st19"
            d="M367.3,308.8c7.4-15.3,14.6-30.7,22.3-45.8c14.8-29.1-17.8-35-25.8-52.9c-0.7-1.6-2-3-3.1-4.5 c17-10.2,33.9-20.5,51.1-30.3c0.3-0.2,4,5.7,6.1,8.7c1.4,5.8,2.8,11.7,4.2,17.5c-26.6,36.2-26.9,48.6-1.9,78.6 c-17.1,10.5-34.1,20.9-51.2,31.4C368.5,310.6,367.9,309.7,367.3,308.8z"
          ></path>
          <path
            className="st18"
            d="M521.1,222.7c-33.8-16.3-67.6-32.5-101.5-48.8c-0.4-0.2-0.7-0.4-1.1-0.7c0,0-0.2-0.2-0.2-0.2 c26.7,3.8,53.4,7.7,80.1,11.5c3.8,0.5,7.5,1.2,11.3,1.8c0.2,0.2,0.3,0.4,0.5,0.6C513.9,198.9,517.5,210.8,521.1,222.7z"
          ></path>
          <path
            className="st17"
            d="M422.1,201.4c-1.4-5.8-2.8-11.7-4.2-17.5c-0.6-3-1.2-6.1-1.8-9.1c1.7,2,3.3,4.1,5,6.1c0,0,0-0.1,0-0.1 c0.8,1.5,1.7,2.9,2.5,4.4c0,0-0.1,0-0.1,0c7.2,12.4,14.4,24.9,21.6,37.3c-7.7,19.3-15.4,38.6-23.1,57.9c-0.2,0.1-0.4,0-0.6-0.1 C421.7,254,421.9,227.7,422.1,201.4z"
          ></path>
          <path
            className="st17"
            d="M416.1,173.2c0,0.5,0,1,0,1.5C416.1,174.2,416.1,173.7,416.1,173.2z"
          ></path>
          <path
            className="st0"
            d="M622.9,314.5c-2.6-16.8-5.4-33.7-7.8-50.5c-0.3-2.1,1.2-4.5,1.9-6.7c10.4,3.3,20.8,6.5,31.2,9.8 c0,1.3,0.1,2.7,0.1,4c-0.3,0.1-0.4,0.3-0.5,0.6c-1,4.3-1.9,8.6-2.9,12.8c-0.1,0.3-0.2,0.6-0.2,0.9c-0.9,4.2-1.8,8.5-2.8,12.7 c-0.2,0.8-0.3,1.7-0.5,2.5c-1.9,8.5-3.8,16.9-5.7,25.4c-2,8.5-4.1,17-6.1,25.4l-0.3,0c0,0-0.1,0.3-0.1,0.3 c-1.8-10.9-3.7-21.8-5.5-32.7c-0.3-1.5-0.5-2.9-0.8-4.4L622.9,314.5z"
          ></path>
          <path
            className="st8"
            d="M633.8,196c-7.7,19.1-15.4,38.1-23.1,57.2c-9.2,2.8-18.4,5.5-27.6,8.2c-1.1,0.3-2.4,0.2-3.6,0.3 c0-0.8-0.2-1.9,0.2-2.3C597.7,238.2,615.7,217.1,633.8,196z"
          ></path>
          <path
            className="st11"
            d="M623,314.7c0.3,1.5,0.5,2.9,0.8,4.4C623.5,317.6,623.2,316.1,623,314.7z"
          ></path>
          <path
            className="st12"
            d="M851.2,576.7c-19.5,1.8-39,3.7-58.5,5.2c-15.1,1.2-30.2,1.9-45.3,2.9c-0.2-0.1-0.5-0.2-0.7-0.3 c2.3-7.1,4.7-14.2,7-21.4c0.5-0.2,1.1-0.4,1.6-0.6c33.4-6.5,66.9-12.9,100.3-19.4c0.5,0,0.9,0.1,1.4,0.1 C855.1,554.4,853.1,565.6,851.2,576.7z"
          ></path>
          <path
            className="st11"
            d="M994.5,408.3c1.3-0.5,2.6-1.1,3.8-1.6c0.1,0.5,0.2,1,0.2,1.5c0,0.3-0.1,0.6-0.1,0.9c-0.2,0.8-0.4,1.6-0.6,2.4 c0,0.9-0.1,1.8-0.1,2.8c-0.2,4.8-0.5,9.5-0.7,14.3c-0.1,0.6-0.1,1.3-0.2,1.9c-0.2,3.3-0.4,6.7-0.6,10c-0.1,1.2-0.2,2.4-0.3,3.6 c0,2.5,0,5.1,0,7.6c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7c-1,1.6-2,3.2-3,4.8c-0.4,0.1-0.8,0.2-1.2,0.3 c-0.5,0.6-1.1,1.2-1.6,1.9c0,0,0-0.3,0-0.2c-0.3,0.6-0.6,1.3-1,1.9c-0.2,0.2-0.4,0.5-0.7,0.7c-1.9,1.4-4,2.7-5.6,4.3 c-19.5,19.3-38.9,38.7-58.4,58.1c6.6-11,13.1-21.9,19.8-32.9C960.4,463.6,977.4,436,994.5,408.3L994.5,408.3z"
          ></path>
          <path
            className="st11"
            d="M851.2,576.7c1.9-11.1,3.9-22.3,5.8-33.4c0.3-0.2,0.7-0.4,1-0.6c3.2-0.6,6.4-1.1,9.6-1.9 c17.2-4.1,34.4-8.4,51.6-12.5c0.6-0.1,1.2-0.2,1.8-0.3c1.4,3.8,2.7,7.7,4.1,11.5c-11.1,5.8-22.2,11.6-33.3,17.4 c-6.8,3.4-13.6,6.8-20.5,10.2c-0.3,0-0.5,0.2-0.7,0.3c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.2-1.7,0.5-2.5,0.7c-2.4,1.3-4.9,2.5-7.3,3.8 C852.4,576.2,851.8,576.5,851.2,576.7z"
          ></path>
          <path
            className="st1"
            d="M925.1,539.5c-1.4-3.8-2.7-7.7-4.1-11.5c0.1-0.1,0.1-0.3,0.2-0.4c0.6-0.7,1.1-1.3,1.7-2 c0.3-0.5,0.5-0.9,0.8-1.4c19.5-19.4,38.9-38.7,58.4-58.1c1.7-1.6,3.7-2.9,5.6-4.3C966.8,487.7,945.9,513.6,925.1,539.5z"
          ></path>
          <path
            className="st8"
            d="M871.3,567c6.8-3.4,13.6-6.8,20.5-10.2C885,560.2,878.2,563.6,871.3,567z"
          ></path>
          <path
            className="st8"
            d="M862.8,571.4c2.6-1.3,5.3-2.7,7.9-4C868,568.7,865.4,570.1,862.8,571.4z"
          ></path>
          <path
            className="st12"
            d="M853,575.9c2.4-1.3,4.9-2.5,7.3-3.8C857.8,573.4,855.4,574.7,853,575.9z"
          ></path>
          <path
            className="st1"
            d="M989.3,459.5c0.5-0.6,1.1-1.2,1.6-1.9C990.4,458.2,989.9,458.9,989.3,459.5z"
          ></path>
          <path
            className="st1"
            d="M988.4,461.1c0.3-0.6,0.6-1.3,1-1.9C989,459.9,988.7,460.5,988.4,461.1z"
          ></path>
          <path
            className="st13"
            d="M995.1,452.5c0.1-0.2,0.2-0.5,0.3-0.7C995.5,452.1,995.3,452.3,995.1,452.5z"
          ></path>
          <path
            className="st13"
            d="M996,443.9c0.1-1.2,0.2-2.4,0.3-3.6C996.2,441.5,996.1,442.7,996,443.9z"
          ></path>
          <path
            className="st13"
            d="M997.7,414.1c0-0.9,0.1-1.8,0.1-2.8C997.8,412.3,997.8,413.2,997.7,414.1z"
          ></path>
          <path
            className="st13"
            d="M996.9,430.3c0.1-0.6,0.1-1.3,0.2-1.9C997,429,996.9,429.7,996.9,430.3z"
          ></path>
          <path
            className="st8"
            d="M998.4,409c0-0.3,0.1-0.6,0.1-0.9c0,0.2,0.1,0.4,0.1,0.6C998.7,408.9,998.5,408.9,998.4,409z"
          ></path>
          <path
            className="st11"
            d="M924.6,333.3c0.5,0.3,0.9,0.6,1.4,1c3.1,15,6.3,29.9,9.4,44.9c0.1,0.6,0.2,1.1,0.3,1.7 c0.6,2.6,1.2,5.2,1.8,7.8c0,0.1-0.1,0.2-0.1,0.3c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.5,0.2,1.1,0.3,1.6c-3.9-6.8-7.9-13.5-11.6-20.4 c-2.7-5-5-10.3-7.5-15.4c-0.1-1-0.2-2-0.3-3C920.5,346,922.5,339.7,924.6,333.3z"
          ></path>
          <path
            className="st8"
            d="M940.4,399.1c-0.4-0.7-0.9-1.4-1.3-2.1C939.5,397.7,940,398.4,940.4,399.1z"
          ></path>
          <path
            className="st13"
            d="M935.6,380.9c-0.1-0.6-0.2-1.1-0.3-1.7C935.4,379.8,935.5,380.3,935.6,380.9z"
          ></path>
          <path
            className="st13"
            d="M937.5,389.5c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.1-0.3C937.6,388.9,937.6,389.2,937.5,389.5z"
          ></path>
          <path
            className="st8"
            d="M995,405.5c-0.2,0.9-0.4,1.8-0.5,2.7c0,0-0.1,0.1-0.1,0.1c-0.8,0.3-1.7,0.4-2.3,1c-28.6,30-57.1,60-85.6,90 c-0.6,0.3-1.1,0.6-1.7,0.8c-3.2-20-6.4-39.9-9.6-59.9c0-1,0.1-2,0.1-3c0.9-0.1,1.8-0.1,2.7-0.2c26.9-9.1,53.8-18.2,80.8-27.1 C984.1,408.2,989.6,407,995,405.5z"
          ></path>
          <path
            className="st23"
            d="M895.4,437.3c0,1-0.1,2-0.1,3c-17.5,14-35,28.1-52.6,42.1c4-9.7,8.1-19.4,12.1-29.2c4-13.7,8.1-27.4,11.9-41.2 c6.5-23.8,12.8-47.6,19.2-71.5c-0.1-0.7-0.3-1.5-0.4-2.2c0.8-0.5,1.5-1.1,2.3-1.6c0.9,8.4,1.7,16.8,2.6,25.2c0,2.4,0.1,4.8,0.1,7.3 c0,2.1,0.1,4.3,0.1,6.4c1.1,9.3,2.2,18.6,3.3,27.8c0,5.2,0,10.4,0,15.6c0.3,3.5,0.7,7.1,1,10.6c0.3,0.8,0.5,1.7,0.8,2.5 c0,1.3,0,2.6,0,3.9C895.4,436.4,895.4,436.8,895.4,437.3L895.4,437.3z"
          ></path>
          <path
            className="st21"
            d="M890.4,375.6c0-2.1-0.1-4.3-0.1-6.4c0-2.4-0.1-4.8-0.1-7.3c-0.9-8.4-1.7-16.8-2.6-25.2 c0.4-0.1,0.9-0.1,1.3-0.2c9.8,5.3,19.7,10.5,29.5,15.8c0.1,1,0.2,2,0.3,3c-6.8,23.9-13.5,47.8-20.4,71.6c-0.5,1.9-2,3.5-3,5.3 c-0.3-0.8-0.5-1.7-0.8-2.5c-0.3-3.5-0.7-7.1-1-10.6c0-5.2,0-10.4,0-15.6C892.6,394.2,891.5,384.9,890.4,375.6z"
          ></path>
          <path
            className="st8"
            d="M895.4,437.2c0-0.4,0-0.8,0-1.2C895.4,436.4,895.4,436.8,895.4,437.2z"
          ></path>
          <path
            className="st28"
            d="M608.4,619.3c-6.7,4.2-12.9,9.8-20.2,12.5c-20.7,7.6-41.7,14-62.7,20.9c-1.4,0.4-2.7,0.7-4.1,1.1 c-2.1-0.3-4.3-0.6-6.4-0.9c-14-7.5-28.1-14.9-42.1-22.4c-1.5-0.8-2.8-2.2-4.2-3.3c35.9-8.8,71.7-17.5,107.6-26.3 c5.1,3,10.1,6,15.2,9c3.6,2.2,7.2,4.3,10.8,6.5c1.2,0.3,2.4,0.6,3.5,0.9c0.5,0.1,1,0.3,1.5,0.4C607.7,618.3,608.1,618.8,608.4,619.3 z"
          ></path>
          <path
            className="st26"
            d="M576.4,600.9c-35.9,8.8-71.7,17.5-107.6,26.3c-0.7-0.1-1.3-0.1-2-0.2c-0.5-0.3-1-0.6-1.5-0.9 c-0.7-0.2-1.4-0.5-2.1-0.7c0-0.2-0.1-0.3-0.2-0.4c29.5-13.8,59-27.6,88.5-41.4c0.3,0.5,0.7,1.1,1,1.6c0.8,2.1,1.6,4.2,2.4,6.3 c-4-0.1-8.1-0.1-12.1-0.2c-3.9-0.2-8.5-2-11.5-0.4c-18.4,9.5-36.5,19.6-54.7,29.6c-0.8,0.4-1.4,1.2-2.1,1.8c0.9,0,1.9,0.3,2.8,0.1 c31.8-7.4,63.5-14.9,95.3-22.4c1.2,0.2,2.4,0.3,3.6,0.5C576.5,600.6,576.5,600.7,576.4,600.9z"
          ></path>
          <path
            className="st29"
            d="M462.9,624.9c0.1,0.1,0.2,0.2,0.2,0.4c-0.6,0.6-1.2,1.2-1.8,1.8c-11.5-8.1-22.9-16.2-34.4-24.3 c-1-0.7-1.7-1.8-2.5-2.8C437.3,608.3,450.1,616.6,462.9,624.9z"
          ></path>
          <path
            className="st5"
            d="M476.2,678.8c-1.8,0.9-2.6,1.7-3.3,1.5c-0.9-0.2-1.7-1-2.6-1.5c0.7-0.5,1.4-1.3,2.1-1.3 C473.3,677.5,474.2,678.1,476.2,678.8z"
          ></path>
          <path
            className="st5"
            d="M477.7,677.1c-0.2-0.8-0.5-1.3-0.3-1.5c0.4-0.5,1-0.9,1.5-1.2c0.2-0.1,0.6,0.5,1,0.8 C479.3,675.7,478.7,676.2,477.7,677.1z"
          ></path>
          <path
            className="st28"
            d="M465.3,626.1c0.5,0.3,1,0.6,1.5,0.9C466.2,626.7,465.8,626.4,465.3,626.1z"
          ></path>
          <path
            className="st28"
            d="M528.5,654.5c0.3,0.2,0.6,0.4,0.8,0.6C529,654.9,528.8,654.7,528.5,654.5z"
          ></path>
          <path
            className="st22"
            d="M218.3,459.2c-1.5,0.7-3,1.4-4.5,2c-1-5.8-1.9-11.6-2.9-17.4c1.5-2.8,2.9-5.6,4.4-8.5 C216.3,443.3,217.3,451.2,218.3,459.2z"
          ></path>
          <path
            className="st27"
            d="M647.9,271.6c0-0.3,0.2-0.5,0.5-0.6c0.6,0.1,1.2,0.1,1.8,0.2c14.6,18.4,29.2,36.7,43.8,55.1 c-8.5,4.6-16.6,10.3-25.6,13.7c-12.6,4.7-25.8,7.8-38.7,11.5c2-8.5,4.1-17,6.1-25.4c1.9-8.5,3.8-16.9,5.7-25.4 c0.2-0.8,0.3-1.7,0.5-2.5c0.9-4.2,1.8-8.5,2.8-12.7c0.1-0.3,0.2-0.6,0.2-0.9C646,280.2,647,275.9,647.9,271.6z"
          ></path>
          <path
            className="st27"
            d="M590.7,533.3c-0.3-0.5-0.7-1.1-1-1.6c1.4-2.3,2.8-4.7,4.2-7c0.3-0.3,0.6-0.6,0.9-0.9 c7.7-3.9,15.4-7.8,23.1-11.7c0.2,0,0.5-0.1,0.7-0.1c32.5-16.7,64.9-33.3,97.4-50c-6.5,17-13.1,34-19.6,51 c-18,13.3-35.9,26.6-53.9,40c-0.5,0.1-1.1,0.2-1.6,0.3c-6.6,5-13.1,10-19.7,15c-0.6-0.6-1.1-1.1-1.7-1.7c-1.4-1.7-2.9-3.4-4.3-5 c-0.3-0.3-0.6-0.6-0.9-0.9C606.5,551.5,598.6,542.4,590.7,533.3z"
          ></path>
          <path
            className="st28"
            d="M462.9,624.9c-12.8-8.3-25.7-16.6-38.5-24.9c-0.5-0.4-1.1-0.7-1.6-1.1c-5.5-3.8-11-7.5-16.4-11.6 c-0.7-0.6-0.4-2.5-0.6-3.8c2-0.1,4.1-0.4,6.1-0.4c21.6,0.6,43.4,2.7,64.9,1.7c25.5-1.3,50.9-5.3,76.4-7.9c2.1-0.2,4.3,1.1,6.4,1.7 c-2.7,1.7-5.5,3.3-8.2,5C522,597.4,492.4,611.2,462.9,624.9z"
          ></path>
          <path
            className="st26"
            d="M437,535.7c8-2.9,15.8-6.3,23.9-8.6c20.9-5.9,42-11.2,63-16.8c19.3,5.1,38.5,10.3,57.8,15.4 c0.8,0.5,1.5,1,2.3,1.5c-12.5,1-24.9,2.4-37.4,3C510.1,532.2,473.6,533.9,437,535.7z"
          ></path>
          <path
            className="st18"
            d="M619.6,566.6c0.6,0.6,1.1,1.1,1.7,1.7c0.4,0.5,0.7,1.1,1.1,1.6c1.2,9.4,2.4,18.8,3.6,28.2 c-1.4,0.2-2.8,0.4-4.3,0.6c-2.4-0.7-4.8-1.7-7.3-2c-19.8-1.9-39.7-3.5-59.5-5.3c-0.8-2.1-1.6-4.2-2.4-6.3c0.8,0,1.5-0.1,2.3-0.1 c1,1.1,2,2.3,3,3.4c0.7-1.4,1.3-2.8,2-4.3c17.3-4.2,34.7-8.3,51.9-12.7c2.3-0.6,4.1-3.2,6.2-4.9C618.5,566.6,619,566.6,619.6,566.6z "
          ></path>
          <path
            className="st10"
            d="M584,527.2c-0.8-0.5-1.5-1-2.3-1.5c10.7-25.9,4.6-36-23.9-39.7c-0.7-0.1-1.3-0.8-2-1.3c0.7-0.8,1.3-1.6,2-2.4 c11.8,0.4,23.5,0.8,35.3,1.1c-1.6,1.6-3.2,3.3-4.7,4.9c-0.3,0.3-0.7,0.5-1,0.8c-0.4,0.5-0.8,0.9-1.3,1.4c0,0,0.1-0.1,0.1-0.1 c-0.2,0.2-0.3,0.4-0.5,0.6c0.5,10.6,1,21.2,1.4,31.8c0,1.1,0.1,2.2,0.1,3.3c-0.2,0.2-0.2,0.4-0.1,0.7 C586.1,527,585.1,527.1,584,527.2z"
          ></path>
          <path
            className="st26"
            d="M590.7,533.3c7.9,9.1,15.8,18.2,23.7,27.3C606.5,551.5,598.6,542.4,590.7,533.3z"
          ></path>
          <path
            className="st26"
            d="M427.6,536.5c2.7-0.3,5.4-0.6,8.1-0.9C433,535.9,430.3,536.2,427.6,536.5z"
          ></path>
          <path
            className="st26"
            d="M559.8,584.3c-0.7,1.4-1.3,2.8-2,4.3c-1-1.1-2-2.3-3-3.4C556.5,584.8,558.1,584.5,559.8,584.3z"
          ></path>
          <path
            className="st26"
            d="M587.2,526.9c-0.1-0.2-0.1-0.5,0.1-0.7c2.2-0.5,4.4-1,6.7-1.5c-1.4,2.3-2.8,4.7-4.2,7 C588.8,530.1,588,528.5,587.2,526.9z"
          ></path>
          <path
            className="st26"
            d="M619.6,566.6c-0.6,0-1.1,0-1.7,0c-0.9-1.7-1.7-3.4-2.6-5C616.7,563.2,618.1,564.9,619.6,566.6z"
          ></path>
          <path
            className="st6"
            d="M437.3,649.2c-6.4,5.6-12.8,11.3-19.2,16.9C424.5,660.4,430.9,654.8,437.3,649.2z"
          ></path>
          <path
            className="st10"
            d="M870.8,304.5c17.2,8.7,34.3,17.5,51.5,26.2c-11.1,1.7-22.3,3.3-33.4,5c0,0,0-0.1,0-0.1 C882.8,325.2,876.8,314.9,870.8,304.5z"
          ></path>
          <path
            className="st8"
            d="M885.4,338.4c0.1,0.7,0.3,1.5,0.4,2.2c-0.1,0.2-0.2,0.4-0.4,0.6c-0.5,0.8-1.1,1.6-1.6,2.4l-0.1,0.1 c-0.5,0.7-1,1.4-1.5,2c0,0.2-0.1,0.3-0.2,0.4c-0.6,0.8-1.2,1.7-1.7,2.5c-13,19.9-25.9,39.8-38.9,59.7c-0.9-0.3-1.9-0.6-2.8-0.8 c-23.2-4.6-46.4-9.2-69.7-13.8c-0.2-0.2-0.4-0.4-0.6-0.6c0.9-5.1,1.7-10.3,2.6-15.4c0.6,3,1.3,6,1.9,9.1c-0.2,1.6-0.4,3.1-0.6,4.7 c1.9-0.6,3.8-0.9,5.6-1.7c34.8-16.9,69.5-33.8,104.3-50.7C883.1,338.5,884.3,338.6,885.4,338.4z"
          ></path>
          <path
            className="st7"
            d="M888.9,335.7c11.1-1.7,22.3-3.3,33.4-5c0.8,0.9,1.5,1.7,2.3,2.6c-2,6.3-4,12.7-6.1,19 c-9.8-5.3-19.7-10.5-29.5-15.8C888.9,336.3,888.9,336,888.9,335.7z"
          ></path>
          <path
            className="st8"
            d="M777.2,378.5c0.5-0.7,1-1.3,1.4-2C778.2,377.2,777.7,377.8,777.2,378.5z"
          ></path>
          <path
            className="st22"
            d="M752.7,296.7c0.2-2.3,0.3-4.6,0.5-7c4.3-12.5,8.6-24.9,13-37.4c-4,14.8-8,29.6-12,44.5 C753.6,296.8,753.1,296.7,752.7,296.7z"
          ></path>
          <path
            className="st27"
            d="M772.8,344.3c-0.2-0.6-0.4-1.3-0.6-1.9C772.4,343,772.6,343.6,772.8,344.3z"
          ></path>
          <path
            className="st27"
            d="M770.9,377.7c-0.9,5.1-1.7,10.3-2.6,15.4c-12.9,0.6-25.9,1.1-38.8,1.7c-0.1-0.2-0.2-0.4-0.3-0.6 c0.2-0.8,0.5-1.7,0.7-2.5c1.2-0.5,2.5-1,3.7-1.5c0.1-0.2,0.2-0.5,0.3-0.7c3.9-4.5,7.8-9,11.8-13.5c0.3-0.1,0.5-0.2,0.8-0.3 c8.6-9.1,17.2-18.2,25.7-27.3c0.2,3.9,0.4,7.7,0.6,11.6c-0.5,2.5-1.1,5-1.6,7.4c0,0.5,0,0.9,0,1.4c0,1.3-0.1,2.5-0.1,3.8 c0,0.9,0,1.9,0.1,2.8c0,0.6,0,1.1,0,1.7C771,377.3,770.9,377.5,770.9,377.7z"
          ></path>
          <path
            className="st8"
            d="M771.2,367.4c0.5-2.5,1.1-5,1.6-7.4C772.3,362.5,771.8,365,771.2,367.4z"
          ></path>
          <path
            className="st8"
            d="M771.1,372.6c0-1.3,0.1-2.5,0.1-3.8C771.1,370.1,771.1,371.3,771.1,372.6z"
          ></path>
          <path
            className="st8"
            d="M771.2,377.1c0-0.6,0-1.1,0-1.7C771.2,376,771.2,376.5,771.2,377.1z"
          ></path>
          <path
            className="st8"
            d="M565.1,456.7c15.6-9,31.1-18,46.7-26.9c25.3-14.4,50.6-28.6,75.9-42.9c12.5,2.3,25,4.6,37.5,6.9 c-19.2,7.3-38.4,14.8-57.7,21.9c-14.9,5.5-29.8,10.7-44.8,16c-14.7,6.3-29.3,12.6-44,18.9c-0.9,0.8-1.8,1.6-2.8,2.4 c-0.8,0.1-1.5,0.2-2.3,0.2C570.9,454.6,568,455.7,565.1,456.7z"
          ></path>
          <path
            className="st24"
            d="M725.3,393.9c-12.5-2.3-25-4.6-37.5-6.9c2.8-18.2,5.6-36.4,8.5-54.6c9.6,17,19.2,34,28.8,51 c0.5,0.9,1.2,1.7,1.8,2.5c0.4-1,1.2-2,1.2-3c0.4-12.2,0.7-24.3,1-36.5c0.2,14.3,0.5,28.6,0.7,42.8c0.1,0.3,0.3,0.7,0.4,1 c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.2,0.9-0.3,1.3c-0.2,0.8-0.5,1.7-0.7,2.5C727.9,394.1,726.6,394,725.3,393.9z"
          ></path>
          <path
            className="st29"
            d="M559.8,416.6c-0.1,1.1-0.2,2.2-0.2,3.3c-0.3,1-0.6,2-0.9,2.9c-0.1,3.1-0.2,6.1-0.3,9.2c0,0.6-0.1,1.1-0.1,1.7 c-0.3,3.7-0.6,7.4-0.8,11.1c-0.1,0.6-0.2,1.3-0.3,1.9c-0.2,1.4-0.3,2.7-0.5,4.1c-0.1,2.3-0.1,4.5-0.2,6.8c-0.1,0.6-0.3,1.3-0.4,1.9 c-0.1,0.4-0.1,0.7-0.2,1.1c-0.2,0.5-0.5,1-0.7,1.5c-0.4-0.3-0.8-0.7-1.1-1c-0.7-3.6-1-7.4-2.3-10.7c-5-12.1-10.3-24.1-15.5-36.1 c0.2-0.1,0.4-0.1,0.6-0.1c7.7-5.4,15.4-10.7,23.1-16.1c0.2-0.1,0.5-0.2,0.7-0.3c0.3,0.4,0.6,0.7,0.9,1.1c-0.5,4.8-1,9.7-1.4,14.5 C560,414.4,559.9,415.5,559.8,416.6z"
          ></path>
          <path
            className="st21"
            d="M555.2,461.9c0.2-0.5,0.5-1,0.7-1.5c0.4-0.1,0.9-0.3,1.3-0.4c2.6-1.1,5.3-2.2,7.9-3.3c2.9-1.1,5.8-2.2,8.7-3.3 c-1.4,1.1-2.8,2.3-4.2,3.3c-2.7,2.1-5.5,4.1-8.2,6.2c3,1.3,6,2.3,8.9,3.8c8.6,4.6,17.2,9.5,25.7,14.2c-0.9,0.8-1.9,1.7-2.8,2.5 c-11.8-0.4-23.5-0.8-35.3-1.1c-1.6-0.3-3.2-0.6-4.8-0.9c-0.3,0-0.6-0.1-0.9-0.1c0.8-5.9,1.7-11.7,2.5-17.6 C554.8,463.1,555,462.5,555.2,461.9z"
          ></path>
          <path
            className="st26"
            d="M556.5,457.5c0.1-2.3,0.1-4.5,0.2-6.8C556.6,453,556.6,455.3,556.5,457.5z"
          ></path>
          <path
            className="st26"
            d="M559.8,416.6c0.1-1.1,0.2-2.2,0.3-3.3C560,414.4,559.9,415.5,559.8,416.6z"
          ></path>
          <path
            className="st26"
            d="M561.5,398.8c-0.3-0.4-0.6-0.7-0.9-1.1c0.1-0.4,0.2-0.8,0.3-1.3c0.3-0.1,0.6-0.1,1-0.2 C561.7,397.1,561.6,397.9,561.5,398.8z"
          ></path>
          <path
            className="st26"
            d="M558.7,422.8c0.3-1,0.6-2,0.9-2.9C559.3,420.9,559,421.9,558.7,422.8z"
          ></path>
          <path
            className="st26"
            d="M558.3,433.7c0-0.6,0.1-1.1,0.1-1.7C558.3,432.6,558.3,433.1,558.3,433.7z"
          ></path>
          <path
            className="st29"
            d="M561.9,396.2c-0.3,0.1-0.6,0.1-1,0.2c0.1-1.3,0.1-2.5,0.2-3.8c0.3,0.1,0.6,0.2,0.8,0.3 C561.9,394,561.9,395.1,561.9,396.2z"
          ></path>
          <path
            className="st26"
            d="M557.2,446.7c0.1-0.6,0.2-1.3,0.3-1.9C557.4,445.4,557.3,446.1,557.2,446.7z"
          ></path>
          <path
            className="st26"
            d="M557.2,460c-0.4,0.1-0.9,0.3-1.3,0.4c0.1-0.4,0.1-0.7,0.2-1.1C556.4,459.6,556.8,459.8,557.2,460z"
          ></path>
          <path
            className="st29"
            d="M746.5,375.8c-0.3,0.1-0.5,0.2-0.8,0.2c-3.9,4.5-7.8,9-11.8,13.5c-0.1,0.2-0.2,0.5-0.3,0.7 c-1.1,0.1-2.3,0.1-3.4,0.2c0,0-0.1-0.1-0.1-0.1c0.1-0.4,0.2-0.7,0.3-1.1c-0.1-12.2-0.9-24.6,0.3-36.7c0.3-3.3,6.2-7.9,10.1-8.5 c6.9-1.2,14.3,0.1,21.5,0.6c2.2,0.2,4.4,1.3,6.6,2c-1,2-1.7,4.3-3,6C759.5,360.4,752.9,368.1,746.5,375.8z"
          ></path>
          <path
            className="st27"
            d="M730.4,389.2c-0.1,0.4-0.2,0.7-0.3,1.1c-0.1-0.3-0.3-0.7-0.4-1C730,389.3,730.2,389.2,730.4,389.2z"
          ></path>
          <path
            className="st19"
            d="M324.8,520.6c1.2-4.3,2.4-8.5,3.5-12.8c1.7,0.2,3.7-0.2,4.9,0.7c5.9,3.9,11.7,8.1,17.5,12.2 c-3.3,2.2-6.5,4.4-9.8,6.6c-5.2,2.2-10.4,4.3-15.7,6.5C325.2,529.4,325,525,324.8,520.6z"
          ></path>
          <path
            className="st21"
            d="M341,527.3c3.3-2.2,6.5-4.4,9.8-6.6c6.1-2.1,12.2-4.1,18.3-6.2c0.6,0.9,1.2,1.9,1.8,2.8 c-1.9,9.4-3.8,18.7-5.7,28.1c-1.1,0.7-2.2,1.4-3.4,2.1C354.9,540.8,347.9,534,341,527.3z"
          ></path>
          <path
            className="st13"
            d="M434.1,496.9c-1.9-0.2-3.8-0.3-5.7-0.5c4.6-13.8,9.3-27.7,13.9-41.5c0.4-0.3,0.8-0.5,1.2-0.8 c0.2,0.4,0.4,0.8,0.6,1.1c5.9,10.1,27.3,17.6,5.3,32.2c-4.2,2.9-8.4,5.8-12.6,8.7c0,0,0-0.1,0-0.1 C435.9,496.4,435,496.6,434.1,496.9z"
          ></path>
          <path
            className="st11"
            d="M449.4,487.5c21.9-14.7,0.6-22.2-5.3-32.2c-0.2-0.4-0.4-0.7-0.6-1.1c0-0.8,0.1-1.6,0.1-2.4 c5.9-7.4,11.7-14.8,17.6-22.2c1.5,16.7,3.1,33.4,4.6,50.1c-3.1,1.5-6.3,3-9.4,4.6C454.1,485.3,451.7,486.4,449.4,487.5z"
          ></path>
          <path
            className="st13"
            d="M365.2,545.4c1.9-9.4,3.8-18.7,5.7-28.1c0.5,0.1,1,0.1,1.5,0.2c4.7,1.7,9.4,3.7,14.3,4.7 c1.2,0.3,3.1-2.7,4.6-4.1c0.2-0.1,0.4-0.2,0.6-0.2c1.2,0.6,2.4,1.2,3.6,1.8c-3.6,3.5-7.3,6.9-10.9,10.4c0,0,0,0,0,0 c-0.8,0.5-1.6,1-2.3,1.5C376.6,536.1,370.9,540.7,365.2,545.4z"
          ></path>
          <path
            className="st4"
            d="M382.3,531.5c0.8-0.5,1.6-1,2.3-1.5C383.8,530.5,383,531,382.3,531.5z"
          ></path>
          <path
            className="st18"
            d="M436.8,496.1C436.8,496.1,436.8,496.2,436.8,496.1C436.8,496.2,436.8,496.1,436.8,496.1z"
          ></path>
          <path
            className="st22"
            d="M553,481.4c1.6,0.3,3.2,0.6,4.8,0.9c-0.7,0.8-1.3,1.6-2,2.4c0.7,0.4,1.3,1.2,2,1.3 c28.5,3.7,34.5,13.7,23.9,39.7c-19.3-5.1-38.5-10.3-57.8-15.4c2.9-7.6,5.7-15.1,8.6-22.7c0.6-1.9,1.2-3.8,1.8-5.7 c0.5-0.8,0.9-1.6,1.4-2.4C541.5,480.2,547.2,480.8,553,481.4z"
          ></path>
          <path
            className="st27"
            d="M534.3,481.9c-0.6,1.9-1.2,3.8-1.8,5.7c-3-1.2-6-2.5-9-3.7C527.1,483.2,530.7,482.5,534.3,481.9z"
          ></path>
          <path
            className="st17"
            d="M554.7,463.7c-0.8,5.9-1.7,11.7-2.5,17.6c-28.4-0.6-56.8-1.2-85.2-1.8c5.1-13,10.1-26.1,15.2-39.1 c10.7,3.7,21.3,7.7,32.1,11.2C527.7,455.8,541.2,459.7,554.7,463.7z"
          ></path>
          <path
            className="st0"
            d="M482.2,440.4c-5.1,13-10.1,26.1-15.2,39.1c-0.4,0-0.8,0.1-1.2,0.1c-1.5-16.7-3.1-33.4-4.6-50.1 c-5.6-37.8-11.2-75.6-16.8-113.5c0.5,0.3,1.1,0.6,1.6,1c0.2,0.3,0.5,0.6,0.7,0.9c0,0,0,0.1,0,0.1c3.4,11.3,7,22.6,10.3,33.9 c8.4,28.4,16.8,56.9,25.2,85.4C482.1,438.3,482.2,439.3,482.2,440.4z M447.3,323.5c-0.1-0.1-0.1-0.2-0.2-0.3c0,0.2,0,0.3-0.1,0.5 C447.1,323.6,447.2,323.5,447.3,323.5z"
          ></path>
          <path
            className="st18"
            d="M753.7,563.2c-2.3,7.1-4.6,14.2-7,21.4c-21.4-0.9-42.7-1.8-64.1-2.7c5.2-5.5,10.4-11.1,15.7-16.6 c0.3-0.2,0.6-0.4,0.9-0.6c-0.2-12.2-0.7-24.5-0.2-36.7c0.1-2.1,4.4-4,6.8-6l0.1,0.1c1.3,1.2,2.7,2.3,4,3.5l0,0 c2.6,2.2,5.2,4.5,7.8,6.7c0.2,0.1,0.4,0.1,0.5,0.2c9.1,8,18.3,15.9,27.4,23.9l0,0c2.3,2.1,4.5,4.3,6.8,6.4 C752.8,562.9,753.3,563,753.7,563.2z"
          ></path>
          <path
            className="st13"
            d="M626,598.2c-1.2-9.4-2.4-18.8-3.6-28.2c0.3-0.3,0.6-0.5,0.8-0.8c24.4-1.3,48.8-2.6,73.2-3.8 c-0.9,0.6-1.7,1.4-2.7,1.9c-20.7,9.9-41.4,19.7-62.2,29.6C629.7,597.3,627.8,597.7,626,598.2z"
          ></path>
          <path
            className="st1"
            d="M631.6,596.8c20.7-9.9,41.4-19.7,62.2-29.6c1-0.5,1.8-1.3,2.7-1.9c0.6,0,1.2,0,1.9,0 c-5.2,5.5-10.4,11.1-15.7,16.6C665.6,586.9,648.6,591.8,631.6,596.8z"
          ></path>
          <path
            className="st6"
            d="M554.9,591.5c19.8,1.7,39.7,3.4,59.5,5.3c2.5,0.2,4.8,1.3,7.3,2c-10.1,0.4-20.3,0.7-30.4,1.1 c-5,0.2-10,0.4-15,0.7c-1.2-0.2-2.4-0.3-3.6-0.5c-10-2.9-20-5.8-29.9-8.7C546.8,591.4,550.9,591.4,554.9,591.5z"
          ></path>
          <path
            className="st22"
            d="M576.3,600.5c5-0.2,10-0.4,15-0.7c3.2,4.8,6.4,9.5,9.6,14.3c-3.1-1.4-6.2-2.8-9.4-4.2c-5.1-3-10.1-6-15.2-9 C576.5,600.7,576.5,600.6,576.3,600.5z"
          ></path>
          <path
            className="st29"
            d="M591.5,609.9c3.1,1.4,6.2,2.8,9.4,4.2c0.2,0.2,0.4,0.2,0.6,0.2c0.3,0.7,0.5,1.4,0.8,2.1 C598.7,614.2,595.1,612.1,591.5,609.9z"
          ></path>
          <path
            className="st22"
            d="M602.3,616.4c-0.3-0.7-0.5-1.4-0.8-2.1c1.4,1,2.9,2,4.3,3C604.7,617,603.5,616.7,602.3,616.4z"
          ></path>
          <path
            className="st22"
            d="M608.4,619.3c-0.4-0.5-0.7-1-1.1-1.6c0.6,0.2,1.1,0.5,1.7,0.7C608.9,618.7,608.6,619,608.4,619.3z"
          ></path>
          <path
            className="st22"
            d="M345.4,212.5c-11.1,8.4-22.3,16.9-33.4,25.3c-3.7,2.8-7.3,5.8-10.9,8.6c-0.2,1.6-1.1,3.5-0.6,4.9 c7.2,22.2,14.6,44.3,22,66.4c-14.1,3.5-28.3,6.9-42.4,10.4c2.7-14.7,5.4-29.4,8.1-44.1c2-7.8,4.6-15.6,5.8-23.5 C298.4,230.7,327.7,227.9,345.4,212.5z"
          ></path>
          <path
            className="st29"
            d="M287.6,414.9c-0.1-0.8-0.1-1.6-0.2-2.4c2,0.1,4.1,0.2,6.1,0.3c0.6,0.2,1.1,0.3,1.7,0.5 c7.9,3.4,15.9,6.7,23.8,10.1c0.2,0.1,0.5,0.2,0.7,0.2c12,5.6,24.1,11.3,36.1,16.9c-17-4-34.1-8-51.1-12c-2.3-1.7-4.6-3.4-6.9-5.1 c0,0-0.1-0.1-0.1-0.1c-0.9-0.8-1.7-1.6-2.6-2.4c0,0,0,0,0,0c-1.2-1.1-2.4-2.1-3.5-3.2c-0.7-0.4-1.5-0.9-2.2-1.3 C288.7,415.9,288.2,415.4,287.6,414.9z"
          ></path>
          <path
            className="st26"
            d="M355.8,440.5c-12-5.6-24.1-11.3-36.1-16.9c1.5,0.3,3.1,0.5,4.5,1c21,8.1,41.9,16.2,62.8,24.4 c1.7,1.5,3.4,2.9,5.1,4.4c-1,0-2,0-2.9,0c-6.7-2.3-13.4-4.7-20-7c-0.9-0.5-1.8-1.1-2.8-1.7c-0.7-0.1-1.4-0.1-2.2-0.2 c-2.5-1.3-5-2.7-7.4-4C356.5,440.5,356.2,440.5,355.8,440.5z"
          ></path>
          <path
            className="st22"
            d="M276.8,423.3c7.2,0.6,14.3,1.2,21.5,1.8c1.8,1.2,3.6,2.5,5.4,3.7c0.2,0.6,0.5,1.2,0.7,1.8 c-6.3-1.2-12.7-2.2-18.9-3.7C282.5,426.1,279.7,424.5,276.8,423.3z"
          ></path>
          <path
            className="st6"
            d="M412.7,481.9c2,2.6,3.9,5.2,5.9,7.7c-3.1-2.5-6.2-5.1-9.3-7.6C410.4,482,411.5,482,412.7,481.9z"
          ></path>
          <path
            className="st29"
            d="M356.9,440.5c2.5,1.3,5,2.7,7.4,4C361.8,443.2,359.3,441.9,356.9,440.5z"
          ></path>
          <path
            className="st26"
            d="M303.7,428.8c-1.8-1.2-3.6-2.5-5.4-3.7c-0.2-0.6-0.4-1.1-0.5-1.7c2.3,1.7,4.6,3.4,6.9,5.1 C304.4,428.6,304,428.7,303.7,428.8z"
          ></path>
          <path
            className="st29"
            d="M366.5,444.7c0.9,0.6,1.8,1.1,2.8,1.7C368.3,445.8,367.4,445.3,366.5,444.7z"
          ></path>
          <path
            className="st21"
            d="M391.3,518c-1.6,1.5-3.4,4.4-4.6,4.1c-4.9-1.1-9.5-3.1-14.3-4.7C378.8,517.6,385.1,517.8,391.3,518z"
          ></path>
          <path
            className="st21"
            d="M395.5,519.6c-1.2-0.6-2.4-1.2-3.6-1.8c2.6-1,5.2-2,7.8-2.9c0.2-0.1,0.7,0.7,1,1 C398.9,517.2,397.2,518.4,395.5,519.6z"
          ></path>
          <path
            className="st23"
            d="M392.2,453.3c-1.7-1.5-3.4-2.9-5.1-4.4c-2-3.1-3.6-6.5-6-9.2c-9.3-10.4-17.3-26.6-28.6-29.4 c-19.8-5-41.6-1.8-62.6-2c-0.8,0-1.6-0.1-2.4-0.1c-0.6,0-1.2,0-1.7,0c-0.5,0-1,0.1-1.5,0.1c-1.1,0-2.2,0-3.3,0.1 c-0.1-0.2-0.1-0.4,0-0.6c-0.2-0.3-0.4-0.7-0.6-1c0,0-0.1,0-0.1,0c-0.1-1-0.3-1.9-0.4-2.9c0.9-0.2,2.1-0.2,2.8-0.7 c16.3-13.4,14.4-58.3-3-70.8c0.1-1.4,0.3-2.8,0.4-4.3c14.1-3.5,28.3-6.9,42.4-10.4c0.7,0.2,1.5,0.4,2.2,0.6 c-0.1,0.2-0.2,0.4-0.4,0.5c-7,13.8-14.2,27.4-20.7,41.4c-0.7,1.6,2.8,5.2,4.4,7.8c1.5,1.1,2.8,2.9,4.5,3.2 c20.7,4.5,41.4,8.7,62.1,13.1c0.8-0.3,1.6-0.6,2.4-0.9c20.4,6.2,40.8,12.5,61.2,18.7c-0.9,1.8-1.5,3.8-2.8,5.3 c-13.9,15.4-27.9,30.7-41.8,46C393.1,453.4,392.6,453.4,392.2,453.3z"
          ></path>
          <path
            className="st8"
            d="M393.6,453.4c14-15.3,27.9-30.6,41.8-46c1.3-1.4,1.9-3.5,2.8-5.3c0,0,0.1,0,0.1,0c0.5,0,0.9-0.1,1.4-0.1 c1.3,16.6,2.7,33.1,4,49.7c0,0.8-0.1,1.6-0.1,2.4c-0.4,0.3-0.8,0.5-1.2,0.8c-16-0.4-32-0.9-48-1.3 C394,453.6,393.8,453.5,393.6,453.4z"
          ></path>
          <path
            className="st21"
            d="M394.3,453.6c16,0.4,32,0.9,48,1.3c-4.6,13.8-9.3,27.7-13.9,41.5C417,482.1,405.6,467.9,394.3,453.6z"
          ></path>
          <path
            className="st19"
            d="M279.6,332.4c17.4,12.5,19.4,57.4,3,70.8c-0.7,0.6-1.8,0.5-2.8,0.7c-0.1-19-0.1-38-0.2-57.1c0-4-0.1-8-0.1-12 C279.5,334,279.5,333.2,279.6,332.4z"
          ></path>
          <path
            className="st23"
            d="M279.5,334.9c0,4,0.1,8,0.1,12C279.6,342.8,279.6,338.8,279.5,334.9z"
          ></path>
          <path
            className="st16"
            d="M248.6,283.4C248.6,283.4,248.6,283.5,248.6,283.4C248.6,283.5,248.6,283.4,248.6,283.4z"
          ></path>
          <path
            className="st26"
            d="M287.5,408.3c0.8,0,1.6,0.1,2.4,0.1c1.2,1.5,2.4,3,3.6,4.5c-2-0.1-4.1-0.2-6.1-0.3c0,0,0.1,0,0.1,0 c-0.6,0-1.3,0-1.9,0.1c-0.6-0.5-1.1-1.1-1.7-1.6c0.1-0.9,0.2-1.7,0.3-2.6c0.5,0,1-0.1,1.5-0.1C286.3,408.2,286.9,408.2,287.5,408.3z "
          ></path>
          <path
            className="st26"
            d="M291.6,417.7c1.2,1.1,2.4,2.1,3.5,3.2C293.9,419.9,292.7,418.8,291.6,417.7z"
          ></path>
          <path
            className="st27"
            d="M284.2,408.3c-0.1,0.9-0.2,1.7-0.3,2.6c-1-0.7-2-1.4-3-2c-0.1-0.2-0.1-0.3,0.1-0.5 C282,408.4,283.1,408.3,284.2,408.3z"
          ></path>
          <path
            className="st26"
            d="M295.1,420.9c0.9,0.8,1.7,1.6,2.6,2.4C296.8,422.5,296,421.7,295.1,420.9z"
          ></path>
          <path
            className="st29"
            d="M285.5,412.5c0.6,0,1.3,0,1.9-0.1C286.8,412.5,286.1,412.5,285.5,412.5z"
          ></path>
          <path
            className="st26"
            d="M287.6,414.9c0.6,0.5,1.2,1,1.8,1.5C288.8,415.9,288.2,415.4,287.6,414.9z"
          ></path>
          <path
            className="st26"
            d="M280.3,406.8c0.2,0.3,0.4,0.7,0.6,1C280.7,407.5,280.5,407.1,280.3,406.8z"
          ></path>
          <path
            className="st8"
            d="M516.2,325.5c0.4-0.1,0.8-0.3,1.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1c0.5,2.7,1,5.5,1.4,8.2c0.2,0.9,0.4,1.8,0.6,2.7 c-0.1,1.5-0.4,3-0.1,4.5c3.2,20.5,6.5,40.9,9.7,61.4c-4.3-9.3-9-18.6-12.9-28.1c-7-16.6-13.6-33.5-20.3-50.2 C502.8,325,509.5,325.2,516.2,325.5z"
          ></path>
          <path
            className="st6"
            d="M561.1,392.6c-0.1,1.3-0.1,2.5-0.2,3.8c-0.1,0.4-0.2,0.8-0.3,1.3c-0.2,0.1-0.5,0.2-0.7,0.3 c-7.7,5.4-15.4,10.7-23.1,16.1c-0.2,0-0.4,0.1-0.6,0.1c-0.8-0.3-1.7-0.6-2.5-0.9c-0.1-1.2-0.1-2.4-0.2-3.6 c3.1-8.2,5.9-16.6,9.5-24.6c3.6-8.3,8.7-16,11.7-24.4c2-5.4,1.5-11.7,2.1-17.6C558.2,359.6,559.6,376.1,561.1,392.6z"
          ></path>
          <path
            className="st22"
            d="M533.5,409.6c0.1,1.2,0.1,2.4,0.2,3.6c-0.7-0.1-1.3-0.1-2-0.2c-0.8-3.3-1.5-6.7-2.3-10 c-3.2-20.5-6.5-40.9-9.7-61.4c-0.2-1.4,0.1-3,0.1-4.5C524.4,361.3,528.9,385.5,533.5,409.6z"
          ></path>
          <path
            className="st6"
            d="M548.1,300.5c2.8,13.6,5.6,27.2,8.4,40.8C553.8,327.7,551,314.1,548.1,300.5z"
          ></path>
          <path
            className="st6"
            d="M545.3,284.6c0.6,2.9,1.2,5.7,1.8,8.6C546.5,290.4,545.9,287.5,545.3,284.6z"
          ></path>
          <path
            className="st22"
            d="M519.2,334.4c-0.5-2.7-1-5.5-1.4-8.2C518.3,328.9,518.8,331.7,519.2,334.4z"
          ></path>
          <path
            className="st6"
            d="M548,297.5c0.1,0.2,0.1,0.5,0.1,0.7c-0.1-0.2-0.2-0.3-0.2-0.5C547.8,297.7,547.9,297.6,548,297.5z"
          ></path>
          <path
            className="st23"
            d="M488.4,232.1c5.4,20.5,10.8,40.9,16.3,61.4c-2.4-0.2-4.7-0.4-7.1-0.6c-10.4-4.1-20.8-8.1-31.2-12.2 c6.5-16.3,12.9-32.5,19.4-48.8C486.6,231.9,487.5,232,488.4,232.1z"
          ></path>
          <path
            className="st7"
            d="M516.2,325.5c-6.7-0.3-13.3-0.5-20-0.8c-0.7-0.4-1.3-0.7-2-1.1c-0.7-0.7-1.5-1.4-2.2-2.1 c-0.3-0.1-0.7-0.1-1-0.2l0,0c-5.7-10.4-11.5-20.8-17.2-31.2c4.4,0.6,8.7,1.3,13.1,1.9C496.7,303.2,506.4,314.3,516.2,325.5z"
          ></path>
          <path
            className="st3"
            d="M486.9,292c-4.4-0.6-8.7-1.3-13.1-1.9c-0.5-0.1-1-0.2-1.5-0.3c-8.8-1.6-17.6-3.1-26.5-4.7 c-2.4-0.1-4.8-0.2-7.2-0.4c-2.8-0.7-5.5-1.3-8.3-2c-0.3,0-0.5,0.2-0.7,0.4c-0.5,0.1-1.1,0.1-1.6,0.2c-0.1-0.7-0.2-1.4-0.2-2 c5.2-0.1,10.4-0.2,15.6-0.3c1.5,0,2.9,0,4.4,0c3.8,0,7.7,0,11.5-0.1c0.4,0,0.7,0,1.1,0c1.6,0,3.2,0,4.7-0.1c0.4-0.1,0.8-0.1,1.3-0.2 c10.4,4.1,20.8,8.1,31.2,12.2C494,292.6,490.5,292.3,486.9,292z"
          ></path>
          <path
            className="st27"
            d="M466.4,280.7c-0.4,0.1-0.8,0.1-1.3,0.2c-6.4-19.4-12.7-38.7-19.1-58.1c0.5-0.2,1-0.4,1.5-0.6 c11,2.7,22,5.5,33.1,8.2c1.7,0.5,3.4,0.9,5.1,1.4C479.3,248.1,472.9,264.4,466.4,280.7z"
          ></path>
          <path
            className="st24"
            d="M480.7,230.5c-11-2.7-22-5.5-33.1-8.2c-4.1-7.2-8-14.5-12.5-21.5c-3.5-5.5-7.7-10.4-11.6-15.6c0,0,0.1,0,0.1,0 c-0.8-1.5-1.7-2.9-2.5-4.4c0,0,0,0.1,0,0.1c2.1-0.1,5.1-1.3,6.1-0.3c9.9,9.5,19,19.7,29.2,28.9c6.7,6,14.7,10.5,21.9,16 C479.6,226.5,479.9,228.8,480.7,230.5z"
          ></path>
          <path
            className="st22"
            d="M418.5,173.3c0.4,0.2,0.7,0.4,1.1,0.7C419.3,173.7,418.9,173.5,418.5,173.3z"
          ></path>
          <path
            className="st7"
            d="M324.6,318.4c-0.7-0.2-1.5-0.4-2.2-0.6c-7.3-22.1-14.7-44.2-22-66.4c-0.5-1.4,0.4-3.3,0.6-4.9 c22.1,20.8,44.2,41.6,66.3,62.4c0.6,0.9,1.2,1.7,1.7,2.6c0,0,0,0,0,0c-1.2-0.1-2.4-0.2-3.6-0.3c-0.6,0.3-1.1,0.5-1.7,0.8 C350.7,314.1,337.6,316.2,324.6,318.4z"
          ></path>
          <path
            className="st2"
            d="M427.8,281.4c0.1,0.7,0.2,1.4,0.2,2c-0.2,0-0.3,0.1-0.3,0.3c-1,0.8-2,1.5-3.1,2.3c-0.5,0.4-0.9,0.9-1.4,1.3 l-0.3,0l-0.1,0.3c-0.6,0.5-1.2,0.9-1.9,1.4c-2.9,2.7-5.9,5.4-8.8,8.1c-0.5,0.5-1,0.9-1.5,1.4l-0.3,0l0,0.3c-0.4,0.4-0.8,0.7-1.3,1.1 c-0.8,0.8-1.5,1.6-2.3,2.4c-0.2,0.1-0.4,0.2-0.4,0.5l-0.2,0l0,0.2c-0.3,0.2-0.5,0.5-0.8,0.7l-0.3-0.1l0,0.3c-0.2,0-0.4,0.1-0.4,0.3 c-1.7,0.8-3.3,1.7-5,2.5c-0.2,0.3-0.5,0.5-0.7,0.8c-0.9,1.3-1.9,2.6-2.8,4c0,0,0.1,0.1,0.1,0.1c-0.4,0.3-0.8,0.6-1.1,0.9 c-1.4,1.3-2.7,2.7-4.1,4c-3.3,2.7-6.7,5.3-10,8c-0.8,0.4-1.8,0.7-2.5,1.2c-11.9,9.3-23.9,18.7-35.8,28c8.8-14.1,17.5-28.2,26.3-42.4 c0,0,0,0,0,0c17.1-10.5,34.1-20.9,51.2-31.4c0.4,0.1,0.8,0.1,1.1,0.2c0.2,0.1,0.4,0.2,0.6,0.1C424,280.7,425.9,281,427.8,281.4z"
          ></path>
          <path
            className="st21"
            d="M421.4,280.3c-0.4-0.1-0.8-0.1-1.1-0.2c-25-30-24.7-42.5,1.9-78.6C421.9,227.7,421.7,254,421.4,280.3z"
          ></path>
          <path
            className="st6"
            d="M427.8,281.4c-1.9-0.3-3.8-0.7-5.7-1c7.7-19.3,15.4-38.6,23.1-57.9c0.3,0.1,0.6,0.2,1,0.3 c6.4,19.4,12.7,38.7,19.1,58.1c-1.6,0-3.2,0-4.7,0.1c-0.4,0-0.7,0-1.1,0c-3.8,0-7.7,0-11.5,0.1c-1.5,0-2.9,0-4.4,0 C438.2,281.1,433,281.2,427.8,281.4z"
          ></path>
          <path
            className="st7"
            d="M446.1,222.8c-0.3-0.1-0.6-0.2-1-0.3c-7.2-12.4-14.4-24.9-21.6-37.3c3.9,5.2,8.1,10.2,11.6,15.6 c4.5,7,8.3,14.3,12.5,21.5C447.1,222.4,446.6,222.6,446.1,222.8z"
          ></path>
          <path
            className="st7"
            d="M421.1,180.8c0.8,1.5,1.7,2.9,2.5,4.4C422.8,183.7,421.9,182.3,421.1,180.8z"
          ></path>
          <path
            className="st15"
            d="M641.6,300.6c-1.9,8.5-3.8,16.9-5.7,25.4C637.7,317.5,639.7,309.1,641.6,300.6z"
          ></path>
          <path
            className="st15"
            d="M644.8,285.4c-0.9,4.2-1.8,8.5-2.8,12.7C643,293.8,643.9,289.6,644.8,285.4z"
          ></path>
          <path
            className="st15"
            d="M647.9,271.6c-1,4.3-1.9,8.6-2.9,12.8C646,280.2,647,275.9,647.9,271.6z"
          ></path>
          <path
            className="st8"
            d="M858,542.6c-0.3,0.2-0.7,0.4-1,0.6c-0.5,0-0.9-0.1-1.4-0.1c-1.1-0.5-2.2-1.1-3.4-1.4 c-25.3-7.9-50.6-15.7-75.9-23.6c22.3-3.4,44.5-7.2,66.8-10.2c19.7-2.6,39.5-4.2,59.3-6.2C887.6,515.4,872.8,529,858,542.6z"
          ></path>
          <path
            className="st21"
            d="M776.3,518.1c25.3,7.9,50.6,15.7,75.9,23.6c1.2,0.4,2.3,1,3.4,1.4c-33.4,6.5-66.9,12.9-100.3,19.4 c5.3-14.9,10.7-29.7,16-44.6C773,518,774.6,518.1,776.3,518.1z"
          ></path>
          <path
            className="st25"
            d="M771.3,517.9c-5.3,14.9-10.7,29.7-16,44.6c-0.5,0.2-1.1,0.4-1.6,0.6c-0.4-0.1-0.9-0.3-1.3-0.4 c-2.3-2.1-4.5-4.3-6.8-6.4c0,0,0,0,0,0c-9.1-8-18.3-15.9-27.4-23.9c-0.2-0.1-0.4-0.1-0.5-0.2c-2.6-2.2-5.2-4.5-7.8-6.7c0,0,0,0,0,0 c-1.3-1.2-2.7-2.3-4-3.5c0,0-0.1-0.1-0.2-0.1c-2-2.4-4-4.9-5.9-7.3c16,0.4,32,0.7,48.1,1.4C755.7,516.3,763.5,517.3,771.3,517.9z"
          ></path>
          <path
            className="st13"
            d="M906.5,499.3c28.5-30,57-60,85.6-90c0.5-0.6,1.5-0.7,2.3-1c-17,27.7-34,55.3-50.9,83 c-6.7,10.9-13.2,21.9-19.8,32.9c-0.3,0.5-0.5,0.9-0.8,1.4c-0.6,0.7-1.1,1.3-1.7,2c-0.1,0.1-0.1,0.3-0.2,0.4 c-0.6,0.1-1.2,0.2-1.8,0.3C915,518.6,910.8,508.9,906.5,499.3z"
          ></path>
          <path
            className="st21"
            d="M906.5,499.3c4.2,9.6,8.5,19.3,12.7,28.9c-17.2,4.2-34.4,8.4-51.6,12.5c-3.2,0.8-6.4,1.2-9.6,1.9 c14.8-13.6,29.6-27.2,44.4-40.8c0.8-0.6,1.6-1.1,2.4-1.7C905.4,499.9,906,499.6,906.5,499.3z"
          ></path>
          <path
            className="st11"
            d="M921.2,527.6c0.6-0.7,1.1-1.3,1.7-2C922.3,526.3,921.7,526.9,921.2,527.6z"
          ></path>
          <path
            className="st19"
            d="M904.8,500.1c-0.8,0.6-1.6,1.1-2.4,1.7c-19.8,2-39.6,3.6-59.3,6.2c-22.3,2.9-44.6,6.7-66.8,10.2 c-1.7-0.1-3.3-0.1-5-0.2c-7.8-0.7-15.6-1.6-23.4-1.9c-16-0.6-32-0.9-48.1-1.4c-0.9-0.1-1.7-0.3-2.6-0.4c-0.3-0.4-0.5-0.8-0.8-1.2 c6.5-17,13.1-34,19.6-51c0,0,0-0.1,0-0.1c0.3-0.6,0.7-1.2,1-1.8c4.8,0.8,9.6,1.3,14.3,2.3c36.1,7.4,72.1,14.9,108.2,22.3 c0.8-0.3,1.5-0.7,2.3-1c0.3-0.5,0.6-0.9,0.9-1.4c17.5-14,35-28.1,52.6-42.1C898.4,460.2,901.6,480.2,904.8,500.1z"
          ></path>
          <path
            className="st28"
            d="M882.1,346.1c0.2-0.1,0.2-0.2,0.2-0.4c0.5-0.7,1-1.4,1.5-2c0,0,0.1-0.1,0.1-0.1c0.5-0.8,1.1-1.6,1.6-2.4 c0.2-0.2,0.3-0.4,0.4-0.6c-6.4,23.8-12.7,47.7-19.2,71.5c-3.8,13.8-7.9,27.5-11.9,41.2c-4.4-14.7-8.8-29.4-13.2-44.1 c0-0.3-0.1-0.6-0.2-0.8c13-19.9,25.9-39.8,38.9-59.7C880.9,347.8,881.5,346.9,882.1,346.1z"
          ></path>
          <path
            className="st5"
            d="M841.6,409.1c4.4,14.7,8.8,29.4,13.2,44.1c-4,9.7-8.1,19.4-12.1,29.2c-0.3,0.5-0.6,0.9-0.9,1.4 c-0.8,0-1.5,0-2.3-0.1c-0.3-0.4-0.7-0.9-1-1.3c-0.1-0.6-0.3-1.1-0.4-1.7c3.4-5.8,7.5-11.4,9.8-17.7c0.7-2-4-8.6-5.1-8.4 c-12.7,2.9-16-1.6-12-13.3c2.9-9.9,5.8-19.8,8.7-29.7C840.2,410.8,840.9,410,841.6,409.1z"
          ></path>
          <path
            className="st8"
            d="M890.4,375.6c1.1,9.3,2.2,18.5,3.3,27.8C892.6,394.2,891.5,384.9,890.4,375.6z"
          ></path>
          <path
            className="st8"
            d="M893.6,419c0.3,3.5,0.7,7.1,1,10.6C894.3,426.1,894,422.6,893.6,419z"
          ></path>
          <path
            className="st8"
            d="M890.3,361.9c0,2.4,0.1,4.8,0.1,7.3C890.3,366.8,890.3,364.4,890.3,361.9z"
          ></path>
          <path
            className="st21"
            d="M542.8,591.3c10,2.9,20,5.8,29.9,8.7c-31.8,7.5-63.5,14.9-95.3,22.4c-0.9,0.2-1.9,0-2.8-0.1 c0.7-0.6,1.3-1.3,2.1-1.8c18.2-10,36.2-20.2,54.7-29.6C534.3,589.3,538.9,591.1,542.8,591.3z"
          ></path>
          <path
            className="st21"
            d="M698.2,565.3c-0.6,0-1.2,0-1.9,0c-24.4,1.3-48.8,2.6-73.2,3.8c5.9-5.3,11.9-10.6,17.8-15.9 c0.5-0.1,1.1-0.2,1.6-0.3c4.2-2.8,8.5-5.4,12.6-8.3c13.6-9.4,27.2-18.9,40.8-28.3c1.1,16.1,2.1,32.2,3.2,48.3 C698.9,564.9,698.6,565.1,698.2,565.3z"
          ></path>
          <path
            className="st29"
            d="M618.6,512c-0.2,0-0.5,0.1-0.7,0.1c-4,1-8.1,2.1-12.1,3.1c12.2-11.9,24.4-23.9,36.6-35.7 c10-9.7,20.2-19.2,30.4-28.8c1.1-0.1,2.2-0.2,3.4-0.2c12.8,3,25.5,6.1,38.3,9.1c-22.8,12.1-45.6,24.1-68.3,36.4 C636.9,501.1,627.8,506.7,618.6,512z"
          ></path>
          <path
            className="st26"
            d="M618.6,512c9.2-5.3,18.2-10.9,27.6-15.9c22.7-12.3,45.5-24.3,68.3-36.4c0.4,0,0.9,0,1.3,0 c0.1,0.8,0.2,1.5,0.2,2.3c0,0,0,0.1,0,0.1C683.6,478.7,651.1,495.3,618.6,512z"
          ></path>
          <path
            className="st25"
            d="M696,516.4c-13.6,9.4-27.2,18.9-40.8,28.3c-4.1,2.9-8.4,5.5-12.6,8.3c18-13.3,35.9-26.6,53.9-40 c0.3,0.4,0.5,0.8,0.8,1.2C696.8,514.9,696.4,515.7,696,516.4z"
          ></path>
          <path
            className="st26"
            d="M605.8,515.3c4-1,8.1-2.1,12.1-3.1c-7.7,3.9-15.4,7.8-23.1,11.7c0.2-0.7,0.4-1.5,0.6-2.2 c0.2-0.5,0.5-1,0.7-1.5c1.4-0.5,2.9-1,4.3-1.5c1.2-0.7,2.5-1.4,3.7-2.1C604.7,516.1,605.3,515.7,605.8,515.3z"
          ></path>
          <path
            className="st25"
            d="M641,553.3c-5.9,5.3-11.9,10.6-17.8,15.9c-0.3,0.3-0.6,0.5-0.8,0.8c-0.4-0.5-0.7-1.1-1.1-1.6 C627.9,563.3,634.4,558.3,641,553.3z"
          ></path>
          <path
            className="st17"
            d="M595.5,521.7c-0.2,0.7-0.4,1.5-0.6,2.2c-0.3,0.3-0.6,0.6-0.9,0.9c-2.2,0.5-4.4,1-6.7,1.5 c0-1.1-0.1-2.2-0.1-3.3C589.9,522.5,592.7,522.1,595.5,521.7z"
          ></path>
          <path
            className="st16"
            d="M595.5,521.7c-2.8,0.4-5.5,0.9-8.3,1.3c-0.5-10.6-1-21.2-1.4-31.8c0.2-0.2,0.4-0.4,0.5-0.6 c0,0-0.1,0.1-0.1,0.1c0.4-0.5,0.8-0.9,1.3-1.4c0.3-0.3,0.7-0.5,1-0.8c1.8-0.1,3.8,0.3,5.3-0.4c22.6-10.3,45.1-20.8,67.7-31.2 c-11.8,11.4-23.6,22.8-35.4,34.2c-10,9.7-19.9,19.4-29.8,29.2C595.9,520.6,595.7,521.2,595.5,521.7z"
          ></path>
          <path
            className="st2"
            d="M661.4,456.8c-22.6,10.4-45.1,20.9-67.7,31.2c-1.5,0.7-3.5,0.3-5.3,0.4c1.6-1.6,3.2-3.3,4.7-4.9 c0.9-0.8,1.9-1.7,2.8-2.5c0.5-0.4,1-0.8,1.4-1.1c0.5-0.6,1-1.2,1.6-1.8l-0.3,0.1c0.5-0.3,1-0.7,1.5-1l-0.2,0.2 c0.4-0.4,0.8-0.7,1.2-1.1c1-0.7,1.9-1.5,2.9-2.2c6.9-4.6,13.9-9.2,20.8-13.8c8.4-2.3,16.9-4.6,25.3-6.9c6.3-0.6,12.7-1.1,19-1.7 c-1.8,0.9-3.6,1.8-5.3,2.7C663.1,455.1,662.3,455.9,661.4,456.8L661.4,456.8z"
          ></path>
          <path
            className="st2"
            d="M587.3,489.2c-0.4,0.5-0.8,0.9-1.3,1.4C586.5,490.1,586.9,489.7,587.3,489.2z"
          ></path>
          <path
            className="st2"
            d="M586.2,490.5c-0.1,0.3-0.2,0.5-0.5,0.6C585.9,490.9,586,490.7,586.2,490.5z"
          ></path>
          <path
            className="st21"
            d="M769,393.7c23.2,4.6,46.4,9.2,69.7,13.8c-2.9,1.6-5.6,3.5-8.6,4.6c-20.4,7.9-40.9,15.6-61.4,23.4 c-0.5,0-1,0.1-1.5,0.1c-3.6-1-7.1-2-10.7-3C760.6,419.6,764.8,406.7,769,393.7z"
          ></path>
          <path
            className="st18"
            d="M769,393.7c-4.2,13-8.4,25.9-12.6,38.9c-1.9-0.5-3.8-0.9-5.7-1.4c-0.7,0-1.3-0.1-2-0.1c-0.5-0.2-1-0.5-1.4-0.7 c-0.4,0-0.8-0.1-1.2-0.1c-0.2-0.2-0.4-0.5-0.6-0.7c-5.3-11.3-10.6-22.6-15.9-33.8c0-0.3,0-0.7,0-1c12.9-0.6,25.9-1.1,38.8-1.7 C768.6,393.3,768.8,393.5,769,393.7z"
          ></path>
          <path
            className="st12"
            d="M768.6,435.5c20.5-7.8,41-15.5,61.4-23.4c3-1.2,5.8-3.1,8.6-4.6c0.9,0.3,1.9,0.6,2.8,0.8 c0.1,0.3,0.2,0.5,0.2,0.8c-0.7,0.8-1.5,1.7-2.2,2.5c-10.8,10.6-21.6,21.3-32.4,31.9C794.2,440.9,781.4,438.2,768.6,435.5z"
          ></path>
          <path
            className="st23"
            d="M882.1,346.1c-0.6,0.8-1.2,1.7-1.7,2.5C880.9,347.8,881.5,346.9,882.1,346.1z"
          ></path>
          <path
            className="st23"
            d="M885.5,341.1c-0.5,0.8-1.1,1.6-1.6,2.4C884.4,342.8,884.9,342,885.5,341.1z"
          ></path>
          <path
            className="st23"
            d="M883.8,343.7c-0.5,0.7-1,1.4-1.5,2C882.8,345,883.3,344.4,883.8,343.7z"
          ></path>
          <path
            className="st21"
            d="M729.6,394.8c0,0.3,0,0.7,0,1c-0.3,0.2-0.7,0.5-1,0.7c-14.4,11.4-29,22.6-43.2,34.2c-7.3,6-14.1,12.6-21.1,19 c-0.8,0.1-1.6,0.3-2.4,0.4c-13-6-25.9-12-38.9-18.1l-0.2-0.2c14.9-5.3,29.9-10.5,44.8-16c19.3-7.2,38.5-14.6,57.7-21.9 c1.3,0.1,2.6,0.2,4,0.3C729.4,394.4,729.5,394.6,729.6,394.8z"
          ></path>
          <path
            className="st26"
            d="M733.9,389.5c3.9-4.5,7.8-9,11.8-13.5C741.8,380.5,737.9,385,733.9,389.5z"
          ></path>
          <path
            className="st26"
            d="M730.3,390.4c1.1-0.1,2.3-0.1,3.4-0.2c-1.2,0.5-2.5,1-3.7,1.5C730.1,391.2,730.2,390.8,730.3,390.4z"
          ></path>
          <path
            className="st13"
            d="M604.2,474.1c-1,0.7-1.9,1.5-2.9,2.2c-0.4,0.4-0.8,0.7-1.2,1.1c0,0,0.2-0.2,0.2-0.2c-0.5,0.3-1,0.7-1.5,1 c0,0,0.3-0.1,0.3-0.1c-0.5,0.6-1,1.2-1.6,1.8c-0.5,0.4-1,0.8-1.4,1.1c-8.6-4.7-17.1-9.6-25.7-14.2c-2.8-1.5-5.9-2.5-8.9-3.8 c2.7-2.1,5.5-4.1,8.2-6.2c1.4-1.1,2.8-2.2,4.2-3.3c0.8-0.1,1.5-0.2,2.3-0.2c0.9-0.8,1.8-1.6,2.7-2.4c14.7-6.3,29.3-12.6,44-18.9 c0,0,0.2,0.2,0.2,0.2c-0.1,0.9-0.2,1.7-0.4,2.6C616.5,447.8,610.3,460.9,604.2,474.1z"
          ></path>
          <path
            className="st21"
            d="M578.8,450.8c-0.9,0.8-1.8,1.6-2.7,2.4C577,452.4,577.9,451.6,578.8,450.8z"
          ></path>
          <path
            className="st28"
            d="M531.7,413c0.7,0.1,1.3,0.1,2,0.2c0.8,0.3,1.7,0.6,2.5,0.9c5.2,12,10.5,24,15.5,36.1c1.4,3.3,1.6,7.1,2.3,10.7 c-21.7-6.5-43.4-12.8-64.9-19.8c-2.1-0.7-4.6-5.2-4.4-7.6c2.2-17.9,5-35.8,7.6-53.7c0.2-0.4,0.4-0.8,0.6-1.2 C505.9,390.1,518.8,401.6,531.7,413z"
          ></path>
          <path
            className="st6"
            d="M492.4,379.9c-2.6,17.9-5.4,35.7-7.6,53.7c-0.3,2.4,2.2,6.9,4.4,7.6c21.5,6.9,43.2,13.3,64.9,19.8 c0.4,0.3,0.8,0.7,1.1,1c-0.2,0.6-0.4,1.2-0.5,1.8c-13.5-4-27-7.9-40.4-12.2c-10.8-3.5-21.4-7.4-32.1-11.2c0-1,0-2.1-0.1-3.1 c0.9-6,1.5-12,2.6-18C487.2,406.1,489.8,393,492.4,379.9z"
          ></path>
          <path
            className="st28"
            d="M536.8,414c7.7-5.4,15.4-10.7,23.1-16.1C552.2,403.3,544.5,408.6,536.8,414z"
          ></path>
          <path
            className="st13"
            d="M443.6,451.7c-1.3-16.6-2.7-33.1-4-49.7c-0.3-1.4-0.5-2.8-0.8-4.2c0-0.5,0-1-0.1-1.5c-1-7-2-14-2.9-21 c0-0.7,0-1.4-0.1-2.1c0.4-10.3,0.8-20.6,1.3-31c2.4-8.8,4.9-17.6,7.3-26.4c5.6,37.8,11.2,75.6,16.8,113.5 C455.3,436.9,449.5,444.3,443.6,451.7z"
          ></path>
          <path
            className="st27"
            d="M438.8,396.4c0,0.5,0,1,0.1,1.5c-0.2,1.4-0.4,2.9-0.6,4.3c0,0-0.1,0-0.1,0c-20.4-6.2-40.8-12.5-61.2-18.7 c7.6-3.9,15.3-7.8,22.9-11.9c9.7-5.2,19.3-10.7,29-16c-7.6-5.7-17.3-10-22.2-17.4c-4.9-7.4-29.4-1.3-15.5-21.5 c1.4-1.3,2.7-2.7,4.1-4c0.4-0.3,0.8-0.6,1.1-0.9c0,0-0.1-0.1-0.1-0.1c2.8-2.4,5.7-4.8,8.5-7.3c0-0.2,0.2-0.3,0.4-0.3 c0,0,0.3-0.2,0.3-0.2c0.3-0.2,0.5-0.5,0.8-0.7c0,0,0.2-0.2,0.2-0.2c0.1-0.3,0.2-0.4,0.4-0.5c0.8-0.8,1.5-1.6,2.3-2.4 c0.4-0.4,0.8-0.7,1.3-1.1c0.2,0,0.3-0.1,0.3-0.3c0.5-0.5,1-0.9,1.5-1.4c2.9-2.7,5.9-5.4,8.8-8.1c0.6-0.5,1.2-0.9,1.9-1.4 c0.2,0,0.4-0.1,0.4-0.3c0.5-0.4,0.9-0.9,1.3-1.3c1-0.8,2-1.5,3.1-2.3c0-0.2,0.1-0.3,0.3-0.3c0.5-0.1,1.1-0.1,1.6-0.2 c0.3,1.3,0.6,2.6,0.9,4c0.1,10.4-0.2,20.9,0.6,31.3c0.4,5.4,2.7,10.6,4.1,16c1.5-4.7,3-9.4,4.5-14.1c0.9-2.8,1.9-5.6,2.8-8.4 c2,2.2,4,4.5,5.9,6.7c-0.6-0.2-1.2-0.4-1.8-0.6c0,0,0-0.1,0-0.1c-0.2-0.3-0.5-0.6-0.7-0.9c-0.5-0.3-1.1-0.6-1.6-1 c-2.4,8.8-4.9,17.6-7.3,26.4c-0.5,0-0.9,0.1-1.4,0.1c0-0.3,0-0.6,0-0.9c0,0,0,0,0,0c-0.2,0.6-0.5,1.2-0.7,1.9 c0.3,9.9,0.6,19.9,0.9,29.8c0,0.7,0,1.4,0.1,2.1C436.9,382.5,437.8,389.4,438.8,396.4z"
          ></path>
          <path
            className="st5"
            d="M446.6,318c0.6,0.2,1.2,0.4,1.8,0.6c0,0,0.1,0.1,0.1,0.1c0.6,0.8,1.1,1.6,1.7,2.4c0.1,0.2,0.2,0.3,0.4,0.3 c1.3,1.6,2.5,3.3,3.8,4.9c0.2,0.3,0.4,0.6,0.6,0.9c0.9,1.2,1.8,2.4,2.7,3.5l0,0c0.3,0.3,0.5,0.6,0.8,0.9l0,0.2 c1.2,1.7,2.3,3.4,3.5,5c0.2,0.2,0.4,0.5,0.6,0.7c9.5,12.6,19,25.1,28.5,37.7l0.1,0.2l0.2,0c0.5,1.1,1.1,2.2,1.6,3.2 c-0.2,0.4-0.4,0.8-0.6,1.2c-2.6,13.1-5.2,26.2-7.6,39.4c-1.1,6-1.8,12-2.6,18c-8.4-28.5-16.7-56.9-25.2-85.4 C453.6,340.6,450.1,329.3,446.6,318z"
          ></path>
          <path
            className="st5"
            d="M447.3,323.5c-0.1,0.1-0.2,0.2-0.2,0.2c0-0.2,0-0.3,0.1-0.5C447.2,323.3,447.2,323.4,447.3,323.5z"
          ></path>
          <path
            className="st5"
            d="M445.9,317c0.2,0.3,0.5,0.6,0.7,0.9C446.4,317.6,446.2,317.3,445.9,317z"
          ></path>
          <path
            className="st27"
            d="M696,516.4c0.4-0.7,0.8-1.4,1.2-2.1c0.9,0.1,1.7,0.3,2.6,0.4c2,2.4,4,4.9,5.9,7.3c-2.4,2-6.7,3.9-6.8,6 c-0.5,12.2,0,24.5,0.2,36.7C698.1,548.6,697.1,532.5,696,516.4z"
          ></path>
          <path
            className="st27"
            d="M718.3,532.5c9.1,8,18.3,15.9,27.4,23.9C736.5,548.4,727.4,540.4,718.3,532.5z"
          ></path>
          <path
            className="st27"
            d="M709.9,525.6c2.6,2.2,5.2,4.5,7.8,6.7C715.1,530.1,712.5,527.9,709.9,525.6z"
          ></path>
          <path
            className="st27"
            d="M745.6,556.3c2.3,2.1,4.5,4.3,6.8,6.4C750.1,560.6,747.9,558.5,745.6,556.3z"
          ></path>
          <path
            className="st27"
            d="M705.9,522.1c1.3,1.2,2.7,2.3,4,3.5C708.6,524.5,707.2,523.3,705.9,522.1z"
          ></path>
          <path
            className="st27"
            d="M293.5,412.8c-1.2-1.5-2.4-3-3.6-4.5c21,0.2,42.8-3,62.6,2c11.3,2.9,19.3,19.1,28.6,29.4c2.4,2.7,4,6.1,6,9.2 c-20.9-8.1-41.9-16.3-62.8-24.4c-1.4-0.6-3-0.7-4.5-1c-0.3-0.1-0.5-0.1-0.7-0.2c-7.9-3.4-15.9-6.7-23.8-10.1 C294.6,413.1,294.1,412.9,293.5,412.8z"
          ></path>
          <path
            className="st26"
            d="M295.2,413.3c7.9,3.4,15.9,6.7,23.8,10.1C311.1,420,303.1,416.6,295.2,413.3z"
          ></path>
          <path
            className="st26"
            d="M391,316.6c-13.9,20.2,10.6,14.1,15.5,21.5c5,7.4,14.6,11.7,22.2,17.4c-9.6,5.4-19.3,10.8-29,16 c-7.6,4.1-15.3,7.9-22.9,11.9c-0.8,0.3-1.6,0.6-2.4,0.9c0-0.6,0-1.2,0-1.8c2.2-19.3,4.3-38.6,6.5-58 C384.4,321.9,387.7,319.3,391,316.6z"
          ></path>
          <path
            className="st12"
            d="M369,311.5c-8.8,14.1-17.5,28.2-26.3,42.4c-0.7,1-1.3,2-2,2.9c-0.7,0-1.4,0-2.2,0.1 c-4.8-12.6-9.6-25.3-14.4-37.9c0.2-0.2,0.3-0.3,0.4-0.5c13-2.1,26.1-4.3,39.2-6.4c0.6-0.3,1.1-0.5,1.7-0.8 C366.6,311.2,367.8,311.4,369,311.5z"
          ></path>
          <path
            className="st13"
            d="M324.2,318.9c4.8,12.6,9.6,25.3,14.4,37.9c-10.2,3.8-20.4,7.5-30.6,11.3c-1.6-2.7-5.1-6.2-4.4-7.8 C310,346.3,317.2,332.7,324.2,318.9z"
          ></path>
          <path
            className="st7"
            d="M307.9,368.1c10.2-3.8,20.4-7.5,30.6-11.3c0.7,0,1.4,0,2.2-0.1c0.8,0.8,1.5,1.5,2.3,2.3 c1.2,0.9,2.3,1.9,3.5,2.8l-0.1,0c9.1,6.8,18.2,13.6,27.3,20.4c0.3,0.1,0.5,0.2,0.8,0.3c0,0.6,0,1.2,0,1.8 c-20.7-4.3-41.4-8.6-62.1-13.1C310.8,371,309.4,369.2,307.9,368.1z"
          ></path>
          <path
            className="st27"
            d="M287.5,408.3c-0.6,0-1.2,0-1.7,0C286.3,408.2,286.9,408.2,287.5,408.3z"
          ></path>
          <path
            className="st25"
            d="M438.3,402.2c0.2-1.4,0.4-2.9,0.6-4.3c0.3,1.4,0.5,2.8,0.8,4.2C439.2,402.1,438.7,402.1,438.3,402.2z"
          ></path>
          <path
            className="st19"
            d="M493,378.7c-0.5-1.1-1.1-2.2-1.6-3.2c0.9-17.3,1.9-34.5,2.8-51.8c0.7,0.4,1.3,0.7,2,1.1 c6.7,16.7,13.3,33.6,20.3,50.2c4,9.5,8.6,18.7,12.9,28.1c0.8,3.3,1.5,6.7,2.3,10C518.8,401.6,505.9,390.1,493,378.7z"
          ></path>
          <path
            className="st23"
            d="M494.2,323.6c-0.9,17.3-1.9,34.5-2.8,51.8c0,0-0.2,0-0.2,0s-0.1-0.2-0.1-0.2c-9.5-12.6-19-25.1-28.5-37.7 c-0.2-0.2-0.4-0.5-0.6-0.7c-1.2-1.7-2.3-3.4-3.5-5c0,0,0-0.2,0-0.2c-0.3-0.3-0.5-0.6-0.8-0.9c0,0,0,0,0,0c-0.9-1.2-1.8-2.4-2.7-3.5 c-0.2-0.3-0.4-0.6-0.6-0.9c-1.3-1.6-2.5-3.3-3.8-4.9c-0.2,0-0.3-0.1-0.4-0.3c-0.6-0.8-1.1-1.6-1.7-2.4c0,0-0.1-0.1-0.1-0.1 c-2-2.2-4-4.5-5.9-6.7c-4-8.2-8-16.5-12-24.7c-0.3-1.3-0.6-2.6-0.9-4c0.2-0.3,0.4-0.4,0.7-0.4c1.8,1.1,3.5,2.2,5.3,3.3 c18.5,11.7,37,23.5,55.4,35.2c0,0,0,0,0,0c0.3,0.1,0.7,0.1,1,0.2C492.7,322.2,493.5,322.9,494.2,323.6z"
          ></path>
          <path
            className="st24"
            d="M491,321.3c-18.5-11.7-37-23.5-55.4-35.2c1-0.4,2-0.9,3-1.3c2.4,0.1,4.8,0.2,7.2,0.4 c8.8,1.6,17.6,3.1,26.5,4.7c0.5,0.1,1,0.2,1.5,0.3C479.5,300.5,485.3,310.9,491,321.3z"
          ></path>
          <path
            className="st19"
            d="M492,321.5c-0.3-0.1-0.7-0.1-1-0.2C491.3,321.4,491.7,321.5,492,321.5z"
          ></path>
          <path
            className="st7"
            d="M472.3,289.9c-8.8-1.6-17.6-3.1-26.5-4.7C454.6,286.7,463.5,288.3,472.3,289.9z"
          ></path>
          <path
            className="st7"
            d="M438.6,284.8c-1,0.4-2,0.9-3,1.3c-1.8-1.1-3.5-2.2-5.3-3.3C433,283.5,435.8,284.1,438.6,284.8z"
          ></path>
          <path
            className="st2"
            d="M443.3,281c1.5,0,2.9,0,4.4,0C446.3,281,444.8,281,443.3,281z"
          ></path>
          <path
            className="st2"
            d="M459.3,281c0.4,0,0.7,0,1.1,0C460,280.9,459.6,281,459.3,281z"
          ></path>
          <path
            className="st19"
            d="M365.4,311.1c-0.6,0.3-1.1,0.5-1.7,0.8C364.3,311.6,364.8,311.4,365.4,311.1z"
          ></path>
          <path
            className="st17"
            d="M343,359c-0.8-0.8-1.5-1.5-2.3-2.3c0.7-1,1.3-2,2-2.9c11.9-9.3,23.8-18.7,35.8-28c0.7-0.6,1.7-0.8,2.5-1.2 c-2.2,19.3-4.3,38.6-6.5,58c-0.3-0.1-0.5-0.2-0.8-0.3c-9.1-6.8-18.2-13.6-27.3-20.4c0,0,0.1,0,0.1,0C345.4,360.9,344.2,360,343,359z "
          ></path>
          <path
            className="st26"
            d="M421,289c-2.9,2.7-5.9,5.4-8.8,8.1C415.1,294.5,418,291.8,421,289z"
          ></path>
          <path
            className="st26"
            d="M404.7,304.3c-2.8,2.4-5.7,4.8-8.5,7.3c0.9-1.3,1.9-2.6,2.9-4c0.2-0.3,0.5-0.5,0.7-0.8 C401.4,306,403.1,305.1,404.7,304.3z"
          ></path>
          <path
            className="st26"
            d="M427.7,283.7c-1,0.8-2,1.5-3.1,2.3C425.7,285.3,426.7,284.5,427.7,283.7z"
          ></path>
          <path
            className="st26"
            d="M409.1,300c-0.8,0.8-1.5,1.6-2.3,2.4C407.6,301.6,408.3,300.8,409.1,300z"
          ></path>
          <path
            className="st17"
            d="M399.7,306.8c-0.2,0.3-0.4,0.5-0.7,0.8C399.3,307.3,399.5,307.1,399.7,306.8z"
          ></path>
          <path
            className="st26"
            d="M396.3,311.7c-0.4,0.3-0.8,0.6-1.1,0.9C395.5,312.3,395.9,312,396.3,311.7z"
          ></path>
          <polygon
            className="st26"
            points="405.4,303.8 405.1,304 405.2,303.7 "
          ></polygon>
          <polygon
            className="st26"
            points="406.4,302.9 406.2,303 406.2,302.8 "
          ></polygon>
          <path
            className="st26"
            d="M410.7,298.6c0,0.2-0.1,0.3-0.3,0.3c0,0,0-0.3,0-0.3L410.7,298.6z"
          ></path>
          <path
            className="st26"
            d="M423.3,287.4c-0.1,0.2-0.2,0.3-0.4,0.3c0,0,0.1-0.3,0.1-0.3L423.3,287.4z"
          ></path>
          <path
            className="st7"
            d="M746.1,430.3c0.4,0,0.8,0.1,1.2,0.1c0.5,0.2,1,0.5,1.4,0.7c0.7,0,1.3,0.1,2,0.1c6.3,3,12.7,6,19,8.9 c1.8,0.3,3.7,0.6,5.5,0.8c4.1,1.4,8.2,2.9,12.4,4.3c1,0.3,1.9,0.6,2.9,0.9c1.2,0.5,2.5,0.9,3.7,1.4c0.5,0.1,0.9,0.2,1.4,0.3 c1,0.2,1.9,0.3,2.9,0.5c1.1,0.3,2.1,0.6,3.2,0.9c12.3,11,24.6,22,36.8,33c0.3,0.4,0.7,0.9,1,1.3c0,0.4,0,0.7,0,1.1 c-36.1-7.5-72.1-14.9-108.2-22.3c-4.7-1-9.5-1.5-14.3-2.3c-0.1-0.4-0.2-0.8-0.3-1.2C726.5,449.3,736.3,439.8,746.1,430.3z"
          ></path>
          <path
            className="st23"
            d="M839.5,484.7c0-0.4,0-0.7,0-1.1c0.8,0,1.5,0,2.3,0.1C841,484,840.2,484.4,839.5,484.7z"
          ></path>
          <path
            className="st27"
            d="M716,461.9c-0.1-0.8-0.2-1.5-0.2-2.3c0.3-0.3,0.6-0.5,0.9-0.8c0.1,0.4,0.2,0.8,0.3,1.2 C716.7,460.7,716.3,461.3,716,461.9z"
          ></path>
          <path
            className="st0"
            d="M807,443.6c10.8-10.6,21.6-21.3,32.4-31.9c-2.9,9.9-5.8,19.8-8.7,29.7c-3.9,3.8-7.8,7.6-11.7,11.4 C815,449.6,811,446.6,807,443.6z"
          ></path>
          <path
            className="st17"
            d="M819,452.7c3.9-3.8,7.8-7.6,11.7-11.4c-4,11.7-0.7,16.3,12,13.3c1.1-0.3,5.9,6.3,5.1,8.4 c-2.3,6.2-6.4,11.8-9.8,17.7c-5.5-8.3-11-16.6-16.4-25C820.8,454.7,819.9,453.7,819,452.7z"
          ></path>
          <path
            className="st10"
            d="M821.7,455.7c5.5,8.3,11,16.6,16.4,25c0.1,0.6,0.3,1.1,0.4,1.7c-12.3-11-24.6-22-36.8-33 C808.3,451.4,815,453.6,821.7,455.7z"
          ></path>
          <path
            className="st10"
            d="M716.7,458.8c-0.3,0.3-0.6,0.5-0.9,0.8c-0.4,0-0.9,0-1.3,0c-12.8-3-25.5-6.1-38.3-9.1 c21.2-6.6,42.4-13.3,63.6-19.9c1.9-0.3,3.8-0.6,5.7-1c0.2,0.2,0.4,0.5,0.6,0.7C736.3,439.8,726.5,449.3,716.7,458.8z"
          ></path>
          <path
            className="st17"
            d="M664,454.3c1.8-0.9,3.6-1.8,5.3-2.7c1.2-0.3,2.3-0.6,3.5-0.9c-10.1,9.6-20.3,19.1-30.4,28.8 c-12.3,11.8-24.4,23.8-36.6,35.7c-0.5,0.4-1.1,0.8-1.6,1.2c-1.2,0.7-2.5,1.4-3.7,2.1c-1.4,0.5-2.9,1-4.3,1.5 c9.9-9.7,19.8-19.5,29.8-29.2c11.8-11.4,23.6-22.8,35.4-34.2c0,0,0,0,0,0C662.3,455.9,663.1,455.1,664,454.3z"
          ></path>
          <path
            className="st3"
            d="M672.8,450.8c-1.2,0.3-2.3,0.6-3.5,0.9c-6.3,0.6-12.7,1.1-19,1.7c3.9-1.1,7.7-2.1,11.6-3.2 c0.8-0.1,1.6-0.3,2.4-0.4c15.2-4.6,30.3-9.2,45.5-13.9c10-1.8,20-3.5,30.1-5.3c-21.2,6.6-42.4,13.3-63.6,19.9 C675.1,450.6,673.9,450.7,672.8,450.8z"
          ></path>
          <path
            className="st29"
            d="M600.5,518.6c1.2-0.7,2.5-1.4,3.7-2.1C603,517.2,601.7,517.9,600.5,518.6z"
          ></path>
          <path
            className="st18"
            d="M661.8,450.1c-3.9,1.1-7.7,2.1-11.6,3.2c-8.4,2.3-16.9,4.6-25.3,6.9c-0.8-8.5-1.6-17.1-2.4-25.6 c0.1-0.9,0.2-1.7,0.4-2.6C635.9,438.1,648.9,444.1,661.8,450.1z"
          ></path>
          <path
            className="st12"
            d="M622.6,434.6c0.8,8.5,1.6,17.1,2.4,25.6c-6.9,4.6-13.9,9.2-20.8,13.8C610.3,460.9,616.5,447.8,622.6,434.6z"
          ></path>
          <path
            className="st16"
            d="M664,454.3c-0.9,0.8-1.7,1.6-2.6,2.4C662.3,455.9,663.1,455.1,664,454.3z"
          ></path>
          <path
            className="st21"
            d="M597.4,479.8c0.5-0.6,1-1.2,1.6-1.8C598.5,478.7,597.9,479.3,597.4,479.8z"
          ></path>
          <path
            className="st21"
            d="M600,477.4c0.4-0.4,0.8-0.7,1.2-1.1C600.9,476.6,600.4,477,600,477.4z"
          ></path>
          <path
            className="st21"
            d="M598.7,478.2c0.5-0.3,1-0.7,1.5-1C599.7,477.5,599.2,477.9,598.7,478.2z"
          ></path>
          <path
            className="st3"
            d="M821.7,455.7c-6.7-2.1-13.3-4.2-20-6.3c-1.1-0.3-2.1-0.6-3.2-0.9c-1-0.2-1.9-0.3-2.9-0.5 c-0.5-0.1-0.9-0.2-1.4-0.3c-1.2-0.5-2.5-0.9-3.7-1.4c-1-0.3-1.9-0.6-2.9-0.9c-4.1-1.4-8.2-2.9-12.4-4.3c-1.8-0.3-3.7-0.6-5.5-0.8 c-0.8-1.5-1.7-3-2.5-4.6c0.5,0,1-0.1,1.5-0.1c12.8,2.7,25.6,5.4,38.4,8.1c4,3,8,6.1,12,9.1C819.9,453.7,820.8,454.7,821.7,455.7z"
          ></path>
          <path
            className="st10"
            d="M767.1,435.6c0.8,1.5,1.7,3,2.5,4.6c-6.3-3-12.7-6-19-8.9c1.9,0.4,3.8,0.9,5.7,1.4 C760,433.6,763.6,434.6,767.1,435.6z"
          ></path>
          <path
            className="st6"
            d="M739.8,430.6c-10,1.8-20,3.5-30.1,5.3c6.3-13.1,12.6-26.2,18.9-39.3c0.3-0.2,0.7-0.5,1-0.7 c5.3,11.3,10.6,22.6,15.9,33.8C743.6,430,741.7,430.3,739.8,430.6z"
          ></path>
          <path
            className="st10"
            d="M748.7,431.1c-0.5-0.2-1-0.5-1.4-0.7C747.7,430.7,748.2,430.9,748.7,431.1z"
          ></path>
          <path
            className="st12"
            d="M728.6,396.5c-6.3,13.1-12.6,26.2-18.9,39.3c-15.2,4.6-30.3,9.2-45.5,13.9c7-6.3,13.8-13,21.1-19 C699.6,419.1,714.2,407.9,728.6,396.5z"
          ></path>
          <path
            className="st25"
            d="M435.6,342.6c0.5,0,0.9-0.1,1.4-0.1c-0.4,10.3-0.8,20.6-1.3,31c-0.3-9.9-0.6-19.9-0.9-29.8 C435.1,343.3,435.4,342.9,435.6,342.6z"
          ></path>
          <path
            className="st25"
            d="M438.8,396.4c-1-7-2-14-2.9-21C436.9,382.5,437.8,389.4,438.8,396.4z"
          ></path>
          <path
            className="st26"
            d="M430.5,287.2c4,8.2,8,16.5,12,24.7c-0.9,2.8-1.9,5.6-2.8,8.4c-1.5,4.7-3,9.4-4.5,14.1 c-1.4-5.3-3.7-10.6-4.1-16C430.3,308.1,430.6,297.6,430.5,287.2z"
          ></path>
          <path
            className="st26"
            d="M435.6,342.6c-0.3,0.3-0.5,0.7-0.8,1c0.2-0.6,0.5-1.2,0.7-1.9l0,0C435.6,342,435.6,342.3,435.6,342.6z"
          ></path>
          <path
            className="st26"
            d="M435.6,341.7C435.6,341.7,435.6,341.7,435.6,341.7L435.6,341.7z"
          ></path>
          <path
            className="st27"
            d="M462.6,337.6c9.5,12.6,19,25.1,28.5,37.7C481.6,362.7,472.1,350.1,462.6,337.6z"
          ></path>
          <path
            className="st27"
            d="M450.6,321.4c1.3,1.6,2.5,3.3,3.8,4.9C453.1,324.7,451.8,323.1,450.6,321.4z"
          ></path>
          <path
            className="st27"
            d="M458.4,331.8c1.2,1.7,2.3,3.4,3.5,5C460.8,335.2,459.6,333.5,458.4,331.8z"
          ></path>
          <path
            className="st27"
            d="M455,327.2c0.9,1.2,1.8,2.4,2.7,3.5C456.8,329.6,455.9,328.4,455,327.2z"
          ></path>
          <path
            className="st27"
            d="M448.5,318.7c0.6,0.8,1.1,1.6,1.7,2.4C449.6,320.3,449.1,319.5,448.5,318.7z"
          ></path>
          <path
            className="st27"
            d="M457.7,330.7c0.3,0.3,0.5,0.6,0.8,0.9C458.2,331.4,457.9,331,457.7,330.7z"
          ></path>
          <path
            className="st10"
            d="M346.4,361.8c9.1,6.8,18.2,13.6,27.3,20.4C364.6,375.4,355.5,368.6,346.4,361.8z"
          ></path>
          <path
            className="st10"
            d="M343,359c1.2,0.9,2.3,1.9,3.5,2.8C345.4,360.9,344.2,360,343,359z"
          ></path>
          <path
            className="st10"
            d="M775.2,441c4.1,1.4,8.2,2.9,12.4,4.3C783.4,443.9,779.3,442.5,775.2,441z"
          ></path>
          <path
            className="st10"
            d="M790.4,446.2c1.2,0.5,2.5,0.9,3.7,1.4C792.9,447.2,791.7,446.7,790.4,446.2z"
          ></path>
          <path
            className="st10"
            d="M795.6,447.9c1,0.2,1.9,0.3,2.9,0.5C797.5,448.3,796.5,448.1,795.6,447.9z"
          ></path>
        </svg>
      </div>
    </section>
  );
}

export default CmsRowCervello;
