import React, { useState, useEffect } from "react";
import Head from "next/head";
import CmsRow from "./CmsRow";
import CmsRowCervello from "./CmsRowCervello";
import CmsFooter from "./CmsFooter";

import dynamic from "next/dynamic";

import Aos from "aos";
import "aos/dist/aos.css";

function CmsPage({ structure }) {
  const CmsMap = dynamic(() => import("./CmsMap"), {
    ssr: false,
  });

  useEffect(() => {
    if (document.readyState !== "loading") {
      applyAos();
    }
  });

  const iubendaImplementation = `
    <script type="text/javascript">
    var _iub = _iub || [];
    _iub.csConfiguration = {"perPurposeConsent":true,"siteId":1552732,"whitelabel":false,"cookiePolicyId":29693700,"lang":"it", "banner":{ "acceptButtonDisplay":true,"backgroundColor":"#5c5d5f","closeButtonRejects":true,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonDisplay":true,"textColor":"#ffffff","usesThirdParties":false }};
    </script>
    <script type="text/javascript" src="//cs.iubenda.com/sync/1552732.js"></script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
  `;

  return (
    <>
      <Head>
        <title>{structure.page.title}</title>
        <meta name="description" content={structure.page.description} />
      </Head>
      <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }}></div>
      <main
        className={`${structure.page.template} cmsPage`}
        id={`${structure.page.id ?? ""}`}
      >
        {structure.rows.map(function (row, i) {
          if (row.id == "riga-cervello") {
            return (
              <div key={i}>
                <CmsRowCervello row={row} page_id={structure.page.id} />
              </div>
            );
          } else {
            return <CmsRow row={row} page_id={structure.page.id} key={i} />;
          }
        })}
      </main>
      <CmsFooter structure={structure} />
    </>
  );
}

function applyAos() {
  // console.log("AOS");
  /* HOMEPAGE */
  applyAosToElement("#image-1663316918", "fade-right");
  applyAosToElement("#text-1663254855", "fade-left");
  applyAosToElement("#text-1663313719", "fade-up");
  applyAosToElement("#text-1663313737", "fade-right");
  applyDelayToElement("#text-1663313737", 300);
  applyAosToElement("#text-1663313739", "fade-left");
  applyDelayToElement("#text-1663313739", 500);
  applyAosToElement("#link-1663319695", "fade-left");
  applyDelayToElement("#text-1663313739", 700);

  applyAosToElement("#text-1663319960", "fade-right");

  applyAosToElement("#text-1663320593", "fade-up");
  applyAosToElement("#text-1663320604", "fade-right");
  applyDelayToElement("#text-1663320604", 300);
  applyAosToElement("#text-1663320605", "fade-left");
  applyDelayToElement("#text-1663320605", 500);
  applyAosToElement("#link-1663320723", "fade-left");
  applyDelayToElement("#text-1663320723", 700);

  /* AGENZIA */
  applyAosToElement("#page-146 .intro-image-component .caption", "fade-right");
  applyAosToElement("#text-1663344264", "fade-up");
  applyAosToElement("#text-1663570686", "fade-right");
  applyAosToElementByIndex("#agenzia-titolone", 0, "fade-left");
  applyAosToElementByIndex("#agenzia-titolone", 1, "fade-right");
  applyAosToElement("#text-1663572046", "fade-left");
  applyAosToElement("#text-1663572062", "fade-right");
  applyAosToElementByIndex("#agenzia-titolone", 2, "fade-left");

  /* SERVIZI */
  applyAosToElement("#text-1663574199", "fade-up");

  /* CONTATTI */
  applyAosToElement("#text-1663576855", "fade-up");
  applyAosToElement("#text-1663576864", "fade-right");
  applyDelayToElement("#text-1663576864", 300);
  applyAosToElement("#text-1663576866", "fade-left");
  applyDelayToElement("#text-1663576866", 500);

  Aos.init();
}

function applyAosToElement(selector, effect) {
  if (document.querySelector(selector)) {
    document.querySelector(selector).setAttribute("data-aos", effect);
  }
}

function applyDelayToElement(selector, effect) {
  if (document.querySelector(selector)) {
    document.querySelector(selector).setAttribute("data-aos-delay", effect);
  }
}

function applyAosToElementByIndex(selector, index, effect) {
  if (document.querySelectorAll(selector)[index]) {
    document.querySelectorAll(selector)[index].setAttribute("data-aos", effect);
  }
}

export default CmsPage;
