import React, { useState, useEffect } from "react";

import styles from './Hamburger.module.scss'

function Hamburger({}) {
    // const [isActive, setIsActive] = useState(0);

    function toogleBurger(e) {
        // setIsActive(!isActive)
        if(!document.querySelector('button.burger').classList.contains('menu_open')) {
            document.body.classList.add('mobileMenuOpened');
            document.querySelector("body, html").classList.add("no-scroll");
            document.querySelector('button.burger').classList.add("menu_open")
        } else {
            document.body.classList.remove('mobileMenuOpened');
            document.querySelector("body, html").classList.remove("no-scroll");
            document.querySelector('button.burger').classList.remove("menu_open")
        }
    }

    useEffect(() => {
        // toogleBurger();
    })

  return (
    <button onClick={toogleBurger} className={`btn_menu burger`} type="button">
        <i className={`btn_menu__bars`} aria-hidden="true"></i>
    </button>
  );
}

export default Hamburger;
