import React, { useState, useEffect } from "react";
import Link from "next/link";

import Logo from "./Logo";
import { NavLinkMobile } from "./NavLinkMobile";
import Hamburger from "./Hamburger";
import Image from "next/image";

function NavBarMobile({ navigation }) {
  const [show, setShow] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setShow(false);
        } else {
          setShow(true);
        }
        setLastScrollY(window.scrollY);

        if (window.scrollY > 0) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("load", controlNavbar);
      window.addEventListener("scroll", controlNavbar);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <>
      <nav
        className={`mainNavMobile ${!show ? "navHidden" : ""} ${
          !isTop ? "floating" : ""
        }`}
      >
        <div className="flex h-full w-full justify-between items-center">
          <Link href="/">
            <a className="Logo">
              <Logo />
            </a>
          </Link>
          <Hamburger />
        </div>
      </nav>
      {/* <div className="mobileCanvasOverlay"></div> */}
      <div className="mobileOffCanvas">
        <div className="mobileMenu">
          <div className="mobileMenuContainer">
            <div>
              <a className="MobileLogo">
                <Image
                  alt=""
                  src="/images/logo_big.png"
                  layout="intrinsic"
                  width="501"
                  height="71"
                  priority={true}
                />
              </a>
              {typeof navigation !== "undefined" && navigation.length > 0 ? (
                <ul className={`navBar`}>
                  {navigation.map(function (link, i) {
                    return (
                      <li key={i}>
                        <NavLinkMobile link={link} exact mobile={true} />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBarMobile;
